import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useParams } from "react-router-dom";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

import Loading from "../components/loading";
import { CatalogItem } from "../external";
import CatalogItemComponent from "../components/catalog/catalog-item";

const createCatalogWorker = createWorkerFactory(
  () => import("../workers/catalog")
);

const CatalogItemPublicPage = (): JSX.Element => {
  const { itemId } = useParams();
  const [catalogItem, setCatalogItem] = useState<CatalogItem>();
  const [catalogItemLoaded, setCatalogItemLoaded] = useState<boolean>(false);
  const [catalogLoadError, setCatalogLoadError] = useState<string>();

  const catalogWorker = useWorker(createCatalogWorker);

  useEffect(() => {
    (async () => {
      try {
        const catalogItemResponse = await catalogWorker.getCatalogItemPublic(
          itemId!
        );
        setCatalogItem(catalogItemResponse);
        setCatalogItemLoaded(true);
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 404) {
            setCatalogLoadError(`Item not found with ID ${itemId}`);
          }
        } else if (e instanceof Error) {
          setCatalogLoadError(`Error occurred loading item - ${e.message}`);
        } else {
          setCatalogLoadError(`Error occurred loading item.`);
        }
      }
    })();
  }, [catalogWorker, itemId]);

  if (catalogLoadError) {
    return (
      <Container>
        <Alert variant="danger">{catalogLoadError}</Alert>
      </Container>
    );
  }

  if (!catalogItemLoaded) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          {catalogItemLoaded && catalogItem ? (
            <CatalogItemComponent
              catalogItem={catalogItem}
              restrictForm={true}
            ></CatalogItemComponent>
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CatalogItemPublicPage;
