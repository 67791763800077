import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import CloseButton from "react-bootstrap/CloseButton";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Badge from "react-bootstrap/Badge";

import {
  CatalogItem,
  CatalogItemExternalAttributesWithId,
  Client,
  ItemDimensionsRequired,
} from "../../external";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { PlusCircleFill } from "react-bootstrap-icons";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import { useDropzone } from "react-dropzone";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import Decimal from "decimal.js";
import { convertToPNG, fileToBase64 } from "../../helpers/convertToPNG";

import { Task } from "../../external/task-component/openapi";
import EbayComponent from "../ebay/ebay-category";
import BoxDimensionRowComponent from "./catalog-item-parts/box-dimension-row";
import DimensionsComponent from "./catalog-item-parts/dimensions";
import SaveStatusAlertComponent from "./catalog-item-parts/save-status-alert";
import AuditHistoryComponent from "./catalog-item-parts/audit-history";
import MultiStringEntryComponent from "./catalog-item-parts/multi-string-entry";
import CatalogItemImageCarousel from "./catalog-item-parts/image-carousel";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { calculateCubeFromArrayOfBoxDims } from "../../helpers/calculateCube";
import CatalogItemIdentifiersComponent from "./catalog-item-parts/identifiers";
import {
  CloseGlobalSidebarEvent,
  OpenGlobalSidebarEvent,
} from "../globaloffcanvas/events";
import { uploadImageFromUrl } from "../../helpers/uploadImageFromUrl";
import CatalogItemDescription from "./catalog-item-parts/description";
import SelectClient from "../clients/select-client";
import { CatalogItemCreation } from "../../pages/catalog/new-catalog";

const boxCubeWarning = 5;
const boxCubeBlock = 20;
const warningWeight = 199;
const offCanvasSize = 12;

export interface CatalogItemComponentProps {
  catalogItem: CatalogItem | CatalogItemCreation;
  restrictForm?: boolean;
  persist?: (
    catalogItem: CatalogItem | CatalogItemCreation
  ) => Promise<AxiosResponse<CatalogItem>>;
  offCanvas?: boolean;
  persistTask?: (
    taskItem: CatalogItem | CatalogItemCreation,
    title: string,
    description: string
  ) => Promise<AxiosResponse<Task>>;
  isCreationMode?: boolean;
  isCatalogCreateRequest?: boolean;
}

const createCatalogItemsWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

const CatalogItemComponent = ({
  catalogItem: initialCatalogItem,
  restrictForm = false,
  offCanvas = false,
  persist,
  persistTask,
  isCreationMode = false,
  isCatalogCreateRequest = false,
}: CatalogItemComponentProps): JSX.Element => {
  const handleRemoveBoxDimension = (rowId: number) => {
    if (boxDimensions !== undefined) {
      const updatedRows = boxDimensions.filter((row, index) => index !== rowId);
      setBoxDimensions(updatedRows);
    }
  };

  const handleUpdateBoxDimension = (
    rowId: number,
    updatedData: ItemDimensionsRequired
  ) => {
    if (!showBoxDimsMultiBoxes) {
      setBoxDimensions([updatedData]);
    } else {
      if (boxDimensions !== undefined) {
        const updatedRows = boxDimensions.map((row, index) => {
          if (index === rowId) {
            return { ...row, ...updatedData };
          }
          return row;
        });

        setBoxDimensions(updatedRows);
      }
    }
  };

  const handleTextboxChange = (event: {
    target: { value: any; name: string };
  }) => {
    const typedValue = event.target.value;
    const selectedName = event.target.name;
    if (selectedName === "ebayCategory1") {
      setEbayCategoryOneValue(typedValue);
    }

    if (selectedName === "ebayCategory2") {
      setEbayCategoryTwoValue(typedValue);
    }
    setFormDirty(true);
  };

  const handleEbayDropdownChangeOne = (value: string) => {
    setEbayCategoryOneValue(value);
    setFormDirty(true);
  };

  const handleEbayDropdownChangeTwo = (value: string) => {
    setEbayCategoryTwoValue(value);
    setFormDirty(true);
  };

  const navigate = useNavigate();

  const [viewOnly, setViewOnly] = useState<boolean>(restrictForm);
  const [showRejections, setShowRejections] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDocumentUploadModal, setShowDocumentUploadModal] =
    useState<boolean>(false);
  const [showImageFromUrlModal, setShowImageFromUrlModal] =
    useState<boolean>(false);
  const [getAllImagesFromUrlModal, setGetAllImagesFromUrlModal] =
    useState<boolean>(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<string>();
  const [showDocumentDeleteModal, setShowDocumentDeleteModal] =
    useState<boolean>(false);

  const [userToken, setUserToken] = useState<string>();
  const [scopes, setScopes] = useState<string[]>();
  const [catalogItem, setCatalogItem] = useState<
    CatalogItem | CatalogItemCreation
  >(initialCatalogItem);

  const [showBoxDimsOrBoxCount, setShowBoxDimsOrBoxCount] =
    useState<boolean>(true);

  const [showBoxDimsMultiBoxes, setShowBoxDimsMultiBoxes] =
    useState<boolean>(true);
  const [addBox, setAddBox] = useState<boolean>(false);
  const [boxDimensions, setBoxDimensions] = useState(
    catalogItem.dimensions?.boxes
  );
  const [numberOfDuplicateBoxDims, setNumberOfDuplicateBoxDims] =
    useState<number>(0);

  const [clientSkuHyperLinks, setClientSkuHyperLinks] = useState<string[]>(
    catalogItem.clientSkuHyperlinks
  );
  const [clientImageLinks, setClientImageLinksState] = useState<string[]>(
    catalogItem.clientImageLinks
  );
  const [clientImageLinksLength, setClientImageLinksLengthState] =
    useState<number>(0);

  const [ebayCategoryOneValue, setEbayCategoryOneValue] = useState(
    catalogItem.ebayCategory?.[0] || ""
  );

  const [ebayCategoryTwoValue, setEbayCategoryTwoValue] = useState(
    catalogItem.ebayCategory?.[1] || ""
  );

  const [tempAttachedFilesLinks, setTempAttachedFilesLinksState] = useState<
    string[]
  >([]);
  const [attachedFilesLinks, setAttachedFilesLinksState] = useState<string[]>(
    []
  );
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showSaveSuccessMessage, setShowSaveSuccessMessage] =
    useState<boolean>(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState<string>("");
  const [saveError, setSaveError] = useState<string | undefined>(undefined);
  const [showSaveError, setShowSaveError] = useState<boolean>(false);
  const [urlForImageValue, setUrlForImageValue] = useState("");
  const [urlForWebsiteValue, setUrlForWebsiteValue] = useState("");
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [clientId, setClientId] = useState("");
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogItemsWorker = useWorker(createCatalogItemsWorker);

  // set default values once (or when reset on purpose)
  const [shouldReset, setShouldReset] = useState(true);

  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { touchedFields, errors, isDirty, isValid },
  } = useForm<Omit<CatalogItem, "latestRawClientData" | "extraData">>({
    mode: "onChange",
    defaultValues: {
      ...catalogItem,
      retailPricePence: catalogItem.retailPricePence
        ? new Decimal(catalogItem.retailPricePence).dividedBy(100).toNumber()
        : 0 // the form shows the value in pounds so we divide by a 100
        ? catalogItem.retailPricePence
        : undefined,
      costPricePence: catalogItem.costPricePence
        ? new Decimal(catalogItem.costPricePence).dividedBy(100).toNumber()
        : 0, // the form shows the value in pounds so we divide by a 100
      weightGrams: catalogItem.weightGrams
        ? new Decimal(catalogItem.weightGrams).dividedBy(1_000).toNumber()
        : 0 // the form shows the value in KG so we divide by 1000
        ? catalogItem.weightGrams
        : undefined,
    },
  });

  const [recommendedRetailPricePence, setRecommendedRetailPricePence] =
    useState<number | undefined>(catalogItem.retailPricePence);

  const [costPricePenceState, setCostPricePence] = useState<number | undefined>(
    catalogItem.costPricePence
  );

  useEffect(() => {
    if (selectedClient) {
      setClientId(selectedClient.clientId);
      setValue("clientId", selectedClient.clientId);
      catalogItem.clientId = clientId;
    }
  }, [selectedClient, clientId, catalogItem.clientId, catalogItem, setValue]);

  const boxCountValue = watch("boxCount");

  useEffect(() => {
    if (catalogItem.clientId !== undefined) {
      trigger("clientId");
    }
  }, [catalogItem.clientId, trigger]);

  useEffect(() => {
    if (catalogItem.clientItemId !== undefined) {
      trigger("clientItemId");
    }
  }, [catalogItem.clientItemId, trigger]);

  useEffect(() => {
    if (catalogItem.name !== undefined) {
      trigger("name");
    }
  }, [catalogItem.name, trigger]);

  const handleOnCurrencyValueChange: CurrencyInputProps["onValueChange"] = (
    value,
    valueName
  ): void => {
    if (!value) {
      if (valueName === "retailPricePence") {
        setRecommendedRetailPricePence(0);
      } else if (valueName === "costPricePence") setCostPricePence(0);
      return;
    }
    const valueChanged = new Decimal(value);

    const multipliedValue = valueChanged.times(100);

    const valueAsNumber = Number(multipliedValue.toString());

    if (valueName === "retailPricePence") {
      setRecommendedRetailPricePence(valueAsNumber);
    } else if (valueName === "costPricePence") setCostPricePence(valueAsNumber);
  };

  const deleteAttachment = async (file: string): Promise<void> => {
    if (!userToken) {
      throw new Error("User token not available");
    }

    const parts = file.split("/");

    const uploadType = parts[0];
    const fileName = parts[1];

    try {
      await catalogItemsWorker.deleteCatalogItemFile(
        userToken,
        catalogItem.clientId,
        catalogItem.itemId as string,
        fileName,
        uploadType
      );

      setAttachedFilesLinksState(
        attachedFilesLinks.filter((attached) => attached !== fileName)
      );
    } catch (e) {
      setIsDeleting(false);
      throw new Error("Error deleting file");
    }
  };

  const handleUrlForImageScrapingValue = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setUrlForWebsiteValue(event.target.value);
  };

  const handleUrlForImageValue = (event: ChangeEvent<HTMLInputElement>) => {
    setUrlForImageValue(event.target.value);
  };

  const handleUrlForScrapingImagesButtonClick = async () => {
    if (catalogItem.itemId !== undefined) {
      setGetAllImagesFromUrlModal(false);
      window.dispatchEvent(CloseGlobalSidebarEvent());
      window.dispatchEvent(
        OpenGlobalSidebarEvent("fetch-images", catalogItem.itemId, {
          url: urlForWebsiteValue,
          clientId: catalogItem.clientId,
        })
      );
    }
  };

  const handleUrlForImageButtonClick = async () => {
    setIsUploading(true);

    const itemId = catalogItem.itemId;
    if (itemId === undefined) {
      console.warn("Item ID is missing");
      return;
    }

    let url = process.env.REACT_APP_POST_IMAGE_FROM_URL as string;
    url = url.replaceAll(":itemId:", itemId);

    if (url && userToken) {
      try {
        const imageUploaded = await uploadImageFromUrl(
          url,
          urlForImageValue,
          itemId,
          catalogItem.clientId,
          userToken
        );

        if (imageUploaded) {
          setIsUploading(false);
          setShowDocumentUploadModal(false);
          setShouldReset(true);
          navigate(0);
          acceptedFiles = [];
        }
      } catch (e) {
        setIsUploading(false);
        setSaveError("An error occurred while uploading the file.");
        setShowSaveError(true);
        throw new Error("Error uploading file");
      }
    }
  };

  const upload = () => {
    try {
      setIsUploading(true);

      if (!acceptedFiles[0]) {
        throw new Error("bad accepted file");
      }
      const fileReader = new FileReader();

      fileReader.readAsDataURL(acceptedFiles[0]);

      fileReader.onload = async (e) => {
        if (userToken && catalogItem.itemId) {
          // remove the data URL prefix and ensuring the resulting string is properly encoded in Base64 format
          // by using a modulus to determine the remainder when encoded.length is divided by 4.
          // In Base64 encoding, the length of the encoded string should be a multiple of 4.
          let encoded = e
            .target!.result!.toString()
            .replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }

          const convertedFile = await convertToPNG(acceptedFiles[0]);
          const base64String = await fileToBase64(convertedFile);

          catalogItemsWorker
            .postCatalogItemFile(
              userToken,
              catalogItem.clientId,
              catalogItem.itemId,
              convertedFile.name,
              "fromLocalFile",
              base64String
            )
            .then(async (result) => {
              setIsUploading(false);
              setShowDocumentUploadModal(false);
              setShouldReset(true);
              navigate(0);
              acceptedFiles = [];
            });
        }
      };
    } catch (e) {
      setIsUploading(false);
      throw new Error("Error uploading file");
    }
  };

  const weightGrams = useWatch({
    control,
    name: "weightGrams",
    defaultValue: 0,
  });

  let { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      accept: {
        "text/csv": [".csv"],
      },
      maxFiles: 1,
    });

  const prepareData = async (
    formValues: CatalogItem
  ): Promise<CatalogItem | CatalogItemCreation | false> => {
    if (
      formValues.dimensions?.product?.height &&
      typeof formValues.dimensions.product.height === "string"
    ) {
      formValues.dimensions.product.height = parseFloat(
        formValues.dimensions.product.height
      );
    }

    if (
      formValues.dimensions?.product?.width &&
      typeof formValues.dimensions.product.width === "string"
    ) {
      formValues.dimensions.product.width = parseFloat(
        formValues.dimensions.product.width
      );
    }
    if (
      formValues.dimensions?.product?.length &&
      typeof formValues.dimensions.product.length === "string"
    ) {
      formValues.dimensions.product.length = parseFloat(
        formValues.dimensions.product.length
      );
    } else if (
      formValues.dimensions &&
      formValues.dimensions.product &&
      formValues.dimensions.product.length === ""
    ) {
      formValues.dimensions.product.length = "";
    }

    let updatedBoxDimensions: ItemDimensionsRequired[] = [];

    if (!showBoxDimsMultiBoxes && boxDimensions) {
      const box = boxDimensions[0] as ItemDimensionsRequired;

      if (numberOfDuplicateBoxDims < 1) {
        console.warn("Box Dimensions are invalid (numberOfDuplicateBoxDims)");
        return false;
      }

      for (let i = 0; i < numberOfDuplicateBoxDims; i++) {
        updatedBoxDimensions.push({ ...box });
      }
    } else {
      if (boxDimensions === undefined) {
        updatedBoxDimensions = [];
      } else {
        updatedBoxDimensions = boxDimensions as ItemDimensionsRequired[];
      }
    }

    if (updatedBoxDimensions.length > 0) {
      for (const box of updatedBoxDimensions) {
        for (const key of ["height", "width", "length"]) {
          const value = box[key as keyof ItemDimensionsRequired];
          if (
            (typeof value === "string" && value.trim() === "") ||
            (typeof value === "number" && value < 0.0001)
          ) {
            console.warn("Box Dimensions are invalid");
            return false;
          }
        }
      }
    }

    if (ebayCategoryOneValue) {
      formValues.ebayCategory = [ebayCategoryOneValue];
    }

    if (ebayCategoryTwoValue) {
      formValues.ebayCategory = [ebayCategoryOneValue, ebayCategoryTwoValue];
    }

    if (Array.isArray(formValues.ebayCategory)) {
      formValues.ebayCategory = formValues.ebayCategory.filter(
        (category) => category !== undefined
      );
    }

    let clientItemId = catalogItem.clientItemId;
    let clientId = catalogItem.clientId;

    if (isCreationMode) {
      if (selectedClient) {
        clientId = selectedClient?.clientId;
      }
      if (formValues.clientItemId) {
        clientItemId = formValues.clientItemId;
      }

      if (typeof formValues === "object" && formValues !== null) {
        delete (formValues as Partial<typeof formValues>).itemId;
      }
    }

    if (!clientId) {
      console.warn("ClientID is missing from form value");
      return false;
    }

    if (!clientItemId) {
      console.warn("Client Item ID is missing from form value");
      return false;
    }

    const data: CatalogItemExternalAttributesWithId = {
      ...formValues,
      clientImageLinks: clientImageLinks,
      dimensions: {
        boxes: updatedBoxDimensions,
        product: formValues.dimensions?.product,
      },
      weightGrams: formValues.weightGrams ? formValues.weightGrams * 1000 : 0,
      itemId: catalogItem.itemId!,
      clientItemId,
      clientId,
      clientSkuHyperlinks: clientSkuHyperLinks,
      retailPricePence: recommendedRetailPricePence
        ? recommendedRetailPricePence
        : 0,
      costPricePence: costPricePenceState ? costPricePenceState : 0,
      boxCount:
        updatedBoxDimensions.length > 0
          ? undefined
          : formValues.boxCount || undefined,
      ebayCategory: formValues.ebayCategory,
      // Items in creation cannot be set as suitable for listing or archived
      suitableForListing: isCreationMode
        ? false
        : formValues.suitableForListing,
      archived: isCreationMode ? false : formValues.archived,
    };

    if (isCreationMode) {
      delete (data as CatalogItemCreation).itemId;
    }

    return data as CatalogItem;
  };

  const onSubmitTask = async () => {
    // Trigger validation to ensure the form is valid
    trigger();
    if (!isValid) {
      console.warn("Task Form is invalid!");
      console.warn(errors);
      return;
    }

    const formValues = getValues();

    const data: CatalogItem | CatalogItemCreation | boolean = await prepareData(
      formValues
    );

    if (data === false) {
      console.warn("Task Form is invalid");
      return;
    }

    setSaveSuccessMessage("");
    setSaveError(undefined);
    setIsSaving(true);
    setShowSaveSuccessMessage(false);

    if (!persistTask) {
      throw new Error("No persistTask function is available");
    }

    const persistResult = await persistTask(
      data,
      "Catalogue Item Change Request",
      "A change request has been submitted for a Catalogue Item."
    );

    if (persistResult.status >= 200 && persistResult.status <= 299) {
      setSaveSuccessMessage(
        "You have successfully submitted a Change Request. Please wait for the request to be actioned."
      );
      setCatalogItem(data);
      setBoxDimensions(data.dimensions?.boxes);
      setShouldReset(true);
      setShowSaveSuccessMessage(true);
      setIsSaving(false);
      setFormDirty(false);
    } else {
      console.error(JSON.stringify(persistResult, null, 2));
      setSaveError(JSON.stringify(persistResult.data));
    }
  };

  const onSubmitCatalogue = async () => {
    // Trigger validation to ensure the form is valid
    trigger();
    if (!isValid) {
      console.warn("Catalogue Form is invalid!");
      console.warn("errors", errors);

      const errorFieldNames = Object.keys(errors).join(", ");
      setSaveError(`The following fields are required: ${errorFieldNames}`);
      setShowSaveError(true);
      setTimeout(() => {
        setSaveError(undefined);
        setShowSaveError(false);
      }, 3000);

      return;
    }

    const formValues = getValues();

    const data: CatalogItem | CatalogItemCreation | boolean = await prepareData(
      formValues
    );

    if (data === false) {
      console.warn("Catalogue Form is invalid!");
      return;
    }

    setSaveSuccessMessage("");
    setShowSaveError(false);
    setSaveError(undefined);
    setIsSaving(true);
    setShowSaveSuccessMessage(false);

    if (!persist) {
      throw new Error("No persist function is available");
    }

    try {
      const persistResult = await persist(data);

      if (persistResult.status >= 200 && persistResult.status <= 299) {
        setSaveSuccessMessage(
          "Catalogue Item has been saved. The Data is all up to date."
        );
        setShowSaveSuccessMessage(true);
        setTimeout(() => {
          setSaveSuccessMessage("");
          setShowSaveSuccessMessage(false);
        }, 3000);
        setCatalogItem(data);
        setBoxDimensions(data.dimensions?.boxes);
        setShouldReset(true);
        setIsSaving(false);
        setFormDirty(false);
      } else {
        console.error(JSON.stringify(persistResult, null, 2));
        setSaveError(JSON.stringify(persistResult.data));
        setIsSaving(false);
      }
    } catch (e) {
      setSaveError((e as any).response.data.message);
      setIsSaving(false);
    }
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const onSelectedImageIndex = (num: number) => {
    setSelectedImageIndex(num);
  };

  useEffect(() => {
    if (
      !catalogItem.dimensions?.boxes ||
      catalogItem.dimensions.boxes.length === 0
    ) {
      setShowBoxDimsOrBoxCount(true);
    } else {
      setShowBoxDimsOrBoxCount(false);
    }
  }, [catalogItem.dimensions?.boxes]);

  useEffect(() => {
    if (addBox) {
      const tempBox: ItemDimensionsRequired = {
        width: 0,
        length: 0,
        height: 0,
      };

      const updatedBoxDimensions =
        boxDimensions !== undefined ? [...boxDimensions, tempBox] : [tempBox];

      setBoxDimensions(updatedBoxDimensions);
      setAddBox(false);
    }
  }, [addBox, boxDimensions]);

  useEffect(() => {
    const boxesDirty = boxDimensions !== catalogItem.dimensions?.boxes;

    if (boxesDirty && !isDirty) {
      setFormDirty(true);
    }
  }, [catalogItem.dimensions?.boxes, boxDimensions, isDirty]);

  useEffect(() => {
    if (numberOfDuplicateBoxDims && !isDirty) {
      setFormDirty(true);
    }
  }, [numberOfDuplicateBoxDims, isDirty]);

  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      setShowRejections(true);
    }
  }, [fileRejections]);

  useEffect(() => {
    const imagesDirty = clientImageLinks !== catalogItem.clientImageLinks;
    setFormDirty(imagesDirty || isDirty);
  }, [catalogItem.clientImageLinks, clientImageLinks, isDirty]);

  useEffect(() => {
    setClientImageLinksLengthState(catalogItem.clientImageLinks.length);
  }, [catalogItem.clientImageLinks]);

  useEffect(() => {
    if (!catalogItem.attachedFiles) {
      setAttachedFilesLinksState([]);
      return;
    }
    const attachmentLinks = catalogItem.attachedFiles.map(
      (file) => file.filePath
    );
    setAttachedFilesLinksState(attachmentLinks);
  }, [catalogItem.attachedFiles]);

  useEffect(() => {
    const skuLinksDirty =
      clientSkuHyperLinks !== catalogItem.clientSkuHyperlinks;

    if (skuLinksDirty && !isDirty) {
      setFormDirty(true);
    }
  }, [catalogItem.clientSkuHyperlinks, clientSkuHyperLinks, isDirty]);

  useEffect(() => {
    if (shouldReset) {
      reset({
        ...catalogItem,
        weightGrams: catalogItem.weightGrams
          ? catalogItem.weightGrams / 1000
          : undefined,
      });

      setShouldReset(false);
    }
  }, [catalogItem, reset, shouldReset]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }

    if (userAuthenticatedContext.scopes) {
      setScopes(userAuthenticatedContext.scopes);

      if (!restrictForm) {
        setViewOnly(
          !(
            userAuthenticatedContext.scopes.includes("edit:catalogue-item") ||
            userAuthenticatedContext.scopes.includes(
              "create:task-item:change-request"
            )
          )
        );
      }
    }
  }, [userAuthenticatedContext, restrictForm]);

  return (
    <Container>
      {!viewOnly &&
      boxDimensions !== undefined &&
      calculateCubeFromArrayOfBoxDims(boxDimensions) >= boxCubeBlock ? (
        <Container>
          <Alert
            variant="danger"
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            This is a very large Item meaning updating or creating a Change
            Request has been blocked. Please ensure the Total Box Dimensions
            (m³) is less than {boxCubeBlock}
          </Alert>
        </Container>
      ) : !viewOnly ? (
        <Container>
          <SaveStatusAlertComponent
            isDirty={formDirty}
            isSaving={isSaving}
            showSaveSuccessMessage={showSaveSuccessMessage}
            submitCatalogue={onSubmitCatalogue}
            submitTask={onSubmitTask}
            saveError={saveError}
            scopes={scopes}
            successMessage={saveSuccessMessage}
          />
        </Container>
      ) : null}
      <Row>
        <Col>
          <Container>
            <h5>Images</h5>
            <Row sm={1} md={viewOnly ? 1 : 2}>
              <Col>
                <CatalogItemImageCarousel
                  catalogItem={catalogItem as CatalogItem}
                  viewOnly={viewOnly}
                  clientImageLinks={clientImageLinks}
                  attachedFilesLinks={attachedFilesLinks}
                  selectedImageIndex={selectedImageIndex}
                  onSelectedImageIndex={onSelectedImageIndex}
                />
              </Col>
              {!viewOnly ? imageThumbnailsAndUploads() : null}
              {!viewOnly ? (
                <Row>
                  <Col>
                    <ButtonGroup>
                      <Button
                        variant="success"
                        disabled={isCreationMode || isDirty}
                        onClick={() => {
                          setShowDocumentUploadModal(true);
                        }}
                      >
                        Upload Image
                      </Button>
                      <Button
                        variant="info"
                        disabled={isCreationMode || isDirty}
                        onClick={() => {
                          setShowImageFromUrlModal(true);
                        }}
                      >
                        Import from Image URL
                      </Button>
                      <Button
                        variant="warning"
                        disabled={isCreationMode || isDirty}
                        onClick={() => {
                          setGetAllImagesFromUrlModal(true);
                        }}
                      >
                        Scan for Images from URL
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              ) : null}
            </Row>
            <hr />
          </Container>
          <Container>
            <Form onSubmit={onSubmitCatalogue}>
              <Form.Group className="mb-3">
                <MultiStringEntryComponent<CatalogItem>
                  objectKey={"clientSkuHyperlinks"}
                  entries={clientSkuHyperLinks}
                  title={"External/Client Links"}
                  singleEntryTitle="Link"
                  setValueMethod={setClientSkuHyperLinks}
                  register={register}
                  viewOnly={viewOnly}
                  isSaving={isSaving}
                  isURLList={true}
                  required={false}
                />
              </Form.Group>
              <hr />
              <Row>
                <Col sm={offCanvas ? offCanvasSize : 4}>
                  {!isCreationMode ? (
                    <Form.Group className="mb-3">
                      <Form.Label>Client ID</Form.Label>
                      <Form.Control
                        {...register("clientId", {
                          disabled: true,
                        })}
                      />
                    </Form.Group>
                  ) : (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Client ID{" "}
                          {isCreationMode ? (
                            <Badge bg="danger">Required</Badge>
                          ) : null}
                        </Form.Label>
                        <SelectClient
                          selectedClient={selectedClient}
                          setSelectedClient={setSelectedClient}
                          setFormDirty={setFormDirty}
                          clients={[]}
                        />
                      </Form.Group>
                    </>
                  )}
                </Col>
                <Col sm={offCanvas ? offCanvasSize : 4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Client SKU/Item ID{" "}
                      {isCreationMode ? (
                        <Badge bg="danger">Required</Badge>
                      ) : null}
                    </Form.Label>
                    <Form.Control
                      {...register("clientItemId", {
                        disabled: !isCreationMode,
                        minLength: {
                          message:
                            "The Client SKU/Item Id needs to be at least 10 characters in length.",
                          value: 10,
                        },
                        required: isCreationMode,
                      })}
                      isValid={!errors.clientItemId?.message}
                      isInvalid={errors.clientItemId?.message !== undefined}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.clientItemId?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {!isCreationMode ? (
                  <Col sm={offCanvas ? offCanvasSize : 4}>
                    <Form.Group className="mb-3">
                      <Form.Label>ClearCycle Item ID</Form.Label>
                      <Form.Control
                        {...register("itemId", {
                          disabled: true,
                        })}
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                <Col sm={offCanvas ? offCanvasSize : 4}>
                  <Form.Group className="mb-3">
                    <Form.Label>SKU Override</Form.Label>
                    <Form.Control
                      as={"input"}
                      {...register("clearCycleSkuOverride", {
                        disabled: viewOnly,
                      })}
                    />
                    <Form.Text id={`${catalogItem.itemId}-altsku`} muted>
                      Used for when SKU should be overridden. Often for the
                      purposes of StoreFeeder SKU length limits.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Form.Group className="mb-3">
                <Form.Label>
                  Item Name <Badge bg="danger">Required</Badge>
                </Form.Label>
                <Form.Control
                  {...register("name", {
                    setValueAs: (value) => {
                      if (value === "") {
                        value = undefined;
                      } else {
                        return value;
                      }
                    },
                    minLength: {
                      message:
                        "The name needs to be at least 10 characters in length.",
                      value: 10,
                    },
                    required: true,
                    disabled: viewOnly || isSaving,
                  })}
                  type="text"
                  isValid={!errors.name?.message}
                  isInvalid={errors.name?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>
                  Description <Badge bg="danger">Required</Badge>
                </Form.Label>
                <CatalogItemDescription
                  control={control}
                  isSaving={isSaving}
                  viewOnly={viewOnly}
                  catalogItem={catalogItem as CatalogItem}
                ></CatalogItemDescription>
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
                <Form.Text id={`${catalogItem.itemId}-descriptionhelp`} muted>
                  The description should be 2-3 sentences.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="clientCategory">
                <Form.Label>Client Category</Form.Label>
                <Form.Control
                  {...register("clientCategory", {
                    required: false,
                    disabled: viewOnly || isSaving,
                    setValueAs: (value) => {
                      if (value === "") {
                        value = undefined;
                      } else {
                        return value;
                      }
                    },
                  })}
                  aria-describedby={`${catalogItem.itemId}-product`}
                  isValid={
                    touchedFields.clientCategory &&
                    !errors.clientCategory?.message
                  }
                  isInvalid={errors.clientCategory?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.clientCategory?.message}
                </Form.Control.Feedback>
                <Form.Text id={`${catalogItem.itemId}-product`} muted>
                  Generally this is provided by the original listing/website.
                  Not related to listing category.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Colour</Form.Label>
                <Form.Control
                  {...register("colour", {
                    disabled: viewOnly || isSaving,
                    required: false,
                    setValueAs: (value) => {
                      if (value === "") {
                        value = undefined;
                      } else {
                        return value;
                      }
                    },
                  })}
                  type="text"
                  isValid={touchedFields.colour && !errors.colour?.message}
                  isInvalid={errors.colour?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.colour?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              {!isCreationMode ? (
                <Row className="mb-4">
                  <Col>
                    <h5>Item Options</h5>
                    <Row>
                      <Col>
                        <Form.Check
                          type="switch"
                          {...register("archived", {
                            disabled: viewOnly || isSaving,
                          })}
                          label={"Archived"}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              <hr />
              <h5>Pricing</h5>
              <Row>
                <Col>
                  <Form.Label>
                    Recommended Retail Price (RRP)
                    <Badge bg="danger">Required</Badge>
                  </Form.Label>
                  <Form.Group className="mb-3">
                    <InputGroup>
                      <InputGroup.Text>£</InputGroup.Text>
                      <Controller
                        name="retailPricePence"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isDirty, error },
                          formState,
                        }) => (
                          <>
                            <CurrencyInput
                              style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
                              onBlur={onBlur}
                              allowNegativeValue={false}
                              decimalsLimit={2}
                              defaultValue={value}
                              onValueChange={handleOnCurrencyValueChange}
                              decimalScale={2}
                              disableAbbreviations={true}
                              disableGroupSeparators={true}
                              disabled={viewOnly || isSaving}
                              name={name}
                              className={
                                value
                                  ? invalid
                                    ? "is-invalid form-control"
                                    : "is-valid form-control"
                                  : "form-control"
                              }
                              onChange={onChange}
                              ref={ref}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error?.message}
                            </Form.Control.Feedback>
                          </>
                        )}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Cost Price </Form.Label>
                  <Form.Group className="mb-3">
                    <InputGroup>
                      <InputGroup.Text>£</InputGroup.Text>
                      <Controller
                        name="costPricePence"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isDirty, error },
                          formState,
                        }) => (
                          <>
                            <CurrencyInput
                              style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
                              onBlur={onBlur}
                              allowNegativeValue={false}
                              decimalsLimit={2}
                              defaultValue={value}
                              onValueChange={handleOnCurrencyValueChange}
                              decimalScale={2}
                              disableAbbreviations={true}
                              disableGroupSeparators={true}
                              disabled={viewOnly || isSaving}
                              name={name}
                              className={
                                isDirty && invalid
                                  ? "is-invalid form-control"
                                  : "form-control"
                              }
                              onChange={onChange}
                              ref={ref}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error?.message}
                            </Form.Control.Feedback>
                          </>
                        )}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <h5 className="mb-3 pt-3">Product Dimensions</h5>
              <DimensionsComponent
                itemName="product"
                item={catalogItem.dimensions?.product as ItemDimensionsRequired}
                register={register}
                isSaving={isSaving}
                viewOnly={viewOnly}
                offCanvas={offCanvas}
                offCanvasSize={offCanvasSize}
              />
              <Row>
                <Col sm={offCanvas ? offCanvasSize : 4}>
                  <Form.Group className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Label className="me-3">
                        Weight <Badge bg="danger">Required</Badge>
                      </Form.Label>
                      <Controller
                        name="weightGrams"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isDirty, error },
                          formState,
                        }) => (
                          <>
                            <Form.Control
                              aria-label="Weight Grams"
                              aria-describedby="weight-grams"
                              style={{
                                borderRadius: "0.25rem 0 0 0.25rem",
                                color:
                                  weightGrams && weightGrams > warningWeight
                                    ? "red"
                                    : "inherit",
                              }}
                              type="number"
                              defaultValue={value}
                              isValid={
                                value &&
                                touchedFields.weightGrams &&
                                !errors.weightGrams?.message
                                  ? true
                                  : false
                              }
                              isInvalid={
                                errors.weightGrams?.message !== undefined
                              }
                              {...register("weightGrams", {
                                required: false,
                                min: {
                                  message:
                                    "Value must cannot be less than 0.0001",
                                  value: 0.0001,
                                },
                                max: 99999999,
                                disabled: viewOnly || isSaving,
                              })}
                              onKeyDown={(event) => {
                                // Prevent entering '-' character (minus sign)
                                if (
                                  event.key === "-" ||
                                  event.key === "e" ||
                                  event.key === "E"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <InputGroup.Text
                              style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
                            >
                              kg
                            </InputGroup.Text>
                            {value && value > warningWeight && (
                              <Form.Text className="text-danger catalogue-field-warning">
                                Heavy Item!
                              </Form.Text>
                            )}
                            <Form.Control.Feedback type="invalid">
                              {errors.weightGrams?.message}
                            </Form.Control.Feedback>
                          </>
                        )}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <h5 className="mb-3 pt-3">Box Dimensions</h5>
              {!viewOnly ? (
                <Form.Check
                  type="switch"
                  id="box-dimensions-switch"
                  label="Box Dimensions/Number of Boxes"
                  checked={showBoxDimsOrBoxCount}
                  disabled={isSaving || viewOnly}
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  onChange={(e) => {
                    const value = e.target.checked;
                    setShowBoxDimsOrBoxCount(value);
                  }}
                />
              ) : null}
              {!showBoxDimsOrBoxCount ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!viewOnly ? (
                        <Form.Check
                          type="switch"
                          id="box-dimensions-multi-boxes"
                          label="Unique Boxes"
                          checked={showBoxDimsMultiBoxes}
                          style={{ marginBottom: "1em" }}
                          onChange={(e) => {
                            const value = e.target.checked;
                            setShowBoxDimsMultiBoxes(value);
                          }}
                        />
                      ) : null}
                      {!viewOnly && showBoxDimsMultiBoxes ? (
                        <PlusCircleFill
                          size={25}
                          color="blue"
                          style={{ marginLeft: "0.5em", marginBottom: "1em" }}
                          onClick={() => {
                            setAddBox(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  {showBoxDimsMultiBoxes ? (
                    <>
                      <Row>
                        <Col sm={offCanvas ? offCanvasSize : 3}>
                          <Form.Label>Total Box Dimensions (m³)</Form.Label>
                          <Form.Group className="mb-3">
                            <InputGroup>
                              <InputGroup.Text>m³</InputGroup.Text>
                              <Form.Control
                                aria-label="meterSquared"
                                aria-describedby="meterSquared"
                                style={{
                                  color:
                                    boxDimensions &&
                                    calculateCubeFromArrayOfBoxDims(
                                      boxDimensions
                                    ) > boxCubeWarning
                                      ? "red"
                                      : "inherit",
                                }}
                                type="number"
                                value={
                                  boxDimensions
                                    ? calculateCubeFromArrayOfBoxDims(
                                        boxDimensions
                                      )
                                    : ""
                                }
                                readOnly // Make the input read-only
                                disabled={true}
                              />
                              {boxDimensions && (
                                <>
                                  {calculateCubeFromArrayOfBoxDims(
                                    boxDimensions
                                  ) > boxCubeWarning && (
                                    <>
                                      {calculateCubeFromArrayOfBoxDims(
                                        boxDimensions
                                      ) <= boxCubeBlock ? (
                                        <Form.Text className="text-danger catalogue-field-warning">
                                          Large Item!
                                        </Form.Text>
                                      ) : (
                                        <Form.Text className="text-danger catalogue-field-warning">
                                          Very Large Item! Updating the item has
                                          been blocked!
                                        </Form.Text>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      {boxDimensions !== undefined
                        ? boxDimensions.map((row, index) => (
                            <BoxDimensionRowComponent
                              key={index}
                              rowId={index}
                              rowData={row as ItemDimensionsRequired}
                              isSaving={isSaving}
                              viewOnly={viewOnly}
                              register={register}
                              onRemove={handleRemoveBoxDimension}
                              onUpdate={handleUpdateBoxDimension}
                              showBoxDimsMultiBoxes={showBoxDimsMultiBoxes}
                              offCanvas={offCanvas}
                              offCanvasSize={offCanvasSize}
                            />
                          ))
                        : null}
                    </>
                  ) : (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Number of Duplicate Box Dimensions
                        </Form.Label>
                        <Form.Control
                          aria-label="numberOfDuplicateBoxDims"
                          aria-describedby="numberOfDupelicateBoxDims"
                          style={{ maxWidth: "120px" }}
                          type="number"
                          disabled={isSaving || viewOnly}
                          defaultValue={numberOfDuplicateBoxDims} // Set the default value here
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            setNumberOfDuplicateBoxDims(number);
                          }}
                          isInvalid={numberOfDuplicateBoxDims < 1}
                        />
                        <Form.Control.Feedback type="invalid">
                          Must be greater than 0.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <>
                        {boxDimensions !== undefined &&
                        boxDimensions.length > 0 ? (
                          <BoxDimensionRowComponent
                            rowId={0}
                            rowData={boxDimensions[0] as ItemDimensionsRequired}
                            isSaving={isSaving}
                            viewOnly={viewOnly}
                            register={register}
                            onRemove={handleRemoveBoxDimension}
                            onUpdate={handleUpdateBoxDimension}
                            showBoxDimsMultiBoxes={showBoxDimsMultiBoxes}
                            offCanvas={offCanvas}
                            offCanvasSize={offCanvasSize}
                          />
                        ) : (
                          <BoxDimensionRowComponent
                            rowId={0}
                            rowData={{ height: 0, width: 0, length: 0 }}
                            isSaving={isSaving}
                            viewOnly={viewOnly}
                            register={register}
                            onRemove={handleRemoveBoxDimension}
                            onUpdate={handleUpdateBoxDimension}
                            showBoxDimsMultiBoxes={showBoxDimsMultiBoxes}
                            offCanvas={offCanvas}
                            offCanvasSize={offCanvasSize}
                          />
                        )}
                      </>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h5 className="mb-3 pt-3">Number of Boxes</h5>
                      <Badge bg="danger" className="ms-2">
                        Required
                      </Badge>
                    </div>
                    <Form.Control
                      aria-label="boxCount"
                      aria-describedby="boxCount"
                      type="number"
                      {...register("boxCount", {
                        required: false,
                        max: 99999999,
                        disabled: viewOnly || isSaving,
                        min: {
                          message: "Value must be greater than 0.",
                          value: 1,
                        },
                      })}
                      isValid={
                        boxCountValue &&
                        touchedFields.boxCount &&
                        !errors.boxCount?.message
                          ? true
                          : false
                      }
                      isInvalid={errors.boxCount?.message !== undefined}
                      onKeyDown={(event) => {
                        // Prevent entering '-' character (minus sign)
                        if (
                          event.key === "-" ||
                          event.key === "e" ||
                          event.key === "E"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.boxCount?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <hr />
              <FormGroup className="mb-3">
                <EbayComponent
                  isSaving={isSaving}
                  viewOnly={viewOnly}
                  handleTextboxChange={handleTextboxChange}
                  ebayCategoryOneValue={ebayCategoryOneValue}
                  ebayCategoryTwoValue={ebayCategoryTwoValue}
                  offCanvas={offCanvas}
                  offCanvasSize={offCanvasSize}
                  childToParentEbayCategoryTwo={handleEbayDropdownChangeTwo}
                  childToParentEbayCategoryOne={handleEbayDropdownChangeOne}
                />
              </FormGroup>
            </Form>
          </Container>
        </Col>
      </Row>
      <hr />
      <CatalogItemIdentifiersComponent
        control={control}
        controlName={"identifiers"}
        isSaving={isSaving}
        viewOnly={viewOnly}
        register={register}
      />
      <hr />
      <Row className="mb-5">
        <AuditHistoryComponent catalogItem={catalogItem as CatalogItem} />
      </Row>
    </Container>
  );

  function imageThumbnailsAndUploads() {
    return (
      <Col>
        <Row sm={3} md={3} lg={4}>
          {[...clientImageLinks].map((image, index) => (
            <Col key={`clientImageLink-${index}`}>
              <Card className="m-1 catalog-item-image-thumb">
                {!viewOnly ? (
                  <Card.Header>
                    <CloseButton
                      aria-label="Remove image"
                      disabled={isSaving || viewOnly}
                      onClick={() => {
                        const newImageArray = [
                          ...clientImageLinks.slice(0, index),
                          ...clientImageLinks.slice(index + 1),
                        ];
                        setClientImageLinksLengthState(newImageArray.length);
                        setClientImageLinksState(newImageArray);
                      }}
                    />
                  </Card.Header>
                ) : null}
                <Card.Img
                  src={image}
                  onClick={() => onSelectedImageIndex(index)}
                  alt=""
                />
              </Card>
            </Col>
          ))}
          {[...attachedFilesLinks].map((image, index) => (
            <Col key={`clientImageLink-${index}`}>
              <Card className="m-1 catalog-item-image-thumb">
                {!viewOnly ? (
                  <Card.Header>
                    <CloseButton
                      aria-label="Remove image"
                      onClick={() => {
                        const newImageArray = [
                          ...attachedFilesLinks.slice(0, index),
                          ...attachedFilesLinks.slice(index + 1),
                        ];
                        setTempAttachedFilesLinksState(newImageArray);
                        const url = attachedFilesLinks[index];
                        const fileNameAndType = url.substring(
                          url.lastIndexOf("/", url.lastIndexOf("/") - 1) + 1
                        );
                        setFileToBeDeleted(fileNameAndType);
                        setShowDocumentDeleteModal(true);
                      }}
                    />
                  </Card.Header>
                ) : null}
                <Card.Img
                  src={image}
                  onClick={() => {
                    onSelectedImageIndex(index + clientImageLinksLength);
                  }}
                  alt=""
                />
              </Card>
            </Col>
          ))}
          <Modal show={showDocumentDeleteModal}>
            <ModalBody>
              <p className="d-flex justify-content-center">
                Permanently Delete?
              </p>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                      if (fileToBeDeleted) {
                        deleteAttachment(fileToBeDeleted).then(() => {
                          setAttachedFilesLinksState(tempAttachedFilesLinks);
                          setShowDocumentDeleteModal(false);
                          setIsDeleting(false);
                        });
                      }
                    }}
                  >
                    {" "}
                    {isDeleting ? (
                      <Spinner
                        as={"span"}
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        size="sm"
                        style={{ marginRight: "0.5em" }}
                      />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="warning"
                    onClick={() => {
                      setFileToBeDeleted("");
                      setShowDocumentDeleteModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Row>
        {uploadImageModal()}
        {uploadImageFromUrlModal()}
        {getImagesFromUrlModal()}
      </Col>
    );

    function getImagesFromUrlModal() {
      return (
        <Modal show={getAllImagesFromUrlModal}>
          <ModalBody>
            <div className="container">
              <div>
                <FormControl
                  type="text"
                  placeholder="Enter URL of Website..."
                  value={urlForWebsiteValue}
                  onChange={handleUrlForImageScrapingValue}
                />
              </div>
            </div>
          </ModalBody>
          {showSaveError ? (
            <Alert
              variant="danger"
              dismissible
              onClose={() => {
                setSaveError(undefined);
                setShowSaveError(false);
              }}
            >
              {saveError}
            </Alert>
          ) : (
            <></>
          )}
          <ModalFooter>
            <Button
              disabled={isUploading}
              variant="success"
              onClick={handleUrlForScrapingImagesButtonClick}
            >
              <Spinner
                as={"span"}
                animation="border"
                role="status"
                aria-hidden="true"
                size="sm"
                style={{ marginRight: "0.5em" }}
                className={isUploading ? "" : "visually-hidden"}
              />
              Fetch Images
            </Button>
            <Button
              disabled={isUploading}
              color="secondary"
              onClick={() => {
                acceptedFiles = [];
                setGetAllImagesFromUrlModal(false);
                setSaveError(undefined);
                setShowSaveError(false);
                setUrlForWebsiteValue("");
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    function uploadImageFromUrlModal() {
      return (
        <Modal show={showImageFromUrlModal}>
          <ModalBody>
            <div className="container">
              <div>
                <FormControl
                  type="text"
                  placeholder="Enter URL of Image..."
                  value={urlForImageValue}
                  onChange={handleUrlForImageValue}
                />
              </div>
            </div>
          </ModalBody>
          {showSaveError ? (
            <Alert
              variant="danger"
              dismissible
              onClose={() => {
                setSaveError(undefined);
                setShowSaveError(false);
              }}
            >
              {saveError}
            </Alert>
          ) : (
            <></>
          )}
          <ModalFooter>
            <Button
              disabled={isUploading}
              variant="success"
              onClick={handleUrlForImageButtonClick}
            >
              <Spinner
                as={"span"}
                animation="border"
                role="status"
                aria-hidden="true"
                size="sm"
                style={{ marginRight: "0.5em" }}
                className={isUploading ? "" : "visually-hidden"}
              />
              Save
            </Button>
            <Button
              disabled={isUploading}
              color="secondary"
              onClick={() => {
                acceptedFiles = [];
                setShowImageFromUrlModal(false);
                setSaveError(undefined);
                setShowSaveError(false);
                setUrlForImageValue("");
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    function uploadImageModal() {
      return (
        <Modal show={showDocumentUploadModal}>
          <ModalBody>
            <div className="container">
              <div {...getRootProps({})}>
                <input {...getInputProps()} />
                {acceptedFiles && acceptedFiles.length > 0 ? (
                  acceptedFiles.map((f, i) => (
                    <p style={{ color: "black" }} className="m-3" key={i}>
                      📄 {f.name} ({Math.floor(f.size / 1024)}KB)
                    </p>
                  ))
                ) : (
                  <p style={{ color: "gray" }} className="m-3">
                    Drag 'n' drop your document, or click to select file
                  </p>
                )}
              </div>
            </div>
          </ModalBody>
          {fileRejections && fileRejections.length > 0 && showRejections ? (
            <Row className="m-1">
              <Alert
                variant="danger"
                onClose={() => {
                  setShowRejections(false);
                }}
                dismissible
              >
                <p className="m-0">
                  Error! File must be a single .jpg/jpeg or .png.
                </p>
              </Alert>
            </Row>
          ) : (
            <></>
          )}
          <ModalFooter>
            <Button
              disabled={
                isUploading || !acceptedFiles || acceptedFiles.length === 0
              }
              variant="success"
              onClick={upload}
            >
              <Spinner
                as={"span"}
                animation="border"
                role="status"
                aria-hidden="true"
                size="sm"
                style={{ marginRight: "0.5em" }}
                className={isUploading ? "" : "visually-hidden"}
              />
              Upload
            </Button>
            <Button
              disabled={isUploading}
              color="secondary"
              onClick={() => {
                acceptedFiles = [];
                setShowDocumentUploadModal(false);
                setShowRejections(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
};

export default CatalogItemComponent;
