import React, { useCallback, useContext, useEffect, useState } from "react";
import PalletTableComponent from "../../components/pallet/pallet-table";
import { PalletState } from "../../components/pallet/types";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Offcanvas from "react-bootstrap/Offcanvas";
import { UserAuthenticatedContext } from "../../components/profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import DynamicSearchComponent, {
  SearchFieldConfig,
} from "../../components/dynamic-search-component";
import Loading from "../../components/loading";
import PaginationComponent from "../../components/pagination";
import { Client, ItemsPaginated, Meta } from "../../external";
import { PalletFilters } from "../../workers/pallet";
import { getOpenSearchItems } from "../../helpers/opensearch-helpers";

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const PalletListPage = () => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const clientsWorker = useWorker(createClientsWorker);

  const [pallets, setPallets] = useState<PalletState[]>([]);
  const [palletsLoaded, setPalletsLoaded] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);

  const [sortField, setSortField] = useState("createdAtTimestamp");
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("desc");

  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [meta, setMeta] = useState<Meta>(() => ({
    next: null,
    previous: null,
    current: 1,
    totalPages: 1,
    maxPerPage: 50,
    totalResults: 0,
  }));

  const [filters, setFilters] = useState<PalletFilters>(() => ({
    count: 25,
    page: 1,
    sortField,
    sortDirection,
  }));

  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);

  const handleTableSort = useCallback(
    (sortField: string, sortDirection: "asc" | "desc") => {
      setSortField(sortField);
      setSortDirection(sortDirection);
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortField: sortField,
        sortDirection: sortDirection,
      }));
    },
    []
  );

  const searchFields: SearchFieldConfig[] = [
    { key: "id", label: "Pallet Number", type: "text" },
    { key: "location", label: "Location", type: "text" },
    { key: "createdAtTimestamp", label: "Created At", type: "date" },
  ];

  const getPallets = useCallback(
    async (authToken: string, filters: PalletFilters) => {
      try {
        const response = await getOpenSearchItems<PalletState>(
          authToken,
          {
            ...filters,
            location: filters.location ?? undefined,
          },
          "PALLET"
        );
        return response as ItemsPaginated<PalletState>;
      } catch (error) {
        console.error("Error fetching pallets:", error);
        setIsError(true);
        setError(
          "An error occured loading the pallets. If problem persists please contact support."
        );
        throw error;
      }
    },
    []
  );

  const defaultCurrentPage = 1;
  const handleCount = (count: number) => {
    setFilters({ ...filters, count: count, page: defaultCurrentPage });
  };
  const handleCurrentPage = (pageNumber: number) => {
    setFilters({ ...filters, page: pageNumber });
  };

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext.token, clientsWorker]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const pallets = await getPallets(
          userAuthenticatedContext.token!,
          filters
        );
        setPallets(pallets?.data);
        setPalletsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext.token, getPallets, filters]);

  if (
    !pallets ||
    !palletsLoaded ||
    !clientsLoaded ||
    !userAuthenticatedContext ||
    !userAuthenticatedContext.token
  ) {
    return <Loading />;
  }

  return (
    <Container style={{ width: "95%", minWidth: "95%" }}>
      <Row>
        <Col lg="3">
          <Offcanvas
            show={showFilters}
            placement="start"
            responsive="lg"
            onHide={handleCloseFilters}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ justifyContent: "left" }}>
              <DynamicSearchComponent<PalletState, PalletFilters>
                clients={clients}
                isLoaded={true}
                setItems={setPallets}
                setItemsLoaded={setPalletsLoaded}
                searchFields={searchFields}
                filters={filters}
                setFilters={setFilters}
                onSearch={getPallets}
                sortField={sortField}
                sortDirection={sortDirection}
                setMeta={setMeta}
                setIsError={setIsError}
                setError={setError}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
        <Col
          lg="9"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >
          <Row>
            <Col>
              <h2 style={{ textAlign: "left" }}>Pallet List</h2>
            </Col>
          </Row>
          <Row>
            <ButtonGroup>
              <Row>
                <Col xs="auto" className="d-lg-none" style={{ float: "left" }}>
                  <Button
                    variant="success"
                    onClick={handleShowFilters}
                    className="btn-block"
                  >
                    Show Filters
                  </Button>
                </Col>
              </Row>
            </ButtonGroup>
          </Row>
          {isError ? (
            <Alert variant="danger">{error}</Alert>
          ) : palletsLoaded ? (
            <PalletTableComponent
              pallets={pallets}
              palletsLoaded={palletsLoaded}
              filters={filters}
              childToParentTableSort={(
                sortField: string,
                sortDirection: "asc" | "desc"
              ) => {
                handleTableSort(sortField, sortDirection);
              }}
            />
          ) : (
            <Loading />
          )}
          <Row>
            <PaginationComponent
              meta={meta}
              itemsLoaded={palletsLoaded}
              childToParentPages={handleCurrentPage}
              childToParentCount={handleCount}
            ></PaginationComponent>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PalletListPage;
