import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { DashCircleFill, TrashFill } from "react-bootstrap-icons";

import { useFeatureFlagEnabled } from "posthog-js/react";

import { Client } from "../external";
import React, { useState } from "react";
import SearchCurrencyFixedComponent from "./search-currency-fixed";
import SearchCurrencyRangeComponent from "./search-currency-range";
import EbaySearchComponent from "./ebay/ebay-category-search";
import { EbayCategory } from "../workers/ebay-categories";
import { useCircleDarkMode } from "../hooks/useCircleDarkMode";
import { CatalogFilters } from "../workers/catalog";

type ListingType = "ready" | "notReady";

export const SearchControlsComponent = ({
  clients,
  isLoaded,
  childToParentClients,
  childToParentQuarantine,
  childToParentClear,
  childToParentTextSearch,
  childToParentPriceFixed,
  childToParentPriceRange,
  childToParentStatus,
  childToParentEbayCategory,
  filters,
  quarantineCount,
  childToParentListing,
}: {
  clients: Client[];
  isLoaded: boolean;
  childToParentClients: (value?: string) => void;
  childToParentQuarantine: (value?: boolean) => void;
  childToParentTextSearch: (value?: string) => void;
  childToParentClear: (value: boolean) => void;
  childToParentPriceFixed: (value?: string) => void;
  childToParentPriceRange: (min?: string, max?: string) => void;
  childToParentStatus: (value?: string) => void;
  childToParentEbayCategory: (value?: EbayCategory[]) => void;
  quarantineCount: number;
  filters: CatalogFilters;
  childToParentListing: (value?: boolean) => void;
}): JSX.Element => {
  function getPriceLabel(): string | undefined {
    if (filters === undefined) return undefined;

    if (filters.priceFixed !== undefined) {
      return `£${filters.priceFixed}`;
    } else if (
      filters.priceMin !== undefined &&
      filters.priceMax !== undefined
    ) {
      // If both min and max prices are present, return the average
      return `£${filters.priceMin} - £${filters.priceMax}`;
    } else if (
      filters.priceMax !== undefined &&
      filters.priceMin === undefined
    ) {
      return `Less than or Equal to £${filters.priceMax}`;
    } else if (
      filters.priceMin !== undefined &&
      filters.priceMax === undefined
    ) {
      return `Greater than or Equal to £${filters.priceMin}`;
    } else {
      // If none of the conditions match, return undefined
      return undefined;
    }
  }

  const darkMode = useCircleDarkMode();
  const [showPriceFixed, setShowPriceFixed] = useState<boolean>(
    filters.priceFixed !== undefined && filters.priceFixed !== null
  );
  const [showPriceRange, setShowPriceRange] = useState<boolean>(false);

  const defaultListing: ListingType[] = ["ready", "notReady"];
  const [listing, setListing] = useState<ListingType[]>(
    filters.suitableForListing === undefined
      ? defaultListing
      : filters.suitableForListing
      ? ["ready"]
      : ["notReady"]
  );

  const [priceFixed, setPriceFixed] = useState<number | string | undefined>("");
  const [priceMin, setPriceMin] = useState<number | string | undefined>("");
  const [priceMax, setPriceMax] = useState<number | string | undefined>("");

  const [searchText, setSearchText] = useState<string>("");
  const [appliedSearchText, setAppliedSearchText] = useState<
    string | undefined
  >(filters.text);

  const [appliedEbayCategory, setAppliedEbayCategory] = useState<
    EbayCategory[] | undefined
  >(filters.ebayCategory);
  const [ebaySearchText, setEbaySearchText] = useState<string>("");

  const shouldShowQuarantineCount = useFeatureFlagEnabled(
    "catalog_showQuarantineCount"
  );

  const handleListingChange = (value: ListingType) => {
    let newListingData: ListingType[] = [...listing];

    if (newListingData.includes(value)) {
      newListingData = newListingData.filter((item) => item !== value);
    } else {
      newListingData.push(value);
    }
    setListing(newListingData);

    if (newListingData.length === 1) {
      if (newListingData.includes("ready")) {
        childToParentListing(true);
      } else {
        childToParentListing(false);
      }
    } else {
      childToParentListing();
    }
  };

  const handleQuarantineChange = (quarantine?: boolean) => {
    childToParentQuarantine(quarantine);
  };

  const handleClientChange = (clientId?: string) => {
    if (clientId === "all") clientId = undefined;
    childToParentClients(clientId);
  };

  const handleTextSearchButton = () => {
    setSearchText("");
    setAppliedSearchText(searchText);
    childToParentTextSearch(searchText);
  };

  const handleTextSearch = (search: string) => {
    setSearchText(search);
  };

  const handleClear = (clear: boolean) => {
    setSearchText("");
    setAppliedSearchText(undefined);
    setPriceFixed("");
    setShowPriceFixed(false);
    setShowPriceRange(false);
    setAppliedEbayCategory(undefined);
    setEbaySearchText("");
    setListing(defaultListing);
    childToParentClear(clear);
  };

  const handlePriceFixed = (price?: string) => {
    if (price === undefined || price === "") {
      childToParentPriceFixed(undefined);
    } else {
      setPriceFixed(parseFloat(price));
      childToParentPriceFixed(price);
    }
  };

  const handlePriceRange = (min?: string, max?: string) => {
    setPriceMin("");
    setPriceMax("");
    childToParentPriceRange(min, max);
  };

  const handlePriceChange = (price?: string) => {
    switch (price) {
      case "fixed":
        setShowPriceFixed(true);
        setShowPriceRange(false);
        break;
      case "range":
        setShowPriceFixed(false);
        setShowPriceRange(true);
        break;
      default:
        setShowPriceFixed(false);
        setShowPriceRange(false);
        break;
    }
  };

  const handleStatusChange = (status?: string) => {
    switch (status) {
      case "all":
        childToParentStatus(undefined);
        break;
      default:
        childToParentStatus(status);
        break;
    }
  };

  const handleEbayCategory = (selectedEbayCategory?: string) => {
    if (selectedEbayCategory) {
      const json: EbayCategory = JSON.parse(selectedEbayCategory);
      if (appliedEbayCategory === undefined) {
        setAppliedEbayCategory([json]);
        childToParentEbayCategory([json]);
      } else {
        setAppliedEbayCategory([...appliedEbayCategory, json]);
        childToParentEbayCategory([...appliedEbayCategory, json]);
      }
    }
  };

  const handleRemoveEbayCategory = (index: number) => {
    if (appliedEbayCategory && appliedEbayCategory.length > 0) {
      const appliedEbayCategoryCopy = [...appliedEbayCategory];
      appliedEbayCategoryCopy.splice(index, 1);
      setAppliedEbayCategory(appliedEbayCategoryCopy);
      childToParentEbayCategory(appliedEbayCategoryCopy);
    }
  };

  const getClientFilterTitle = (clientId: string, clientName: string) => (
    <span>
      {clientName}{" "}
      <span
        style={{
          color: darkMode ? "lightcoral" : "coral",
        }}
      >
        {clientId}
      </span>
    </span>
  );

  const getClientDropdownTitle = () => {
    const client = clients.find((c) => c.clientId === filters.clientId);

    if (client) {
      return getClientFilterTitle(client.clientId, client.clientName);
    } else {
      return "All Clients";
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} className="d-flex">
              <div
                onClick={() => {
                  handleClear(true);
                }}
                style={{ cursor: "pointer", marginBottom: "10px" }}
              >
                <TrashFill /> Clear All
              </div>
            </Col>
          </Row>
          {shouldShowQuarantineCount ? <hr /> : null}
          {shouldShowQuarantineCount ? (
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Form.Check
                    type="switch"
                    id="switch-quarantine"
                    disabled={!isLoaded}
                    checked={filters.quarantine}
                    onChange={(e) => {
                      handleQuarantineChange(e.target.checked);
                    }}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    View Quarantined Items ({quarantineCount})
                  </span>
                </div>
              </Col>
            </Row>
          ) : null}
          {!filters.quarantine ? (
            <>
              <hr />
              <Row>
                <Col xs={12}>
                  <Form.Label>Client</Form.Label>
                  <InputGroup className="mb-3">
                    <DropdownButton
                      disabled={!isLoaded || filters.quarantine}
                      variant={
                        darkMode ? "outline-primary" : "outline-secondary"
                      }
                      aria-label="Select Client"
                      title={getClientDropdownTitle()}
                    >
                      <Dropdown.Item
                        key="all"
                        onClick={() => handleClientChange("all")}
                      >
                        <b>All Clients</b>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {clients
                        .sort((a, b) =>
                          a.clientName.localeCompare(b.clientName)
                        )
                        .map((c) => (
                          <Dropdown.Item
                            key={c.clientId}
                            onClick={() => handleClientChange(c.clientId)}
                          >
                            {getClientFilterTitle(c.clientId, c.clientName)}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </InputGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12}>
                  <Form.Label>Search: {appliedSearchText}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={searchText}
                      disabled={!isLoaded || filters.quarantine}
                      onChange={(e) => handleTextSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleTextSearchButton();
                        }
                      }}
                    />
                    <Button
                      variant={
                        darkMode ? "outline-primary" : "outline-secondary"
                      }
                      onClick={() => handleTextSearchButton()}
                      disabled={!isLoaded || filters.quarantine}
                    >
                      Apply
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12}></Col>
                <Form.Label>Price:{getPriceLabel()}</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Fixed"
                    name="priceGroup"
                    type="radio"
                    value="fixed"
                    disabled={!isLoaded || filters.quarantine}
                    checked={showPriceFixed}
                    onChange={(e) => handlePriceChange(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Range"
                    name="priceGroup"
                    type="radio"
                    value="range"
                    disabled={!isLoaded || filters.quarantine}
                    checked={showPriceRange}
                    onChange={(e) => handlePriceChange(e.target.value)}
                  />
                </div>
                {showPriceFixed ? (
                  <SearchCurrencyFixedComponent
                    disabled={!isLoaded || filters.quarantine}
                    childToParent={handlePriceFixed}
                    value={priceFixed}
                  ></SearchCurrencyFixedComponent>
                ) : null}
                {showPriceRange ? (
                  <SearchCurrencyRangeComponent
                    disabled={!isLoaded || filters.quarantine}
                    childToParent={handlePriceRange}
                    valueMin={priceMin}
                    valueMax={priceMax}
                  ></SearchCurrencyRangeComponent>
                ) : null}
              </Row>
              <hr />
              <Row>
                <Col xs={12}>
                  <Form.Label>eBay Category</Form.Label>
                  <EbaySearchComponent
                    isLoading={!isLoaded}
                    viewOnly={filters.quarantine}
                    childToParent={handleEbayCategory}
                    filters={true}
                    searchTerm={ebaySearchText}
                    setSearchTerm={setEbaySearchText}
                  ></EbaySearchComponent>
                  {appliedEbayCategory && appliedEbayCategory.length > 0 ? (
                    <Col>
                      {appliedEbayCategory.map((category, idx) => (
                        <Row key={idx}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <DashCircleFill
                              size={25}
                              color={
                                filters.quarantine
                                  ? "grey"
                                  : isLoaded
                                  ? "red"
                                  : "grey"
                              }
                              style={{
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                if (isLoaded && !filters.quarantine) {
                                  handleRemoveEbayCategory(idx);
                                }
                              }}
                            />
                            {category.id} - {category.name}
                          </div>
                        </Row>
                      ))}
                    </Col>
                  ) : null}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12}></Col>
                <Form.Label>Status</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="All"
                    name="statusGroup"
                    id="all"
                    type="radio"
                    value="all"
                    disabled={!isLoaded || filters.quarantine}
                    checked={filters.archived === undefined}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Active"
                    id="active"
                    name="statusGroup"
                    type="radio"
                    value="0"
                    disabled={!isLoaded || filters.quarantine}
                    checked={filters.archived === "0"}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Archived"
                    id="archived"
                    name="statusGroup"
                    type="radio"
                    value="1"
                    disabled={!isLoaded || filters.quarantine}
                    checked={filters.archived === "1"}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  />
                </div>
              </Row>
              <hr />
              <Row>
                <Col xs={12}></Col>
                <Form.Label>
                  Listing <small>(Min. 1 Selected Option)</small>
                </Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Ready"
                    id="ready"
                    name="ready"
                    type="checkbox"
                    value={"ready" as ListingType}
                    disabled={!isLoaded || filters.quarantine}
                    checked={listing.includes("ready" as ListingType)}
                    onChange={(e) => {
                      if (
                        listing.length === 1 &&
                        listing.includes(e.target.value as ListingType)
                      ) {
                        return;
                      }
                      handleListingChange(e.target.value as ListingType);
                    }}
                  />
                  <Form.Check
                    inline
                    label="Not Ready"
                    id="notReady"
                    name="notReady"
                    type="checkbox"
                    value={"notReady" as ListingType}
                    disabled={!isLoaded || filters.quarantine}
                    checked={listing.includes("notReady" as ListingType)}
                    onChange={(e) => {
                      if (
                        listing.length === 1 &&
                        listing.includes(e.target.value as ListingType)
                      ) {
                        return;
                      }
                      handleListingChange(e.target.value as ListingType);
                    }}
                  />
                </div>
              </Row>
            </>
          ) : null}
        </Form>
      </Card.Body>
    </Card>
  );
};
