/// <reference types="react-scripts" />

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { Lightbulb, LightbulbOff } from "react-bootstrap-icons";
import { DecodeHintType, Result, useZxing } from "react-zxing";
import { BarcodeFormat } from "@zxing/library";

const hints = new Map<DecodeHintType, any>();
const formats = [
  BarcodeFormat.QR_CODE,
  BarcodeFormat.CODE_39,
  BarcodeFormat.CODE_128,
  BarcodeFormat.UPC_A,
  BarcodeFormat.CODABAR,
];

hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

export interface BarcodeScannerProps {
  onBarcodeDetected: (result: Result) => void;
}

const BarcodeScanner = ({ onBarcodeDetected }: BarcodeScannerProps) => {
  const [beepAudio] = useState<HTMLAudioElement>(
    new Audio("/Barcode-scanner-beep-sound.mp3")
  );

  const {
    ref,
    torch: { on: torchOn, off: torchOff, status: torchStatus },
  } = useZxing({
    onDecodeResult(result) {
      beepAudio.play();
      onBarcodeDetected(result);
    },
    hints,
  });

  const [torchAvailable, setTorchAvailable] = useState(false);
  useEffect(() => {
    setTorchAvailable(torchStatus !== "unavailable");
  }, [torchStatus]);

  return (
    <Container>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="mt-2"
            style={{
              border: "5px solid grey",
              borderRadius: "5px",
              overflow: "hidden",
              height: "97%",
            }}
          >
            <video
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                maxHeight: "300px",
                marginBottom: "0",
                aspectRatio: "3/4",
              }}
              ref={ref}
              width={300}
              height={400}
              id="barcodeVideo"
              autoPlay
              playsInline
              muted
            />
          </div>
        </Col>
      </Row>
      {torchAvailable ? (
        <Row>
          <Col>
            <Button
              size="lg"
              className="w-100 mt-2"
              onClick={() => {
                if (torchStatus === "on") {
                  torchOff();
                } else {
                  torchOn();
                }
              }}
            >
              {torchStatus === "off" ? <Lightbulb /> : <LightbulbOff />}
            </Button>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

export default BarcodeScanner;
