import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Client, DuplicateCatalogItemBody } from "../../external";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../profile";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";

const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

export interface CatalogItemDuplicateModalComponentProps {
  itemId: string;
  clientItemId: string;
  clients: Client[];
  showModal: boolean;
  handleCloseModal: () => void;
}

const CatalogItemDuplicateModalComponent = (
  props: CatalogItemDuplicateModalComponentProps
): JSX.Element => {
  const { itemId, clientItemId, clients, showModal, handleCloseModal } = props;
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogWorker = useWorker(createCatalogWorker);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DuplicateCatalogItemBody>();

  const [newItemId, setNewItemId] = useState<string>();
  const [userToken, setUserToken] = useState<string>();
  const [showPostSuccess, setShowPostSuccess] = useState(false);
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  useEffect(() => {
    if (showPostSuccess) {
      console.info("show success message");
      setTimeout(() => {
        setShowPostSuccess(false);
        navigate(`/catalog/item/${newItemId}`);
        navigate(0);
      }, 2000);
    }
  }, [showPostSuccess, navigate, newItemId]);

  const onSubmit = handleSubmit(
    (formDuplicateItem) => {
      if (formDuplicateItem) {
        (async () => {
          if (userToken) {
            setIsSaving(true);
            setPostError("");
            setShowPostError(false);
            setShowPostSuccess(false);

            try {
              formDuplicateItem.clientId = formDuplicateItem.clientId.trim();
              formDuplicateItem.clientItemId =
                formDuplicateItem.clientItemId.trim();

              const postResult = await catalogWorker.postDuplicateCatalogItem(
                userToken,
                {
                  itemId: itemId,
                  clientId: formDuplicateItem.clientId,
                  clientItemId: formDuplicateItem.clientItemId,
                }
              );

              console.log(postResult);

              if ([200].includes(postResult.status)) {
                setNewItemId(postResult.data.itemId);
                setShowPostSuccess(true);
              } else {
                setShowPostError(true);
                setPostError(
                  `Invalid status code ${postResult.status} returned from server`
                );
              }
            } catch (e) {
              await new Promise(() =>
                setTimeout(() => {
                  setShowPostError(true);
                  setPostError(
                    `Duplicating Catalogue Item failed with error "${
                      (e as unknown as Error).message
                    }"`
                  );
                }, 2000)
              );
            } finally {
              await new Promise((resolve) => {
                // Give a small timeout here to give the illusion of loading
                setTimeout(() => {
                  setIsSaving(false);
                  resolve(undefined);
                }, 2000);
              });
            }
          }
        })();
      }
    },
    (invalidData) => {
      console.log(errors.clientItemId);
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size={"lg"}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>
          Duplicate {clientItemId} ({itemId})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {isSaving ? <Loading /> : <></>}
          <Container>
            {showPostError && !isSaving ? (
              <Alert
                variant="danger"
                dismissible
                onClose={() => {
                  setPostError(undefined);
                  setShowPostError(false);
                }}
              >
                {postError}
              </Alert>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            <Form noValidate onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Client</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select
                    aria-label="Select Client"
                    {...register("clientId", {
                      disabled: isSaving,
                      required: {
                        message: "Please select a Client.",
                        value: true,
                      },
                    })}
                    isInvalid={errors.clientId?.type === "required"}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select a Client
                    </option>
                    {[...clients]
                      .sort((a, b) => a.clientName.localeCompare(b.clientName))
                      .map((c, i) => (
                        <option value={c.clientId} key={c.clientId}>
                          {c.clientName}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.clientId?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Client Item ID</Form.Label>
                <Form.Control
                  type="text"
                  {...register("clientItemId", {
                    disabled: isSaving,
                    required: {
                      message: "Please specify the Client Item ID.",
                      value: true,
                    },
                    minLength: {
                      message:
                        "The Client Item ID needs to be at least 3 characters in length.",
                      value: 3,
                    },
                  })}
                  isInvalid={
                    errors.clientItemId?.type === "required" ||
                    errors.clientItemId?.type === "minLength"
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.clientItemId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
          disabled={isSaving}
        >
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={isSaving}>
          Duplicate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CatalogItemDuplicateModalComponent;
