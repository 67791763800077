/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";
import { useDebugMode } from "../../../hooks/useDebugMode";

type LoadInitialValuePluginProps = {
  namespace: string;
  initialValue?: string;
};

export function LoadInitialValuePlugin({
  initialValue,
}: LoadInitialValuePluginProps) {
  const [editor] = useLexicalComposerContext();
  const [hasWritten, setHasWritten] = useState(false);

  const isDebugMode = useDebugMode();

  // Run useEffect with no dependencies - this will only run at load
  useEffect(() => {
    editor.update(
      () => {
        if (initialValue && !hasWritten) {
          // Make this only run once
          setHasWritten(true);

          // In the browser you can use the native DOMParser API to parse the HTML string.
          // Once you have the DOM instance it's easy to generate LexicalNodes.
          const parser = new DOMParser();
          const v = parser.parseFromString(initialValue, "text/html");

          // Retrieve and clear the root
          const root = $getRoot();
          root.clear();

          // Generate nodes from the DOM, which generates HTML nodes
          const nodes = $generateNodesFromDOM(editor, v);
          $insertNodes(nodes);
        }
      },
      {
        onUpdate: isDebugMode
          ? () => {
              // Debugging purposes
              console.info(editor.toJSON());
            }
          : undefined,
      }
    );
  }, []);

  return null;
}
