export type OpenSidebarVariants = "catalog" | "client" | "fetch-images";
export const OpenGlobalSidebarEventName = "openGlobalSidebar";
export const CloseGlobalSidebarEventName = "closeGlobalSidebar";

export interface OpenGlobalSidebarEventDetail {
  variant: OpenSidebarVariants;
  id: string;
  data?: any;
}
export interface CloseGlobalSidebarEventDetail {}

export const OpenGlobalSidebarEvent = (
  openSidebarVariant: OpenSidebarVariants,
  id: string,
  data?: any
): CustomEvent<OpenGlobalSidebarEventDetail> => {
  return new CustomEvent<OpenGlobalSidebarEventDetail>(
    OpenGlobalSidebarEventName,
    {
      detail: {
        variant: openSidebarVariant,
        id,
        data,
      },
    }
  );
};

export const CloseGlobalSidebarEvent =
  (): CustomEvent<CloseGlobalSidebarEventDetail> => {
    return new CustomEvent<CloseGlobalSidebarEventDetail>(
      CloseGlobalSidebarEventName,
      {
        detail: {},
      }
    );
  };
