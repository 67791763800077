import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../profile";
import CatalogItemComponent from "../catalog/catalog-item";
import { getCatalogItem } from "../../workers/catalog";
import {
  CloseGlobalSidebarEventName,
  OpenGlobalSidebarEventDetail,
  OpenGlobalSidebarEventName,
  OpenSidebarVariants,
} from "./events";
import Loading from "../loading";
import CatalogItemImageImportUrl from "../catalog/catalog-item-parts/image-import-url";

const createImageHelpersWorker = createWorkerFactory(
  () => import("../../workers/images")
);

const GlobalOffCanvasComponent = (): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState<OpenSidebarVariants>();
  const [id, setId] = useState<string>();
  const [data, setData] = useState<any>();

  const imageWorker = useWorker(createImageHelpersWorker);

  const handleClose = () => {
    setData(undefined);
    setVariant(undefined);
    setId(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [offcanvasBody, setOffCanvasBody] = useState<JSX.Element>();
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    const onOpenSidebarEvent = (e: Event) => {
      const event = e as CustomEvent<OpenGlobalSidebarEventDetail>;
      handleShow();
      setVariant(event.detail.variant);
      setId(event.detail.id);
      setData(event.detail.data);
    };
    const onCloseSidebarEvent = (e: Event) => {
      handleClose();
    };

    window.addEventListener(OpenGlobalSidebarEventName, onOpenSidebarEvent);
    window.addEventListener(CloseGlobalSidebarEventName, onCloseSidebarEvent);

    // Return function is run on component destruction
    return () => {
      window.removeEventListener(
        OpenGlobalSidebarEventName,
        onOpenSidebarEvent
      );
      window.removeEventListener(
        CloseGlobalSidebarEventName,
        onCloseSidebarEvent
      );
    };
  }, []);

  useEffect(() => {
    setOffCanvasBody(<></>);
    setTitle("");
    (async () => {
      switch (variant) {
        case "catalog":
          setTitle("Catalogue Item");
          if (id && userAuthenticatedContext.token) {
            try {
              const catalogueItem = await getCatalogItem(
                userAuthenticatedContext.token,
                id
              );

              setOffCanvasBody(
                <CatalogItemComponent
                  catalogItem={catalogueItem}
                  restrictForm={true}
                  offCanvas={true}
                />
              );
            } catch (error) {
              setOffCanvasBody(<>Error in loading Data</>);
              throw new Error("Failed to get current catalogue item: " + id);
            }
          }
          break;
        case "client":
          setOffCanvasBody(<>Client ({id})</>);
          setTitle("Client");
          break;
        case "fetch-images":
          setTitle("Retrieved Images");
          setOffCanvasBody(<></>);
          if (!userAuthenticatedContext.token) {
            throw new Error("No token!");
          }
          setOffCanvasBody(<Loading />);

          try {
            const getImagesResult = await imageWorker.GetImagesForUrl(
              data.url,
              "GENERIC",
              userAuthenticatedContext.token
            );

            if (getImagesResult.urls.length === 0) {
              setOffCanvasBody(<>No Images Returned</>);
            } else {
              let urlForImageUpload = process.env
                .REACT_APP_POST_IMAGE_FROM_URL as string;

              setOffCanvasBody(
                <CatalogItemImageImportUrl
                  urls={getImagesResult.urls}
                  urlForImageUpload={urlForImageUpload}
                  clientId={data.clientId}
                  itemId={id}
                  userToken={userAuthenticatedContext.token}
                ></CatalogItemImageImportUrl>
              );
            }
          } catch (e) {
            setOffCanvasBody(<>Error in loading Data</>);
            throw new Error("Failed to return images");
          }
          break;
        default:
          setOffCanvasBody(<></>);
          setTitle("");
          break;
      }
    })();
  }, [userAuthenticatedContext, id, variant, data, imageWorker]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{offcanvasBody}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default GlobalOffCanvasComponent;
