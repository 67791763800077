import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { CatalogItem, ItemDimensionsRequired } from "../../../external";
import { UseFormRegister } from "react-hook-form";

type DimensionTypes = "product";

const DimensionsComponent = ({
  itemName,
  item,
  register,
  isSaving,
  viewOnly,
  offCanvas,
  offCanvasSize,
}: {
  itemName: DimensionTypes;
  item: ItemDimensionsRequired | undefined;
  register: UseFormRegister<CatalogItem>;
  isSaving: boolean;
  viewOnly: boolean;
  offCanvas: boolean;
  offCanvasSize: number;
}) => {
  return (
    <Row className="d-flex align-items-center">
      <Col sm={offCanvas ? offCanvasSize : 4}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">Height</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Height"
              aria-label="Item height"
              aria-describedby="item-height"
              type="number"
              disabled={isSaving || viewOnly}
              {...register(`dimensions.${itemName}.height`, {
                min: 0,
                max: 9999,
                setValueAs: (value) => {
                  if (value === "") {
                    value = undefined;
                  } else {
                    return value;
                  }
                },
              })}
            />
            <InputGroup.Text id="item-height">cm</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>

      <Col sm={offCanvas ? offCanvasSize : 4}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">Width</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Width"
              aria-label="Item width"
              aria-describedby="item-width"
              type="number"
              disabled={isSaving || viewOnly}
              {...register(`dimensions.${itemName}.width`, {
                min: 0,
                max: 9999,
                setValueAs: (value) => {
                  if (value === "") {
                    value = undefined;
                  } else {
                    return value;
                  }
                },
              })}
            />
            <InputGroup.Text id="item-width">cm</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>

      <Col sm={offCanvas ? offCanvasSize : 4}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">Length</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Depth/Length"
              aria-label="Item depth or length"
              aria-describedby="item-depth"
              type="number"
              disabled={isSaving || viewOnly}
              {...register(`dimensions.${itemName}.length`, {
                min: 0,
                max: 9999,
                setValueAs: (value) => {
                  if (value === "") {
                    value = undefined;
                  } else {
                    return value;
                  }
                },
              })}
            />
            <InputGroup.Text id="item-depth">cm</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DimensionsComponent;
