import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { Table } from "@tanstack/react-table";

export interface ManifestTableHeaderBarProps<T> {
  showFilters: boolean;
  setShowFilters: (show: boolean) => void;
  table: Table<T>;
  onSaveButtonClicked?: () => void;
  onCancelButtonClicked?: () => void;
}

const TableHeaderBar = <T extends Object>({
  showFilters,
  setShowFilters,
  table,
  onCancelButtonClicked,
  onSaveButtonClicked,
}: ManifestTableHeaderBarProps<T>) => {
  return (
    <Row className="m-1">
      <Col lg={4} style={{ alignItems: "center" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Table Settings</Accordion.Header>
            <Accordion.Body>
              <p>Table settings are saved locally in your browser.</p>
              <Row>
                <Col>
                  <h4>Settings</h4>
                  <Form>
                    <Form.Switch
                      label="Show Filters"
                      checked={showFilters}
                      onChange={(e) => {
                        setShowFilters(e.target.checked);
                      }}
                    ></Form.Switch>
                  </Form>
                </Col>
                <Col>
                  <h4>Columns</h4>
                  <Form>
                    <Form.Check
                      type="switch"
                      checked={table.getIsAllColumnsVisible()}
                      onChange={table.getToggleAllColumnsVisibilityHandler()}
                      label={<b>Toggle All</b>}
                    />
                    {table.getAllLeafColumns().map((column, i) => (
                      <Form.Check
                        key={i}
                        type="switch"
                        checked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        label={column.id}
                      />
                    ))}
                  </Form>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col lg={8} className="d-flex justify-content-end">
        <ButtonToolbar className="d-block">
          <ButtonGroup className="me-2">
            <Button
              variant="warning"
              size="lg"
              onClick={() => table.resetColumnFilters()}
            >
              Reset Filters
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              size="lg"
              onClick={() => {
                if (onSaveButtonClicked) {
                  onSaveButtonClicked();
                } else {
                  console.error("No save button handler defined!");
                }
              }}
            >
              Save
            </Button>
            <Button
              size="lg"
              variant="danger"
              onClick={() => {
                if (onCancelButtonClicked) {
                  onCancelButtonClicked();
                } else {
                  console.error("No cancel button handler defined!");
                }
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
    </Row>
  );
};

export default TableHeaderBar;
