import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useGradingAndRefurbStateNestedField } from "../persist-helpers";
import UploadImagesDetailedInfoComponent from "./upload-image";

export interface DamagesCheckboxTypeComponentProps {
  optionKey: string;
  optionName: string;
  showImage: boolean;
  passthroughKey: string;
  stepOutputKey: string;
  refurbIdentifier: string;
}

const DamagesCheckboxTypeComponent = ({
  optionName,
  showImage,
  optionKey,
  stepOutputKey,
  passthroughKey,
  refurbIdentifier,
}: DamagesCheckboxTypeComponentProps) => {
  const [checkboxState, setCheckboxState] = useGradingAndRefurbStateNestedField(
    stepOutputKey,
    refurbIdentifier,
    optionKey,
    false
  );

  return (
    <Col key={passthroughKey}>
      <Container
        style={{
          display: "flex",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            style={{
              fontSize: "1.5em",
            }}
            htmlFor={`checkbox-${passthroughKey}`}
          >
            {optionName}
          </label>
          <input
            type="checkbox"
            id={`checkbox-${passthroughKey}`}
            checked={checkboxState}
            style={{
              width: "40px",
              height: "40px",
              margin: "12px 2em",
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCheckboxState(e.target.checked);
            }}
          ></input>
        </Col>
        {showImage ? (
          <Col>
            <UploadImagesDetailedInfoComponent />
          </Col>
        ) : null}
      </Container>
    </Col>
  );
};

export default DamagesCheckboxTypeComponent;
