import React, { useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";

const ReportingDataUploadPage = () => {
  const getPresignedUploadUrl =
    process.env.REACT_APP_GET_PRESIGNED_URL_FOR_DATA_UPLOAD_URL;
  const getProcessingLogsUrl =
    process.env.REACT_APP_GET_LOGS_FOR_REPORTING_UPLOAD_PROCESSING_URL;

  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadResult, setUploadResult] = React.useState("");
  const [isRetrievingLogs, setIsRetrievingLogs] = React.useState(false);
  const [processingLogs, setProcessingLogs] = React.useState<
    Array<{
      message: string;
      timestamp: number;
    }>
  >([]);
  const [logHoursAgo, setLogHoursAgo] = React.useState(48);
  const [logFilterString, setLogFilterString] = React.useState("ERROR");
  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    (acceptedFiles) => {
      setIsUploading(true);
      setUploadResult("");
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async () => {
          try {
            const binaryStr = reader.result;
            const response = await axios.get(getPresignedUploadUrl!, {
              params: {
                objectKey: file.name,
              },
            });

            const { url } = response.data;
            // Upload the file to S3
            await axios.put(url, binaryStr, {
              headers: {
                "Content-Type": file.type,
              },
            });

            console.log("File uploaded successfully");
            setUploadResult("File uploaded successfully");
            setTimeout(() => {
              setUploadResult("");
            }, 5000);
          } catch (error) {
            setUploadResult("Error uploading file! " + error);
          } finally {
            setIsUploading(false);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [getPresignedUploadUrl]
  );

  const retrieveLogs = useCallback(async () => {
    setIsRetrievingLogs(true);
    try {
      const response = await axios.get(getProcessingLogsUrl!, {
        params: {
          hoursAgo: logHoursAgo,
          filterPattern: logFilterString,
        },
      });
      const logs: Array<{
        message: string;
        timestamp: number;
      }> = response.data;

      // Sort logs backwards
      logs.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return 1;
        } else if (a.timestamp > b.timestamp) {
          return -1;
        }
        return 0;
      });
      setProcessingLogs(logs);
    } catch (error) {
      console.error("Error retrieving logs", error);
      setProcessingLogs([
        {
          message: "Error retrieving logs",
          timestamp: Date.now(),
        },
      ]);
    } finally {
      setIsRetrievingLogs(false);
    }
  }, [getProcessingLogsUrl, logFilterString, logHoursAgo]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
    },
  });
  if (!getPresignedUploadUrl) {
    return (
      <>
        <h2>Error in configuration! No URL provided.</h2>
      </>
    );
  }

  return (
    <Container>
      <h1>Reporting File Uploads</h1>
      <Row>
        <Col>
          <p>
            Upload your file containing the agreed names to upload to the
            correct table. Logs can take a couple of minutes to be processed.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            {...getRootProps({
              className: "dropzone m-3 p-3",
              style: {
                border: "1px dashed darkgrey",
              },
            })}
          >
            <input disabled={isUploading} {...getInputProps()} />
            {acceptedFiles && acceptedFiles.length > 0 ? (
              acceptedFiles.map((f, i) => (
                <p
                  style={{ color: "black" }}
                  className="m-2 text-center"
                  key={i}
                >
                  📄 {f.name} ({Math.floor(f.size / 1024)}KB)
                </p>
              ))
            ) : (
              <p style={{ color: "gray" }} className="m-2 text-center">
                Drag 'n' drop your document, or click to select file
              </p>
            )}
          </div>
        </Col>
      </Row>
      <h4>Log Retrieval Settings</h4>
      <Row className="mt-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="hoursago-entry">Hours Ago</InputGroup.Text>
            <Form.Control
              id="log-retrieval-settings"
              aria-describedby="hoursago-entry"
              type="number"
              disabled={isRetrievingLogs}
              defaultValue={logHoursAgo}
              onChange={(e) => setLogHoursAgo(Number(e.target.value))}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="filter-entry">Filter pattern</InputGroup.Text>
            <Form.Control
              id="basic-url"
              aria-describedby="filter-entry"
              disabled={isRetrievingLogs}
              defaultValue={logFilterString}
              onChange={(e) => setLogFilterString(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col>
          <Button
            variant="success"
            disabled={isRetrievingLogs || !logHoursAgo}
            onClick={() => retrieveLogs()}
          >
            {isRetrievingLogs ? (
              <>
                Retrieving...
                <Spinner className="ms-2" size="sm" animation="border" />
              </>
            ) : (
              "Retrieve Logs"
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isRetrievingLogs && !logHoursAgo ? (
            <Alert variant="warning">Log hours must be provided.</Alert>
          ) : null}
          {isUploading ? (
            <Alert variant="info">
              Uploading...
              <Spinner className="ms-2" size="sm" animation="border" />
            </Alert>
          ) : uploadResult && uploadResult.length > 0 ? (
            <Alert variant="success">{uploadResult}</Alert>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          {processingLogs.map((log, i) => (
            <pre key={i} className="mb-4" style={{ whiteSpace: "normal" }}>
              {new Date(log.timestamp).toLocaleString()}: {log.message}
            </pre>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ReportingDataUploadPage, {
  onRedirecting: () => <Loading />,
});
