import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useFeatureFlagPayload } from "posthog-js/react";
import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";

export interface AppsFeatureFlagEntry {
  link: string;
  key: string;
  name: string;
}

const tabHeight = 1200;
const AppsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const appsConfigurationPayload = useFeatureFlagPayload(
    "appsPageEntries"
  ) as unknown as AppsFeatureFlagEntry[];

  const [key, setKey] = useState<string>(searchParams.get("tab") ?? "");

  useEffect(() => {
    if (key !== null) {
      setSearchParams({ tab: key });
    } else {
      setSearchParams({});
    }

    return () => {
      setSearchParams({});
    };
  }, [key, setSearchParams]);

  if (!key && appsConfigurationPayload?.length > 0) {
    setKey(appsConfigurationPayload[0].key);
  }

  return (
    <Container>
      <Tabs
        id="apps-tab-container"
        activeKey={key}
        onSelect={(k) => setKey(k!)}
      >
        {appsConfigurationPayload
          ? appsConfigurationPayload.map((app, i) => (
              <Tab eventKey={app.key} key={i} title={app.name}>
                <GoogleAppsScriptIframe
                  src={app.link}
                  title={app.name}
                  style={{
                    height: tabHeight,
                    margin: "0.5em",
                  }}
                />
              </Tab>
            ))
          : null}
      </Tabs>
    </Container>
  );
};

export default withAuthenticationRequired(AppsPage, {
  onRedirecting: () => <Loading />,
});
