import { BoxCountComponentProps } from "./types";
import CountComponent from "./count";

const BoxCountComponent = ({
  desiredCount,
  limit,
  stepOutputKey,
  variant,
  refurbIdentifier,
  setStepCompleted,
  stepCompleted,
}: BoxCountComponentProps): JSX.Element => {
  const variantTitleText = () => {
    switch (variant) {
      case "inbound":
        return "Inbound";
      case "outbound":
        return "Outbound";
    }
  };
  return (
    <CountComponent
      title={`${variantTitleText()} Box Count`}
      subtitle={
        desiredCount
          ? `Catalogue expects ${desiredCount} boxes`
          : "Please enter the number of boxes that will be sent"
      }
      limit={limit}
      stepOutputKey={stepOutputKey}
      refurbIdentifier={refurbIdentifier}
      setStepCompleted={setStepCompleted}
      stepCompleted={stepCompleted}
    />
  );
};

export default BoxCountComponent;
