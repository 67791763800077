import { useCallback, useEffect, useMemo, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { $isHeadingNode } from "@lexical/rich-text";
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import {
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { useEditorHistoryState } from "../context/EditorHistoryState";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ListOl,
  ListUl,
  Trash,
  TypeBold,
  TypeItalic,
} from "react-bootstrap-icons";
import { EditorButtonIconSize, EditorButtonStyle } from "../Editor";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const blockTypeToBlockName = {
  bullet: "Bulleted List",
  number: "Numbered List",
  check: "Check List",
  paragraph: "Normal",
  // Not supported entry types for now
  // code: "Code Block",
  // h1: "Heading 1",
  // h2: "Heading 2",
  // h3: "Heading 3",
  // h4: "Heading 4",
  // h5: "Heading 5",
  // h6: "Heading 6",
  // quote: "Quote",
};

export interface ActionsPluginProps {
  readOnly?: boolean;
}

export function ActionsPlugin({ readOnly }: ActionsPluginProps) {
  const [editor] = useLexicalComposerContext();
  const { historyState } = useEditorHistoryState();

  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  const { undoStack, redoStack } = historyState ?? {};
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  // useEffect(() => {
  //   if (!readOnly && canEdit !== editor.isEditable()) {
  //     setCanEdit(editor.isEditable());
  //     editor.setEditable(canEdit);
  //   }
  // }, [editor, readOnly, canEdit]);

  const MandatoryPlugins = useMemo(() => {
    return <ClearEditorPlugin />;
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const root = $getRoot();
          const children = root.getChildren();

          if (children.length > 1) {
            setIsEditorEmpty(false);
            return;
          }

          if ($isParagraphNode(children[0])) {
            setIsEditorEmpty(children[0].getChildren().length === 0);
          } else {
            setIsEditorEmpty(false);
          }
        });
      }),
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [activeEditor, editor]);

  useEffect(
    function checkEditorHistoryActions() {
      return editor.registerUpdateListener(() => {
        setCanRedo(redoStack?.length !== 0);
        setCanUndo(undoStack?.length !== 0);
      });
    },
    [editor, undoStack, redoStack]
  );

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  // When the selection changes, update the toolbar
  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <>
      {!readOnly && !canEdit ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-right`}>
              Editing this may cause changes even without changing content, as
              new HTML is generated when using the editor
            </Tooltip>
          }
        >
          <ButtonGroup className="mb-2 me-2">
            <Button
              variant={EditorButtonStyle}
              onClick={() => {
                setCanEdit(!canEdit);
                editor.setEditable(true);
              }}
            >
              Edit
            </Button>
          </ButtonGroup>
        </OverlayTrigger>
      ) : null}
      {MandatoryPlugins}
      {canEdit ? (
        <ButtonGroup className="mb-2 me-2">
          <Button
            disabled={!isEditable}
            active={isBold}
            variant={EditorButtonStyle}
            aria-label="Format text as bold"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
          >
            <TypeBold size={EditorButtonIconSize} />
          </Button>
          <Button
            active={isItalic}
            disabled={!isEditable}
            aria-label="Format text as italics"
            variant={EditorButtonStyle}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            }}
          >
            <TypeItalic size={EditorButtonIconSize} />
          </Button>
        </ButtonGroup>
      ) : null}
      {canEdit ? (
        <ButtonGroup className="mb-2 me-2">
          <Button
            active={blockType === "number"}
            disabled={!isEditable}
            variant={EditorButtonStyle}
            aria-label="Insert numbered list"
            onClick={formatNumberedList}
          >
            <ListOl size={EditorButtonIconSize} />
          </Button>
          <Button
            active={blockType === "bullet"}
            disabled={!isEditable}
            aria-label="Insert bulleted list"
            variant={EditorButtonStyle}
            onClick={formatBulletList}
          >
            <ListUl size={EditorButtonIconSize} />
          </Button>
        </ButtonGroup>
      ) : null}
      {canEdit ? (
        <ButtonGroup className="mb-2 me-2">
          <Button
            variant={EditorButtonStyle}
            disabled={!canUndo || !isEditable}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
          >
            <ArrowCounterclockwise size={EditorButtonIconSize} />
          </Button>
          <Button
            variant={EditorButtonStyle}
            disabled={!canRedo || !isEditable}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
          >
            <ArrowClockwise size={EditorButtonIconSize} />
          </Button>
        </ButtonGroup>
      ) : null}
      {canEdit ? (
        <ButtonGroup className="mb-2">
          <Button
            variant={EditorButtonStyle}
            disabled={isEditorEmpty || !isEditable}
            onClick={() => {
              editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            }}
          >
            Clear All <Trash size={EditorButtonIconSize} />
          </Button>
        </ButtonGroup>
      ) : null}
    </>
  );
}
