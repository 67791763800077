import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { InventoryItem } from "../../external";
import Alert from "react-bootstrap/Alert";

interface InventoryInboundNotesProps {
  index: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: InventoryItem[];
  handleNotesUpdate: (notes: string, setFieldValue: any, index: number) => void;
  notesSaved: boolean;
}

const InventoryInboundNotesModal: React.FC<InventoryInboundNotesProps> = ({
  index,
  isModalOpen,
  handleCloseModal,
  setFieldValue,
  values,
  handleNotesUpdate,
  notesSaved,
}) => {
  const [note, setNote] = React.useState<string>("");

  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          {notesSaved ? (
            <Alert variant="success">Notes saved successfully.</Alert>
          ) : null}
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={values[index]?.comments || ""}
            onChange={(e) => setNote(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => handleNotesUpdate(note, setFieldValue, index)}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryInboundNotesModal;
