import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FaqSection from "../components/faqs/faq-section";
import faqsData from "../components/faqs/faq-data";
import { UserAuthenticatedContext } from "../components/profile";
import { useCircleDarkMode } from "../hooks/useCircleDarkMode";

const FaqPage: React.FC = () => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [userToken, setUserToken] = useState<string>();

  const darkMode = useCircleDarkMode();
  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  return (
    <Container className="justify-content-md-center">
      <h1>FAQs and Support</h1>
      <div>
        <h2>Contents</h2>
        <ButtonGroup vertical>
          {faqsData.map((faqSection, index) => (
            <Button
              key={index}
              variant={darkMode ? "outline-light" : "outline-dark"}
              href={`#section${index}`}
              className="p-2"
              style={{ textDecoration: "none" }}
            >
              <span style={{ marginLeft: "2em", marginRight: "2em" }}>
                {faqSection.heading}
              </span>
            </Button>
          ))}
        </ButtonGroup>
      </div>
      {faqsData
        .filter((faqSection) => !faqSection.authenticationRequired || userToken)
        .map((faqSection, index) => (
          <FaqSection
            key={index}
            id={`section${index}`}
            heading={faqSection.heading}
            faqs={faqSection.faqs}
          />
        ))}
    </Container>
  );
};

export default FaqPage;
