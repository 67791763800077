import Container from "react-bootstrap/Container";
import ReactBootstrapTable from "react-bootstrap/Table";
import {
  ColumnDef,
  ColumnResizeMode,
  ColumnSizingInfoState,
  ColumnSizingState,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useEffect } from "react";
import { useSkipper } from "./hooks";
import TableHeaderBar from "./header-bar";
import TableFooterBar from "./footer-bar";
import { useLocalStorage } from "usehooks-ts";
import TableFilter from "./filter";

declare module "@tanstack/react-table" {
  // We have to disable this eslint rule to override the module correctly
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const pageSizeOptions = [10, 20, 30, 40, 60, 80, 100];

export interface ClearCycleEditableTableProps<T> {
  tableKey: string;
  columns: ColumnDef<T, any>[];
  tableData: T[];
  setTableData: (v: React.SetStateAction<T[]>) => void;

  /**
   * This function is run when the user clicks the save button.
   * @returns True if successful
   */
  onSaveButtonClicked?: () => Promise<boolean>;
  /**
   * This function is run when the user clicks the cancel button.
   * @returns True if successful.
   */
  onCancelButtonClicked?: () => Promise<boolean>;

  columnResizeMode?: ColumnResizeMode;
}

export const DisplayVertical = (text: string) => (
  <div style={{ writingMode: "vertical-lr" }}>
    <span>{text}</span>
  </div>
);

const headerFooterVariant = "outline-secondary";
const footerButtonIconSize = 24;

const ClearCycleEditableTable = <T extends Object>({
  columns,
  tableKey,
  tableData,
  setTableData,
  onCancelButtonClicked,
  onSaveButtonClicked,
  columnResizeMode = "onChange",
}: ClearCycleEditableTableProps<T>) => {
  const defaultColumn: Partial<ColumnDef<T>> = {
    size: 80,
    cell: ({ getValue, row, column, table }) => {
      const initialValue = getValue();
      // We need to keep and update the state of the cell normally
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [value, setValue] = useState(initialValue);

      // When the input is blurred, we'll call our table meta's updateData function
      const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
      };
      // If the initialValue is changed external, sync it up with our state
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return (
        <input
          value={value as string}
          style={{ width: column.getSize() - 2 }}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
        />
      );
    },
  };

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `cc-${tableKey}__displayColumns`,
    {}
  );
  const [showFilters, setShowFilters] = useLocalStorage<boolean>(
    `cc-${tableKey}__showFilters`,
    true
  );

  const [columnSizing, setColumnSizing] = useLocalStorage<ColumnSizingState>(
    `cc-${tableKey}__columnSizing`,
    {}
  );
  const [columnSizingInfo, setColumnSizingInfo] =
    useLocalStorage<ColumnSizingInfoState>(`cc-${tableKey}__columnSizingInfo`, {
      startOffset: null,
      startSize: null,
      deltaOffset: null,
      deltaPercentage: null,
      isResizingColumn: false,
      columnSizingStart: [],
    });

  const table = useReactTable<T>({
    defaultColumn,
    data: tableData,
    columns,
    state: {
      columnVisibility,
      columnSizing,
      columnSizingInfo,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
    columnResizeMode,
    autoResetPageIndex,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  return (
    <Container style={{ maxWidth: "100%" }}>
      <TableHeaderBar
        {...{
          showFilters,
          setShowFilters,
          table,
          onSaveButtonClicked,
          onCancelButtonClicked,
        }}
      />
      <Container style={{ overflowX: "auto", maxWidth: "100%" }}>
        <ReactBootstrapTable
          style={{
            width: table.getCenterTotalSize(),
          }}
          className="editable-table"
          bordered
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() && showFilters ? (
                          <TableFilter column={header.column} table={table} />
                        ) : null}
                      </div>
                    )}
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`,
                        style: {
                          width: "5px !important",
                          transform:
                            columnResizeMode === "onEnd" &&
                            header.column.getIsResizing()
                              ? `translateX(${
                                  table.getState().columnSizingInfo.deltaOffset
                                }px)`
                              : "",
                        },
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="editable-table-body">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </ReactBootstrapTable>
      </Container>
      <TableFooterBar
        table={table}
        variant={headerFooterVariant}
        iconSize={footerButtonIconSize}
        pageSizeOptions={pageSizeOptions}
      />
    </Container>
  );
};

export default ClearCycleEditableTable;
