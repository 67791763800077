import { CatalogItem } from "../../../external";
import ImageCarousel from "../../image-carousel";

export interface CatalogItemImageCarouselProps {
  catalogItem: CatalogItem;
  viewOnly: boolean;
  clientImageLinks: string[];
  attachedFilesLinks: string[];
  selectedImageIndex: number;
  onSelectedImageIndex: (num: number) => void;
}

const CatalogItemImageCarousel = ({
  catalogItem,
  viewOnly,
  clientImageLinks,
  attachedFilesLinks,
  selectedImageIndex,
  onSelectedImageIndex,
}: CatalogItemImageCarouselProps) => {
  const imagesAvailable =
    (catalogItem.clientImageLinks && catalogItem.clientImageLinks.length > 0) ||
    (catalogItem.attachedFiles && catalogItem.attachedFiles.length > 0);

  if (!imagesAvailable) {
    return <h2>No images (yet)</h2>;
  }

  if (viewOnly) {
    <ImageCarousel
      imageUrls={[...clientImageLinks, ...attachedFilesLinks]}
      maxWidth={"600px"}
    />;
  }

  return (
    <ImageCarousel
      imageUrls={[...clientImageLinks, ...attachedFilesLinks]}
      autoSlide={false}
      maxWidth={"400px"}
      controlledProps={{
        selectedIndex: selectedImageIndex,
        onSelect: onSelectedImageIndex,
      }}
    />
  );
};

export default CatalogItemImageCarousel;
