import { QueryParamConfig } from "use-query-params";
import { EbayCategory } from "../../workers/ebay-categories";

export const EBayCategoryParam: QueryParamConfig<EbayCategory[]> = {
  encode: (value) => {
    if (!value || value.length === 0) {
      return undefined;
    }
    const stringifiedValue = JSON.stringify(value.map((v) => v.id));
    return btoa(stringifiedValue);
  },
  decode: (value): EbayCategory[] => {
    if (!value) {
      return [];
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return [];
      }
      if (value.filter((a) => !a).length === 0) {
        return [];
      }
    }
    const decodedValue = atob(value as string);
    return JSON.parse(decodedValue).map((v: string) => ({ id: v, name: "" }));
  },
};

export const SortDirectionParam: QueryParamConfig<"asc" | "desc"> = {
  encode: (value: "asc" | "desc"): string => value,
  decode: (
    value: string | (string | null)[] | null | undefined
  ): "asc" | "desc" => (value === "asc" || value === "desc" ? value : "asc"),
};
