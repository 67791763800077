// FloatingAlert.tsx
import React from "react";
import Alert, { AlertProps } from "react-bootstrap/Alert";

export interface FloatingAlertProps
  extends AlertProps,
    React.RefAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const FloatingAlert: React.FC<FloatingAlertProps> = (props) => {
  const marginAmount = "20px";

  return (
    <Alert
      {...props}
      className="d-flex"
      style={{
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        bottom: marginAmount,
        left: "0",
        right: "0",
        margin: `0 ${marginAmount}`,
        zIndex: 9999,
        opacity: 0.9,
      }}
    >
      {props.children}
    </Alert>
  );
};

export default FloatingAlert;
