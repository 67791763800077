import { Editor } from "./Editor";

export interface WysiwygEditorProps {
  namespace: string;
  value: string | undefined;
  setValue: (value: string) => void;
  viewOnly: boolean;
  isSaving: boolean;
  maxLength?: number;
}

const WYSIWYGEditor = ({
  value,
  setValue,
  viewOnly,
  isSaving,
  namespace,
}: WysiwygEditorProps) => {
  const onChange = (e: string) => {
    setValue(e);
  };
  return (
    <Editor
      namespace={namespace}
      initialValue={value}
      readOnly={viewOnly || isSaving}
      onChange={onChange}
    ></Editor>
  );
};
export default WYSIWYGEditor;
