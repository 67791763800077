import { CSSProperties, useState } from "react";
import Carousel, { CarouselProps } from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";

export interface ImageCarouselProps {
  imageUrls: string[];
  autoSlide?: boolean;
  maxWidth?: string;
  rounded?: boolean;
  controlledProps?: ImageCarouselControlledProps;
  imageCustomStyles?: CSSProperties;
  noAnimation?: boolean;
}

export interface ImageCarouselControlledProps {
  selectedIndex: number;
  onSelect: (num: number) => void;
  currentSlideOverlay?: JSX.Element;
}

const ImageCarousel = (props: ImageCarouselProps): JSX.Element => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  let {
    imageUrls,
    controlledProps,
    maxWidth = "600px",
    autoSlide = true,
    rounded = true,
    noAnimation = false,
    imageCustomStyles,
  } = props;

  let slideInterval: null | number = 2500;
  if (!autoSlide) {
    slideInterval = null;
  }
  if (imageCustomStyles === undefined) {
    imageCustomStyles = {};
  }

  const baseCarouselProps: CarouselProps = {
    style: { maxWidth, margin: "0.5em" },
    variant: "dark",
    interval: slideInterval,
    slide: !noAnimation,
  };

  const ControlledCarousel = (
    p: ImageCarouselProps & {
      enableClickToPreview: boolean;
    }
  ) => (
    <Carousel
      activeIndex={p.controlledProps!.selectedIndex}
      onSelect={p.controlledProps!.onSelect}
      {...baseCarouselProps}
    >
      {imageUrls.map((imageUrl, index) => (
        <Carousel.Item
          key={`imageUrl-${index}`}
          onClick={
            p.enableClickToPreview
              ? () => {
                  setShowPreviewModal(true);
                }
              : undefined
          }
        >
          <Image
            style={imageCustomStyles}
            className="d-block w-100"
            rounded={rounded}
            src={imageUrl}
          />
          {p.controlledProps!.currentSlideOverlay ? (
            <Carousel.Caption
              style={{
                color: "white",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              {p.controlledProps!.currentSlideOverlay}
            </Carousel.Caption>
          ) : null}
        </Carousel.Item>
      ))}
    </Carousel>
  );

  const UncontrolledCarousel = (
    p: ImageCarouselProps & { enableClickToPreview: boolean }
  ) => (
    <Carousel {...baseCarouselProps}>
      {p.imageUrls.map((imageUrl, index) => (
        <Carousel.Item key={`imageUrl-${index}`}>
          <Image
            className="d-block"
            rounded={p.rounded}
            src={imageUrl}
            style={p.imageCustomStyles}
            onClick={
              p.enableClickToPreview
                ? () => {
                    setShowPreviewModal(true);
                  }
                : undefined
            }
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );

  const ImageFullViewModal = () => (
    <Modal
      show={showPreviewModal}
      onHide={() => setShowPreviewModal(false)}
      dialogClassName="modal-90w modal-90h"
      aria-labelledby="show-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="show-preview-modal">Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UncontrolledCarousel
          {...props}
          imageCustomStyles={{
            aspectRatio: "auto",
            maxHeight: "80vh",
            maxWidth: "100%", // keeps image fully visible
          }}
          enableClickToPreview={false}
        />
      </Modal.Body>
    </Modal>
  );

  if (controlledProps) {
    return (
      <>
        <ControlledCarousel {...props} enableClickToPreview={true} />
        <ImageFullViewModal />
      </>
    );
  }
  return (
    <>
      <UncontrolledCarousel {...props} enableClickToPreview={true} />
      <ImageFullViewModal />
    </>
  );
};

export default ImageCarousel;
