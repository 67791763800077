import Dinero from "dinero.js";
import { AuditHistoryEntry, CatalogItem, Client } from "../../external";
import "../../App.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import CatalogItemDuplicateModalComponent from "./catalog-duplicate-modal";
import { UserAuthenticatedContext } from "../profile";
import {
  Ban,
  ExclamationCircle,
  Image,
  Link45deg,
} from "react-bootstrap-icons";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";

export interface CatalogRowProps {
  catalogItem: CatalogItem;
  clients: Client[];
}

const CatalogRowComponent = (props: CatalogRowProps): JSX.Element => {
  const navigate = useNavigate();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [showModal, setShowModal] = useState(false);

  const [attachedFilesLinks, setAttachedFilesLinksState] = useState<string[]>(
    []
  );

  const { catalogItem, clients } = props;

  useEffect(() => {
    if (catalogItem.attachedFiles) {
      const attachmentLinks = catalogItem.attachedFiles?.map(
        (file) => file.filePath
      );
      setAttachedFilesLinksState(attachmentLinks);
    }
  }, [catalogItem.attachedFiles]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const darkMode = useCircleDarkMode();

  const auditHistoryTableItem = (
    auditHistory?: AuditHistoryEntry[]
  ): JSX.Element => (
    <td>
      {auditHistory && auditHistory.length > 0 ? (
        new Date(auditHistory[auditHistory.length - 1].timestamp).toDateString()
      ) : (
        <i style={{ color: "grey" }}>No audit info available</i>
      )}
    </td>
  );

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const linkTableItem = (darkMode: boolean, links?: string[]): JSX.Element => {
    const fontColor = darkMode ? "lightblue" : "blue";
    return (
      <td>
        {links && links.length > 0 ? (
          links.map((link, i) => (
            <Button
              size="sm"
              key={i}
              onClick={(e) => {
                e.preventDefault();
                // Prevents the row click event from firing
                e.stopPropagation();
                openInNewTab(link);
              }}
              variant={darkMode ? "dark" : "light"}
              style={{
                fontSize: "1.25em",
                color: fontColor,
                textDecoration: "none",
              }}
            >
              <Link45deg color={fontColor} /> {i + 1}
            </Button>
          ))
        ) : (
          <Ban color="lightgrey" />
        )}
      </td>
    );
  };

  const picturesTableItem = (
    itemName: string,
    pictureLinks?: string[]
  ): JSX.Element => {
    const picWidth = 90;
    return (
      <td
        style={{
          maxWidth: `${picWidth + 2}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {pictureLinks && pictureLinks.length > 0 ? (
            <picture>
              <source srcSet={pictureLinks[0]}></source>
              <img
                style={{ maxWidth: `${picWidth}px` }}
                alt={`${itemName}`}
                src={pictureLinks[0]}
              ></img>
            </picture>
          ) : (
            <Image color="lightgrey" />
          )}
        </div>
      </td>
    );
  };

  const moneyTableItem = (priceInPence?: number): JSX.Element => {
    if (
      !priceInPence ||
      (priceInPence as unknown as string) === "" ||
      typeof priceInPence !== "number"
    ) {
      return (
        <td>
          {priceInPence}
          <ExclamationCircle
            style={{ marginBottom: "0.25em", marginLeft: "0.25em" }}
            size={18}
            color="red"
          />
        </td>
      );
    }

    return (
      <td>
        {Dinero({
          amount: Math.round(priceInPence),
          currency: "GBP",
        }).toFormat("$0,0.00")}
      </td>
    );
  };

  return (
    <tr
      key={catalogItem.itemId}
      className="catalog-item-row"
      style={{
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.preventDefault();

        const target = e.target as HTMLElement;

        if (
          target.nodeName !== "BUTTON" &&
          !(e.target instanceof HTMLAnchorElement) &&
          !(target.classList.contains("modal") || target.closest(".modal"))
        )
          if (
            userAuthenticatedContext.scopes?.includes("edit:catalogue-item") ||
            userAuthenticatedContext.scopes?.includes(
              "create:task-item:change-request"
            )
          ) {
            navigate(`/catalog/item/${catalogItem.itemId}`);
          } else {
            navigate(`/catalog/item/public/${catalogItem.itemId}`);
          }
      }}
    >
      {picturesTableItem(catalogItem.name ? catalogItem.name : "", [
        ...catalogItem.clientImageLinks,
        ...attachedFilesLinks,
      ])}
      <td>{catalogItem.clientId}</td>
      <td style={{ maxWidth: "150px" }}>
        <code>{catalogItem.clientItemId}</code>
      </td>
      <td style={{ maxWidth: "250px" }}>
        {catalogItem.name ? catalogItem.name : ""}
      </td>
      {moneyTableItem(
        catalogItem.retailPricePence ? catalogItem.retailPricePence : 0
      )}
      {linkTableItem(darkMode ?? false, catalogItem.clientSkuHyperlinks)}
      {auditHistoryTableItem(catalogItem.auditHistory)}
      {userAuthenticatedContext.scopes?.includes("create:catalogue-item") ? (
        <td>
          <Button
            size="sm"
            variant="primary"
            onClick={() => setShowModal(true)}
          >
            Duplicate
          </Button>
        </td>
      ) : null}
      <CatalogItemDuplicateModalComponent
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        itemId={catalogItem.itemId}
        clientItemId={catalogItem.clientItemId}
        clients={clients}
      />
    </tr>
  );
};

export default CatalogRowComponent;
