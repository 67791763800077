import { Dispatch, SetStateAction } from "react";
import { useLocalStorage } from "usehooks-ts";

const privateUseDebugMode = (): readonly [
  boolean,
  Dispatch<SetStateAction<number>>
] => {
  // We're going to wrap this hook for 2 different use cases so this disable is OK
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = useLocalStorage<number>(
    "ClearCycle_debug",
    process.env.REACT_APP_CCENV === "dev" ? 1 : 0 // Set debug to TRUE in dev
  );

  return [value !== 0, setValue];
};

export const useDebugMode = (): boolean => {
  const use = privateUseDebugMode();
  return use[0];
};

export const useReadWriteDebugMode = (): readonly [
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const [isDebugMode, setIsDebugModeNumber] = privateUseDebugMode();

  const updateFunc: Dispatch<SetStateAction<boolean>> = (value) => {
    const newValue = value instanceof Function ? value(isDebugMode) : value;
    setIsDebugModeNumber(newValue ? 1 : 0);
  };

  return [isDebugMode, updateFunc];
};
