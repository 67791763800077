import { CatalogItem } from "../external";
import { CatalogItemCreation } from "../pages/catalog/new-catalog";

const fieldNames: { [key: string]: string } = {
  clientId: "Client ID",
  clientItemId: "Client SKU/Item ID",
  itemId: "ClearCycle Item ID",
  name: "Item Name",
  description: "Description",
  retailPricePence: "Recommended Retail Price (RRP)",
  weightGrams: "Weight",
  boxDetails: "Box Details",
  images: "Listing Images",
};

export const missingListingData = (
  catalogItem: Partial<CatalogItem | CatalogItemCreation>
): string[] => {
  const requiredFields = [
    "clientId",
    "clientItemId",
    "itemId",
    "name",
    "description",
    "retailPricePence",
    "weightGrams",
  ];
  const missingKeys = requiredFields.filter(
    (key) =>
      !(key in catalogItem) ||
      catalogItem[key as keyof CatalogItem] === undefined ||
      catalogItem[key as keyof CatalogItem] === null ||
      (["retailPricePence", "weightGrams", "boxCount"].includes(key) &&
        catalogItem[key as keyof CatalogItem] === 0)
  );

  if (
    !catalogItem.boxCount &&
    (!catalogItem.dimensions || catalogItem.dimensions.boxes.length === 0)
  ) {
    missingKeys.push("boxDetails");
  }

  if (
    (!catalogItem.listingImageLinks ||
      (catalogItem.listingImageLinks &&
        catalogItem.listingImageLinks.length === 0)) &&
    (!catalogItem.attachedFiles ||
      (catalogItem.attachedFiles && catalogItem.attachedFiles.length === 0))
  ) {
    missingKeys.push("images");
  }

  return missingKeys.map((key) => fieldNames[key] || key);
};
