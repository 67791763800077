import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { updateTaskStatus } from "../../workers/task";
import { UserAuthenticatedContext } from "../profile";

interface Props {
  taskId: string;
  setTaskStatus: (status: string) => void;
  setShowRejectModal: (show: boolean) => void;
  showRejectModal: boolean;
  rejectReason: string;
  setRejectReason: (reason: string) => void;
}

const RejectModal: React.FC<Props> = ({
  taskId,
  setTaskStatus,
  setShowRejectModal,
  showRejectModal,
  rejectReason,
  setRejectReason,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const handleRejectChange = async () => {
    if (userAuthenticatedContext.token) {
      setIsSaving(true);
      await updateTaskStatus(
        userAuthenticatedContext.token,
        taskId,
        "rejected",
        rejectReason
      );
      setTaskStatus("rejected");
      setShowRejectModal(false);
      setIsSaving(false);
    }
  };

  const handleRejectReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRejectReason(event.target.value);
  };

  return (
    <Modal
      show={showRejectModal}
      onHide={() => {
        // Only allow closing if not in the process of saving
        if (!isSaving) {
          setShowRejectModal(false);
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Reject</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="rejectReason">
            <Form.Control
              type="text"
              placeholder="Enter Reason for Rejection"
              value={rejectReason}
              onChange={handleRejectReasonChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSaving}
          variant="secondary"
          onClick={() => setShowRejectModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!rejectReason || isSaving}
          onClick={async () => {
            await handleRejectChange();
            setShowRejectModal(false);
          }}
        >
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectModal;
