import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";

import { Client } from "../../external";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../profile";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { QuestionCircle } from "react-bootstrap-icons";

export interface CatalogUploadComponentProps {
  clients: Client[];
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const createCatalogItemsWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

const renderTooltip = () => (
  <Tooltip id="button-tooltip">
    Marking the upload as priority will raise a Manifested Item Update request
    foor each catalogue item.
  </Tooltip>
);

const CatalogUploadComponent = ({
  clients,
}: CatalogUploadComponentProps): JSX.Element => {
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [userToken, setUserToken] = useState<string>();
  const [showRejections, setShowRejections] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadComplete, setUploadIsComplete] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>();
  const [isMigration, setIsMigration] = useState<boolean>(false);
  const [isPriority, setIsPriority] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isMigrationSelected, setIsMigrationSelected] = useState(false);
  const [isPrioritySelected, setIsPrioritySelected] = useState(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const catalogItemsWorker = useWorker(createCatalogItemsWorker);
  const navigate = useNavigate();

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback((acceptedFiles) => {
    // TODO: Do something with the files
    console.info(acceptedFiles);
  }, []);

  const {
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      setShowRejections(true);
    }
  }, [fileRejections]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const upload = () => {
    try {
      setIsUploading(true);
      setUploadIsComplete(false);
      setUploadMessage("");

      if (!acceptedFiles[0]) {
        throw new Error("bad accepted file");
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(acceptedFiles[0]);
      fileReader.onload = (e) => {
        if (userToken) {
          let encoded = e
            .target!.result!.toString()
            .replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          catalogItemsWorker
            .postCatalogTemplate(
              userToken,
              selectedClient!.clientId,
              encoded,
              isMigration,
              isPriority
            )
            .then((result) => {
              setUploadIsComplete(true);
              setUploadMessage(result.message);
              console.info(result);
              setIsUploading(false);
            })
            .catch((error: AxiosError) => {
              setUploadMessage(error.message);
              setIsUploading(false);
            });
        }
      };
    } catch (e) {
      setIsUploading(false);
    }
  };

  const showUploadModal = () => {
    setShowConfirmationModal(true);
  };

  const confirmUpload = () => {
    upload();
    setShowConfirmationModal(false);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  return (
    <Container>
      <h1>Catalogue Upload</h1>
      <Row className="m-1">
        <Alert variant="success">
          <p>
            <a
              href="/downloads/CatalogUploadTemplate.csv"
              download={"CatalogUploadTemplate.csv"}
            >
              Download the template
            </a>
            , populate it and upload it again here. Select the client below. You
            will be asked to confirm before submission.
          </p>
          <hr />
          <p className="mb-0">
            To create a file in the correct format, you can download the CSV,
            open it in Excel, paste in the fields, then save the file and upload
            it again. If you want to create a file from scratch, ensure you save
            in Excel as a CSV (Save As &gt; File Format &gt; CSV
            (Comma-delimited))
          </p>
        </Alert>
      </Row>
      <Row className="m-1">
        <Alert variant="info">
          Items are keyed on the client ID and SKU ID. The data you upload will
          overwrite any existing data in the system that matches the current
          client ID and SKU.
        </Alert>
      </Row>
      <Row className="m-1 align-items-center">
        <Col xs="auto">
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="search-client">
              {selectedClient ? selectedClient.clientName : "Select Client"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[...clients]
                .sort((a, b) => a.clientName.localeCompare(b.clientName))
                .map((c, i) => (
                  <Dropdown.Item
                    onClick={() => setSelectedClient(c)}
                    eventKey={`${i}`}
                    key={c.clientId}
                  >
                    {c.clientName}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      {fileRejections && fileRejections.length > 0 && showRejections ? (
        <Row className="m-1">
          <Alert
            variant="danger"
            onClose={() => {
              setShowRejections(false);
            }}
            dismissible
          >
            <p className="m-0">
              Invalid file type uploaded! The file must be a CSV file.
            </p>
          </Alert>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-4 m-2">
        {selectedClient ? (
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {acceptedFiles && acceptedFiles.length > 0 ? (
                acceptedFiles.map((f, i) => (
                  <p style={{ color: "black" }} className="m-3" key={i}>
                    📄 {f.name} ({Math.floor(f.size / 1024)}KB)
                  </p>
                ))
              ) : (
                <p className="m-3">
                  Drag 'n' drop your populated CSV here with items for{" "}
                  {selectedClient.clientName}, or click to select file
                </p>
              )}
            </div>
          </div>
        ) : (
          <i>Select a client to continue</i>
        )}
      </Row>
      <Row>
        <Col className="m-3">
          <Button
            variant="success"
            disabled={
              isUploading ||
              !selectedClient ||
              !acceptedFiles ||
              acceptedFiles.length === 0
            }
            onClick={showUploadModal}
          >
            <Spinner
              as={"span"}
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
              style={{ marginRight: "0.5em" }}
              className={isUploading ? "" : "visually-hidden"}
            />
            Upload
          </Button>
          <Button
            style={{ marginLeft: "0.5em" }}
            onClick={() => navigate("/catalog/uploaded")}
          >
            View All Uploads
          </Button>
        </Col>
      </Row>
      <Row>
        <Col class="m-3">
          <Alert
            className={uploadMessage ? "" : "visually-hidden"}
            variant={uploadMessage && uploadComplete ? "success" : "danger"}
            onClose={() => setUploadIsComplete(false)}
            dismissible
          >
            <p>
              {uploadMessage && uploadComplete
                ? "Successsfully uploaded file"
                : "Error uploading file"}
            </p>
            {uploadMessage ? (
              <>
                <hr />
                <p className="mb-0">{uploadMessage}</p>
              </>
            ) : (
              <></>
            )}
          </Alert>
        </Col>
      </Row>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <p>Is this a Client Migration?</p>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Form.Check
                type="radio"
                label="No"
                name="migrationRadio"
                id="migrationRadioNo"
                onClick={() => {
                  setIsMigration(false);
                  setIsMigrationSelected(true);
                }}
              />
            </Col>
            <Col md="auto">
              <Form.Check
                type="radio"
                label="Yes"
                name="migrationRadio"
                id="migrationRadioYes"
                onClick={() => {
                  setIsMigration(true);
                  setIsMigrationSelected(true);
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ margin: 0 }}>
                  Would you like to set these to Priority?
                </p>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <QuestionCircle
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      color: "blue",
                    }}
                  />
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Form.Check
                type="radio"
                label="No"
                name="priorityRadio"
                id="priorityRadioNo"
                onClick={() => {
                  setIsPriority(false);
                  setIsPrioritySelected(true);
                }}
              />
            </Col>
            <Col md="auto">
              <Form.Check
                type="radio"
                label="Yes"
                name="priorityRadio"
                id="priorityRadioYes"
                onClick={() => {
                  setIsPriority(true);
                  setIsPrioritySelected(true);
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmationModal(false);
              setIsMigrationSelected(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={confirmUpload}
            disabled={!isMigrationSelected || !isPrioritySelected}
          >
            {isMigrationSelected ? "Migrate" : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CatalogUploadComponent;
