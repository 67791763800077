import { useState } from "react";
import Button from "react-bootstrap/Button";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { useLocalStorage } from "usehooks-ts";

import { Client } from "../../external";
import UploadManifestFileComponent from "./upload-manifest";
import ClearCycleEditableTable, { DisplayVertical } from "../table";
import CurrencyInput from "react-currency-input-field";

/**
 * Specifies fields for an imported manifest template.
 *
 * This interface *must* match with the Import Manifest template
 * that is provided to download.
 */
export interface ImportedManifestEntry {
  productSku: string;
  variant: string;
  palletRef: string;
  retailPricePence: number;
  itemDescription: string;
  productCategory: string;
  quantity: number;
  clientGrade: string;
  clientGradingComments: string;
  inboundDate: string;
  originalOrderRef: string;
  originalReturnRef: string;
  weightGrams: string;
  hyperlink: string;
  packageLengthCM: number;
  packageWidthCM: number;
  packageHeightCM: number;
  productLengthCM: number;
  productWidthCM: number;
  productHeightCM: number;
}

export interface ManifestEntry extends ImportedManifestEntry {
  inventoryId: string;
  quarantine?: boolean;
  catalogItemId?: string;
}

export interface InboundManifestComponentProps {
  clients: Client[];
  userToken: string;
  manifestId: string;
}

const columnHelper = createColumnHelper<ManifestEntry>();
const defaultColumns = [
  columnHelper.accessor("productSku", {
    header: () => "Product SKU",
    enableSorting: true,
    size: 150,
  }),
  columnHelper.accessor("variant", {
    header: () => "Variant",
    enableSorting: true,
    size: 150,
  }),
  columnHelper.accessor("palletRef", {
    header: () => "Pallet Ref",
    enableSorting: true,
    size: 150,
  }),
  columnHelper.accessor("quantity", {
    header: () => "Quantity",
  }),
  columnHelper.accessor("clientGrade", {
    header: () => "Client Grade",
    size: 150,
  }),
  columnHelper.accessor("clientGradingComments", {
    header: () => "Client Grading Comments",
    size: 250,
  }),
  columnHelper.accessor("inboundDate", {
    header: () => "Inbound Date",
  }),
  columnHelper.accessor("originalOrderRef", {
    header: () => "Original Order Ref",
    size: 150,
  }),
  {
    header: "Catalogue Overrides",
    columns: [
      columnHelper.accessor("retailPricePence", {
        header: () => "Retail Price",
        cell: ({ table, column, row, cell }) => {
          return (
            <CurrencyInput
              prefix="£"
              allowNegativeValue={false}
              className={"form-control"}
              value={cell.getValue()}
              disableAbbreviations={true}
              step={1}
              decimalsLimit={2}
              onValueChange={(value, name) => {
                if (value === null || value === undefined) {
                  table.options.meta?.updateData(row.index, column.id, null);
                  return;
                }
                table.options.meta?.updateData(row.index, column.id, value);
              }}
            />
          );
        },
      }),
      columnHelper.accessor("itemDescription", {
        header: () => "Description",
        size: 250,
      }),
      columnHelper.accessor("productCategory", {
        header: () => "Product Category",
        size: 150,
      }),
      columnHelper.accessor("weightGrams", {
        header: () => "Weight (g)",
      }),
    ],
  },
  {
    header: "Package Dims (cm)",
    columns: [
      columnHelper.accessor("packageLengthCM", {
        header: () => DisplayVertical("Length"),
        size: 60,
      }),
      columnHelper.accessor("packageWidthCM", {
        header: () => DisplayVertical("Width"),
        size: 60,
      }),
      columnHelper.accessor("packageHeightCM", {
        header: () => DisplayVertical("Height"),
        size: 60,
      }),
    ],
  },
  {
    header: "Product Dims (cm)",
    columns: [
      columnHelper.accessor("productLengthCM", {
        header: () => DisplayVertical("Length"),
        size: 60,
      }),
      columnHelper.accessor("productWidthCM", {
        header: () => DisplayVertical("Width"),
        size: 60,
      }),
      columnHelper.accessor("productHeightCM", {
        header: () => DisplayVertical("Height"),
        size: 60,
      }),
    ],
  },
  columnHelper.accessor("quarantine", {
    header: () => DisplayVertical("Quarantine"),
    enableSorting: true,
    size: 30,
    cell: ({ table, column, row, cell }) => (
      <input
        type="checkbox"
        style={{ height: "26px", width: "26px" }}
        checked={cell.getValue()}
        onChange={(e) => {
          table.options.meta?.updateData(
            row.index,
            column.id,
            e.target.checked
          );
        }}
      ></input>
    ),
    enableColumnFilter: false,
  }),
  {
    id: `search`,
    cell: (s) => (
      <Button
        size="sm"
        onClick={() => {
          // Original CSV values
          // s.row.original
          console.log(s);
        }}
      >
        Search
      </Button>
    ),
    enableColumnFilter: false,
    header: "Search",
    size: 40,
  } as ColumnDef<ManifestEntry>,
  {
    id: `create`,
    cell: (s) => (
      <Button
        size="sm"
        variant="success"
        onClick={() => {
          // Original CSV values
          // s.row.original
          console.log(s);
        }}
      >
        Create
      </Button>
    ),
    header: "Create",
    enableColumnFilter: false,
    size: 40,
  } as ColumnDef<ManifestEntry>,
  {
    id: `shipping-service`,
    header: "Shipping Service",
    cell: (s) => <span style={{ color: "darkred" }}>2-Man XXL</span>,
    accessorFn: (row) => row.clientGrade,
    enableColumnFilter: false,
  } as ColumnDef<ManifestEntry>,
  {
    id: `exit`,
    header: "Exit",
    cell: (s) => <span style={{ color: "darkgreen" }}>Auction</span>,
    accessorFn: (row) => row.clientGrade,
    enableColumnFilter: false,
  } as ColumnDef<ManifestEntry>,
];

const InboundManifestComponent = ({
  userToken,
  clients,
  manifestId,
}: InboundManifestComponentProps) => {
  const [manifestRows, setManifestRows] = useLocalStorage<ManifestEntry[]>(
    `cc-manifest__manifestRows-${manifestId}`,
    []
  );

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  if (!manifestRows || manifestRows.length === 0) {
    return <UploadManifestFileComponent setCSVData={setManifestRows} />;
  }

  return (
    <ClearCycleEditableTable
      columns={columns}
      tableData={manifestRows}
      setTableData={setManifestRows}
      tableKey="manifest"
    />
  );
};

export default InboundManifestComponent;
