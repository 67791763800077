import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface SaveTaskModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  saveTask: (taskId: string, catalogItemId: string) => Promise<void>; // change to axios promise
  taskId: string;
  catalogItemId: string;
  isError?: boolean;
  errorMessage?: string;
}

const SaveTaskModal: React.FC<SaveTaskModalProps> = ({
  showModal,
  setShowModal,
  saveTask,
  taskId,
  catalogItemId,
  isError = false,
  errorMessage,
}) => {
  const saveSuccessMessage = "Would you like to mark the task as Complete?";

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Catalogue Item Created</h4>
          <p style={{ fontSize: "1.25rem", marginTop: "0.5rem" }}>
            {catalogItemId}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{errorMessage}</div>}
        <p style={{ margin: "1rem 0" }}>{saveSuccessMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => saveTask(taskId, catalogItemId)}
        >
          Mark Task as Complete
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close Window
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveTaskModal;
