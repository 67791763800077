/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "../../App.scss";

interface ImageRowProps {
  imageUrls: string[];
  selectedImageIndex: number;
  selectImage?: (index: number) => void;
}

const ImageRow: React.FC<ImageRowProps> = ({
  imageUrls,
  selectImage,
  selectedImageIndex,
}) => {
  return (
    <div className="image-row mb-2">
      {imageUrls.map((url, index) => (
        <div
          key={index}
          style={{
            border:
              index === selectedImageIndex ? "3px dashed darkgreen" : "none",
            borderRadius: "var(--bs-border-radius)",
          }}
          className="image-container"
          onClick={() => selectImage && selectImage(index)}
        >
          <img src={url} alt={`Image ${index + 1}`} className="square-image" />
        </div>
      ))}
    </div>
  );
};

export default ImageRow;
