// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { CatalogItem } from "../../../external";
import { formatPenceValueAsMoneyString } from "../../../helpers/money";
import ImageCarousel from "../../image-carousel";
import { getDomainNameFromLink } from "./link-check";

export interface CatalogItemPreviewComponentProps {
  catalogItem: CatalogItem;

  /**
   * Width (CSS attribute).
   * @default 20rem
   */
  width?: string;

  /**
   * Width (CSS attribute).
   * @default 5em
   */
  marginBottom?: string;

  /**
   * Preview the entire item at once instead of a summary
   */
  fullPreview?: boolean;
}

const CatalogItemPreviewComponent = ({
  catalogItem,
  width,
  marginBottom,
  fullPreview,
}: CatalogItemPreviewComponentProps) => {
  return (
    <Card
      style={{ width: width ?? "20rem", marginBottom: marginBottom ?? "5em" }}
    >
      {/* <Card.Header>
        <Nav variant="pills" defaultActiveKey="#first">
          <Nav.Item>
            <Nav.Link href="#first">Active</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#link">Link</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#disabled" disabled>
              Disabled
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header> */}
      {catalogItem.clientImageLinks &&
      catalogItem.clientImageLinks.length > 0 ? (
        <ImageCarousel imageUrls={catalogItem.clientImageLinks}></ImageCarousel>
      ) : (
        <div className="m-3">
          <i>No images available</i>
        </div>
      )}
      <Card.Body>
        <Card.Title>{catalogItem.name}</Card.Title>
        <hr />
        {catalogItem.productCategory ? (
          <Card.Text>
            <b>Category:</b> {catalogItem.productCategory}
          </Card.Text>
        ) : null}
        <Card.Text>
          <b>Retail Price:</b>{" "}
          {catalogItem.retailPricePence
            ? formatPenceValueAsMoneyString(catalogItem.retailPricePence)
            : 0}
        </Card.Text>
        <Card.Text>
          <b>Cost Price:</b>{" "}
          {catalogItem.costPricePence ? (
            formatPenceValueAsMoneyString(catalogItem.costPricePence)
          ) : (
            <i>Not set</i>
          )}
        </Card.Text>
        {!!catalogItem.overallSize ? (
          <Card.Text>
            <b>Dimensions</b>
            <br />
            <span>
              Height: {catalogItem.overallSize.height}cm
              <br />
              Width: {catalogItem.overallSize.width}cm
              <br />
              Depth or Length: {catalogItem.overallSize.length}cm
            </span>
          </Card.Text>
        ) : null}
        {catalogItem.clientSkuHyperlinks
          ? catalogItem.clientSkuHyperlinks.map((l, linkIndex) => (
              <Card.Text key={`link-${linkIndex}`}>
                <b>Links</b>
                <br />
                <a target={"_blank"} href={l} rel="noreferrer">
                  Link {linkIndex + 1} ({getDomainNameFromLink(l)})
                </a>{" "}
                (opens in new tab)
              </Card.Text>
            ))
          : null}
        {catalogItem.weightGrams ? (
          <Card.Text>
            <b>Weight:</b> {`${catalogItem.weightGrams / 1000}kg`}
          </Card.Text>
        ) : null}
        <hr />
        <Card.Text
          dangerouslySetInnerHTML={{
            __html:
              catalogItem.description ?? `<i>No description available</i>`,
          }}
        ></Card.Text>
        {/* <Button variant="primary">Show Full Preview</Button> */}
      </Card.Body>
    </Card>
  );
};

export default CatalogItemPreviewComponent;
