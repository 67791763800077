import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useSessionStorage } from "usehooks-ts";

const CubeEstimator = (): JSX.Element => {
  const [selectedOption, setSelectedOption] = useSessionStorage(
    "calcSelectedOption",
    "U"
  );

  const [height, setHeight] = useSessionStorage("calcHeight", 96);
  const [width, setWidth] = useSessionStorage("calcWidth", 250);
  const [length, setLength] = useSessionStorage("calcLength", 250);
  const [boxCount, setBoxCount] = useSessionStorage("calcBoxCount", 2);
  const [estimatedCube, setEstimatedCube] = useState(0);
  const [estimatedCubePerBox, setEstimatedCubePerBox] = useState(0);

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(parseInt(event.target.value));
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(parseInt(event.target.value));
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLength(parseInt(event.target.value));
  };
  const handleBoxCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBoxCount(parseInt(event.target.value));
  };

  const resetCalc = () => {
    setBoxCount(2);
    setWidth(200);
    setHeight(96);
    setLength(250);
    setSelectedOption("U");
  };

  const uShapeCalculation = useCallback(() => {
    // 5 pieces - 2 corners, a left, a right and a middle
    const cornerSquareCM = height * height * height;
    const cornerSquareM = cornerSquareCM / 1000000;

    const backCM = (width - 2 * height) * height * height;
    const backM = backCM / 1000000;

    const leftAndRightCM = (length - height) * height * height;
    const leftAndRightM = leftAndRightCM / 1000000;

    const squareM = cornerSquareM * 2 + backM + leftAndRightM * 2;
    return squareM;
  }, [height, width, length]);

  const cornerCalc = useCallback(() => {
    const cornerSquareCM = height * height * height;
    const cornerSquareM = cornerSquareCM / 1000000;

    const backCM = (width - height) * height * height;
    const backM = backCM / 1000000;

    const leftRightCM = (length - height) * height * height;
    const leftRightM = leftRightCM / 1000000;

    const squareM = cornerSquareM + leftRightM + backM;
    return squareM;
  }, [height, width, length]);

  const chaiseEnd = useCallback(() => {
    const backCM = width * height * height;
    const backM = backCM / 1000000;

    const chaiseCM = (length - height) * height * (height / 2);
    const chaiseM = chaiseCM / 1000000;

    const squareM = chaiseM + backM;
    return squareM;
  }, [height, width, length]);

  useEffect(() => {
    if (selectedOption === "U") {
      setEstimatedCube(uShapeCalculation());
    } else if (selectedOption === "Corner") {
      setEstimatedCube(cornerCalc());
    } else if (selectedOption === "Chaise End") {
      setEstimatedCube(chaiseEnd());
    }
    setEstimatedCubePerBox(estimatedCube / boxCount);
  }, [
    selectedOption,
    uShapeCalculation,
    cornerCalc,
    chaiseEnd,
    estimatedCube,
    boxCount,
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Cube Estimator</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Enter the <i>Product Dimensions</i> and the number of boxes below.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={1}>
          <h4 className="text">Sofas</h4>
        </Col>
        <Col>
          <ButtonGroup aria-label="Default select example">
            <Button
              variant="outline-primary"
              onClick={() => setSelectedOption("U")}
              active={selectedOption === "U"}
            >
              U-Shaped
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setSelectedOption("Corner")}
              active={selectedOption === "Corner"}
            >
              Corner
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setSelectedOption("Chaise End")}
              active={selectedOption === "Chaise End"}
            >
              Chaise End
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <hr />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Height
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="number"
            placeholder="Height"
            value={height}
            min={10}
            max={160}
            maxLength={3}
            onChange={handleHeightChange}
            style={{ maxWidth: "150px" }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Width
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="number"
            placeholder="Width"
            value={width}
            min={10}
            max={"600"}
            maxLength={3}
            onChange={handleWidthChange}
            style={{ maxWidth: "150px" }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Length
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="number"
            placeholder="Length"
            value={length}
            min={10}
            max={600}
            maxLength={3}
            onChange={handleLengthChange}
            style={{ maxWidth: "150px" }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Box Count
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="number"
            placeholder="Box Count"
            value={boxCount}
            min={1}
            max={10}
            maxLength={2}
            onChange={handleBoxCountChange}
            style={{ maxWidth: "150px" }}
          />
        </Col>
      </Form.Group>
      <Row>
        <Col>
          <h4>Calculated Cube</h4>
        </Col>
        <Col>
          <h4 style={{ color: estimatedCube > 4 ? "red" : "" }}>
            {estimatedCube.toFixed(3)} m³
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Box dimension</h4>
          <i>Enter this value for BOX Height, Width and Length</i>
        </Col>
        <Col>
          <h4>{(Math.pow(estimatedCubePerBox, 0.333) * 100).toFixed(1)} cm</h4>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Button variant="warning" onClick={resetCalc}>
            Reset
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default CubeEstimator;
