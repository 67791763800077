import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import { BaseGradingComponentProps } from "./types";
import { useGradingAndRefurbState } from "./persist-helpers";

export interface LinkCheckComponentProps {
  links: string[];
}

const openLinkInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const getDomainNameFromLink = (url: string) => {
  const urlWithoutProtocol = url.replace("https://", "").replace("http://", "");
  return urlWithoutProtocol.split("/")[0];
};

type LinkOutcome = {
  title: string;
  value: string;
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "dark"
    | "link";
};

const outcomeArray: LinkOutcome[] = [
  { title: "Product Matches", value: "match", variant: "success" },
  {
    title: "Incorrect Product",
    value: "incorrect_product",
    variant: "danger",
  },
  {
    title: "Link Not Working/Dead/Error Page",
    value: "dead",
    variant: "warning",
  },
  {
    title: "Page redirects/goes to other page (e.g. menu/category page)",
    value: "redirection",
    variant: "dark",
  },
];

type LinkFeedbackEntries = {
  url: string;
  visited: boolean;
  valid?: boolean;
  selectedOutcome?: string;
};

const LinkCheckComponent = ({
  links,
  refurbIdentifier,
  stepOutputKey,
  setStepCompleted,
}: LinkCheckComponentProps & BaseGradingComponentProps): JSX.Element => {
  const [linkFeedbackEntries, updateLinkFeedbackEntries] =
    useGradingAndRefurbState<Array<LinkFeedbackEntries>>(
      stepOutputKey,
      refurbIdentifier,
      links.map((l) => ({
        url: l,
        visited: false,
      }))
    );

  const onLinkClick = (link: string, index: number) => {
    openLinkInNewTab(link);
    if (!linkFeedbackEntries[index].visited) {
      const nextLinkFeedbackEntries = linkFeedbackEntries.map((c, i) => {
        if (index === i) {
          return {
            ...c,
            visited: true,
          };
        } else {
          return c;
        }
      });
      updateLinkFeedbackEntries(nextLinkFeedbackEntries);
    }
  };

  const processOutcomeClick = (outcome: LinkOutcome, linkIndex: number) => {
    const nextLinkFeedbackEntries = linkFeedbackEntries.map((c, i) => {
      if (linkIndex === i) {
        return {
          ...c,
          selectedOutcome: outcome.value,
        };
      } else {
        return c;
      }
    });
    updateLinkFeedbackEntries(nextLinkFeedbackEntries);
  };

  useEffect(() => {
    if (linkFeedbackEntries.every((l) => l.visited && !!l.selectedOutcome)) {
      setStepCompleted(true);
    }
  }, [linkFeedbackEntries, setStepCompleted]);

  return (
    <Container>
      <h2>Verify Item</h2>
      <Row>
        <p>
          When visiting a link, verify the item on the page appears the same as
          the website page.
        </p>
        <p>
          When you have visited the page, close the tab and return to this page
          and select the relevant option.
        </p>
      </Row>
      {links.map((link, linkIndex) => (
        <Card key={`links-${linkIndex}`}>
          <Card.Body>
            <Row>
              <Col>
                <Button
                  className="m-2 w-100"
                  size="lg"
                  variant={
                    linkFeedbackEntries[linkIndex].visited
                      ? "outline-info"
                      : "info"
                  }
                  onClick={() => onLinkClick(link, linkIndex)}
                >
                  Open Link {linkIndex + 1}
                  <hr style={{ margin: "5px" }} />
                  <span>{getDomainNameFromLink(link)}</span>
                </Button>
              </Col>
            </Row>
            {linkFeedbackEntries[linkIndex].visited ? (
              <Row xs={1} sm={1} lg={2} xl={2} xxl={3}>
                {outcomeArray.map((outcome, outcomeIndex) => (
                  <Col key={`outcome-${outcomeIndex}`}>
                    <Button
                      size="lg"
                      variant={
                        linkFeedbackEntries[linkIndex].selectedOutcome
                          ? linkFeedbackEntries[linkIndex].selectedOutcome ===
                            outcome.value
                            ? "success"
                            : "outline-primary"
                          : outcome.variant
                      }
                      className="m-2 w-100"
                      onClick={() => processOutcomeClick(outcome, linkIndex)}
                    >
                      {outcome.title}
                    </Button>
                  </Col>
                ))}
              </Row>
            ) : null}
          </Card.Body>
        </Card>
      ))}
      <Row>
        <Col>
          {!linkFeedbackEntries.every(
            (l) => l.visited && !!l.selectedOutcome
          ) ? (
            <i className="m-2">Visit all links to continue</i>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default LinkCheckComponent;
