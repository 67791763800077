import { withAuthenticationRequired } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import { useContext, useEffect, useState } from "react";
import { UserAuthenticatedContext } from "../../components/profile";
import CatalogItemComponent from "../../components/catalog/catalog-item";
import { CatalogItem } from "../../external";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import SaveTaskModal from "../../components/task/save-task-modal";
import axios from "axios";

const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

const createTaskWorker = createWorkerFactory(
  () => import("../../workers/task")
);
export interface CatalogItemCreation extends Omit<CatalogItem, "itemId"> {
  itemId?: string;
  createdFromTaskId?: string;
}

const defaultCatalogItem: CatalogItem = {
  clientId: "",
  clientItemId: "",
  clientSkuHyperlinks: [],
  clientImageLinks: [],
  identifiers: [],
  archived: false,
  itemId: "UNSAVED ITEM",
  createdAtTimestamp: new Date().toISOString(),
  lastUpdatedTimestamp: new Date().toISOString(),
  clearCycleImageLinks: [],
  imageTags: [],
  previousIdentifiers: [],
  auditHistory: [],
};

const NewCatalogPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [showTaskLinkModal, setShowTaskLinkModal] = useState<boolean>(false);
  const [createdCatalogItemId, setCreatedCatalogItemId] = useState<string>();
  const [sail, setSail] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogWorker = useWorker(createCatalogWorker);
  const taskWorker = useWorker(createTaskWorker);

  const location = useLocation();

  const item = location.state?.item ?? null;
  const isCatalogCreateRequest =
    location.state?.isCatalogCreateRequest ?? false;
  const taskId = location.state?.taskId ?? null;

  useEffect(() => {
    if (createdCatalogItemId) {
      setShowTaskLinkModal(true);
    }
  }, [createdCatalogItemId]);

  useEffect(() => {
    if (sail) {
      if (isCatalogCreateRequest) {
        navigate(`/tasks`);
        setShowTaskLinkModal(true);
      } else {
        navigate(`/catalog/item/${createdCatalogItemId}`);
      }
    }
  }, [sail, navigate, createdCatalogItemId, isCatalogCreateRequest]);

  let itemWithDefaults: CatalogItemCreation;

  if (item && isCatalogCreateRequest) {
    itemWithDefaults = {
      ...defaultCatalogItem,
      name: item.title,
      clientItemId: item.clientItemId,
      clientId: item.clientId,
      clientSkuHyperlinks: item.websiteUrl ? [item.websiteUrl] : [],
      retailPricePence: item.rrp,
      dimensions: {
        product: {
          length: item.productDims.length,
          width: item.productDims.width,
          height: item.productDims.height,
        },
        boxes: [],
      },
      createdFromTaskId: location.state.taskId,
    };
  }

  const updateTask = async (taskId: string, itemId: string) => {
    if (!userAuthenticatedContext.token) {
      throw new Error("No token is available!");
    }

    try {
      await taskWorker.completeCatalogCreateTask(
        userAuthenticatedContext.token,
        taskId,
        true,
        itemId
      );
    } catch (error) {
      setErrorMessage(
        "An error occurred while completing the task. Please try again. If the problem persists, contact support."
      );
      if (axios.isAxiosError(error)) {
        console.error(
          `Error: ${error.response?.data}, ${error.response?.status}`
        );
      } else {
        console.error(`An unknown error occurred`);
      }
      setIsError(true);
      throw error;
    }
    setSail(true);
  };

  const persist = async (catalogItem: CatalogItemCreation) => {
    if (!userAuthenticatedContext.token) {
      throw new Error("No token is available!");
    }

    try {
      const createdCatalogItem = await catalogWorker.postCatalogItem(
        userAuthenticatedContext.token,
        catalogItem
      );

      const itemId = createdCatalogItem.data.itemId;

      setCreatedCatalogItemId(itemId);

      if (!isCatalogCreateRequest) {
        setSail(true);
      }

      return createdCatalogItem;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          `Error: ${error.response?.data}, ${error.response?.status}`
        );
      } else {
        console.error(`An unknown error occurred: ${error}`);
      }
      throw error;
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Button
            variant="secondary"
            className="mb-2"
            size="sm"
            onClick={() => {
              navigate("/catalogs");
            }}
          >
            Back to Catalogs
          </Button>
        </Col>
      </Row>
      <Row>
        {showTaskLinkModal && (
          <SaveTaskModal
            showModal={showTaskLinkModal}
            setShowModal={setShowTaskLinkModal}
            saveTask={updateTask}
            taskId={taskId}
            catalogItemId={createdCatalogItemId!}
            isError={isError}
            errorMessage={errorMessage}
          />
        )}
        <CatalogItemComponent
          catalogItem={item ? itemWithDefaults : defaultCatalogItem}
          isCreationMode={true}
          isCatalogCreateRequest={isCatalogCreateRequest}
          persist={persist}
        />
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(NewCatalogPage, {
  onRedirecting: () => <Loading />,
});
