import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { useContext, useEffect, useState } from "react";
import CatalogUploadComponent from "../components/catalog/upload-catalog";
import Loading from "../components/loading";
import { UserAuthenticatedContext } from "../components/profile";
import { Client } from "../external";

const createClientsWorker = createWorkerFactory(
  () => import("../workers/clients")
);

const CatalogUploadPage = () => {
  const clientsWorker = useWorker(createClientsWorker);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  if (!clientsLoaded) {
    return <Loading />;
  }

  return <CatalogUploadComponent clients={clients}></CatalogUploadComponent>;
};

export default withAuthenticationRequired(CatalogUploadPage, {
  onRedirecting: () => <Loading />,
});
