import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import ClearCycleEditableTable from "../../components/table";

import { CatalogItem } from "../../external";
import { useLocalStorage } from "usehooks-ts";
import CurrencyInput from "react-currency-input-field";
import Stack from "react-bootstrap/Stack";
import Decimal from "decimal.js";
// import { createWorkerFactory } from "@shopify/react-web-worker";

// const createCatalogWorker = createWorkerFactory(
//   () => import("../../workers/catalog")
// );

export interface CatalogItemEditable
  extends Omit<
    CatalogItem,
    "retailPricePence" | "weightGrams" | "costPricePence"
  > {
  retailPrice?: number;
  weightKg?: number;
  costPrice?: number;
}

const columnHelper = createColumnHelper<CatalogItemEditable>();
const defaultColumns = [
  columnHelper.accessor("clientId", {
    header: () => "Client",
    enableSorting: true,
    size: 80,
    cell: ({ cell }) => (
      <input
        style={{ width: `${cell.column.getSize()}px` }}
        disabled
        value={cell.getValue()}
      ></input>
    ),
  }),
  columnHelper.accessor("clientItemId", {
    header: () => "Client SKU",
    enableSorting: true,
    size: 100,
    cell: ({ cell }) => (
      <input
        style={{ width: `${cell.column.getSize()}px` }}
        disabled
        value={cell.getValue()}
      ></input>
    ),
  }),
  columnHelper.accessor("colour", {
    header: () => "Colour",
    enableSorting: true,
    size: 150,
  }),
  columnHelper.accessor("retailPrice", {
    header: () => "Retail Price (RRP)",
    enableSorting: true,
    size: 200,
    cell: ({ table, column, row, cell }) => {
      return (
        <CurrencyInput
          prefix="£"
          allowNegativeValue={false}
          className={"form-control"}
          value={cell.getValue()}
          disableAbbreviations={true}
          step={1}
          decimalsLimit={2}
          onValueChange={(value, name) => {
            if (value === null || value === undefined) {
              table.options.meta?.updateData(row.index, column.id, null);
              return;
            }
            table.options.meta?.updateData(row.index, column.id, value);
          }}
        />
      );
    },
  }),
  columnHelper.accessor("costPrice", {
    header: () => "Cost Price",
    enableSorting: true,
    size: 200,
    cell: ({ table, column, row, cell }) => {
      return (
        <CurrencyInput
          prefix="£"
          allowNegativeValue={false}
          className={"form-control"}
          value={cell.getValue()}
          disableAbbreviations={true}
          step={1}
          decimalsLimit={2}
          onValueChange={(value, name) => {
            if (value === null || value === undefined) {
              table.options.meta?.updateData(row.index, column.id, null);
              return;
            }
            table.options.meta?.updateData(row.index, column.id, value);
          }}
        />
      );
    },
  }),
  columnHelper.accessor("weightKg", {
    header: () => "Weight (KG)",
    enableSorting: true,
    size: 150,
    cell: ({ table, column, row, cell }) => (
      <input
        type="number"
        style={{ width: `${cell.column.getSize()}px` }}
        step={1}
        value={cell.getValue()}
        onChange={(e) => {
          table.options.meta?.updateData(
            row.index,
            column.id,
            e.target.valueAsNumber
          );
        }}
      ></input>
    ),
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
    enableSorting: true,
    size: 500,
  }),
  columnHelper.accessor("description", {
    header: () => "Description",
    enableSorting: true,
    size: 600,
    cell: ({ table, column, row, cell }) => (
      <textarea
        style={{ width: `${cell.column.getSize()}px` }}
        rows={2}
        value={cell.getValue()}
        onChange={(e) => {
          table.options.meta?.updateData(row.index, column.id, e.target.value);
        }}
      ></textarea>
    ),
  }),
  columnHelper.accessor("dimensions.product", {
    header: () => "Product Dimensions",
    enableSorting: true,
    size: 600,
    cell: ({ table, column, row, cell }) => (
      <Stack direction="horizontal" gap={1}>
        <Stack direction="vertical">
          <label
            style={{ fontWeight: "bolder" }}
            htmlFor={`${row.index}-product-height`}
          >
            Height
          </label>
          <input
            id={`${row.index}-product-height`}
            type="number"
            style={{ width: cell.column.getSize() / 3 }}
            step={1}
            value={cell.getValue()?.height}
            onChange={(e) => {
              table.options.meta?.updateData(row.index, column.id, {
                ...cell.getValue(),
                height: e.target.value,
              });
            }}
          ></input>
        </Stack>
        <Stack direction="vertical">
          <label
            style={{ fontWeight: "bolder" }}
            htmlFor={`${row.index}-product-width`}
          >
            Width
          </label>
          <input
            id={`${row.index}-product-width`}
            type="number"
            style={{ width: cell.column.getSize() / 3 }}
            step={1}
            value={cell.getValue()?.width}
            onChange={(e) => {
              const newValue = {
                ...cell.getValue(),
                width: +e.target.value,
              };
              console.log(newValue);
              table.options.meta?.updateData(row.index, column.id, newValue);
            }}
          ></input>
        </Stack>
        <Stack direction="vertical">
          <label
            style={{ fontWeight: "bolder" }}
            htmlFor={`${row.index}-product-width`}
          >
            Length
          </label>
          <input
            id={`${row.index}-product-length`}
            style={{ width: cell.column.getSize() / 3 }}
            type="number"
            step={1}
            value={cell.getValue()?.length}
            onChange={(e) => {
              console.log(cell.getValue());
              console.info(e.target.value);
              table.options.meta?.updateData(row.index, column.id, {
                ...cell.getValue(),
                length: e.target.value,
              });
            }}
          ></input>
        </Stack>
      </Stack>
    ),
  }),
];

export interface CatalogEditableTableComponentProps {
  data: CatalogItem[];
  exitEditMode: () => void;
  authToken: string;
}

const CatalogEditableTableComponent = ({
  data,
  exitEditMode,
  authToken,
}: CatalogEditableTableComponentProps): JSX.Element => {
  // const catalogWorker = createCatalogWorker();

  const defaultEditableCatalogItems: CatalogItemEditable[] = data.map(
    (item) => ({
      ...item,
      retailPrice: item.retailPricePence
        ? new Decimal(item.retailPricePence).dividedBy(100).toNumber()
        : undefined,
      costPrice: item.costPricePence
        ? new Decimal(item.costPricePence).dividedBy(100).toNumber()
        : undefined,
      weightKg: item.weightGrams
        ? new Decimal(item.weightGrams).dividedBy(1000).toNumber()
        : undefined,
    })
  );

  const onSave = () =>
    new Promise<boolean>((resolve) => {
      const convertedData: CatalogItem[] = editableCatalogItemRows.map(
        (row) => {
          const newRow = {
            ...row,
            retailPricePence: row.retailPrice
              ? new Decimal(row.retailPrice).times(100).toNumber()
              : 0,
            costPricePence: row.costPrice
              ? new Decimal(row.costPrice).times(100).toNumber()
              : 0,
            weightGrams: row.weightKg
              ? new Decimal(row.weightKg).times(1000).toNumber()
              : undefined,
          };

          // Clean up the data to remove converted from fields
          delete newRow.retailPrice;
          delete newRow.costPrice;
          delete newRow.weightKg;

          return newRow;
        }
      );

      console.log(convertedData);

      // TODO: add this back when backend support is added
      // catalogWorker.putCatalogItems(authToken, convertedData);

      resolve(true);
    });

  const onCancel = () =>
    new Promise<boolean>((resolve) => {
      exitEditMode();
      // This is necessary to reset the state so that when we return to edit
      // it'll load the items from the newly passed in data again.
      localStorage.removeItem(`cc-catalogEditRows`);
      resolve(true);
    });

  const [editableCatalogItemRows, setEditableCatalogItemRows] = useLocalStorage<
    CatalogItemEditable[]
  >(`cc-catalogEditRows`, defaultEditableCatalogItems);

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  return (
    <ClearCycleEditableTable
      columns={columns}
      setTableData={setEditableCatalogItemRows}
      tableData={editableCatalogItemRows}
      tableKey="catalogEdit"
      onCancelButtonClicked={onCancel}
      onSaveButtonClicked={onSave}
    />
  );
};

export default CatalogEditableTableComponent;
