import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CreateItemLabelHTML } from "../components/printing/generate-item-label-html";

export interface LabelGeneratorPageProps {
  autoPrint?: boolean;
}

const LabelGeneratorPage = ({ autoPrint }: LabelGeneratorPageProps) => {
  const location = useLocation();
  const [labelHTML, setLabelHTML] = useState<string>("");
  const [error, setError] = useState<string>();

  const [hasShownPrint, setHasShownPrint] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    console.info(location.search);
    const labelOptions = {
      currentBoxNumber: parseInt(queryParams.get("currentBoxNumber") || "1"),
      boxCount: parseInt(queryParams.get("boxCount") || "1"),
      pmid: queryParams.get("pmid") || undefined,
      inventoryId: queryParams.get("inventoryId") || "",
      packerName: queryParams.get("packerName") || "",
      packedDateTime: queryParams.get("packedDateTime") || "",
      palletRef: queryParams.get("palletRef") || "",
      sku: queryParams.get("sku") || "",
      productListingTitle: queryParams.get("productListingTitle") || "",
      grade: queryParams.get("grade") || "",
      comments: queryParams.get("comments") || undefined,
      imageUrls: queryParams.getAll("imageUrls") || undefined,
    };

    CreateItemLabelHTML({ data: labelOptions })
      .then((html) => {
        setLabelHTML(html);
        if (autoPrint) {
          setTimeout(() => {
            if (hasShownPrint) return;
            window.print();
            setHasShownPrint(true);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error generating label HTML:", error);

        if (error.message) {
          setError(error.message);
        }
      });
  }, [location.search, autoPrint, hasShownPrint]);

  return (
    <>
      {error ? (
        <>
          <h5>Error</h5>
          <p>{error}</p>
        </>
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: labelHTML }} />
    </>
  );
};

export default LabelGeneratorPage;
