import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Tooltip, { TooltipProps } from "react-bootstrap/Tooltip";
import CatalogTableComponent from "../components/catalog/catalog-table";
import Loading from "../components/loading";
import { UserAuthenticatedContext } from "../components/profile";
import { CatalogItem, Client, Meta } from "../external";
import PaginationComponent from "../components/pagination";
import CatalogEditableTableComponent from "./catalog/catalog-table";
import { useSessionStorage } from "usehooks-ts";
import { SearchControlsComponent } from "../components/search-controls";
import { EbayCategory } from "../workers/ebay-categories";
import { useNavigate } from "react-router-dom";
import CatalogDownloadModal from "../components/catalog/catalog-download-modal";
import { useFeatureFlagEnabled } from "posthog-js/react";
import {
  ClockHistory,
  Download,
  FileEarmarkArrowUp,
  PencilSquare,
  Plus,
} from "react-bootstrap-icons";
import CatalogItemBatchUpdateCSVUploadModal from "../components/catalog/catalog-csv-upload";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { EBayCategoryParam, SortDirectionParam } from "./catalog/search-params";
import { Filters } from "../components/dynamic-search-component";
import { CatalogFilters } from "../workers/catalog";

const createCatalogItemsWorker = createWorkerFactory(
  () => import("../workers/catalog")
);

const createClientsWorker = createWorkerFactory(
  () => import("../workers/clients")
);

const CatalogPage = (): JSX.Element => {
  const navigate = useNavigate();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const catalogItemsWorker = useWorker(createCatalogItemsWorker);
  const clientsWorker = useWorker(createClientsWorker);

  // Client Section Start
  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);

  // Catalogue Items Section Start
  const [catalogItems, setCatalogItems] = useState<CatalogItem[]>([]);
  const [catalogItemsLoaded, setCatalogItemsLoaded] = useState<boolean>(false);
  const [meta, setMeta] = useState<Meta>({
    next: null,
    previous: null,
    current: 1,
    totalPages: 1,
    maxPerPage: 50,
    totalResults: 0,
  });

  const catalogBulkEditEnabled = useFeatureFlagEnabled("catalog_bulkEdit");

  const defaultCurrentPage = 1;
  const defaultCount = 25;
  const defaultSortField = "lastUpdatedTimestamp";
  const defaultSortDirection = "asc";
  const FiltersQueryConfig = {
    priceMin: NumberParam,
    priceMax: NumberParam,
    priceFixed: NumberParam,
    text: StringParam,
    clientId: StringParam,
    archived: withDefault(StringParam, "0"),
    quarantine: BooleanParam,
    count: withDefault(NumberParam, defaultCount),
    page: withDefault(NumberParam, defaultCurrentPage),
    ebayCategory: EBayCategoryParam,
    sortField: withDefault(StringParam, defaultSortField),
    sortDirection: withDefault(SortDirectionParam, defaultSortDirection),
    suitableForListing: BooleanParam,
  };

  // Quarantine Start
  const [quarantineCount, setQuarantineCount] = useState<number>(0);

  const handleTableSort = (
    sortField: string,
    sortDirection: "asc" | "desc"
  ) => {
    setFilters({
      ...filters,
      sortField: sortField,
      sortDirection: sortDirection,
    });
  };

  const handleCount = (count: number) => {
    setFilters({ ...filters, count: count, page: defaultCurrentPage });
  };
  const handleCurrentPage = (pageNumber: number) => {
    setFilters({ ...filters, page: pageNumber });
  };
  const handleClients = (clientId?: string) => {
    setFilters({
      ...filters,
      clientId: clientId,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleQuarantine = (quarantine?: boolean) => {
    setFilters({
      ...filters,
      quarantine: quarantine,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleTextSearch = (text?: string) => {
    setFilters({
      ...filters,
      text: text,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handlePriceFixed = (price?: string) => {
    const priceFixed = price ? parseFloat(price) : undefined;

    setFilters({
      ...filters,
      priceFixed: priceFixed,
      priceMin: undefined,
      priceMax: undefined,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handlePriceRange = (min?: string, max?: string) => {
    const priceMin = min ? parseFloat(min) : undefined;
    const priceMax = max ? parseFloat(max) : undefined;

    setFilters({
      ...filters,
      priceFixed: undefined,
      priceMin: priceMin,
      priceMax: priceMax,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleEbayCategory = (ebayCategory?: EbayCategory[]) => {
    setFilters({
      ...filters,
      ebayCategory: ebayCategory,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleStatus = (archived?: string) => {
    setFilters({
      ...filters,
      archived: archived,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleListing = (listing?: boolean) => {
    setFilters({
      ...filters,
      suitableForListing: listing,
      page: defaultCurrentPage,
      count: defaultCount,
    });
  };

  const handleClearFilters = (clear: boolean) => {
    clearFilters();
  };

  // Filters Section Start
  const [filters, setFilters] = useQueryParams(FiltersQueryConfig);
  const filtersClean = useCallback(
    (): CatalogFilters => ({
      archived: filters.archived,
      clientId: filters.clientId ?? undefined,
      text: filters.text ?? undefined,
      priceMin: filters.priceMin ?? undefined,
      priceMax: filters.priceMax ?? undefined,
      priceFixed: filters.priceFixed ?? undefined,
      page: filters.page ?? defaultCurrentPage,
      count: filters.count ?? defaultCount,
      sortField: filters.sortField,
      sortDirection: filters.sortDirection ?? defaultSortDirection,
      ebayCategory: filters.ebayCategory,
      suitableForListing: filters.suitableForListing ?? undefined,
    }),
    [filters]
  );
  const [showFilters, setShowFilters] = useState(false);
  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);
  const clearFilters = () => {
    setFilters({
      archived: "0",
      clientId: undefined,
      text: undefined,
      priceMin: undefined,
      priceMax: undefined,
      priceFixed: undefined,
      page: defaultCurrentPage,
      count: defaultCount,
      sortField: "lastUpdatedTimestamp",
      sortDirection: defaultSortDirection,
      ebayCategory: undefined,
      suitableForListing: undefined,
    });
  };

  const [editing, setEditing] = useSessionStorage<boolean>(
    `cc-catalogEditing`,
    false
  );

  const [showClientCatalogDownloadModal, setShowClientCatalogDownloadModal] =
    useState(false);
  const [showCSVUploadModal, setShowCSVUploadModal] = useState(false);
  const [
    shouldTriggerRefreshOnCloseOfCSVUploadModal,
    setShouldTriggerRefreshOnCloseOfCSVUploadModal,
  ] = useState(false);

  const handleCloseCatalogueDownloadModal = () => {
    setShowClientCatalogDownloadModal(false);
  };

  // Get all Clients
  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  const shouldShowQuarantineCount = useFeatureFlagEnabled(
    "catalog_showQuarantineCount"
  );

  const [forceSearch, setForceSearch] = useState(false);
  // Get Items and Quarantine Count
  useEffect(() => {
    setCatalogItems([]);
    setCatalogItemsLoaded(false);

    if (forceSearch) {
      setForceSearch(false);
    }

    (async () => {
      if (userAuthenticatedContext.token && filters) {
        if (shouldShowQuarantineCount) {
          const quarantinedItems = await catalogItemsWorker.getCatalogItems(
            {
              page: filters.page ?? defaultCurrentPage,
              count: filters.count ?? defaultCount,
              quarantine: true,
              sortField: filters.sortField,
              sortDirection: filters.sortDirection ?? defaultSortDirection,
            },
            userAuthenticatedContext.token
          );

          // We are now searching for quarantine items to show as an error message on page load
          setQuarantineCount(quarantinedItems.meta.totalResults);

          if (filters.quarantine) {
            setCatalogItems(quarantinedItems.data);
            setMeta(quarantinedItems.meta);
          }
        }

        if (!filters.quarantine) {
          const catalogItems = await catalogItemsWorker.getCatalogItems(
            filtersClean(),
            userAuthenticatedContext.token
          );

          setCatalogItems(catalogItems.data);
          setMeta(catalogItems.meta);
        }

        setCatalogItemsLoaded(true);
      }
    })();
  }, [
    userAuthenticatedContext,
    catalogItemsWorker,
    filters,
    shouldShowQuarantineCount,
    filtersClean,
    forceSearch,
  ]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const downloadFilterCatalogueItems = (format?: string) => {
    interface CatalogRequest {
      authToken: string;
      searchFields: Filters;
      format?: string;
    }

    (async () => {
      if (userAuthenticatedContext.token) {
        setIsDownloading(true);
        try {
          let catalogRequest: CatalogRequest = {
            authToken: userAuthenticatedContext.token,
            searchFields: filtersClean(),
          };

          if (format) {
            catalogRequest.format = format;
          }

          const result = await catalogItemsWorker.getClientsCatalog(
            catalogRequest
          );

          if ([200].includes(result.status)) {
            window.open(result.data.url, "_blank", "noopener,noreferrer");
          } else {
            console.error("Error retrieving data");
          }
        } catch (e) {
          console.error("Error retrieving data from server");
          console.error(e);
        } finally {
          await new Promise((resolve) => {
            // Give a small timeout here to give the illusion of loading
            setTimeout(() => {
              setIsDownloading(false);
              resolve(undefined);
            }, 1000);
          });
        }
      }
    })();
  };

  const renderDownloadTooltip = (props: TooltipProps) => (
    <Tooltip id="download-button-tooltip" {...props}>
      Download all {meta.totalResults} catalogue items matching the current
      search as a CSV file
    </Tooltip>
  );

  if (
    !catalogItems ||
    !clientsLoaded ||
    !userAuthenticatedContext ||
    !userAuthenticatedContext.token
  ) {
    return <Loading />;
  }

  if (editing) {
    return (
      <Container style={{ width: "95%", minWidth: "95%" }}>
        <Row>
          <CatalogEditableTableComponent
            authToken={userAuthenticatedContext.token}
            data={catalogItems}
            exitEditMode={() => setEditing(false)}
          />
        </Row>
      </Container>
    );
  }

  return (
    <Container style={{ width: "95%", minWidth: "95%" }}>
      <Row>
        <Col lg="3">
          <Offcanvas
            show={showFilters}
            placement="start"
            responsive="lg"
            onHide={handleCloseFilters}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ justifyContent: "left" }}>
              <SearchControlsComponent
                clients={clients}
                isLoaded={catalogItemsLoaded}
                filters={filtersClean()}
                quarantineCount={quarantineCount}
                childToParentClients={handleClients}
                childToParentQuarantine={handleQuarantine}
                childToParentClear={handleClearFilters}
                childToParentTextSearch={handleTextSearch}
                childToParentPriceFixed={handlePriceFixed}
                childToParentPriceRange={handlePriceRange}
                childToParentStatus={handleStatus}
                childToParentEbayCategory={handleEbayCategory}
                childToParentListing={handleListing}
              ></SearchControlsComponent>
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
        <Col
          lg="9"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >
          <Row>
            <Col>
              <h2 style={{ textAlign: "left" }}>Catalogue</h2>
            </Col>
          </Row>
          <Row style={{ float: "left", marginRight: "4px" }}>
            <Col>
              <ButtonGroup style={{ marginRight: "0.5em" }}>
                <Button
                  variant="success"
                  onClick={() => {
                    setShowCSVUploadModal(true);
                  }}
                >
                  <FileEarmarkArrowUp
                    size={16}
                    style={{ paddingBottom: "2px" }}
                  />{" "}
                  Bulk CSV Upload
                </Button>
                <DropdownButton
                  as={ButtonGroup}
                  title={
                    <>
                      CSV Download
                      {isDownloading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "0.5rem" }}
                        />
                      )}
                    </>
                  }
                  id="bg-nested-dropdown"
                  variant="warning"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderDownloadTooltip}
                  >
                    <Dropdown.Item
                      onClick={() => downloadFilterCatalogueItems()}
                    >
                      Download CSV
                    </Dropdown.Item>
                  </OverlayTrigger>

                  <Dropdown.Item
                    onClick={() => downloadFilterCatalogueItems("storefeeder")}
                  >
                    Download StoreFeeder Upload CSV
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
              <DropdownButton as={ButtonGroup} title="Actions">
                <Dropdown.Item onClick={() => navigate("/catalog/new")}>
                  <Plus
                    size={18}
                    color="green"
                    style={{ paddingBottom: "2px" }}
                  />{" "}
                  New Catalogue Item
                </Dropdown.Item>
                <Dropdown.Divider />
                {catalogBulkEditEnabled &&
                userAuthenticatedContext.scopes?.includes(
                  "edit:catalogue-item"
                ) ? (
                  <>
                    <Dropdown.Item onClick={() => setEditing(true)}>
                      <PencilSquare
                        size={18}
                        color="darkred"
                        style={{ paddingBottom: "2px" }}
                      />{" "}
                      Bulk Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                ) : null}
                <Dropdown.Item
                  onClick={() => setShowClientCatalogDownloadModal(true)}
                >
                  <Download size={18} style={{ paddingBottom: "2px" }} />{" "}
                  Download All Client Catalogue
                </Dropdown.Item>
                <Dropdown.Divider />
                {userAuthenticatedContext.scopes?.includes(
                  "create:catalogue-item"
                ) ? (
                  <>
                    <Dropdown.Item onClick={() => navigate("/catalog/upload")}>
                      <FileEarmarkArrowUp
                        size={18}
                        color="blue"
                        style={{ paddingBottom: "2px" }}
                      />{" "}
                      Upload Migration CSV File
                    </Dropdown.Item>
                  </>
                ) : null}
                <Dropdown.Item onClick={() => navigate("/catalog/uploaded")}>
                  <ClockHistory
                    size={18}
                    color="grey"
                    style={{ paddingBottom: "2px" }}
                  />{" "}
                  Migration CSV Upload History
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="d-lg-none" style={{ float: "left" }}>
            <Button variant="success" onClick={handleShowFilters}>
              Show Filters
            </Button>
          </Row>
          <CatalogTableComponent
            catalogItems={catalogItems}
            catalogItemsLoaded={catalogItemsLoaded}
            clients={clients}
            filters={filtersClean()}
            childToParentTableSort={handleTableSort}
          ></CatalogTableComponent>
          <Row>
            <PaginationComponent
              meta={meta}
              itemsLoaded={catalogItemsLoaded}
              childToParentPages={handleCurrentPage}
              childToParentCount={handleCount}
            ></PaginationComponent>
          </Row>
        </Col>
        <Col>
          <CatalogDownloadModal
            showModal={showClientCatalogDownloadModal}
            handleCloseModal={handleCloseCatalogueDownloadModal}
            clients={clients}
          />
          {showCSVUploadModal ? (
            <CatalogItemBatchUpdateCSVUploadModal
              showModal={showCSVUploadModal}
              shouldTriggerRefreshOnClose={
                setShouldTriggerRefreshOnCloseOfCSVUploadModal
              }
              handleCloseModal={() => {
                setShowCSVUploadModal(false);
                if (shouldTriggerRefreshOnCloseOfCSVUploadModal) {
                  setForceSearch(true);
                }
              }}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(CatalogPage, {
  onRedirecting: () => <Loading />,
});
