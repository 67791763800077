import { TaskStats } from "../components/dashboards/task-dashboard";

export const formatTaskStatsData = (taskStats: TaskStats) => {
  const taskStatsData = taskStats.data[0];

  const tasksCreatedOverSevenDaysByAssignedToMap =
    taskStatsData.tasks_created_over_seven_days_ago.tasks_by_assigned_to.buckets.reduce(
      (acc, { key, doc_count }) => {
        acc[key] = doc_count;
        return acc;
      },
      {} as Record<string, number>
    );

  const tasksByAssignedTo = taskStatsData.tasks_by_assigned_to.buckets.map(
    (bucket) => ({
      name: bucket.key,
      value: bucket.doc_count,
      sevenDaysCount: tasksCreatedOverSevenDaysByAssignedToMap[bucket.key] || 0,
    })
  );

  const tasksCreatedOverSevenDaysByTypeMap =
    taskStatsData.tasks_created_over_seven_days_ago.tasks_by_type.buckets.reduce(
      (acc, { key, doc_count }) => {
        acc[key] = doc_count;
        return acc;
      },
      {} as Record<string, number>
    );

  const tasksByType = taskStatsData.tasks_by_type.buckets.map((bucket) => ({
    name: bucket.key,
    value: bucket.doc_count,
    sevenDaysCount: tasksCreatedOverSevenDaysByTypeMap[bucket.key] || 0,
  }));

  const tasksByStatus = taskStatsData.tasks_by_status.buckets.map((bucket) => ({
    name: bucket.key,
    value: bucket.doc_count,
  }));

  const totalTasks = taskStatsData.total_tasks.value;

  return {
    tasksByAssignedTo,
    tasksByStatus,
    tasksByType,
    totalTasks,
  };
};
