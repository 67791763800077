import React from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Spinner from "react-bootstrap/Spinner";
import { BrightnessHighFill, MoonFill } from "react-bootstrap-icons";
import { useCircleDarkModeReadWrite } from "../hooks/useCircleDarkMode";

export const UserAuthenticatedContext =
  React.createContext<UserAuthenticatedContextType>({
    token: undefined,
    user: undefined,
    scopes: undefined,
  });
export type UserAuthenticatedContextType = {
  token?: string;
  user?: User;
  scopes?: string[];
};

const NavProfile = () => {
  const [darkMode, setDarkMode] = useCircleDarkModeReadWrite();

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();

  if (isLoading) {
    return (
      <Container style={{ width: "30px" }}>
        <Spinner size="sm" animation="border" />
      </Container>
    );
  }

  if (!isAuthenticated || !user) {
    return (
      <Container className="nav-profile">
        <Button variant="success" size="sm" onClick={() => loginWithRedirect()}>
          Log In
        </Button>
      </Container>
    );
  }
  const displayTitle = user.name ?? user.email?.toLocaleLowerCase();
  return (
    <NavDropdown align={"end"} title={displayTitle} id="nav-dropdown">
      <NavDropdown.Item eventKey="email" disabled={true}>
        {user.email?.toLocaleLowerCase()}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        eventKey={"dark-mode"}
        onClick={(e) => setDarkMode(!darkMode)}
        active={false}
      >
        {darkMode ? (
          <span>
            <MoonFill style={{ marginRight: "0.5em", marginBottom: "0.2em" }} />
            Disable Dark Mode
          </span>
        ) : (
          <span>
            <BrightnessHighFill
              style={{ marginRight: "0.5em", marginBottom: "0.2em" }}
            />
            Enable Dark Mode
          </span>
        )}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        eventKey="logout"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default NavProfile;
