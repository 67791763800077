import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import { useForm } from "react-hook-form";
import "../../App.scss";
import { Client } from "../../external";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

export type ClientComponentProps = {
  authenticatedUserToken: string;
  client?: Client;
};

export interface ClientFormData {}

const ClientComponent = ({
  authenticatedUserToken,
  client,
}: ClientComponentProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Client>();

  const isExistingClient = !!client;

  const navigate = useNavigate();
  const clientsWorker = useWorker(createClientsWorker);
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();
  const [isInRequestingStatus, setIsInRequestingStatus] = useState(false);
  const [sheetWriteBack, setSheetWriteBack] = useState<boolean | undefined>(
    client?.sheetWriteBack
  );

  const onSubmit = handleSubmit(
    (formClient) => {
      if (formClient) {
        (async () => {
          setIsInRequestingStatus(true);
          setShowPostError(false);
          setPostError(undefined);

          try {
            // Set to upper case for consistency
            // Existing clients will already be uppercase
            formClient.clientId = formClient.clientId.toUpperCase().trim();
            formClient.clientName = formClient.clientName.trim();
            formClient.googleSheetId = formClient.googleSheetId?.trim();
            formClient.sheetWriteBack = Boolean(sheetWriteBack);

            const postResult = await clientsWorker.postClient(
              formClient,
              authenticatedUserToken,
              !isExistingClient
            );
            if ([200, 201].includes(postResult.status)) {
              navigate(`/client/${formClient.clientId}`);
            } else {
              setShowPostError(true);
              setPostError(
                `Invalid status code ${postResult.status} returned from server`
              );
            }
          } catch (e) {
            console.error(e);
            await new Promise((resolve) =>
              setTimeout(() => {
                setShowPostError(true);
                setPostError(
                  `Creating/updating client failed with error "${
                    (e as unknown as Error).message
                  }"`
                );
                resolve(undefined);
              }, 500)
            );
          } finally {
            await new Promise((resolve) => {
              // Give a small timeout here to give the illusion of loading
              setTimeout(() => {
                setIsInRequestingStatus(false);
                resolve(undefined);
              }, 500);
            });
          }
        })();
      }
    },
    (invalidData) => {
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );
  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <h1>{isExistingClient ? client.clientName : "New Client"}</h1>
        {!isExistingClient ? (
          <Alert variant="info">
            <p className="mb-0">
              Client ID can only be set when creating a client - it cannot be
              changed after you save!
            </p>
          </Alert>
        ) : (
          <></>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Client ID</Form.Label>
          <Form.Control
            autoComplete="off"
            defaultValue={client?.clientId}
            readOnly={isExistingClient}
            style={{
              textTransform: "uppercase",
              backgroundColor: isExistingClient ? "#d1d1d1" : undefined,
            }}
            {...register("clientId", {
              required: true,
              minLength: 2,
              maxLength: 10,
            })}
            isInvalid={
              errors.clientId?.type === "required" ||
              errors.clientId?.type === "minLength" ||
              errors.clientId?.type === "maxLength" ||
              watch("clientId")?.includes(" ")
            }
            as="input"
          />
          <Form.Control.Feedback type="invalid">
            Client ID must be between 2 and 10 characters long with no spaces.
          </Form.Control.Feedback>
          <Form.Label style={{ fontSize: "small" }}>
            This ID will be used for SKU prefixes, barcodes, labels etc. so it's
            good to have an ID that invokes the client's brand, or an acronym if
            appropriate. <b>This cannot be changed later.</b>
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Client Name</Form.Label>
          <Form.Control
            autoComplete="off"
            defaultValue={client?.clientName}
            {...register("clientName", {
              required: true,
              minLength: 5,
              maxLength: 200,
            })}
            isInvalid={
              errors.clientName?.type === "required" ||
              errors.clientName?.type === "minLength" ||
              errors.clientName?.type === "maxLength"
            }
          />
          <Form.Control.Feedback type="invalid">
            Client name must be at least 5 characters
          </Form.Control.Feedback>
          <Form.Label style={{ fontSize: "small" }}>
            This name will be shown on reports and summaries, and potentially
            other places. <i>This can be changed later.</i>
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Google Sheet ID</Form.Label>
          <Form.Control
            autoComplete="off"
            defaultValue={client?.googleSheetId}
            {...register("googleSheetId", {
              required: false,
              minLength: 5,
              maxLength: 200,
            })}
            isInvalid={
              errors.googleSheetId?.type === "required" ||
              errors.googleSheetId?.type === "minLength" ||
              errors.googleSheetId?.type === "maxLength"
            }
          />
          <Form.Control.Feedback type="invalid">
            Invalid Google Sheet ID
          </Form.Control.Feedback>
          <Form.Label style={{ fontSize: "small" }}>
            This is used to pull from and write back to sheets. If empty, no
            pull/push will occur for this client.
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Google Sheet Write Back</Form.Label>
          <Form.Check
            type="switch"
            checked={sheetWriteBack}
            {...register("sheetWriteBack", {
              onChange: (e) => {
                setSheetWriteBack(e.target.checked);
              },
            })}
            label={
              watch("sheetWriteBack") ? (
                <span>Enabled</span>
              ) : (
                <span>Disabled</span>
              )
            }
          />
        </Form.Group>
        <Button
          className="mb-3"
          variant="primary"
          type="submit"
          disabled={isInRequestingStatus}
        >
          <Spinner
            as={"span"}
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
            style={{ marginRight: "0.5em" }}
            className={isInRequestingStatus ? "" : "visually-hidden"}
          />
          Save
        </Button>
        {showPostError ? (
          <Alert
            variant="danger"
            dismissible
            onClose={() => {
              setPostError(undefined);
              setShowPostError(false);
            }}
          >
            {postError}
          </Alert>
        ) : (
          <></>
        )}
      </Form>
    </Container>
  );
};

export default ClientComponent;
