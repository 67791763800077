export enum RateType {
  flatFee = "flatFee",
  percentage = "percentage",
}

export enum ClientRateCardRetailField {
  marketingRate = "marketingRate",
  exitRate = "exitRate",
  marketplaceAndPaymentFeesRate = "marketplaceAndPaymentFeesRate",
  perItemBaseRate = "perItemBaseRate",
}

export enum ClientRateCardAuctionField {
  nonBer = "nonBer",
  ber = "ber",
  handlingCharge = "handlingCharge",
}
