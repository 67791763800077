import { CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";

import { ActionsPlugin } from "./plugins/Actions";
import {
  EditorHistoryStateContext,
  useEditorHistoryState,
} from "./context/EditorHistoryState";
import { LoadInitialValuePlugin } from "./plugins/LoadInitial";
import { OnChangePlugin } from "./plugins/OnChange";

export const EditorButtonStyle = "info";
export const EditorButtonIconSize = 24;

const EDITOR_NODES = [
  AutoLinkNode,
  CodeNode,
  HeadingNode,
  LinkNode,
  ListNode,
  ListItemNode,
  QuoteNode,
];

type EditorProps = {
  className?: string;
  namespace: string;
  initialValue?: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
};

export function Editor(props: EditorProps) {
  const config: InitialConfigType = {
    namespace: props.namespace,
    nodes: EDITOR_NODES,
    // Editable = false by default. To avoid regenerating the description
    editable: false,
    theme: {
      link: "cursor-pointer",
      text: {
        bold: "font-semibold",
        underline: "underline decoration-wavy",
        italic: "italic",
        strikethrough: "line-through",
        underlineStrikethrough: "underlined-line-through",
      },
    },
    onError: (error) => {
      console.error(error);
    },
  };
  return (
    <div id="editor-wrapper">
      <EditorHistoryStateContext>
        <LexicalEditorComponent
          namespace={props.namespace}
          config={config}
          initialValue={props.initialValue}
          onChange={props.onChange}
          canEdit={!props.readOnly}
        />
      </EditorHistoryStateContext>
    </div>
  );
}

type LexicalEditorProps = {
  config: Parameters<typeof LexicalComposer>["0"]["initialConfig"];
  initialValue?: string;
  namespace: string;
  onChange: (value: string) => void;
  canEdit: boolean;
};

const editorHeightEM = 10;
export function LexicalEditorComponent({
  initialValue,
  config,
  namespace,
  onChange,
  canEdit,
}: LexicalEditorProps) {
  const { historyState } = useEditorHistoryState();

  return (
    <LexicalComposer initialConfig={config}>
      {/* Official Plugins */}
      <ActionsPlugin readOnly={!canEdit} />
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className="form-control"
            style={{ minHeight: `${editorHeightEM}rem` }}
            spellCheck={true}
          />
        }
        placeholder={Placeholder}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin externalHistoryState={historyState} />
      <ListPlugin />
      {/* Custom Plugins */}
      <LoadInitialValuePlugin
        initialValue={initialValue}
        namespace={namespace}
      />
      {/* <FloatingMenuPlugin /> // Not used for now */}
      <OnChangePlugin namespace={namespace} onChange={onChange} />
    </LexicalComposer>
  );
}

const Placeholder = () => {
  return (
    <div
      style={{
        position: "relative",
        top: `${editorHeightEM - 19.6}rem`,
        left: "1rem",
        opacity: "0.5",
        userSelect: "none",
      }}
    >
      Start writing...
    </div>
  );
};
