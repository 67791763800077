import { useState } from "react";
import Button from "react-bootstrap/Button";
import { InventoryItemStoredImage } from "../../external/inventory-component/types";
import ImageCarousel from "../image-carousel";
import { useDebugMode } from "../../hooks/useDebugMode";
import { Accordion, Spinner } from "react-bootstrap";
import ImageRow from "./image-row";

export interface InventoryImagesProps {
  images: InventoryItemStoredImage[];
  deleteImageFunction: (image: InventoryItemStoredImage) => Promise<any>;
}

const InventoryImagesComponent = (props: InventoryImagesProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<InventoryItemStoredImage>(
    props.images[0]
  );
  const [blockActions, setBlockActions] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const debugMode = useDebugMode();

  return (
    <>
      <ImageCarousel
        imageUrls={props.images.map((image) => image.imageUrl)}
        autoSlide={false}
        maxWidth="100%"
        rounded={true}
        noAnimation={true}
        imageCustomStyles={{
          objectFit: "cover",
          aspectRatio: "1/1",
        }}
        controlledProps={{
          selectedIndex: selectedImageIndex,
          onSelect: (i) => {
            setSelectedImageIndex(i);
            setSelectedImage(props.images[i]);
          },
          currentSlideOverlay: (
            <>
              <b>
                Image {selectedImageIndex + 1} of {props.images.length}
              </b>
              <br />
              {selectedImage?.comment ? selectedImage?.comment : null}

              <Button
                variant="danger"
                disabled={blockActions}
                className={
                  confirmDelete && !blockActions ? "confirm-delete" : ""
                }
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation(); // prevents parent onClick from firing
                  if (confirmDelete) {
                    setBlockActions(true);
                    await props.deleteImageFunction(selectedImage);
                    setBlockActions(false);
                    setConfirmDelete(false);
                  } else {
                    setConfirmDelete(true);
                  }
                }}
              >
                {blockActions ? (
                  <>
                    Deleting... <Spinner size="sm" />
                  </>
                ) : confirmDelete ? (
                  "Confirm Delete?"
                ) : (
                  "Delete image"
                )}
              </Button>
            </>
          ),
        }}
      />
      <ImageRow
        imageUrls={props.images.map((image) => image.imageUrl)}
        selectedImageIndex={selectedImageIndex}
        selectImage={(i) => {
          setSelectedImageIndex(i);
          setSelectedImage(props.images[i]);
        }}
      />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Image Details</Accordion.Header>
          <Accordion.Body>
            <>
              {selectedImage?.comment ? (
                <>
                  <b>Comment</b>
                  <p>{selectedImage.comment}</p>
                </>
              ) : (
                <i>No comment on image</i>
              )}
            </>

            {selectedImage?.imageSource ? (
              <>
                <hr />
                <b>Image Source</b>
                <p style={{ textTransform: "capitalize" }}>
                  {selectedImage.imageSource}
                  {selectedImage.imageSource === "grading" &&
                  selectedImage.gradingId ? (
                    <>
                      {" - "}
                      <i>Added in Grading session {selectedImage.gradingId}</i>
                    </>
                  ) : null}
                </p>
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
        {debugMode ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <b style={{ color: "lightcoral" }}>Debug Information</b>
            </Accordion.Header>
            <Accordion.Body>
              {Object.entries(selectedImage).map(([key, value], i) => (
                <div key={i}>
                  <b>{key}</b>{" "}
                  <code style={{ wordWrap: "break-word" }}>
                    {JSON.stringify(value)}
                  </code>
                  <br />
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ) : null}
      </Accordion>
    </>
  );
};

export default InventoryImagesComponent;
