import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FloatingAlert from "../../floating-alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface SaveStatusAlertComponentProps {
  isDirty: boolean;
  isSaving: boolean;
  showSaveSuccessMessage: boolean;
  submitCatalogue: () => void;
  submitTask: () => void;
  successMessage: string;
  saveError?: string;
  scopes?: string[];
}

const SaveStatusAlertComponent = ({
  isDirty,
  isSaving,
  showSaveSuccessMessage,
  submitCatalogue,
  submitTask,
  successMessage,
  saveError,
  scopes,
}: SaveStatusAlertComponentProps) => {
  const savingSpinner = (
    <Spinner
      as={"span"}
      animation="border"
      role="status"
      aria-hidden="true"
      size="sm"
      style={{ marginRight: "0.5em" }}
      className={isSaving ? "" : "visually-hidden"}
    />
  );

  const buttons = [];

  if (scopes && scopes.includes("edit:catalogue-item")) {
    buttons.push(
      <Button
        key="save"
        variant="success"
        disabled={isSaving}
        onClick={() => submitCatalogue()}
      >
        {savingSpinner}
        Save Changes
      </Button>
    );
  }

  if (
    scopes &&
    scopes.includes("create:task-item:data-request") &&
    !scopes.includes("edit:catalogue-item")
  ) {
    buttons.push(
      <Button
        key="task"
        variant="success"
        disabled={isSaving}
        onClick={() => submitTask()}
      >
        {savingSpinner}
        Submit a Change Request
      </Button>
    );
  }

  // showSaveSuccessMessage && !isDirty
  const variant = saveError
    ? "danger"
    : showSaveSuccessMessage
    ? "success"
    : "warning";

  const message = saveError
    ? saveError
    : showSaveSuccessMessage
    ? successMessage
    : "Data has changed. Ability to upload images is disabled until changes are saved.";

  if (isDirty) {
    return (
      <FloatingAlert variant={variant}>
        <Row style={{ flexGrow: 1 }}>
          <Col
            xs={12}
            sm={8}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {message}
          </Col>
          {buttons && buttons.length > 0 && !showSaveSuccessMessage ? (
            <Col
              xs={12}
              sm={4}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ButtonGroup>{buttons.map((b) => b)}</ButtonGroup>
            </Col>
          ) : null}
        </Row>
      </FloatingAlert>
    );
  }

  return null;
};

export default SaveStatusAlertComponent;
