import Placeholder from "react-bootstrap/Placeholder";

export const generatePlaceholderTableRows = (
  placeholderRowCount: number,
  placeholderColumnCount: number
) => {
  const rows: JSX.Element[] = [];
  for (let i = 0; i < placeholderRowCount; i++) {
    const row = (
      <tr key={i}>
        {Array.from({ length: placeholderColumnCount }, (_, j) => (
          <td key={j}>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </td>
        ))}
      </tr>
    );
    rows.push(row);
  }
  return <>{rows}</>;
};
