import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import React, { CSSProperties, useEffect, useState } from "react";
import { Camera, Floppy2, Image } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import { useDebugMode } from "../../hooks/useDebugMode";

export type PreviewSizes = "1" | "2" | "3" | "4" | "5" | "6";

// Typescript support for Modernizr
declare global {
  interface Window {
    Modernizr: {
      capture: boolean;
    };
  }
}

export interface ImageUploadComponentProps {
  onDrop?: (acceptedFiles: File[]) => void;
  onRemoveFile?: (file: File, fileIndex: number) => void;
  maxFiles?: number;
  showMaxFilesText?: boolean;

  previewSizes?: {
    xs: PreviewSizes;
    sm: PreviewSizes;
    md: PreviewSizes;
    lg: PreviewSizes;
    xl: PreviewSizes;
    xxl: PreviewSizes;
  };

  /**
   * Optional settings for saving the images
   */
  saveSettings?: {
    /**
     * Function called when the save button is clicked
     * @param files files to save
     */
    onSave: (files: File[]) => void;

    /**
     * Optional text for the save button
     * @default "Save Images"
     */
    saveButtonText?: string;
  };
}

const imgStyle: CSSProperties = {
  objectFit: "cover",
  aspectRatio: "1/1",
  maxHeight: "500px",
  maxWidth: "750px",
};

const ImageUploadComponent = ({
  onDrop,
  onRemoveFile: deleteFile,
  maxFiles = 1,
  showMaxFilesText = true,
  saveSettings,
  previewSizes,
}: ImageUploadComponentProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);

  type Comment = {
    fileId: string;
    comment: string;
  };
  const [comments, setComments] = useState<Comment[]>([]);
  const [capture, setCapture] = useState(false);

  const handleCommentChange = (
    fileId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newComment = event.target.value;
    setComments((prevComments) => {
      const updatedComments = [...prevComments];
      const commentIndex = updatedComments.findIndex(
        (comment) => comment.fileId === fileId
      );
      if (commentIndex !== -1) {
        updatedComments[commentIndex].comment = newComment;
      } else {
        updatedComments.push({ fileId, comment: newComment });
      }
      return updatedComments;
    });
  };
  const debugMode = useDebugMode();

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles,
    accept: {
      "image/*": [],
    },
    multiple: maxFiles > 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (debugMode) {
        console.debug("Accepted files", acceptedFiles);
      }
      if (onDrop) {
        onDrop(acceptedFiles);
      }
    },
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...files];
    const indexOfFile = newFiles.indexOf(file);
    if (debugMode) {
      console.info("Removing file from uploader", file, indexOfFile);
    }
    newFiles.splice(indexOfFile, 1);
    setFiles(newFiles);

    // call the delete function from incoming props
    if (deleteFile) {
      deleteFile(file, indexOfFile);
    }
  };

  const thumbs = files.map((file, fileIndex) => (
    <>
      <Card
        key={`imgcard-${fileIndex}`}
        style={{ marginTop: "5px", marginBottom: "5px" }}
      >
        <Card.Img
          src={(file as any).preview}
          style={imgStyle}
          alt={file.name}
        />
        <Card.ImgOverlay>
          <Card.Text>
            <Button
              style={{ width: "100%", maxWidth: "5em" }}
              variant="danger"
              onClick={removeFile(file)}
              size="sm"
            >
              Remove
            </Button>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>
      <Row>
        <Col>
          <Form.Group className="mb-1">
            <Form.Control
              value={
                comments.find((comment) => comment.fileId === file.name)
                  ?.comment ?? ""
              }
              onChange={(event) =>
                handleCommentChange(
                  file.name,
                  event as React.ChangeEvent<HTMLTextAreaElement>
                )
              }
              placeholder="Add a comment"
              style={{ width: "100%" }}
              as="textarea"
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  ));

  useEffect(
    // Nested function is intentional - returning a function in a React Component
    // is a cleanup function that will run when the component is unmounted
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
    },
    [files]
  );

  return (
    <Row>
      <Stack className="container">
        <Row
          direction="horizontal"
          xs={previewSizes?.xs ? previewSizes.xs : "1"}
          sm={previewSizes?.sm ? previewSizes.sm : "2"}
          md={previewSizes?.md ? previewSizes.md : "3"}
          lg={previewSizes?.lg ? previewSizes.lg : "4"}
          xl={previewSizes?.xl ? previewSizes.xl : "5"}
          xxl={previewSizes?.xxl ? previewSizes.xxl : "6"}
        >
          {thumbs.map((thumb, i) => (
            <Col key={i}>{thumb}</Col>
          ))}
        </Row>
        <div
          {...getRootProps({ className: "dropzone" })}
          onClick={(e) => e.stopPropagation}
          style={{ textAlign: "center" }}
        >
          <input
            {...getInputProps()}
            capture={capture ? "environment" : undefined}
          />
          <Button
            type="button"
            disabled={files.length >= maxFiles}
            onClick={() => {
              setCapture(false);
              setTimeout(() => {
                open();
              }, 100);
            }}
          >
            Upload Image{maxFiles > 1 ? "(s)" : ""}
            <Image style={{ marginLeft: "0.5em" }} size={24}></Image>
          </Button>
          {window.Modernizr.capture ? (
            <Button
              type="button"
              disabled={files.length >= maxFiles}
              style={{ marginLeft: "1em" }}
              onClick={() => {
                setCapture(true);
                setTimeout(() => {
                  open();
                }, 100);
              }}
              variant="success"
            >
              Take Image{maxFiles > 1 ? "(s)" : ""}
              <Camera style={{ marginLeft: "0.5em" }} size={24}></Camera>
            </Button>
          ) : null}
          {!!saveSettings ? (
            <Button
              onClick={() => {
                saveSettings.onSave(files);
              }}
              style={{ marginLeft: "1em" }}
              variant="success"
            >
              {saveSettings.saveButtonText
                ? saveSettings.saveButtonText
                : "Save Images"}
              <Floppy2 style={{ marginLeft: "0.5em" }} size={20} />
            </Button>
          ) : null}
        </div>
        {showMaxFilesText ? (
          <div style={{ textAlign: "center" }}>
            {maxFiles > 1 ? <b>Maximum number of images: {maxFiles}</b> : null}
          </div>
        ) : null}
      </Stack>
      {debugMode ? (
        <i style={{ color: "coral" }}>
          Capture supported by browser:{" "}
          <code>{JSON.stringify(!!window.Modernizr.capture)}</code>
        </i>
      ) : null}
    </Row>
  );
};

export default ImageUploadComponent;
