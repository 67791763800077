import React, { useContext, useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import { CatalogItem, Client } from "../external";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuthenticatedContext } from "../components/profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import CatalogItemComponent from "../components/catalog/catalog-item";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CatalogItemDuplicateModalComponent from "../components/catalog/catalog-duplicate-modal";
import DataRequestModalComponent from "../components/data-request-modal";
import CatalogMarkReadyToListModal from "../components/catalog/catalog-mark-ready-to-list-modal";
import { useSearchParams } from "react-router-dom";

const createCatalogWorker = createWorkerFactory(
  () => import("../workers/catalog")
);

const createClientWorker = createWorkerFactory(
  () => import("../workers/clients")
);

const createTaskWorker = createWorkerFactory(() => import("../workers/task"));

const CatalogItemPage = (): JSX.Element => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [scopes, setScopes] = useState<string[]>();

  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("origin");

  const [openDataRequests, setOpenDataRequests] = useState<number>(0);
  const [catalogItem, setCatalogItem] = React.useState<CatalogItem>();
  const [clients, setClients] = React.useState<Client[]>([]);
  const [catalogItemLoaded, setCatalogItemLoaded] =
    React.useState<boolean>(false);
  const [clientsLoaded, setClientsLoaded] = React.useState<boolean>(false);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);
  const [showModalDataRequest, setShowModalDataRequest] = useState(false);
  const [showModalReadyToList, setShowModalReadyToList] = useState(false);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogWorker = useWorker(createCatalogWorker);
  const taskWorker = useWorker(createTaskWorker);
  const clientsWorker = useWorker(createClientWorker);

  const handleCloseModalDuplicate = () => {
    setShowModalDuplicate(false);
  };

  const handleCloseModalDataRequest = () => {
    setShowModalDataRequest(false);
  };

  useEffect(() => {
    if (userAuthenticatedContext.token && itemId) {
      (async () => {
        const tasks = await taskWorker.getExistingTasksCountByIdAndType(
          itemId,
          "catalogueDataRequest",
          userAuthenticatedContext.token!
        );
        setOpenDataRequests(tasks.meta.totalResults);
      })();
    }
  }, [userAuthenticatedContext, taskWorker, itemId]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const catalogItemResponse = await catalogWorker.getCatalogItem(
          userAuthenticatedContext.token!,
          itemId!
        );
        setCatalogItem(catalogItemResponse);
        setCatalogItemLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, catalogWorker, itemId]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }

    if (userAuthenticatedContext.scopes) {
      setScopes(userAuthenticatedContext.scopes);
    }
  }, [userAuthenticatedContext, clientsWorker]);

  const persist = async (catalogItem: CatalogItem) => {
    if (!userAuthenticatedContext.token) {
      throw new Error("No token is available!");
    }

    const item = await catalogWorker.putCatalogItem(
      userAuthenticatedContext.token,
      catalogItem
    );

    setCatalogItem(catalogItem);

    return item;
  };

  const persistTask = async (
    data: CatalogItem,
    title: string,
    description: string
  ) => {
    if (!userAuthenticatedContext.token) {
      throw new Error("No token is available!");
    }

    return taskWorker.postTask(
      "catalogueChangeRequest",
      data,
      userAuthenticatedContext.token,
      title,
      description
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Button
            variant="secondary"
            style={{ marginBottom: "1em" }}
            size="sm"
            onClick={() => navigate("/catalog")}
          >
            Return to Catalogue items
          </Button>
          {catalogItemLoaded &&
          catalogItem &&
          clientsLoaded &&
          clients.length > 0 ? (
            <Col>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setShowModalDuplicate(true)}
                style={{ marginRight: "10px" }} // Adjust the value as needed
              >
                Duplicate
              </Button>
              {scopes && scopes.includes("create:task-item:data-request") ? (
                <>
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={() => setShowModalDataRequest(true)}
                    style={{ marginRight: "10px" }} // Adjust the value as needed
                  >
                    Data Request
                  </Button>
                  {openDataRequests > 0 ? (
                    <span>{openDataRequests} Open Data Requests Available</span>
                  ) : null}
                </>
              ) : null}
              {!catalogItem.suitableForListing ? (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setShowModalReadyToList(true)}
                  style={{ marginRight: "10px" }} // Adjust the value as needed
                >
                  Mark Ready to List
                </Button>
              ) : (
                <Button size="sm" variant="success" disabled={true}>
                  Ready For Listing
                </Button>
              )}
              <DataRequestModalComponent
                catalogItem={catalogItem}
                showModal={showModalDataRequest}
                handleCloseModal={handleCloseModalDataRequest}
                openDataRequests={openDataRequests}
                setOpenDataRequests={setOpenDataRequests}
              />
              <CatalogItemDuplicateModalComponent
                showModal={showModalDuplicate}
                handleCloseModal={handleCloseModalDuplicate}
                itemId={catalogItem.itemId}
                clientItemId={catalogItem.clientItemId}
                clients={clients}
              />
              <CatalogMarkReadyToListModal
                catalogItem={catalogItem}
                showModal={showModalReadyToList}
                handleCloseModal={() => setShowModalReadyToList(false)}
                handleSubmit={persist}
                linkedTaskId={queryParams ? queryParams.toString() : undefined}
              />
            </Col>
          ) : null}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {catalogItemLoaded && catalogItem ? (
            <CatalogItemComponent
              catalogItem={catalogItem}
              persist={persist}
              persistTask={persistTask}
            ></CatalogItemComponent>
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(CatalogItemPage, {
  onRedirecting: () => <Loading />,
});
