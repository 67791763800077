import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useGradingAndRefurbStateTwoLevelNestedField } from "../persist-helpers";
import { DetailedInformationCountOption } from "../../../../external/inventory-component/configuration/detailedInformation";

export interface DamagesCountEntryTypeComponentProps {
  optionKey: string;
  settings: DetailedInformationCountOption;
  stepOutputKey: string;
  refurbIdentifier: string;
}
const DamagesCountEntryTypeComponent = ({
  settings,
  optionKey,
  refurbIdentifier,
  stepOutputKey,
}: DamagesCountEntryTypeComponentProps) => {
  const [state, setState] = useGradingAndRefurbStateTwoLevelNestedField(
    stepOutputKey,
    refurbIdentifier,
    optionKey,
    settings.resultKey,
    0
  );

  return (
    <Col xs={12} md={12} xl={6} xxl={12}>
      <Form.Label className="mt-2">{settings.displayName}</Form.Label>
      <InputGroup className="mb-2">
        <Form.Control
          type="number"
          style={{ fontSize: "1.25em" }}
          defaultValue={state}
          onChange={(e) => {
            setState(Number(e.target.value));
          }}
        ></Form.Control>
        {settings.units ? (
          <InputGroup.Text
            id={`unit${settings.displayName}`}
            style={{ fontSize: "1.25em" }}
          >
            {settings.units}
          </InputGroup.Text>
        ) : null}
      </InputGroup>
    </Col>
  );
};

export default DamagesCountEntryTypeComponent;
