import { useState, useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { useDebounceValue } from "usehooks-ts";

import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getTaskStats } from "../../workers/task";
import { UserAuthenticatedContext } from "../profile";
import { formatTaskStatsData } from "../../helpers/formatTaskStatsData";

const COLORS = ["#9d9bd8", "#9bd8bc", "#d89bb7", "d5d89b"];
export interface Bucket {
  key: string;
  doc_count: number;
}

export interface TasksBy {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket[];
}

export interface TotalTasks {
  value: number;
}

export interface Meta {
  current: number;
  maxPerPage: number;
  next: null;
  previous: null;
  totalPages: number;
  totalResults: number;
}

export interface TaskData {
  tasks_by_assigned_to: TasksBy;
  tasks_by_status: TasksBy;
  tasks_completed_rejected_cancelled_last_seven_days: {
    meta: Meta;
    doc_count: number;
    tasks_by_status: TasksBy;
  };
  tasks_created_over_seven_days_ago: {
    meta: Meta;
    doc_count: number;
    tasks_by_assigned_to: TasksBy;
    tasks_by_type: TasksBy;
  };
  tasks_by_type: TasksBy;
  total_tasks: TotalTasks;
  tasks_created_last_seven_days: {
    meta: Meta;
    doc_count: number;
    tasks_by_type: TasksBy;
  };
}

export interface TaskStats {
  meta: Meta;
  data: TaskData[];
}

type FormattedTaskStats = {
  tasksByAssignedTo: {
    name: string;
    value: number;
    sevenDaysCount: number;
  }[];
  tasksByStatus: {
    name: string;
    value: number;
  }[];
  tasksByType: {
    name: string;
    value: number;
    sevenDaysCount: number;
  }[];
  totalTasks: number;
};

enum TaskTypeEnum {
  catalogueDataRequest = "Catalogue Data",
  catalogueChangeRequest = "Catalogue Change",
  catalogueCreateRequest = "Catalogue Create",
}

enum TaskStatusEnum {
  created = "Created",
  completed = "Completed",
  rejected = "Rejected",
  cancelled = "Cancelled",
  inProgress = "In Progress",
}

const TaskDashboard = (): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [taskStatistics, setTaskStatistics] = useState<FormattedTaskStats>();
  const [loadingDebounced, setLoadingDebounced] = useDebounceValue(true, 750);
  const [loadingError, setLoadingError] = useState<string | null>(null);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      const fetchTaskStats = async (token: string) => {
        try {
          const taskStats = await getTaskStats(token);
          const formattedTaskStats = formatTaskStatsData(taskStats);
          setTaskStatistics(formattedTaskStats);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchTaskStats(userAuthenticatedContext.token)
        .catch((e) => {
          if (e?.message) {
            setLoadingError(e.message);
          } else {
            setLoadingError(e);
          }
        })
        .finally(() => {
          setLoadingDebounced(false);
        });
    }
  }, [setLoadingDebounced, userAuthenticatedContext.token]);

  if (loadingError) {
    <Container>
      <Alert variant="danger">{loadingError}</Alert>
    </Container>;
  }

  if (loadingDebounced) {
    return (
      <Container
        className="w-100 h-100"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "400px",
        }}
      >
        <Spinner animation="border"></Spinner>
      </Container>
    );
  }

  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Live Tasks</h3>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          border: "1px solid #ccc",
        }}
      >
        <Card
          className="m-3 text-center"
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: "150px",
          }}
        >
          <Card.Body
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card.Title style={{ fontSize: "36px" }}>
              {taskStatistics?.totalTasks}
            </Card.Title>
            <Card.Text>Total</Card.Text>
          </Card.Body>
        </Card>
        <Card
          className="m-3 text-center"
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: "250px",
          }}
        >
          <Card.Body>
            <Card.Title>By Status</Card.Title>
            <ListGroup variant="flush">
              {taskStatistics?.tasksByStatus.map(
                ({ name: status, value: count }) => (
                  <ListGroup.Item key={status}>
                    {status in TaskStatusEnum
                      ? `${
                          TaskStatusEnum[status as keyof typeof TaskStatusEnum]
                        }: ${count}`
                      : "Invalid status"}
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Card.Body>
        </Card>
        <Card
          className="m-3 text-center"
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: "250px",
          }}
        >
          <Card.Body>
            <Card.Title>
              By Type <span style={{ color: "red" }}>(7d+)</span>
            </Card.Title>
            <ListGroup variant="flush">
              {taskStatistics?.tasksByType.map(
                ({ name: type, value: count, sevenDaysCount }) => (
                  <ListGroup.Item key={type}>
                    {type in TaskTypeEnum ? (
                      <>
                        {TaskTypeEnum[type as keyof typeof TaskTypeEnum]}:{" "}
                        {count}{" "}
                        <span style={{ color: "red" }}>({sevenDaysCount})</span>
                      </>
                    ) : (
                      "Invalid type"
                    )}
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Card.Body>
        </Card>
        <Card
          className="m-3 text-center"
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: "250px",
          }}
        >
          <Card.Body>
            <Card.Title>
              By Person <span style={{ color: "red" }}>(7d+)</span>
            </Card.Title>
            <ListGroup variant="flush">
              {taskStatistics?.tasksByAssignedTo.map(
                ({ name: person, value: count, sevenDaysCount }) => (
                  <ListGroup.Item key={person}>
                    {person}: {count}{" "}
                    <span style={{ color: "red" }}>({sevenDaysCount})</span>
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Card.Body>
        </Card>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center", // centers items vertically
          border: "1px solid #ccc",
        }}
      >
        <ResponsiveContainer width="50%" height={400}>
          <PieChart width={30} height={400}>
            <Pie
              dataKey="value"
              nameKey="name"
              isAnimationActive={false}
              data={taskStatistics?.tasksByType.map(
                ({ name: type, value }) => ({
                  name:
                    type in TaskTypeEnum
                      ? TaskTypeEnum[type as keyof typeof TaskTypeEnum]
                      : "Invalid type",
                  value,
                })
              )}
              cx={200}
              cy={200}
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {taskStatistics?.tasksByType.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="left" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={taskStatistics?.tasksByAssignedTo}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" name="Tasks" />
        </BarChart>
      </Row>
    </div>
  );
};

export default TaskDashboard;
