import { Printer } from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

export interface PrintUIProps {
  printButtonText?: string;
  printContentsHTML: string;
  externalPrinters?: CirclePrinter[];
}

export type CirclePrinter = {
  internalName: string;
  displayText: string;
  action: () => void;
};

const PrintUI = ({
  printButtonText,
  printContentsHTML,
  externalPrinters,
}: PrintUIProps) => {
  const printLocal = () => {
    const printWindow = window.open("", "PRINT", "height=600,width=800");
    if (!printWindow) {
      return;
    }

    printWindow.document.write(
      `<html>
        <head><title>Print</title></head>
        <body>${printContentsHTML}</body>
      </html>`
    );

    // Set timeout gives time for images to be rendered correctly
    setTimeout(() => {
      // Print is a blocking call, so it will only close after print is confirmed or cancelled
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  const printers: CirclePrinter[] = [
    {
      internalName: "local",
      displayText: "Use Local Printer",
      action: printLocal,
    },
    ...(externalPrinters ? externalPrinters : []),
  ];

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <Printer style={{ marginRight: "0.5em" }} size={24} />{" "}
          {printButtonText ? printButtonText : "Print"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {printers.map((p) => (
            <Dropdown.Item key={p.internalName} onClick={p.action}>
              {p.displayText}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Modal></Modal>
    </>
  );
};

export default PrintUI;
