import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InventoryItem } from "../../external";

import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { YieldCalculationResults } from "../../external/inventory-component/types";

interface InventoryInboundCardProps {
  values: InventoryItem;
  onShowModal: (index: number) => void;
  onShowNotesModal: (index: number, data: InventoryItem) => void;
  onHandleConfirmArrival: (
    index: number,
    value: boolean,
    setFieldValue: any
  ) => void;
  index: number;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  inboundProcessed: boolean;
}

const InventoryInboundCard: React.FC<InventoryInboundCardProps> = ({
  values,
  onShowModal,
  onShowNotesModal,
  onHandleConfirmArrival,
  index,
  disabled,
  setFieldValue,
  inboundProcessed,
}) => {
  const noImageAvailableUrl = "https://via.placeholder.com/150/?text=No+Images";
  const [exit, setExit] = useState<YieldCalculationResults | string>(
    "Loading..."
  );

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const handleViewMore = () => {
    onShowModal(index);
  };

  const handleAddNotes = () => {
    onShowNotesModal(index, values);
  };

  const createInventoryWorker = createWorkerFactory(
    () => import("../../workers/inventory")
  );

  const inventoryWorker = useWorker(createInventoryWorker);

  useEffect(() => {
    const fetchExit = async (id: string) => {
      try {
        const result = await inventoryWorker.getYieldCalculation(
          userAuthenticatedContext.token as string,
          id
        );
        if (result.data) {
          setExit(result.data.exit);
        } else if (result.error) {
          setExit(result.error.message);
        }
      } catch (err) {
        setExit("An unexpected error occurred");
      }
    };

    fetchExit(values.id);
  }, [values.id, inventoryWorker, userAuthenticatedContext.token]);

  return (
    <Col key={values.catalogItemId}>
      <Card className="mb-4">
        {values.imageLinks !== undefined && values.imageLinks.length > 0 ? (
          <Card.Img
            variant="top"
            src={values.imageLinks[0] ? values.imageLinks[0].split("|")[0] : ""}
            alt="Product Image"
            style={{ height: "300px", objectFit: "cover" }}
          />
        ) : (
          <Card.Img
            variant="top"
            src={noImageAvailableUrl}
            alt="Product Image"
            style={{ height: "300px", objectFit: "cover" }}
          />
        )}
        <Card.Body>
          <Card.Title>{values.catalogItemId}</Card.Title>
          <Row>
            <Col>
              <Card.Text>{values.name}</Card.Text>
              <Card.Text>Pallet: {values.pallet}</Card.Text>
              <Card.Text>Exit: {exit}</Card.Text>
            </Col>
          </Row>

          <Button
            variant="primary"
            className="mb-2"
            onClick={handleViewMore}
            disabled={disabled}
            style={{ marginRight: "10px" }}
          >
            View More
          </Button>
          {!inboundProcessed ? (
            <>
              <Button
                disabled={disabled}
                variant="secondary"
                className="mb-2"
                onClick={handleAddNotes}
              >
                Add Notes
              </Button>
              <Form.Check
                disabled={disabled}
                type="checkbox"
                label="Confirm Arrival"
                onChange={async (event) => {
                  const value = event.target.checked;
                  onHandleConfirmArrival(index, value, setFieldValue);
                }}
              />
            </>
          ) : (
            <>
              <Card.Text>Date Confirmed: {values.arrivalDate}</Card.Text>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default InventoryInboundCard;
