import axios from "axios";
import { ItemsPaginated } from "../external";
import { InventoryFilters } from "../workers/inventory";
import { PalletFilters } from "../workers/pallet";
import { TaskFilters } from "../workers/task";

function isInventoryFilters(filters: any): filters is InventoryFilters {
  return "loadReferenceId" in filters;
}

function isPalletFilters(filters: any): filters is PalletFilters {
  return "location" in filters;
}

function isTaskFilters(filters: any): filters is TaskFilters {
  return "taskId" in filters;
}

type CombinedFilters = (InventoryFilters | PalletFilters | TaskFilters) & {
  assignedTo?: {
    name: string;
    email: string;
    itemId?: string;
  };
};

export const createSearchRequestBody = (filters: CombinedFilters) => {
  let sort: Array<{ [key: string]: { order: "asc" | "desc" } }> = [];
  if (filters.sortField) {
    sort.push({
      [filters.sortField]: { order: filters.sortDirection },
    });
  }

  // Determine filter type and delegate to specific helper function
  let query: any;
  if (isInventoryFilters(filters)) {
    query = buildInventoryFilterQuery(filters);
  } else if (isPalletFilters(filters)) {
    query = buildPalletFilterQuery(filters);
  } else if (isTaskFilters(filters)) {
    query = buildTaskFilterQuery(filters);
  }

  return {
    count: filters.count,
    page: filters.page,
    query: query,
    sort: sort,
  };
};

const buildInventoryFilterQuery = (filters: InventoryFilters) => {
  let must: any[] = [];
  let filter: any[] = [];

  if (filters.id) {
    must.push({ wildcard: { "id.keyword": `*${filters.id}*` } });
  }

  if (filters.clientId) {
    must.push({ term: { "clientId.keyword": filters.clientId } });
  }

  if (filters.description) {
    must.push({
      wildcard: { "description.keyword": `*${filters.description}*` },
    });
  }

  if (filters.loadReferenceId) {
    must.push({
      wildcard: { "loadReferenceId.keyword": `${filters.loadReferenceId}*` },
    });
  }

  if (filters.clientItemId) {
    must.push({
      wildcard: { "clientItemId.keyword": `*${filters.clientItemId}*` },
    });
  }

  if (filters.expectedArrivalDate) {
    // Extract only the date portion (YYYY-MM-DD) from the timestamp
    const date = filters.expectedArrivalDate.split("T")[0];

    must.push({
      term: { expectedArrivalDate: date },
    });
  }

  if (filters.text) {
    filter.push({ query_string: { query: filters.text } });
  }

  return { bool: { must, filter } };
};

const buildPalletFilterQuery = (filters: PalletFilters) => {
  let must: any[] = [];
  let filter: any[] = [];

  if (filters.id) {
    must.push({ wildcard: { "id.keyword": `*${filters.id}*` } });
  }

  if (filters.clientId) {
    must.push({ term: { "clientId.keyword": filters.clientId } });
  }

  if (filters.location) {
    must.push({ wildcard: { "location.keyword": `*${filters.location}*` } });
  }

  if (filters.createdAtTimestamp) {
    // Extract only the date portion (YYYY-MM-DD) from the timestamp
    const date = filters.createdAtTimestamp.split("T")[0];

    // Set start and end of the day based on the extracted date
    const dateStart = `${date}T00:00:00.000Z`;
    const dateEnd = `${date}T23:59:59.999Z`;

    must.push({
      range: { createdAtTimestamp: { gte: dateStart, lte: dateEnd } },
    });
  }

  return { bool: { must, filter } };
};

// Helper function for TaskFilters
const buildTaskFilterQuery = (filters: TaskFilters) => {
  let must: any[] = [];
  let filter: any[] = [];

  if (filters.taskId) {
    must.push({ term: { "taskId.keyword": filters.taskId } });
  }

  if (filters.search) {
    filter.push({ query_string: { query: filters.search } });
  }

  if (filters.type) {
    filter.push({ term: { "type.keyword": filters.type } });
  }

  if (filters.assignedTo) {
    must.push({
      term: { "assignedTo.email.keyword": filters.assignedTo.email },
    });
  }

  if (filters.status) {
    filter.push({ terms: { "status.keyword": filters.status } });
  }

  return { bool: { must, filter } };
};

export const getOpenSearchItems = async <T>(
  authToken: string,
  filters: InventoryFilters | PalletFilters | TaskFilters,
  dataType: "INVENTORY" | "PALLET" | "CATALOG" | "TASKS"
): Promise<ItemsPaginated<T>> => {
  const envVarName = `REACT_APP_GET_${dataType}_ITEMS_URL`;
  const getItemsUrl = process.env[envVarName] as string;

  const requestBody = createSearchRequestBody(filters);

  try {
    const getItemsResult = await axios.post(getItemsUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return getItemsResult.data;
  } catch (error) {
    console.error("Error getting items:", error);
    throw error;
  }
};
