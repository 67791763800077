import "../../App.scss";
import { CatalogUploadedTemplateStatusRecord, Client } from "../../external";
import Table from "react-bootstrap/Table";

export interface CatalogUploadedTemplateStatusProps {
  rows: CatalogUploadedTemplateStatusRecord[];
  clients: Client[];
  selectedDate: string;
}

const CatalogUploadedTemplateStatus = ({
  rows,
  clients,
  selectedDate,
}: CatalogUploadedTemplateStatusProps): JSX.Element => {
  const renderRows = () =>
    rows.map((r, i) => {
      const client = clients.find((c) => c.clientId === r.clientId);
      return (
        <tr key={i}>
          <td>
            {client?.clientName} ({client?.clientId})
          </td>
          <td>{r.processedRecordCount}</td>
          <td>{r.skippedRecordCount}</td>
          <td>{new Date(r.timestamp).toLocaleString()}</td>
          <td>
            {r.auditName} ({r.auditEmail})
          </td>
        </tr>
      );
    });

  const renderEmpty = () => {
    return (
      <tr>
        <td colSpan={6}>
          <p>No uploaded catalogues found for {selectedDate}.</p>
        </td>
      </tr>
    );
  };

  return (
    <Table>
      <thead>
        <tr style={{ fontWeight: "bolder" }}>
          <td>Client</td>
          <td>Processed records</td>
          <td>Skipped records</td>
          <td>Time</td>
          <td>User</td>
        </tr>
      </thead>
      <tbody>{rows && rows.length ? renderRows() : renderEmpty()}</tbody>
    </Table>
  );
};

export default CatalogUploadedTemplateStatus;
