import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Loading from "../components/loading";
import FinanceUploadComponent from "../components/finance/upload-finance";

const FinanceUploadPage = (): JSX.Element => {
  return <FinanceUploadComponent />;
};

export default withAuthenticationRequired(FinanceUploadPage, {
  onRedirecting: () => <Loading />,
});
