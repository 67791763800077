import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CatalogItem } from "../../external";
import GradingAndRefurbUIComponent from "../../components/gradingandrefurb/refurb-steps/refurb-parent";
import { DefaultTestRefurbStepsConfiguration } from "../../components/gradingandrefurb/refurb-steps/configuration";
import MockupHeaderComponent from "./mockup-header";

const catalogItem: CatalogItem = {
  imageTags: [],
  lastUpdatedTimestamp: "2022-11-16T15:45:04.103Z",
  auditHistory: [
    {
      timestamp: "2022-11-16T15:45:04.103Z",
      userEmail: "Test",
      userName: "Test",
    },
  ],
  clearCycleImageLinks: [],
  overallSize: {
    height: 78,
    width: 135,
    length: 48,
  },
  clientId: "JLP",
  clientImageLinks: [
    "https://data-assets-prod.live.clearcyclecloud.uk/ASH/154vp0660bcb93a7qxmv1dmv81ajdrf6.jpeg",
    "https://data-assets-prod.live.clearcyclecloud.uk/ASH/uefp8lmqi660bcb9477muqigzzohzrw9.jpeg",
    "https://data-assets-prod.live.clearcyclecloud.uk/ASH/k0c0z660bcb95n0dhrr3k1hahprbfja5.jpeg",
  ],
  attachedFiles: [],
  clientItemId: "gustavdresser",
  clientSkuHyperlinks: [
    "https://www.johnlewis.com/john-lewis-gustav-dressing-table-grey/p6321658",
  ],
  createdAtTimestamp: "2023-02-01T15:50:52.509Z",
  identifiers: [],
  itemId: "1",
  name: "Gustav Dressing Table, Grey",
  previousIdentifiers: [],
  retailPricePence: 69000,
  costPricePence: 4900,
  colour: "Grey",
  description:
    "A suite with generous cushions and smart stitching, promising superior comfort, support and style.",
  productCategory: "Dressing Table",
  weightGrams: 33000,
  archived: false,
};

const refurbIdentifer = "defaultRefurbSteps_Refurb";

const DefaultMockupRefurbPage = (): JSX.Element => (
  <Container>
    <MockupHeaderComponent refurbIdentifier={refurbIdentifer} />
    <Row>
      {/** Below has a margin to account for the fixed footer on Mobile */}
      <Col style={{ marginBottom: "50px" }}>
        <GradingAndRefurbUIComponent
          config={DefaultTestRefurbStepsConfiguration(catalogItem)}
          inventoryId={"defaulttest_mockup"}
          refurbIdentifier={refurbIdentifer}
          catalogItem={catalogItem}
          completeGradingAndRefurb={() => new Promise(() => {})}
        />
      </Col>
    </Row>
  </Container>
);

export default withAuthenticationRequired(DefaultMockupRefurbPage, {
  onRedirecting: () => <Loading />,
});
