import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import {
  ArrowBarLeft,
  ArrowLeft,
  ArrowRight,
  ArrowBarRight,
} from "react-bootstrap-icons";
import { Table } from "@tanstack/react-table";

export interface TableFooterBarProps<T> {
  variant: string;
  iconSize: number;
  pageSizeOptions: number[];
  table: Table<T>;
}

const TableFooterBar = <T extends Object>({
  table,
  variant,
  pageSizeOptions,
  iconSize,
}: TableFooterBarProps<T>) => {
  return (
    <Row>
      <Col className="d-flex" style={{ justifyContent: "left" }}>
        <ButtonGroup className="m-2">
          <Button
            variant={variant}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            aria-label="First page"
          >
            <ArrowBarLeft size={iconSize} />
          </Button>
          <Button
            variant={variant}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            aria-label="Previous page"
          >
            <ArrowLeft size={iconSize} />
          </Button>
          <DropdownButton
            as={ButtonGroup}
            drop="up"
            variant={variant}
            title={`Page ${
              table.getState().pagination.pageIndex + 1
            } of ${table.getPageCount()}`}
          >
            {Array.from({ length: table.getPageCount() }, (v, i) => i).map(
              (i) => (
                <Dropdown.Item key={i} onClick={() => table.setPageIndex(i)}>
                  {i + 1}
                </Dropdown.Item>
              )
            )}
          </DropdownButton>
          <Button
            variant={variant}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="Next page"
          >
            <ArrowRight size={iconSize} />
          </Button>
          <Button
            variant={variant}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            aria-label="Last page"
          >
            <ArrowBarRight size={iconSize} />
          </Button>
        </ButtonGroup>
      </Col>
      <Col className="d-flex" style={{ justifyContent: "center" }}>
        <p>{table.getRowModel().rows.length} Rows</p>
      </Col>
      <Col className="d-flex" style={{ justifyContent: "right" }}>
        <ButtonGroup className="m-2">
          <DropdownButton
            as={ButtonGroup}
            drop="up"
            variant={variant}
            title={`Page size: ${table.getState().pagination.pageSize}`}
          >
            {pageSizeOptions.map((i) => (
              <Dropdown.Item key={i} onClick={() => table.setPageSize(i)}>
                {i}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default TableFooterBar;
