import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { UserAuthenticatedContext } from "../profile";
import { updateTaskStatus } from "../../workers/task";
import TableCell from "./task-item-cell";

interface ComparisonTableProps {
  currentCatalogueState: any;
  proposedCatalogueState: any;
  taskId: string;
  setTaskStatus: (status: string) => void;
  setTaskReason: (status: string) => void;
  taskStatus: string | undefined;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({
  currentCatalogueState,
  proposedCatalogueState,
  taskId,
  setTaskStatus,
  setTaskReason,
  taskStatus,
}) => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [userToken, setUserToken] = useState<string>();
  const [isProcessed, setIsProcessed] = useState<boolean>(false);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  useEffect(() => {
    if (
      taskStatus === "completed" ||
      taskStatus === "rejected" ||
      taskStatus === "cancelled"
    ) {
      setIsProcessed(true);
    }
  }, [taskStatus, isProcessed]);

  function arraysEqual(a: unknown[], b: unknown[]) {
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    for (let i = 0; i < sortedA.length; i++) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
  }

  const handleAcceptClick = () => {
    setShowAcceptModal(true);
  };

  const handleRejectClick = () => {
    setShowRejectModal(true);
  };

  const handleRejectReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRejectReason(event.target.value);
  };

  const handleAcceptChange = async () => {
    if (userToken) {
      try {
        await updateTaskStatus(userToken, taskId, "completed");
        setTaskStatus("completed");
      } catch (error) {
        throw new Error('Error updating task status to "completed"');
      }

      setShowAcceptModal(false);
    }
  };

  const handleRejectChange = async () => {
    if (userToken) {
      await updateTaskStatus(userToken, taskId, "rejected", rejectReason);
      setTaskStatus("rejected");
      setTaskReason(rejectReason);
      setShowRejectModal(false);
    }
  };

  return (
    <Container className="mx-0">
      <Table striped bordered hover className="mx-0">
        <thead>
          <tr>
            <th>Key</th>
            <th>Current</th>
            <th>Proposed</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(currentCatalogueState).map((key) => {
            const currentValue = currentCatalogueState[key];
            const proposedValue = proposedCatalogueState[key];

            const hasChanged =
              Array.isArray(currentValue) && Array.isArray(proposedValue)
                ? !arraysEqual(currentValue, proposedValue)
                : Array.isArray(currentValue) &&
                  currentValue.length === 0 &&
                  proposedValue === undefined
                ? false
                : JSON.stringify(currentValue) !==
                  JSON.stringify(proposedValue);

            return hasChanged &&
              ![
                "lastUpdatedTimestamp",
                "auditHistory",
                "identifiers",
                "createdAtTimestamp",
                "imageTags",
                "previousIdentifiers",
              ].includes(key) ? (
              <tr key={key}>
                <td
                  style={{
                    maxWidth: "200px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  {key}
                </td>
                <TableCell keyName={key} data={currentValue}></TableCell>
                <TableCell
                  keyName={key}
                  data={proposedValue}
                  change={true}
                ></TableCell>
              </tr>
            ) : null;
          })}
        </tbody>
      </Table>
      <Card>
        <Card.Body className="d-flex justify-content-end">
          <Button
            disabled={isProcessed}
            variant="success"
            className="mx-4"
            onClick={handleAcceptClick}
          >
            Accept
          </Button>
          <Button
            variant="danger"
            onClick={handleRejectClick}
            disabled={isProcessed}
          >
            Reject
          </Button>
        </Card.Body>
      </Card>
      <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Accept</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to accept this change?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAcceptModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleAcceptChange();
              setShowAcceptModal(false);
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Reject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="rejectReason">
              <Form.Control
                type="text"
                placeholder="Enter Reason for Rejection"
                value={rejectReason}
                onChange={handleRejectReasonChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!rejectReason}
            onClick={() => {
              handleRejectChange();
              setShowRejectModal(false);
            }}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ComparisonTable;
