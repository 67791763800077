import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormLabel from "react-bootstrap/FormLabel";

import { CatalogItem, ItemDimensionsRequired } from "../../../external";
import { useForm } from "react-hook-form";
import { UseFormRegister } from "react-hook-form";
import Badge from "react-bootstrap/Badge";

export interface BoxDimensionRowComponentProps {
  rowId: number;
  rowData: ItemDimensionsRequired;
  isSaving: boolean;
  viewOnly: boolean;
  register: UseFormRegister<CatalogItem>;
  onRemove: (rowId: number) => void;
  onUpdate: (rowId: number, updatedData: ItemDimensionsRequired) => void;
  showBoxDimsMultiBoxes: boolean;
  offCanvas: boolean;
  offCanvasSize: number;
}

const BoxDimensionRowComponent = ({
  rowId,
  rowData,
  isSaving,
  viewOnly,
  onRemove,
  onUpdate,
  showBoxDimsMultiBoxes,
  offCanvas,
  offCanvasSize,
}: BoxDimensionRowComponentProps) => {
  const handleRemove = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onRemove(rowId);
  };

  const handleInputChange = (
    rowId: number,
    value: number | "",
    key: string
  ) => {
    const updatedData = { ...rowData, [key]: value };
    onUpdate(rowId, updatedData);
  };

  const {
    register,
    formState: { touchedFields, errors },
    trigger,
  } = useForm<ItemDimensionsRequired>({
    mode: "onChange",
    defaultValues: {
      ...rowData,
    },
  });

  return (
    <Row className="d-flex align-items-center">
      <Col sm={offCanvas ? offCanvasSize : 3}>
        <FormLabel className="me-4 pr-4 capitalize-first fw-bold">
          {showBoxDimsMultiBoxes ? `Box ${rowId + 1}` : "Box"}
        </FormLabel>
        {showBoxDimsMultiBoxes && !viewOnly ? (
          <Button disabled={isSaving} variant="danger" onClick={handleRemove}>
            Remove
          </Button>
        ) : null}
      </Col>
      <Col sm={offCanvas ? offCanvasSize : 3}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">
            Height<Badge bg="danger">Required</Badge>
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              {...register("height", {
                required: true,
                min: {
                  message: "Value must cannot be less than 0.0001",
                  value: 0.0001,
                },
                max: 99999999,
                disabled: viewOnly || isSaving,
              })}
              type="number"
              isValid={touchedFields.height && !errors.height?.message}
              isInvalid={errors.height?.message !== undefined}
              onChange={(e) => {
                trigger("height").then((r) => {
                  const inputValue = e.target.value.trim();
                  const newValue = inputValue === "" ? "" : Number(inputValue);
                  handleInputChange(rowId, newValue, "height");
                }); // Trigger validation manually
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
            />
            <InputGroup.Text id="item-height">cm</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.height?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col sm={offCanvas ? offCanvasSize : 3}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">
            Width<Badge bg="danger">Required</Badge>
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              {...register("width", {
                required: true,
                min: {
                  message: "Value must cannot be less than 0.0001",
                  value: 0.0001,
                },
                max: 99999999,
                disabled: viewOnly || isSaving,
              })}
              type="number"
              isValid={touchedFields.width && !errors.width?.message}
              isInvalid={errors.width?.message !== undefined}
              onChange={(e) => {
                trigger("width").then((r) => {
                  const inputValue = e.target.value.trim();
                  const newValue = inputValue === "" ? "" : Number(inputValue);
                  handleInputChange(rowId, newValue, "width");
                }); // Trigger validation manually
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
            />
            <InputGroup.Text id="item-width">cm</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.width?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col sm={offCanvas ? offCanvasSize : 3}>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-3">
            Length<Badge bg="danger">Required</Badge>
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              {...register("length", {
                required: true,
                min: {
                  message: "Value must cannot be less than 0.0001",
                  value: 0.0001,
                },
                max: 99999999,
                disabled: viewOnly || isSaving,
              })}
              type="number"
              isValid={touchedFields.length && !errors.length?.message}
              isInvalid={errors.length?.message !== undefined}
              onChange={(e) => {
                trigger("length").then((r) => {
                  const inputValue = e.target.value.trim();
                  const newValue = inputValue === "" ? "" : Number(inputValue);
                  handleInputChange(rowId, newValue, "length");
                }); // Trigger validation manually
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
            />
            <InputGroup.Text id="item-length">cm</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.length?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default BoxDimensionRowComponent;
