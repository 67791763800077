/// <reference types="react-scripts" />

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const About = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>About</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
