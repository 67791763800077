import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TaskItem from "../../components/task/task-item";
import { UserAuthenticatedContext } from "../../components/profile";

import Loading from "../../components/loading";
import { getAssignees, getTask } from "../../workers/task";
import { Task } from "../../external/task-component/openapi";

interface TaskPageProps {
  taskId: string;
}

const TaskPage: React.FC<TaskPageProps> = () => {
  const [assignees, setAssignees] = useState<{ email: string; name: string }[]>(
    []
  );

  const { taskId } = useParams();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const [taskItem, setTaskItem] = useState<Task>();

  useEffect(() => {
    if (userAuthenticatedContext && userAuthenticatedContext.token && taskId) {
      const bearerToken = userAuthenticatedContext.token;
      const fetchData = async () => {
        const taskItem = await getTask(taskId, bearerToken);
        setTaskItem(taskItem);
      };

      const fetchAssignees = async () => {
        const assigneesForDropdown = await getAssignees(
          userAuthenticatedContext.token ?? ""
        );

        setAssignees([
          ...assigneesForDropdown.data,
          { email: "", name: "Unassigned" },
        ]);
      };

      fetchData();
      fetchAssignees();
    }
  }, [userAuthenticatedContext, taskId]);

  return (
    <div className="mx-5">
      {taskItem === undefined || assignees.length === 0 ? (
        <Loading />
      ) : (
        <TaskItem task={taskItem} assignees={assignees}></TaskItem>
      )}
    </div>
  );
};

export default TaskPage;
