import React, { useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import { useForm } from "react-hook-form";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import Loading from "../loading";
import { Notes } from "../../external/task-component/openapi";

interface Props {
  taskId: string;
  setShowNotesModal: (show: boolean) => void;
  showNotesModal: boolean;
  setTaskNotes: (notes: Notes[] | undefined) => void;
}

const createTaskWorker = createWorkerFactory(
  () => import("../../workers/task")
);

const NotesModal: React.FC<Props> = ({
  taskId,
  setShowNotesModal,
  showNotesModal,
  setTaskNotes,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    notes: string;
  }>();
  const [userToken, setUserToken] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const taskWorker = useWorker(createTaskWorker);
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const onSubmit = handleSubmit(
    (formDataRequest) => {
      if (formDataRequest) {
        (async () => {
          setIsSaving(true);
          if (userToken) {
            try {
              const results = await taskWorker.postNote(
                userToken,
                taskId,
                formDataRequest.notes
              );

              if (results.status === 200) {
                const getTask = await taskWorker.getTask(taskId, userToken);
                setTaskNotes(getTask.notes);
                setShowNotesModal(false);
              } else {
                setPostError(
                  "An Error occurred trying to create a Data Request"
                );
                setShowPostError(true);
              }
            } catch (error) {
              setPostError(
                "We could not create your Data Request at this time."
              );
              setShowPostError(true);
            }
          } else {
            setShowPostError(true);
            setPostError("User token not found.");
          }

          setIsSaving(false);
        })();
      }
    },
    (invalidData) => {
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );

  return (
    <Modal
      show={showNotesModal}
      onHide={() => {
        // Only allow closing if not in the process of saving
        if (!isSaving) {
          setShowNotesModal(false);
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSaving ? (
          <Loading />
        ) : (
          <>
            <Container>
              {showPostError ? (
                <Alert
                  variant="danger"
                  dismissible
                  onClose={() => {
                    setPostError(undefined);
                    setShowPostError(false);
                  }}
                >
                  {postError}
                </Alert>
              ) : (
                <></>
              )}
            </Container>
            <Container>
              <Form noValidate onSubmit={onSubmit}>
                <Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      {...register("notes", {
                        disabled: isSaving,
                        required: {
                          message: "Please provide notes.",
                          value: true,
                        },
                      })}
                      isInvalid={errors.notes?.type === "required"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.notes?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Group>
              </Form>
            </Container>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSaving}
          variant="secondary"
          onClick={() => setShowNotesModal(false)}
        >
          Cancel
        </Button>
        <Button disabled={isSaving} variant="primary" onClick={onSubmit}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotesModal;
