import { useContext, useEffect, useState } from "react";
import { UserAuthenticatedContext } from "../../components/profile";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { AxiosError } from "axios";
import Loading from "../../components/loading";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";

const createManifestWorker = createWorkerFactory(
  () => import("../../workers/manifest")
);

const NewManifestPage = (): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const manifestWorker = createManifestWorker();

  const [manifestId, setManifestId] = useState<string>();
  const [hasStored, setHasStored] = useState<boolean>(false);

  useEffect(() => {
    if (!userAuthenticatedContext.token) {
      console.info("No token available yet");
      return;
    }
    manifestWorker
      .createManifest(userAuthenticatedContext.token)
      .then((manifest) => {
        setManifestId(manifest.id);
        setHasStored(true);
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401) {
            alert(
              "Unauthorized, are you logged in? Try refreshing the page and try again."
            );
          }
          return;
        }
        console.error(e);
      });
  }, [manifestWorker, userAuthenticatedContext.token]);

  if (hasStored) {
    return (
      <Container>
        <Loading />
        <Navigate replace to={`/manifest/${manifestId}`} />
      </Container>
    );
  }

  return (
    <Container>
      <Loading />
    </Container>
  );
};
export default NewManifestPage;
