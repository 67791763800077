import React, {
  useState,
  createRef,
  useEffect,
  useContext,
  MouseEventHandler,
} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import "cropperjs/dist/cropper.css";
import { Clipboard, Shift, XSquare } from "react-bootstrap-icons";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../components/profile";
import { useCopyToClipboard, useLocalStorage } from "usehooks-ts";
import { CroppingToolHistory } from "./cropping-tool-history";

const imageWorkerFactory = createWorkerFactory(
  () => import("../../workers/images")
);

const CroppingTool: React.FC = () => {
  const fileMimeType = "image/jpeg";
  const fileExtension = "jpg";

  const [image, setImage] = useState<string>();
  const [croppedImageData, setCropData] = useState<string>();
  const [rootUploadFilename, setRootUploadFilename] = useState<string>();
  const [imageData, setImageData] = useState<Cropper.Data>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showSavedImageUrlModal, setShowSavedImageUrlModal] =
    useState<boolean>(false);
  const [savedImageUrl, setSavedImageUrl] = useState<string>();
  const [error, setError] = useState<string>();
  const cropperRef = createRef<ReactCropperElement>();
  const [isSquare, setIsSquare] = useState<boolean>(false);
  const imageWorker = useWorker(imageWorkerFactory);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [, copyToClipboard] = useCopyToClipboard();
  const [storedImages, setStoredImages] = useLocalStorage<string[]>(
    `ccCroppingToolStoredImages`,
    []
  );

  const getFilenameForUpload = (uploadedFilename: string) => {
    // Remove any non-alphanumeric characters from the filename
    const cleanedFilename = uploadedFilename.replace(/[^a-z0-9.]/gi, "");

    // Create the file path parts
    const dateNow = new Date();
    const filePrefix = [
      dateNow.getUTCFullYear(),
      `${dateNow.getUTCMonth() + 1}`.padStart(2, "0"),
      `${dateNow.getUTCDate()}`.padStart(2, "0"),
      "_",
      // Element of randomness to avoid name collisions in S3
      // More likely if we say have multiple crops of one image
      (Math.random() * 1000000).toFixed(0),
    ].join("");
    return `${filePrefix}_${cleanedFilename}`;
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };

    const filenameSplit = files[0].name.split(".");
    // Remove the extension from the end
    filenameSplit.pop();

    setRootUploadFilename(filenameSplit.join("."));
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(fileMimeType)
      );
      console.info(croppedImageData?.length);
    }
  };

  const convertDataURIToBinary = (dataURI: string) => {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: fileMimeType });
  };

  const triggerUploadImage = () => {
    const uploadImage = (imageDataBlob: Blob) => {
      try {
        if (!userAuthenticatedContext.token) {
          throw new Error(
            "User credentials could not be found. Please refresh the page."
          );
        }
        if (!rootUploadFilename) {
          throw new Error("No filename found for upload.");
        }
        const uploadFilename = getFilenameForUpload(rootUploadFilename);
        imageWorker
          .GetPresignedUrlForImageUpload(
            `${uploadFilename}.${fileExtension}`,
            userAuthenticatedContext.token,
            "crop"
          )
          .then((response) => {
            imageWorker
              .UploadFileViaPresignedUrl(
                response.uploadUrl,
                imageDataBlob,
                fileMimeType
              )
              .then(() => {
                setSavedImageUrl(response.hostedFileUrl);
                // Add new image to the front of the list so that newest is first
                setStoredImages([response.hostedFileUrl, ...storedImages]);
                setIsUploading(false);
                setShowSavedImageUrlModal(true);
              })
              .catch((e) => {
                console.error("Error uploading image", e);
                setError(`Error occurred uploading image! ${e}`);
                setIsUploading(false);
              });
          })
          .catch((e) => {
            console.error("Error retrieving presigned URL", e);
            setError(`Error occurred retrieving URL for image upload! ${e}`);
            setIsUploading(false);
          });
      } catch (e) {
        console.error(e);
        setError((e as Error).message);
      }
    };

    setIsUploading(true);
    const imageDataAsDataUrl = cropperRef.current?.cropper
      .getCroppedCanvas()
      .toDataURL(fileMimeType);

    if (!imageDataAsDataUrl) {
      setError("");
      setIsUploading(false);
      return;
    }
    const imageDataBlob = convertDataURIToBinary(imageDataAsDataUrl);
    uploadImage(imageDataBlob);
  };

  const onCropEndOrZoom = () => {
    const imageData = cropperRef.current?.cropper.getData();
    setImageData(imageData);
  };

  useEffect(() => {
    if (imageData) {
      setIsSquare(Math.round(imageData.width) === Math.round(imageData.height));
    }
  }, [imageData]);

  useEffect(() => {
    if (isUploading) {
      cropperRef.current?.cropper.disable();
    } else {
      cropperRef.current?.cropper.enable();
    }
  }, [cropperRef, isUploading]);

  const resetForm = () => {
    setImage(undefined);
    setCropData(undefined);
    setIsUploading(false);
  };

  const PreviewModal = () => (
    <Modal
      show={croppedImageData ? true : false}
      onHide={() => setCropData(undefined)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cropped Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {croppedImageData ? (
          <img
            style={{ width: "100%" }}
            src={croppedImageData}
            alt="Cropped preview"
          />
        ) : null}
        <hr />
        <p>
          Right click on the image and select <code>Save Image As...</code> to
          save.
        </p>
      </Modal.Body>
    </Modal>
  );
  const CopyButton = () => {
    const [confirmationShown, setConfirmationShown] = useState(false);

    const handleClick: MouseEventHandler = (e) => {
      if (!savedImageUrl) return;
      copyToClipboard(savedImageUrl);
      setConfirmationShown(true);
      setTimeout(() => {
        setConfirmationShown(false);
      }, 1000);
    };

    return (
      <Button variant="success" onClick={handleClick}>
        {confirmationShown ? (
          "Copied!"
        ) : (
          <>
            Copy <Clipboard />
          </>
        )}
      </Button>
    );
  };

  const SavedImageModal = () => {
    return (
      <Modal
        size="lg"
        show={showSavedImageUrlModal}
        onHide={() => setShowSavedImageUrlModal(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cropped Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            style={{ width: "100%" }}
            src={savedImageUrl}
            alt="Cropped preview"
          />
          <hr />
          <InputGroup>
            <Form.Control
              readOnly
              style={{ fontStyle: "monospace" }}
              defaultValue={savedImageUrl}
            ></Form.Control>
            <CopyButton />
          </InputGroup>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <Container>
      <h1>Image Crop Tool</h1>
      {error ? (
        <Alert
          variant="danger"
          dismissible
          onClose={() => {
            setError(undefined);
          }}
        >
          {error}
        </Alert>
      ) : null}
      {!image ? (
        <Row style={{ width: "100%" }}>
          <Col>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload your image here</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={onChange} />
            </Form.Group>
          </Col>
        </Row>
      ) : null}
      {image ? (
        <>
          <Row>
            <Col lg={10} sm={12}>
              <Cropper
                ref={cropperRef}
                style={{ height: 800, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                cropend={onCropEndOrZoom}
                zoom={onCropEndOrZoom}
                src={image}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
              />
            </Col>
            <Col lg={2} sm={12}>
              <h3>Image Data</h3>
              {imageData ? (
                <Stack>
                  <Button variant="danger" onClick={resetForm}>
                    Reset Input
                  </Button>
                  <br />
                  <ButtonGroup vertical>
                    <Button
                      variant="warning"
                      onClick={getCropData}
                      disabled={isUploading}
                    >
                      Crop
                      <br />
                      <i style={{ fontSize: "0.75em" }}>
                        For copying/saving locally
                      </i>
                    </Button>
                    <Button
                      variant="success"
                      onClick={triggerUploadImage}
                      disabled={isUploading}
                    >
                      Crop And Save
                      <br />
                      <i style={{ fontSize: "0.75em" }}>Creates a link</i>
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <b>File Name</b>
                  <code style={{ fontSize: "0.75em" }}>
                    {rootUploadFilename}
                  </code>
                  <hr />
                  <span>
                    <b>Width:</b> {Math.round(imageData.width)}
                  </span>
                  <span>
                    <b>Height:</b> {Math.round(imageData.height)}
                  </span>
                  <span>
                    <b>Is square?</b>{" "}
                    {isSquare ? (
                      "✅"
                    ) : (
                      <>
                        No <XSquare size={"0.75em"} color="red" />
                      </>
                    )}
                  </span>
                  <hr />
                  <p>
                    Items are always saved in a <code>.jpg</code> format.
                  </p>
                </Stack>
              ) : (
                <i>No data available</i>
              )}
            </Col>
          </Row>
          <br />
          <Row style={{ minHeight: "350px" }}>
            <Col lg={4} xxl={3}>
              <h2>Preview</h2>
              <div
                className="img-preview"
                style={{
                  width: "100%",
                  height: "300px",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              />
            </Col>
            <Col lg={8} xxl={9}>
              <h5>Instructions</h5>
              <p>Use your scroll wheel to zoom the image.</p>
              <p>
                Hold the <Shift /> <code>SHIFT</code> key to persist the aspect
                ratio. When you get below 200px, the aspect ratio will shrink
                down until you get to a square crop.
              </p>
              <p>
                Click in the uncropped area whilst holding the <Shift />{" "}
                <code>SHIFT</code> key to create a new square crop area that
                will stay square.
              </p>
            </Col>
          </Row>
          <br style={{ clear: "both" }} />{" "}
        </>
      ) : null}
      <hr />
      <CroppingToolHistory />
      <br />
      <PreviewModal />
      <SavedImageModal />
    </Container>
  );
};

export default CroppingTool;
