import { BaseGradingComponentProps } from "./types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useGradingAndRefurbState } from "./persist-helpers";
import { useEffect } from "react";

export interface FreeTextComponentProps {
  title: string;
  subtitle?: string;
  lineCount?: number;
  isRequired?: boolean;
}

const FreeTextComponent = ({
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  title,
  subtitle,
  lineCount,
  isRequired,
}: BaseGradingComponentProps & FreeTextComponentProps): JSX.Element => {
  const [inputText, setInputText] = useGradingAndRefurbState<string>(
    stepOutputKey,
    refurbIdentifier
  );

  if (!lineCount) {
    lineCount = 5;
  }

  useEffect(() => {
    setStepCompleted(isRequired ? !!inputText : true);
  }, [inputText, isRequired, setStepCompleted]);

  return (
    <Container>
      <h3>{title}</h3>
      <Row>
        <Col>
          <Form.Group>
            {subtitle ? <Form.Label>{subtitle}</Form.Label> : null}
            {lineCount === 1 ? (
              <Form.Control
                as="input"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                isInvalid={isRequired && !inputText}
              ></Form.Control>
            ) : (
              <Form.Control
                as="textarea"
                rows={lineCount}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                isInvalid={isRequired && !inputText}
              ></Form.Control>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default FreeTextComponent;
