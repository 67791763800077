import { ItemDimensions } from "../external";

export const calculateCubeFromArrayOfBoxDims = (
  boxDimensions: ItemDimensions[]
) => {
  let totalCubicCentimeters = 0;
  boxDimensions.forEach((boxDimension) => {
    totalCubicCentimeters +=
      Number(boxDimension.length) *
      Number(boxDimension.width) *
      Number(boxDimension.height);
  });

  const totalCubicMetres = totalCubicCentimeters / 1000000;
  return Number(totalCubicMetres.toFixed(2));
};
