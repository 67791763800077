import axios from "axios";

export const uploadImageFromUrl = async (
  uploadUrl: string,
  sourceUrl: string,
  itemId: string,
  clientId: string,
  userToken: string
): Promise<boolean> => {
  const url = uploadUrl.replaceAll(":itemId:", itemId);

  console.log(url);

  try {
    await axios.post(
      url,
      {
        url: sourceUrl,
        clientId: clientId,
        itemId: itemId,
      },
      {
        headers: {
          Authorization: userToken,
        },
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    throw new Error("Error uploading file");
  }
};
