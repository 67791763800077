import React from "react";

interface TableCellProps {
  keyName: any;
  data: any;
  change?: boolean;
}

const TableCell: React.FC<TableCellProps> = ({ keyName, data, change }) => {
  return (
    <td
      style={{
        maxWidth: "200px",
        overflowX: "auto",
        whiteSpace: "nowrap",
        ...(change && { color: "red" }),
      }}
    >
      {keyName === "clientImageLinks" ? (
        data.length > 0 ? (
          data.map((image: string | undefined, index: number) => (
            <React.Fragment key={index}>
              <img
                src={image}
                alt="clientImageLinks"
                style={{ width: "100px", marginRight: "5px" }}
              />
              {(index + 1) % 3 === 0 && <br />}
            </React.Fragment>
          ))
        ) : (
          <p>No images</p>
        )
      ) : typeof data === "string" ? (
        data
      ) : (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )}
    </td>
  );
};

export default TableCell;
