import { useEffect } from "react";
import {
  DetailedInformationComponentConfiguration,
  DetailedInformationSection,
} from "../../../external/inventory-component/configuration/detailedInformation";
import {
  GradingStepDimensions,
  GradingStepType,
} from "../../../external/inventory-component/configuration/types";
import DetailedInformationComponent from "./damages";
import { useGradingAndRefurbState } from "./persist-helpers";
import { BaseGradingComponentProps } from "./types";
import { useDebugMode } from "../../../hooks/useDebugMode";

export type DimensionsComponentProps = GradingStepDimensions &
  BaseGradingComponentProps & {
    numberOfBoxes: number;
  };

export const DimensionsComponent = ({
  refurbIdentifier,
  stepOutputKey,
  setStepCompleted,
  title,
  numberOfBoxes,
  stepCompleted,
}: DimensionsComponentProps) => {
  const padStart = (value: number) => String(value).padStart(2, "0");
  const debugMode = useDebugMode();
  const [dimsData, setDimsData] = useGradingAndRefurbState<
    Record<string, Record<string, number>>
  >(stepOutputKey, refurbIdentifier);

  useEffect(() => {
    if (!dimsData) {
      return;
    }
    const numberOfBoxesInData = dimsData ? Object.keys(dimsData).length : 0;
    if (numberOfBoxesInData > numberOfBoxes) {
      console.warn(
        `Number of boxes in data (${numberOfBoxesInData}) is greater than number of boxes (${numberOfBoxes}), so removing extra boxes`
      );
      setDimsData(
        // Remove any boxes that are not in the range of 1 to numberOfBoxes
        Object.fromEntries(
          Object.entries(dimsData).filter(
            ([key, _]) => parseInt(key.replace("box", "")) <= numberOfBoxes
          )
        )
      );
    }
  }, [dimsData, numberOfBoxes, setDimsData]);

  useEffect(() => {
    if (!dimsData) {
      return;
    }
    const validSteps = Object.values(dimsData).map(
      (value: Record<string, number>) => {
        const dim1 = value["dim1"];
        const dim2 = value["dim2"];
        const dim3 = value["dim3"];
        if (
          dim1 !== undefined &&
          dim1 > 0 &&
          dim2 !== undefined &&
          dim2 > 0 &&
          dim3 !== undefined &&
          dim3 > 0
        ) {
          return true;
        }
        return false;
      }
    );
    if (validSteps.every((v) => v)) {
      setStepCompleted(true);
    } else {
      setStepCompleted(false);
    }
  }, [dimsData, setStepCompleted]);

  const sections: DetailedInformationSection[] = Array.from(
    { length: numberOfBoxes },
    (_, index) => ({
      options: [
        {
          optionName: `Dimensions`,
          optionType: "count",
          optionImageRequired: false,
          optionKey: `box${padStart(index + 1)}`,
          countOptions: [
            {
              entryType: "entry",
              displayName: "Height",
              resultKey: `dim1`,
              units: "cm",
            },
            {
              entryType: "entry",
              displayName: "Width",
              resultKey: `dim2`,
              units: "cm",
            },
            {
              entryType: "entry",
              displayName: "Depth/Length",
              resultKey: `dim3`,
              units: "cm",
            },
          ],
        },
      ],
      sectionName: `Box ${index + 1} Dimensions`,
    })
  );

  const configuration: DetailedInformationComponentConfiguration = {
    sections,
  };

  return (
    <>
      <DetailedInformationComponent
        stepCompleted={stepCompleted}
        configuration={configuration}
        refurbIdentifier={refurbIdentifier}
        setStepCompleted={(completed) => {}} // ignore the completion state from the base component
        stepOutputKey={stepOutputKey}
        stepType={GradingStepType.DetailedInformation}
        title={title}
      />
      {debugMode ? (
        <>
          <b>Debug</b>
          <hr />
          <code>stepCompleted:</code>
          {stepCompleted ? "true" : "false"}
          <hr />
          <code>{JSON.stringify(dimsData)}</code>
        </>
      ) : null}
    </>
  );
};
