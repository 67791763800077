import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import {
  Control,
  useFieldArray,
  ArrayPath,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { CatalogItem } from "../../../external";
import { PlusCircle } from "react-bootstrap-icons";
import { useState } from "react";

export interface CatalogItemIdentifiersComponentProps {
  viewOnly: boolean;
  isSaving: boolean;
  control: Control<CatalogItem, any>;
  controlName: ArrayPath<CatalogItem>;
  register: UseFormRegister<CatalogItem>;
}

type IdentifierTypes =
  | "EAN"
  | "GTIN"
  | "ClientItemBarcode"
  | "Unknown"
  | "PreviousSKU";

function CatalogItemIdentifiersComponent({
  control,
  isSaving,
  viewOnly,
  register,
}: CatalogItemIdentifiersComponentProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "identifiers",
  });

  const watchFieldArray = useWatch({
    control,
    name: "identifiers",
    defaultValue: [],
  });

  const [newIdentifierType, setNewIdentifierType] = useState<IdentifierTypes>();
  const [newIdentifierValue, setNewIdentifierValue] = useState<string>();

  const baseTypes: (
    | "EAN"
    | "GTIN"
    | "ClientItemBarcode"
    | "Unknown"
    | "PreviousSKU"
  )[] = ["EAN", "GTIN", "ClientItemBarcode", "Unknown", "PreviousSKU"];

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  // https://www.react-hook-form.com/api/usefieldarray/
  return (
    <Container>
      <h5>Identifiers</h5>
      <Row>
        <Col>
          {controlledFields.map((field, index) => (
            <Row key={field.id} className="mb-1">
              <Col sm="4">
                <Form.Label>{field.type}</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Text key={field.id}>
                  {controlledFields[index].value}
                </Form.Text>
              </Col>
              {!viewOnly ? (
                <Col sm="2">
                  <Button
                    variant="danger"
                    disabled={isSaving}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              ) : null}
            </Row>
          ))}
        </Col>
      </Row>
      {!viewOnly ? (
        <Row>
          <Col sm={4}>
            <Form.Select
              value={newIdentifierType}
              aria-label="Select identifier type"
              onChange={(event) => {
                event.preventDefault();
                if (event.target.value === "") {
                  setNewIdentifierType(undefined);
                } else if (event.target.value) {
                  setNewIdentifierType(event.target.value as IdentifierTypes);
                }
              }}
            >
              <option disabled={!!newIdentifierType} value={""}>
                Select Identifier Type
              </option>
              {baseTypes
                .filter((t) => !controlledFields.some((v) => v.type === t))
                .map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col sm={6}>
            <Form.Control
              defaultValue={""}
              disabled={isSaving || viewOnly || !newIdentifierType}
              value={newIdentifierValue}
              onChange={(event) => {
                event.preventDefault();
                setNewIdentifierValue(event.target.value);
              }}
            ></Form.Control>
          </Col>
          <Col sm={2}>
            <Button
              variant="success"
              disabled={
                isSaving ||
                viewOnly ||
                !newIdentifierType ||
                !newIdentifierValue
              }
              onClick={() => {
                if (newIdentifierType && newIdentifierValue) {
                  append({
                    type: newIdentifierType,
                    value: newIdentifierValue,
                  });
                  setNewIdentifierType("" as IdentifierTypes);
                  setNewIdentifierValue("");
                }
              }}
            >
              Add <PlusCircle />
            </Button>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}

export default CatalogItemIdentifiersComponent;
