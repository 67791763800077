import { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  SerializedEditor,
  SerializedElementNode,
  SerializedLexicalNode,
  SerializedTextNode,
} from "lexical";
import { SerializedListNode } from "@lexical/list";
import { useDebounceCallback } from "usehooks-ts";

type OnChangePluginProps = {
  namespace: string;
  onChange: (value: string) => void;
};

export function OnChangePlugin({ onChange }: OnChangePluginProps) {
  const [editor] = useLexicalComposerContext();
  const [isEditable, setIsEditable] = useState(false);

  editor.registerEditableListener((editable) => {
    setIsEditable(editable);
  });

  const saveContent = useCallback(
    (serializedEditor: SerializedEditor) => {
      // Do this to avoid calling the onChange function if the editor is not editable
      if (isEditable) {
        const result = serializedEditor.editorState.root.children.flatMap(
          (child) => customEditorJSONToHtml(child)
        );
        onChange(result.join(""));
      }
    },
    [onChange, isEditable]
  );

  // Debounce the saveContent function to avoid calling it too often
  const debouncedSaveContent = useDebounceCallback(saveContent, 500, {
    maxWait: 5_000, // 5 seconds maximum wait
  });

  useEffect(() => {
    return editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves }) => {
        // Don't update if nothing changed
        if (dirtyElements.size === 0 && dirtyLeaves.size === 0) {
          return;
        }
        editorState.read(() => {
          debouncedSaveContent(editor.toJSON());
        });
      }
    );
  }, [debouncedSaveContent, editor]);

  return null;
}

// This bit format is used to represent the format of a text node in Lexical
export const IS_BOLD = 1;
export const IS_ITALIC = 1 << 1;
export const IS_STRIKETHROUGH = 1 << 2;
export const IS_UNDERLINE = 1 << 3;
export const IS_CODE = 1 << 4;
export const IS_SUBSCRIPT = 1 << 5;
export const IS_SUPERSCRIPT = 1 << 6;
export const IS_HIGHLIGHT = 1 << 7;

/**
 * Convert Lexical serialized JSON to HTML
 * @param node the serialized node
 * @returns HTML string
 */
export function customEditorJSONToHtml(
  node:
    | SerializedElementNode
    | SerializedLexicalNode
    | SerializedTextNode
    | SerializedListNode
) {
  let html = "";
  let tag = "";
  let textContent = "";

  if (node.type === "text") {
    tag = "p";
    const textNode = node as SerializedTextNode;
    textContent = textNode.text;
    if (textNode.format) {
      if (textNode.format & IS_BOLD) {
        textContent = `<b>${textContent}</b>`;
      }
      if (textNode.format & IS_ITALIC) {
        textContent = `<i>${textContent}</i>`;
      }
    }
  } else if (node.type === "list") {
    const listNode = node as SerializedListNode;
    tag = listNode.tag;
  } else if (node.type === "listitem") {
    tag = "li";
  }
  // Opening tag
  if (tag) {
    html += `<${tag}>`;
  }
  // Render tag content
  if (Object.hasOwn(node, "text")) {
    html += textContent;
  }

  if (Object.hasOwn(node, "children")) {
    const objectWithChildren = node as SerializedElementNode;
    for (let child of objectWithChildren.children) {
      html += customEditorJSONToHtml(child);
    }
  }
  // Closing tag
  if (tag) {
    html += `</${tag}>`;
  }
  return html;
}
