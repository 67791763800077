import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

interface Faq {
  questionNo: number;
  question: string;
  answer: JSX.Element;
}

interface FaqSectionProps {
  id: string;
  heading: string;
  faqs: Faq[];
}

const FaqSection: React.FC<FaqSectionProps> = ({ id, heading, faqs }) => {
  return (
    <div id={id} style={{ marginTop: "2em", marginBottom: "2em" }}>
      <h2>{heading}</h2>
      <Accordion>
        {faqs.map((faq, index) => (
          <Card key={index}>
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>
                <strong>
                  {faq.questionNo}. {faq.question}
                </strong>
              </Accordion.Header>
              <Accordion.Body>{faq.answer}</Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export default FaqSection;
