import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Client, DownloadClientCatalogBody } from "../../external";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../profile";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Loading from "../loading";

const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

export interface CatalogItemDownloadModalComponentProps {
  clients: Client[];
  showModal: boolean;
  handleCloseModal: () => void;
}

const CatalogItemDownloadModalComponent = (
  props: CatalogItemDownloadModalComponentProps
): JSX.Element => {
  const { clients, showModal, handleCloseModal } = props;
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogWorker = useWorker(createCatalogWorker);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DownloadClientCatalogBody>();

  const [userToken, setUserToken] = useState<string>();
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const onSubmit = handleSubmit(
    (formDownloadClientCatalog) => {
      if (formDownloadClientCatalog) {
        (async () => {
          if (userToken) {
            setIsSaving(true);
            setPostError("");
            setShowPostError(false);

            try {
              formDownloadClientCatalog.clientId =
                formDownloadClientCatalog.clientId.trim();

              const result = await catalogWorker.getClientsCatalog({
                clientId: formDownloadClientCatalog.clientId,
                authToken: userToken,
              });

              if ([200].includes(result.status)) {
                window.open(result.data.url, "_blank", "noopener,noreferrer");
              } else {
                setShowPostError(true);
                setPostError(
                  `No catalogue data for client ${formDownloadClientCatalog.clientId}`
                );
              }
            } catch (e) {
              await new Promise((resolve) => {
                setTimeout(() => {
                  setShowPostError(true);
                  setPostError(
                    `Downloading ${
                      formDownloadClientCatalog.clientId
                    } catalogue failed with error "${
                      (e as unknown as Error).message
                    }"`
                  );
                  resolve(undefined);
                }, 2000);
              });
            } finally {
              await new Promise((resolve) => {
                // Give a small timeout here to give the illusion of loading
                setTimeout(() => {
                  setIsSaving(false);
                  resolve(undefined);
                }, 2000);
              });
            }
          }
        })();
      }
    },
    (invalidData) => {
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size={"lg"}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>Download Client Catalogue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {isSaving ? <Loading /> : <></>}
          <Container>
            {showPostError && !isSaving ? (
              <Alert
                variant="danger"
                dismissible
                onClose={() => {
                  setPostError(undefined);
                  setShowPostError(false);
                }}
              >
                {postError}
              </Alert>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            <Form noValidate onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Client</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select
                    aria-label="Select Client"
                    {...register("clientId", {
                      disabled: isSaving,
                      required: {
                        message: "Please select a Client.",
                        value: true,
                      },
                    })}
                    isInvalid={errors.clientId?.type === "required"}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select a Client
                    </option>
                    {[...clients]
                      .sort((a, b) => a.clientName.localeCompare(b.clientName))
                      .map((c, i) => (
                        <option value={c.clientId} key={c.clientId}>
                          {c.clientName}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.clientId?.message?.toString()}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form>
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
          disabled={isSaving}
        >
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={isSaving}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CatalogItemDownloadModalComponent;
