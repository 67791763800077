import axios, { AxiosResponse } from "axios";
import { CatalogItem } from "../external";
import {
  Task,
  TaskItemsPaginated,
  TaskType,
  User,
} from "../external/task-component/openapi";
import { Filters } from "../components/dynamic-search-component";

export interface CreateTaskItem {
  type: TaskType;
  data: CatalogItem;
  taskTitle: string;
  taskDescription: string;
}

export const getTask = async (taskId: string, authToken: string) => {
  let url = process.env.REACT_APP_GET_TASK as string;
  url = url.replaceAll(":taskId:", taskId);

  const fetchTaskItemResult = await axios.get<Task, AxiosResponse<Task>, Task>(
    url,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      validateStatus: () => true,
    }
  );

  return fetchTaskItemResult.data;
};

export const postNote = async (
  authToken: string,
  taskId: string,
  notes: string
) => {
  let url = process.env.REACT_APP_POST_TASK_NOTE as string;
  url = url.replaceAll(":taskId:", taskId);

  return await axios.post(
    url,
    {
      notes: notes,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      validateStatus: () => true,
    }
  );
};

export const postTask = async (
  type: TaskType,
  data: CatalogItem,
  authToken: string,
  title: string,
  description: string,
  notes?: string
) => {
  let url = process.env.REACT_APP_POST_TASK as string;

  return await axios.post<Task, AxiosResponse<Task>, CreateTaskItem>(
    url,
    {
      data: data,
      type: type,
      taskDescription: description,
      taskTitle: title,
      ...(notes ? { notes: notes } : {}),
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      validateStatus: () => true,
    }
  );
};

export interface TaskFilters extends Filters {
  status?: string[];
  type?: string;
  search?: string;
  assignedTo?: User;
  taskId?: string;
}

export interface GetTaskStatsProps {
  count?: number;
  page?: number;
  status?: string;
  type?: string;
  sortBy?: string;
  sortOrder?: string;
  search?: string;
  assignedTo?: User;
}

export const getTaskStats = async (authToken: string) => {
  let getTasksUrl = process.env.REACT_APP_GET_TASKS_ITEMS_URL as string;

  const fetchTaskItemResult = await axios.post(
    getTasksUrl,
    {
      size: 0,
      query: {
        bool: {
          must: [
            {
              terms: {
                "status.keyword": ["created", "in-progress"],
              },
            },
          ],
        },
      },
      aggs: {
        total_tasks: {
          value_count: {
            field: "taskId.keyword",
          },
        },
        tasks_by_status: {
          terms: {
            field: "status.keyword",
          },
        },
        tasks_by_type: {
          terms: {
            field: "type.keyword",
          },
        },
        tasks_by_assigned_to: {
          terms: {
            field: "assignedTo.name.keyword",
            missing: "Unassigned",
          },
        },
        tasks_created_last_seven_days: {
          filter: {
            range: {
              createdAtTimestamp: {
                gte: "now-7d/d",
              },
            },
          },
          aggs: {
            tasks_by_type: {
              terms: {
                field: "type.keyword",
              },
            },
          },
        },
        tasks_created_over_seven_days_ago: {
          filter: {
            range: {
              createdAtTimestamp: {
                lt: "now-7d/d",
              },
            },
          },
          aggs: {
            tasks_by_type: {
              terms: {
                field: "type.keyword",
              },
            },
            tasks_by_assigned_to: {
              terms: {
                field: "assignedTo.name.keyword",
                missing: "Unassigned",
              },
            },
          },
        },
        tasks_completed_rejected_cancelled_last_seven_days: {
          filter: {
            range: {
              "actioned.statusUpdatedTimestamp": {
                gte: "now-7d/d",
              },
            },
          },
          aggs: {
            tasks_by_status: {
              terms: {
                field: "status.keyword",
              },
            },
          },
        },
      },
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return fetchTaskItemResult.data;
};

export const updateTaskStatus = async (
  authToken: string,
  taskId: string,
  status: string,
  reason?: string,
  catalogueClientItemId?: string
) => {
  let updateTaskStatusUrl = process.env.REACT_APP_PUT_TASK as string;
  updateTaskStatusUrl = updateTaskStatusUrl.replaceAll(":taskId:", taskId);

  return await axios.put(
    updateTaskStatusUrl,
    {
      status: status,
      ...(reason ? { reason: reason } : {}),
      ...(catalogueClientItemId
        ? { createdCatalogItemId: catalogueClientItemId }
        : {}),
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const updateAssignedTo = async (
  authToken: string,
  taskId: string,
  user: {
    email: string;
    name: string;
  }
): Promise<AxiosResponse<any, any>> => {
  let updateTaskStatusUrl = process.env.REACT_APP_PUT_TASK as string;
  updateTaskStatusUrl = updateTaskStatusUrl.replaceAll(":taskId:", taskId);

  return await axios.put(
    updateTaskStatusUrl,
    {
      assignedTo: user,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const completeCatalogCreateTask = async (
  authToken: string,
  taskId: string,
  catalogItemCreated: boolean,
  createdCatalogItemId?: string
): Promise<AxiosResponse<any, any>> => {
  let updateTaskStatusUrl = process.env.REACT_APP_PUT_TASK as string;
  updateTaskStatusUrl = updateTaskStatusUrl.replaceAll(":taskId:", taskId);

  return await axios.put(
    updateTaskStatusUrl,
    {
      catalogItemCreated,
      createdCatalogItemId,
      status: "completed",
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export const getAssignees = async (authToken: string) => {
  const roles = [
    "onboarding_manager",
    "marketplace_manager",
    "partner_operations_assistant",
    "leadership_process_and_development_manager",
    "warehousing_stock_controller",
    "warehousing_operative_level_2",
    "refurbishment_engineer_level_2",
  ];
  let requestUrl = process.env.REACT_APP_GET_USERS_TASK;
  requestUrl = `${requestUrl}?role=` + roles.join("&role=");
  return await axios.get(requestUrl, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const getExistingTasksCountByIdAndType = async (
  id: string,
  type: string,
  authToken: string,
  count: number = 10, // Default count to 10 if not provided
  page: number = 1 // Default page to 1 if not provided
) => {
  const url = process.env.REACT_APP_GET_TASKS_ITEMS_URL as string;

  const data = await axios.post(
    url,
    {
      count: count,
      page: page,
      query: {
        bool: {
          minimum_should_match: 1,
          should: [
            {
              bool: {
                must: [
                  {
                    term: {
                      status: "created",
                    },
                  },
                ],
              },
            },
            {
              bool: {
                must: [
                  {
                    term: {
                      status: "in-progress",
                    },
                  },
                ],
              },
            },
          ],
          must: [
            {
              match: {
                type: type,
              },
            },
            {
              match: {
                "data.itemId": id,
              },
            },
          ],
        },
      },
      sort: [
        {
          createdAtTimestamp: {
            order: "desc",
          },
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return data.data as TaskItemsPaginated;
};
