import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useGradingAndRefurbStateTwoLevelNestedField } from "../persist-helpers";
import { DetailedInformationCountOption } from "../../../../external/inventory-component/configuration/detailedInformation";

export interface DamagesCountButtonsTypeComponentProps {
  optionKey: string;
  settings: DetailedInformationCountOption;
  stepOutputKey: string;
  refurbIdentifier: string;
}

const upperLimit = 10;

const DamagesCountButtonsTypeComponent = ({
  settings,
  optionKey,
  refurbIdentifier,
  stepOutputKey,
}: DamagesCountButtonsTypeComponentProps) => {
  const [state, setState] = useGradingAndRefurbStateTwoLevelNestedField(
    stepOutputKey,
    refurbIdentifier,
    optionKey,
    settings.resultKey,
    0
  );

  const increment = () => {
    if (state < upperLimit) {
      setState(state + 1);
    }
  };

  const decrement = () => {
    if (state > 0) {
      setState(state - 1);
    }
  };

  return (
    <Col className="refurb-damage-severity-col">
      <Row>
        <Col className="refurb-severity-level">{settings.displayName}</Col>
      </Row>
      <Row className="refurb-plus-minus-row">
        <Col>
          <Button onClick={decrement} disabled={state === 0}>
            <span>-</span>
          </Button>
          <Form.Control
            style={{ textAlign: "center" }}
            className="refurb-damage-recording-number-input"
            type="number"
            value={state}
            plaintext
            readOnly
          ></Form.Control>
          <Button onClick={increment} disabled={state >= upperLimit}>
            <span>+</span>
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default DamagesCountButtonsTypeComponent;
