/// <reference types="react-scripts" />

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { UserAuthenticatedContext } from "../components/profile";
import Loading from "../components/loading";
import { useReadWriteDebugMode } from "../hooks/useDebugMode";
import {
  CloseGlobalSidebarEvent,
  OpenGlobalSidebarEvent,
} from "../components/globaloffcanvas/events";
import { useContext } from "react";
import { posthog } from "posthog-js";

const DevPage = () => {
  const [isDebug, setIsDebug] = useReadWriteDebugMode();
  const { scopes } = useContext(UserAuthenticatedContext);

  const loadToolbar = () => {
    posthog.loadToolbar({});
  };

  const EnvTable = () => (
    <Accordion title="Environment Variables">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Environment Variables</Accordion.Header>
        <Accordion.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(process.env).map(([key, value]) => (
                <tr key={key}>
                  <td>
                    <code>{key}</code>
                  </td>
                  <td>
                    <code>{value}</code>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  return (
    <Container>
      <h1>Development tools</h1>
      <hr />
      <Row>
        <Col>
          <h2>Controls</h2>
          <Button
            className="m-2"
            variant="success"
            onClick={() => {
              loadToolbar();
            }}
          >
            Posthog Toolbar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Environment variables</h2>
          <EnvTable />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4>Permission Scopes</h4>
          {scopes?.map((scope) => (
            <pre style={{ margin: "0" }} key={scope}>
              {scope}
            </pre>
          ))}
        </Col>
        <Col>
          <h2>JWT - do not share</h2>
          <UserAuthenticatedContext.Consumer>
            {(userAuthenticated) => (
              <Form.Control
                name="description"
                readOnly
                defaultValue={userAuthenticated.token}
                style={{ fontFamily: "monospace" }}
                as="textarea"
                rows={5}
              ></Form.Control>
            )}
          </UserAuthenticatedContext.Consumer>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h2>Global sidebar test</h2>
          <Button
            className="m-2"
            variant="success"
            onClick={() => {
              window.dispatchEvent(OpenGlobalSidebarEvent("catalog", "test"));
            }}
          >
            Open sidebar (catalog)
          </Button>
          <Button
            className="m-2"
            variant="success"
            onClick={() => {
              window.dispatchEvent(OpenGlobalSidebarEvent("client", "test"));
            }}
          >
            Open sidebar (client)
          </Button>
          <Button
            className="m-2"
            variant="danger"
            onClick={() => {
              window.dispatchEvent(CloseGlobalSidebarEvent());
            }}
          >
            Close sidebar
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="d-flex" style={{ alignItems: "center" }}>
          <input
            id="debugmode"
            type="checkbox"
            onChange={(e) => setIsDebug(e.currentTarget.checked)}
            checked={isDebug}
            style={{ marginRight: "0.5em", height: "45px", width: "45px" }}
          ></input>
          <label htmlFor="debugmode" style={{ fontSize: "2em" }}>
            Debug Mode enabled
          </label>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(DevPage, {
  onRedirecting: () => <Loading />,
});
