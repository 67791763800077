import React from "react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

export const NovuComponent = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  async function handleOnNotificationClick(message: IMessage) {
    navigate(`${message.cta?.data.url}`);
  }

  if (user && process.env.REACT_APP_NOVU_APPLICATION_ID) {
    return (
      <NovuProvider
        subscriberId={user.sub}
        applicationIdentifier={process.env.REACT_APP_NOVU_APPLICATION_ID}
      >
        <PopoverNotificationCenter
          colorScheme={"light"}
          onNotificationClick={handleOnNotificationClick}
        >
          {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
        </PopoverNotificationCenter>
      </NovuProvider>
    );
  } else {
    return <Container></Container>;
  }
};

export default withAuthenticationRequired(NovuComponent, {
  onRedirecting: () => <Loading />,
});
