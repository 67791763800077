import { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { CatalogItem } from "../../external";
import { missingListingData } from "../../helpers/missingListingData";
import { useNavigate } from "react-router-dom";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

const createTaskWorker = createWorkerFactory(
  () => import("../../workers/task")
);

export interface CatalogMarkReadyToListModalProps {
  catalogItem: CatalogItem;
  showModal: boolean;
  handleCloseModal: () => void;
  handleSubmit: (
    CatalogItem: CatalogItem
  ) => Promise<AxiosResponse<CatalogItem, any>>;
  linkedTaskId?: string;
}

const CatalogMarkReadyToListModal = (
  props: CatalogMarkReadyToListModalProps
): JSX.Element => {
  const [missingData, setMissingData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { catalogItem, showModal, handleCloseModal, handleSubmit } = props;
  const [showPutError, setShowPutError] = useState(false);
  const [putError, setPutError] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const taskWorker = useWorker(createTaskWorker);

  const navigate = useNavigate();

  const submitMarkReadyToList = async () => {
    const missingDataArray = missingListingData(catalogItem);
    setMissingData(missingDataArray);
    catalogItem.suitableForListing = true;
    setIsLoading(true);
    try {
      await handleSubmit(catalogItem);
    } catch (error) {
      setPutError(`Error Updating Item"${(e as unknown as Error).message}"`);
      setShowPutError(true);
      console.error(error);
    }

    setIsLoading(false);

    handleCloseModal();
    if (props.linkedTaskId) {
      setShowConfirmModal(true);
    }
  };

  const handleConfirmCompleteTask = async (complete: boolean) => {
    setShowConfirmModal(false);
    if (complete && props.linkedTaskId) {
      if (!userAuthenticatedContext.token) {
        throw new Error("No token is available!");
      }

      try {
        if (props.linkedTaskId) {
          const decodedTaskId = decodeURIComponent(props.linkedTaskId);
          const taskId = decodedTaskId.split(":").pop();
          await taskWorker.updateTaskStatus(
            userAuthenticatedContext.token,
            taskId as string,
            "completed"
          );
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(
            `Error: ${error.response?.data}, ${error.response?.status}`
          );
        } else {
          console.error(`An unknown error occurred`);
        }
        throw error;
      }
      navigate(`/tasks`);
    }
  };

  useEffect(() => {
    if (!showModal) {
      const missingDataArray = missingListingData(catalogItem);
      setMissingData(missingDataArray);
    }
  }, [catalogItem, showModal]);

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mark Ready to List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {showPutError && !isLoading ? (
              <Alert
                variant="danger"
                dismissible
                onClose={() => {
                  setPutError(undefined);
                  setShowPutError(false);
                }}
              >
                {putError}
              </Alert>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            {missingData.length > 0 ? (
              <Alert variant="danger">
                Missing required data: {missingData.join(", ")}
              </Alert>
            ) : (
              <p>Are you sure you want to mark this item as ready to list?</p>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitMarkReadyToList}
            disabled={missingData.length > 0 || isLoading}
          >
            Mark Ready to List
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Linked Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to complete the linked task?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleConfirmCompleteTask(false)}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirmCompleteTask(true)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CatalogMarkReadyToListModal;
