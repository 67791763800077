/// <reference types="react-scripts" />

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import Button from "react-bootstrap/Button";
import React, { useContext } from "react";
import { UserAuthenticatedContext } from "../components/profile";
import { useNavigate } from "react-router-dom";

const InboundPage = () => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const navigate = useNavigate();

  if (!userAuthenticatedContext.scopes?.includes("edit:inventory-item")) {
    return (
      <Container>
        <Row>
          <Col className="mb-2">No permission to Inbound</Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Inbound</h1>
      <Row>
        <Col className="mb-2">
          <Button
            variant="success"
            onClick={() => navigate("/inbound/inbound-load")}
          >
            Start Inbounding
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(InboundPage, {
  onRedirecting: () => <Loading />,
});
