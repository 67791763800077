/* eslint-disable @typescript-eslint/no-unused-vars */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useReadWriteDebugMode } from "../../hooks/useDebugMode";
import { useLocalStorage } from "usehooks-ts";
import {
  ClearCyclePersistPrefix,
  getCompletedStepsLocalStorageKey,
  getSelectedIndexLocalStorageKey,
} from "../../components/gradingandrefurb/refurb-steps/persist-helpers";

export interface MockupHeaderComponentProps {
  refurbIdentifier: string;
}

const MockupHeaderComponent = ({
  refurbIdentifier,
}: MockupHeaderComponentProps): JSX.Element => {
  const [isDebug, setIsDebug] = useReadWriteDebugMode();

  const [, setSelectedIndex] = useLocalStorage<number>(
    getSelectedIndexLocalStorageKey(refurbIdentifier),
    0
  );
  const [, setCompletedSteps] = useLocalStorage<Array<number>>(
    getCompletedStepsLocalStorageKey(refurbIdentifier),
    []
  );

  const [_refurbData, setRefurbData] = useLocalStorage<Record<string, any>>(
    `${ClearCyclePersistPrefix}__${refurbIdentifier}`,
    {}
  );

  const reset = () => {
    setSelectedIndex(0);
    setCompletedSteps([]);
    setRefurbData({});
  };

  return (
    <Row>
      <Col>
        <h4>Mockup settings</h4>
      </Col>
      <Col className="d-flex" style={{ alignItems: "center" }}>
        <input
          id="debugmode"
          type="checkbox"
          onChange={(e) => setIsDebug(e.currentTarget.checked)}
          checked={isDebug}
          style={{ height: "15px", width: "45px" }}
        ></input>
        <label htmlFor="debugmode">Debug Mode enabled</label>
      </Col>
      <Col className="d-flex" style={{ justifyItems: "center" }}>
        <Button
          onClick={() => reset()}
          variant="warning"
          size="sm"
          style={{ height: "30px" }}
        >
          Reset Data
        </Button>
      </Col>
      <hr />
    </Row>
  );
};

export default MockupHeaderComponent;
