import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Loading from "../components/loading";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { UserAuthenticatedContext } from "../components/profile";

const FinancePage = (): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const navigate = useNavigate();

  return (
    <Container>
      <h1>Finance</h1>
      <Row>
        <Col className="mb-2">
          {userAuthenticatedContext.scopes?.includes("upload:finance-data") ? (
            <Button
              variant="success"
              onClick={() => navigate("/finance/upload")}
            >
              Upload
            </Button>
          ) : (
            "No permission to upload"
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(FinancePage, {
  onRedirecting: () => <Loading />,
});
