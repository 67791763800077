import { Control, Controller } from "react-hook-form";
import { CatalogItem } from "../../../external";
import WYSIWYGEditor from "../../wysiwyg";

export interface WYSIWYGEditorProps {
  catalogItem: CatalogItem;
  isSaving: boolean;
  viewOnly: boolean;
  control: Control<Omit<CatalogItem, "latestRawClientData" | "extraData">>;
}

const CatalogItemDescription = ({
  isSaving,
  viewOnly,
  control,
  catalogItem,
}: WYSIWYGEditorProps): JSX.Element => {
  return (
    <>
      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <WYSIWYGEditor
              isSaving={isSaving}
              setValue={onChange}
              value={value}
              viewOnly={viewOnly}
              namespace={`${catalogItem.itemId}${name}`}
            />
          );
        }}
      ></Controller>
    </>
  );
};
export default CatalogItemDescription;
