import Dinero from "dinero.js";
import { Money } from "../external";

export const getMoney = (amount: number): Money => ({
  amount: amount,
  currency: {
    base: 10,
    code: "GBP",
    exponent: 2,
  },
  scale: 2,
});

export const formatPenceValueAsMoneyString = (amount: number) => {
  if (typeof amount !== "number") {
    console.warn(
      "formatPenceValueAsMoneyString: amount is not a number",
      amount
    );
    return amount;
  }

  return Dinero({
    amount,
    currency: "GBP",
    precision: 2,
  }).toFormat("$0,0.00");
};
