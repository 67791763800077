import React, { useContext } from "react";
import { useDebugMode } from "../hooks/useDebugMode";
import { UserAuthenticatedContext } from "./profile";
import Loading from "./loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface GoogleAppsScriptIframeProps {
  title: string;
  src: string;
  style?: React.CSSProperties;
}

const GoogleAppsScriptIframe = ({
  title,
  src,
  style,
}: GoogleAppsScriptIframeProps) => {
  const isDebug = useDebugMode();
  const { user } = useContext(UserAuthenticatedContext);

  const [isLoading, setIsLoading] = React.useState(true);

  const iframeStyle: React.CSSProperties = {
    width: "100%",
    height: "600px",
    ...style,
  };

  const iframeParams = [
    `useremail=${user?.email}`,
    `username=${user?.name}`,
    `debug=${isDebug}`,
    `isCircle=true`,
  ].join("&");

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Permissions-Policy#iframes
  const iframeAllowedPermissions = [
    "clipboard-read *",
    "clipboard-write *",
    "geolocation *",
    "camera *",
    "microphone *",
    "accelerometer *",
    "gyroscope *",
    "magnetometer *",
    "speaker *",
    "vibrate *",
    "sync-xhr *",
  ];
  const iframeAllowedSandbox = [
    "allow-forms",
    "allow-modals",
    "allow-orientation-lock",
    "allow-popups",
    "allow-popups-to-escape-sandbox",
    "allow-same-origin",
    "allow-scripts",
    "allow-top-navigation",
  ];

  return (
    <>
      {isLoading ? (
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      ) : null}
      <iframe
        style={iframeStyle}
        title={title}
        allow={iframeAllowedPermissions.join("; ")}
        sandbox={iframeAllowedSandbox.join(" ")}
        onLoad={() => setIsLoading(false)}
        src={`${src}?${iframeParams}`}
      ></iframe>
    </>
  );
};

export default GoogleAppsScriptIframe;
