import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
// import Alert from "react-bootstrap/Alert";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import Loading from "../loading";
import { UserAuthenticatedContext } from "../profile";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import CatalogCSVUploadPreviewTable from "./catalog-csv-upload-preview-table";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import { CatalogItem } from "../../external";

const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

export interface CatalogItemBatchUpdateCSVUploadProps {
  showModal: boolean;
  handleCloseModal: () => void;
  shouldTriggerRefreshOnClose: (shouldTrigger: boolean) => void;
}

const headers = [
  "itemId",
  "clientId",
  "clientItemId",
  ["name", "clientProductTitle"],
  "description",
  "clearCycleSkuOverride",
  "productCategory",
  "clientCategory",
  "clientSkuHyperlinks",
  "clientImageLinks",
  "archived",
  "productDim1",
  "productDim2",
  "productDim3",
  "colour",
  ["retailPrice", "retailPricePence"],
  ["costPrice", "costPricePence"],
  ["weight", "weightGrams"],
  "boxCount",
  "previousSku",
];

export type CatalogItemCSVData = {
  [key: string]: string;
};

const CatalogItemBatchUpdateCSVUploadModal = (
  props: CatalogItemBatchUpdateCSVUploadProps
): JSX.Element => {
  const { showModal, handleCloseModal, shouldTriggerRefreshOnClose } = props;
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const catalogWorker = useWorker(createCatalogWorker);

  const [userToken, setUserToken] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  const [postSuccessMessage, setPostSuccessMessage] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<CatalogItemCSVData[]>();
  const [csvRowStatus, setCsvRowStatus] = useState<string[]>();
  const [fileText, setFileText] = useState<string>();
  const [fileValid, setFileValid] = useState<boolean>();
  const [failedUploads, setFailedUploads] = useState<Partial<CatalogItem>[]>(
    []
  );
  const darkMode = useCircleDarkMode();

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  let { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      // Standard
      "text/csv": [".csv"],
      // Mac OS can upload CSV files as this mime type sometimes
      "text/plain": [".csv"],
      // Windows can upload CSV files as this mime type sometimes
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
  });

  const checkForDuplicateHeaders = (data: CatalogItemCSVData[]) => {
    if (data.length > 0) {
      const headers = Object.keys(data[0]);
      const uniqueHeaders = [...new Set(headers)];
      return headers.length !== uniqueHeaders.length;
    }
    return false;
  };

  const getFileText = useCallback(
    async (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const fileContents = reader.result as string;
            Papa.parse(fileContents, {
              header: true,
              complete: function (results) {
                // Reset
                setFileValid(undefined);
                setErrorMessage(undefined);

                const data = results.data as CatalogItemCSVData[];
                setCsvData(data);
                // Check if the first row contains 'itemId'
                if (data.length > 0) {
                  if ("itemId" in data[0]) {
                    if (checkForDuplicateHeaders(data)) {
                      setFileValid(false);
                      setErrorMessage("File contains duplicate headers.");
                    } else {
                      setFileValid(true);
                    }
                  } else {
                    setFileValid(false);
                    setErrorMessage("File does not contain 'itemId' column.");
                  }
                }

                if (data.length < 1) {
                  setFileValid(false);
                  setErrorMessage("File does not contain any items.");
                }
              },
            });
            resolve(fileContents);
          } catch (e) {
            reject(e);
          }
        };
        reader.onerror = reject;
        reader.readAsText(file);
      }),
    []
  );

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length === 0) {
      setFileText(undefined);
      setCsvData(undefined);
      return;
    }
    getFileText(acceptedFiles[0]).then((fileText) => {
      setFileText(fileText as string);
    });
  }, [acceptedFiles, getFileText]);

  const onSubmit = async () => {
    if (!userToken) {
      console.error("No user token found.");
      setErrorMessage("No user token found. Try refreshing the page!");
      return;
    }
    if (acceptedFiles.length === 0 || !fileText) {
      setErrorMessage("File contents not available");
      return;
    } else if (acceptedFiles && acceptedFiles.length > 1) {
      setErrorMessage("Only one file can be uploaded at a time.");
      return;
    }

    setErrorMessage(undefined);
    setPostSuccessMessage(undefined);
    setHasUploaded(false);
    setIsSaving(true);
    try {
      const result = await catalogWorker.patchCatalogItemsCSV(
        userToken,
        fileText
      );
      setPostSuccessMessage(result.message);
      setHasUploaded(true);
      shouldTriggerRefreshOnClose(true);
      if (result.failedItems && result.failedItems.length > 0) {
        setFailedUploads(result.failedItems);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("An error occurred while uploading the file.");
    } finally {
      setIsSaving(false);
    }
  };

  const downloadHeadersAsCSV = () => {
    const blob = new Blob(
      [headers.map((h) => (Array.isArray(h) ? h[0] : h)).join(",")],
      {
        type: "text/csv;charset=utf-8",
      }
    );
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "headers.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (csvData) {
      setCsvRowStatus(
        csvData.map((row) => {
          // check if the data has an clientItemId and a clientId. If yes, it's being created.
          // check if the data has an itemId. If yes it's being updated
          if (row.itemId) {
            return "Update";
          } else if (row.clientItemId && row.clientId) {
            return "Create";
          } else {
            return "Error";
          }
        })
      );
    } else {
      setCsvRowStatus(undefined);
    }
  }, [csvData]);

  useEffect(() => {
    if (csvRowStatus?.includes("Error")) {
      setErrorMessage(
        "Some items are missing required fields. Please check the preview table for more information."
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [csvRowStatus]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="catalog-file-upload-modal"
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isSaving && !hasUploaded ? (
          <Row>
            <Col style={{ border: "2px dashed #ccc", margin: "0 1em 1em" }}>
              <div {...getRootProps({})}>
                <input {...getInputProps()} />
                {acceptedFiles && acceptedFiles.length > 0 ? (
                  acceptedFiles.map((f, i) => (
                    <p
                      style={{
                        color: darkMode ? "lightgrey" : "black",
                        textAlign: "center",
                      }}
                      className="m-3"
                      key={i}
                    >
                      📄 {f.name} ({(f.size / 1024).toFixed(2)}KB)
                    </p>
                  ))
                ) : (
                  <p
                    style={{ color: "gray", textAlign: "center" }}
                    className="m-3"
                  >
                    Drag 'n' drop your document, or click to select file
                  </p>
                )}
              </div>
            </Col>
          </Row>
        ) : null}
        {csvData &&
        fileValid &&
        csvRowStatus?.length &&
        csvRowStatus.length > 0 ? (
          <>
            <h4>File Upload Preview</h4>
            <CatalogCSVUploadPreviewTable
              csvData={csvData}
              rowStatuses={csvRowStatus}
            />
          </>
        ) : null}
        {failedUploads && failedUploads.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Item ID</th>
                <th>Name</th>
                <th>Raw Data</th>
              </tr>
            </thead>
            <tbody>
              {failedUploads.map((item, index) => (
                <tr key={index}>
                  <td>{item["itemId"]}</td>
                  <td>{item["name"]}</td>
                  <td>
                    <code>{JSON.stringify(item)}</code>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
        {isSaving ? <Loading /> : null}
        <Row>
          <Col>
            {errorMessage && !isSaving ? (
              <Alert
                variant="danger"
                dismissible
                onClose={() => {
                  setErrorMessage(undefined);
                }}
              >
                {errorMessage}
              </Alert>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            {postSuccessMessage && !isSaving ? (
              <Alert
                variant="success"
                dismissible
                onClose={() => {
                  setPostSuccessMessage(undefined);
                }}
              >
                {postSuccessMessage}
              </Alert>
            ) : null}
          </Col>
        </Row>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Help/instructions</Accordion.Header>
            <Accordion.Body>
              Items with an <code>itemId</code> will be updated. For creating
              items, <code>clientItemId</code> and <code>clientId</code> are
              required.
              <hr />
              <h4>Valid headers</h4>
              {headers.map((header, index) =>
                Array.isArray(header) ? (
                  <p key={index} className="csv-upload-header-name">
                    <code>{header.join(", ")}</code>
                  </p>
                ) : (
                  <p key={index} className="csv-upload-header-name">
                    <code>{header}</code>
                  </p>
                )
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="d-flex justify-content-start">
          <Button variant="info" onClick={downloadHeadersAsCSV}>
            Download CSV Headers Template
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant={hasUploaded ? "success" : "danger"}
            style={{ marginRight: "1em" }}
            onClick={handleCloseModal}
            disabled={isSaving}
          >
            {hasUploaded ? "Close" : "Cancel"}
          </Button>
          {!hasUploaded ? (
            <Button
              variant="success"
              onClick={onSubmit}
              disabled={
                isSaving ||
                hasUploaded ||
                !fileText ||
                !fileValid ||
                csvRowStatus?.includes("Error")
              }
            >
              Upload
            </Button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CatalogItemBatchUpdateCSVUploadModal;
