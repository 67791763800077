import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { Navigate, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext, useEffect, useRef, useState } from "react";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../components/profile";
import { AxiosError } from "axios";
import { useDebugMode } from "../../hooks/useDebugMode";

const createInventoryItemsWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);

/**
 * This page is used to create a new grading and refurbishment for an item.
 * It will create a new grading and refurbishment in the database and then
 * redirect to the grading and refurbishment page.
 * @returns Page
 */
const GradingAndRefurbNewPage = (): JSX.Element => {
  const [getSearchParams] = useSearchParams();
  const itemId = getSearchParams.get("itemId");
  const clientId = getSearchParams.get("clientId");
  const sku = getSearchParams.get("sku");

  const inventoryWorker = createInventoryItemsWorker();
  const [gradingAndRefurbId, setGradingId] = useState<string>();
  const [hasStored, setHasStored] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const isDebugEnabled = useDebugMode();

  // Utilise a ref to ensure that the useEffect hook only runs once
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    if (!itemId) {
      console.error("No itemId");
      return;
    }
    if (!clientId) {
      console.error("No clientId");
      return;
    }
    if (!sku) {
      console.error("No sku");
      return;
    }
    if (!userAuthenticatedContext.token) {
      console.info("No token yet");
      return;
    }
    if (initialized) {
      return;
    }
    setInitialized(true);
    hasRun.current = true;

    inventoryWorker
      .startGradingAndRefurb(
        itemId,
        userAuthenticatedContext.token,
        clientId,
        sku
      )
      .then((result) => {
        console.info("Grading and Refurb started successfully", result);
        setGradingId(result.gradingId);
        setHasStored(true);
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401) {
            alert("Unauthorized, are you logged in?");
          } else if (e.response?.status === 404) {
            console.error("Item not found");
          } else {
            console.error("Unexpected error", e);
          }
          return;
        }
        console.error(e);
      });
  }, [
    itemId,
    userAuthenticatedContext.token,
    inventoryWorker,
    gradingAndRefurbId,
    clientId,
    sku,
    initialized,
  ]);

  return (
    <Container>
      <Loading />
      {isDebugEnabled ? (
        <Container>
          <Row>
            <Col>
              <code>hasStored</code>
              <pre>{JSON.stringify(hasStored)}</pre>
            </Col>
          </Row>
        </Container>
      ) : null}

      {hasStored ? (
        <Navigate
          replace
          to={`/gradingandrefurb/${itemId}/${gradingAndRefurbId}`}
        />
      ) : null}
    </Container>
  );
};

export default withAuthenticationRequired(GradingAndRefurbNewPage, {
  onRedirecting: () => <Loading />,
});
