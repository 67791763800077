import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocalStorage } from "usehooks-ts";
import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";
import ImageRetrievalTool from "./image/retrieval-tool";
import CroppingTool from "./image/cropping-tool";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";

const ImageHelpersPage = (): JSX.Element => {
  // Using local storage here will mean that we can remember the tab that the user was on
  const [selectedTabKey, setSelectedTabKey] = useLocalStorage<string>(
    "ccSelectedImageUtilitiesTab",
    "imageHelpers"
  );

  const imageRetrievalToolEnabled = useFeatureFlagEnabled("imageRetrievalTool");
  const imageRetrievalToolLink = useFeatureFlagPayload(
    "imageRetrievalTool"
  ) as string;

  return (
    <Container>
      <Tabs
        id="controlled-tab-example"
        activeKey={selectedTabKey}
        onSelect={(k) => setSelectedTabKey(k as string)}
        className="mb-3"
      >
        <Tab eventKey={"imageHelpers"} title="Get Image URLs from page">
          <h2>Get Image URLs from Page</h2>
          <ImageRetrievalTool />
        </Tab>
        {imageRetrievalToolEnabled ? (
          <Tab eventKey={"imageRetrievalTool"} title="Image Retrieval Tool">
            <GoogleAppsScriptIframe
              title={"Image Retrieval Tool"}
              src={imageRetrievalToolLink}
              style={{ width: "100%", height: "800px" }}
            ></GoogleAppsScriptIframe>
          </Tab>
        ) : null}
        <Tab eventKey={"croppingTool"} title="Crop Image">
          <CroppingTool />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default withAuthenticationRequired(ImageHelpersPage, {
  onRedirecting: () => <Loading />,
});
