export const convertToPNG = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");

      // Calculate the aspect ratio
      const aspectRatio = img.width / img.height;

      // Set the desired maximum width and height
      const maxWidth = 800; // Set your desired maximum width
      const maxHeight = 800; // Set your desired maximum height

      // Calculate the new dimensions based on the aspect ratio and maximum values
      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        if (width / height > aspectRatio) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D; // Type assertion

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const convertedFile = new File(
          [blob as BlobPart],
          file.name.replace(/\.[^/.]+$/, "") + ".png",
          {
            type: "image/png",
          }
        );
        resolve(convertedFile);
      }, "image/png");
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    img.src = URL.createObjectURL(file);
  });
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = event.target!.result!.toString().split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
