import Pagination from "react-bootstrap/Pagination";
import { Meta } from "../external";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export interface MetaProps {
  meta: Meta;
  itemsLoaded: boolean;
  childToParentPages: (value: number) => void;
  childToParentCount: (value: number) => void;
  compactPageCount?: boolean;
}

const PaginationComponent = ({
  meta,
  itemsLoaded,
  childToParentPages,
  childToParentCount,
  compactPageCount,
}: MetaProps): JSX.Element => {
  const pageNumbers = [10, 25, 50, 100, 250, 500];

  const renderCountOptions = () => {
    return pageNumbers.map((pageNumber: number) => {
      return (
        <option value={pageNumber} key={pageNumber}>
          {pageNumber}
        </option>
      );
    });
  };

  const handleClickCount = (number: number) => {
    handleClickPages(1);
    childToParentCount(number);
  };

  const handleClickPages = (page: number) => {
    childToParentPages(page);
  };

  const pageNumbersInformation = () => {
    if (meta.totalPages > 0) {
      return <p style={{ color: "gray" }}>Total Items: {meta.totalResults}</p>;
    }

    return null;
  };

  const renderPageNumbers = () => {
    const pagesToShow: number[] = [];

    // Show the active page and the two pages before it
    for (let i = meta.current - 2; i <= meta.current; i++) {
      if (i > 0 && i <= meta.totalPages) {
        pagesToShow.push(i);
      }
    }

    // Show the two pages after the active page
    for (let i = meta.current + 1; i <= meta.current + 2; i++) {
      if (i > 0 && i <= meta.totalPages) {
        pagesToShow.push(i);
      }
    }

    return pagesToShow.map((page: number, index: number) => {
      const elements = [];

      // Add ellipsis before the page number if it's the first one and greater than 1
      if (index === 0 && page > 1) {
        elements.push(
          <Pagination.Ellipsis
            key={`ellipsis-${page}`}
            disabled={!itemsLoaded}
          />
        );
      }

      // Add the page number
      elements.push(
        <Pagination.Item
          key={page}
          active={page === meta.current}
          disabled={!itemsLoaded && page !== meta.current}
          onClick={() => handleClickPages(page)}
        >
          {page}
        </Pagination.Item>
      );

      // Add ellipsis after the page number if it's the last one and less than total pages
      if (index === pagesToShow.length - 1 && page < meta.totalPages) {
        elements.push(
          <Pagination.Ellipsis
            key={`ellipsis-${page}`}
            disabled={!itemsLoaded}
          />
        );
      }

      return elements;
    });
  };

  return (
    <Container style={{ marginBottom: 20 }}>
      <Row>
        <Col>
          {compactPageCount ? (
            <>
              <label style={{ color: "gray", marginRight: "0.5em" }}>
                Items Per Page
              </label>
              <ButtonGroup>
                {pageNumbers
                  .filter((pn) => pn < meta.totalResults)
                  .map((pageNumber, i) => {
                    return (
                      <Button
                        variant={
                          meta.maxPerPage === pageNumber
                            ? "primary"
                            : "outline-primary"
                        }
                        value={pageNumber}
                        key={pageNumber}
                        size={"sm"}
                        onClick={() => handleClickCount(pageNumber)}
                      >
                        {pageNumber}
                      </Button>
                    );
                  })}
              </ButtonGroup>
              <br />
            </>
          ) : null}
          <label>{pageNumbersInformation()}</label>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Pagination>
              <Pagination.First
                disabled={meta.current === 1 || !itemsLoaded}
                onClick={() => handleClickPages(1)}
              />
              <Pagination.Prev
                disabled={meta.current === 1 || !itemsLoaded}
                onClick={() => handleClickPages(meta.current - 1)}
              />
              {renderPageNumbers()}
              <Pagination.Next
                disabled={meta.next === null || !itemsLoaded}
                onClick={() => handleClickPages(meta.current + 1)}
              />
              <Pagination.Last
                disabled={meta.current >= meta.totalPages || !itemsLoaded}
                onClick={() => handleClickPages(meta.totalPages)}
              />
            </Pagination>
          </div>
        </Col>
      </Row>
      {!compactPageCount ? (
        <Row>
          <Col md={2}>
            <FloatingLabel label="Per Page">
              <Form.Select
                onChange={(e) => handleClickCount(Number(e.target.value))}
                value={meta.maxPerPage}
              >
                {renderCountOptions()}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

export default PaginationComponent;
