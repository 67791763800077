/// <reference types="react-scripts" />

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import TaskDashboard from "../components/dashboards/task-dashboard";

export const HomeComponent = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Home</h1>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h2>Task Summary</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <TaskDashboard />
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default withAuthenticationRequired(HomeComponent, {
  onRedirecting: () => <Loading />,
});
