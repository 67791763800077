import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
  return (
    <Container
      className="w-100 h-100"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "35px",
      }}
    >
      <Spinner animation="border"></Spinner>
    </Container>
  );
};

export default Loading;
