import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useReadLocalStorage } from "usehooks-ts";

export const CroppingToolHistory = () => {
  const storedImages =
    useReadLocalStorage<string[]>(`ccCroppingToolStoredImages`) ?? [];
  const historyDisclaimer = `History is unique per browser and device. History will disappear if you wipe cookies or similar. Images will still persist on the server.`;
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>History</Accordion.Header>
        <Accordion.Body style={{ maxHeight: "600px", overflow: "auto" }}>
          {storedImages.length === 0 ? (
            <>
              <h3>No history</h3>
              <p>{historyDisclaimer}</p>
            </>
          ) : null}
          {storedImages.length > 0 ? (
            <>
              <p>
                Most recent crops are at the top of the list.{" "}
                {historyDisclaimer}
              </p>
              {storedImages.map((url, index) => (
                <div key={index}>
                  <Row>
                    <Col lg={3} xxl={2}>
                      <img
                        src={url}
                        alt={`Cropped history ${index}`}
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          margin: "5px",
                        }}
                      ></img>
                    </Col>
                    <Col lg={9} xxl={10}>
                      <Form.Control
                        type="text"
                        readOnly
                        defaultValue={url}
                      ></Form.Control>
                    </Col>
                  </Row>
                  {index !== storedImages.length - 1 ? <hr /> : null}
                </div>
              ))}
            </>
          ) : null}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
