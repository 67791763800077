import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { FormEvent, KeyboardEventHandler, useState } from "react";

const ScannerTestPage = () => {
  const onKeyPressed: KeyboardEventHandler<HTMLElement> = (event) => {
    console.log(event.key);
    setEntries([...entries, event.key]);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const [entries, setEntries] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Stack direction="vertical" onKeyPress={onKeyPressed}>
              <Button
                variant="warning"
                size="lg"
                className="mb-2"
                onClick={() => {
                  setInputValue("");
                  setEntries([]);
                  console.info(inputRef);
                  inputRef.current?.focus();
                }}
              >
                Clear
              </Button>
              <Form.Control
                style={{
                  fontSize: "2em",
                  fontFamily: "monospace, sans-serif",
                }}
                autoComplete="off"
                autoFocus
                type="text"
                name="type"
                placeholder="Scan Here"
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Stack>
          </Form>
          <Stack>
            {entries.map((e, i) => (
              <div
                key={i}
                style={{ width: "100%", borderBottom: "0.5px solid black" }}
              >
                <code style={{ width: "100%" }}>{e}</code>
              </div>
            ))}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default ScannerTestPage;
