import axios from "axios";
import { GradingStepsConfiguration } from "../components/gradingandrefurb/types";

export interface ValidateConfigurationResult {
  /**
   * Success or error message. Can be shown to the user.
   */
  message: string;

  /**
   * If the configuration was not successfully saved this will show
   * the list of validation errors, if any.
   */
  errors?: string[];
}
export interface PutConfigurationResult extends ValidateConfigurationResult {
  /**
   * If the configuration was successfully saved this will show a success message.
   */
  result?: string;
}

export const putConfiguration = async (
  userToken: string,
  configuration: GradingStepsConfiguration
) => {
  const url = process.env.REACT_APP_GRADING_CONFIGURATION_PUT_URL as string;
  const putResult = await axios.put<PutConfigurationResult>(
    url,
    configuration,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      validateStatus: (status) => [200, 201, 400].includes(status),
    }
  );
  return putResult.data;
};

export const validateConfiguration = async (
  userToken: string,
  configuration: GradingStepsConfiguration
) => {
  const url = process.env
    .REACT_APP_GRADING_CONFIGURATION_VALIDATE_URL as string;
  const validateResult = await axios.post<ValidateConfigurationResult>(
    url,
    configuration,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      validateStatus: (status) => [200, 400].includes(status),
    }
  );
  return validateResult.data;
};

export interface GetConfigurationResult {
  configuration: GradingStepsConfiguration;
  message: string;
}

export const getConfiguration = async (
  userToken: string,
  /**
   * Optional but recommended. This parameter will retrieve configuration by
   * clientCode if one exists, or the latest default configuration if not.
   */
  clientCode?: string,
  /**
   * Optional. This parameter will retrieve configuration by sku if one
   * exists, or the latest default configuration if not.
   */
  sku?: string
) => {
  const url = process.env.REACT_APP_GRADING_CONFIGURATION_GET_URL as string;
  const getResult = await axios.get<GetConfigurationResult>(url, {
    params: {
      clientCode,
      sku,
    },
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    validateStatus: (status) => [200, 400, 404].includes(status),
  });

  if (getResult.status === 400) {
    console.error({
      message: "Invalid parameters provided",
      clientCode,
      sku,
    });
  }
  if (getResult.status === 404) {
    console.error({
      message: "No grading configuration found for parameters provided",
      statusCode: getResult.status,
      clientCode,
      sku,
    });
  }

  return getResult.data;
};
