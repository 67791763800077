import { useSessionStorage } from "usehooks-ts";

const authScopesKey = "cc-circle-auth-scopes";
export const useCircleAuthScopes = (): string[] => {
  const [scopes] = useSessionStorage(authScopesKey, []);
  return scopes;
};

export const useCircleAuthScopesReadWrite = (initialValue: string[]) =>
  useSessionStorage(authScopesKey, initialValue);
