import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import DamagesCountEntryTypeComponent from "./damages/count-entry";
import DamagesCheckboxTypeComponent from "./damages/checkbox-entry";
import { BaseGradingComponentProps } from "./types";
import { useEffect } from "react";
import { GradingStepDetailedInformation } from "../../../external/inventory-component/configuration/types";
import {
  DetailedInformationSection,
  DetailedInformationSectionOption,
} from "../../../external/inventory-component/configuration/detailedInformation";
import DamagesCountButtonsTypeComponent from "./damages/count-buttons";

const DetailedInformationComponent = ({
  configuration,
  refurbIdentifier,
  stepOutputKey,
  setStepCompleted,
}: GradingStepDetailedInformation & BaseGradingComponentProps): JSX.Element => {
  // TODO: work out a better way to set if something is completed
  // Maybe a better MVP would be to set a timer? e.g. engineer has 20 seconds per page?
  useEffect(() => setStepCompleted(true), [setStepCompleted]);

  return (
    <Container>
      {configuration.sections.map((section, sectionIndex) => (
        <DamageSeveritySectionComponent
          option={section}
          stepOutputKey={stepOutputKey}
          refurbIdentifier={refurbIdentifier}
          key={`section-${sectionIndex}`}
        />
      ))}
    </Container>
  );
};

const DamageSeveritySectionComponent = ({
  option,
  stepOutputKey,
  refurbIdentifier,
}: {
  option: DetailedInformationSection;
  stepOutputKey: string;
  refurbIdentifier: string;
}) => (
  <Row>
    <Col>
      <div style={{ marginBottom: "1em" }}>
        <h3 style={{ textAlign: "center" }}>{option.sectionName}</h3>
        {option.options.map((sectionOption, sectionOptionIndex) => (
          <DamageSeverityEntryComponent
            option={sectionOption}
            refurbIdentifier={refurbIdentifier}
            stepOutputKey={stepOutputKey}
            key={`sectionoption-${sectionOptionIndex}`}
          />
        ))}
        {option.imageRequired ? (
          <Row>
            <Col>
              <p>Please take/upload an image of damages</p>
            </Col>
            <Col>
              <input type="file" accept="image/*" capture="environment" />
            </Col>
          </Row>
        ) : null}
        <hr />
      </div>
    </Col>
  </Row>
);

const DamageSeverityEntryComponent = ({
  option,
  stepOutputKey,
  refurbIdentifier,
}: {
  option: DetailedInformationSectionOption;
  stepOutputKey: string;
  refurbIdentifier: string;
}) => {
  if (option.optionType === "count" && !option.countOptions) {
    return (
      <Container>
        <Row>
          <h4>
            Invalid configuration - <code>count</code> configuration is
            incorrect
          </h4>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="damage-severity-entry">
      <Row>
        {option.optionType !== "checkbox" && (
          <Col
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            style={{
              display: "flex",
              fontSize: "1.5em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {option.optionName}
          </Col>
        )}
        <Col>
          <Row>
            {option.optionType === "count" &&
              option.countOptions!.map((optionCountSettings, optionIndex) => {
                if (optionCountSettings.entryType === "entry") {
                  return (
                    <DamagesCountEntryTypeComponent
                      refurbIdentifier={refurbIdentifier}
                      stepOutputKey={stepOutputKey}
                      optionKey={option.optionKey}
                      settings={optionCountSettings}
                      key={`optionentry-${optionIndex}`}
                    />
                  );
                } else {
                  return (
                    <DamagesCountButtonsTypeComponent
                      refurbIdentifier={refurbIdentifier}
                      stepOutputKey={stepOutputKey}
                      optionKey={option.optionKey}
                      settings={optionCountSettings}
                      key={`optioncount-${optionIndex}`}
                    />
                  );
                }
              })}

            {option.optionType === "checkbox" && (
              <DamagesCheckboxTypeComponent
                stepOutputKey={stepOutputKey}
                refurbIdentifier={refurbIdentifier}
                optionKey={option.optionKey}
                optionName={option.optionName}
                showImage={option.optionImageRequired ?? false}
                passthroughKey={`check-${option.optionKey}`}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default DetailedInformationComponent;
