import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip, { TooltipProps } from "react-bootstrap/Tooltip";

import { FieldValues, UseFormRegister, Path } from "react-hook-form";
import { Dispatch, SetStateAction, useState } from "react";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";

export interface MultiStringEntryComponentProps<T extends FieldValues> {
  /**
   * The key for the object in the form.
   */
  objectKey: Path<T>;

  /**
   * The entries to display. Should come from useState.
   */
  entries: string[];

  /**
   * Title for the section/accordion.
   * @example "Client SKU Links"
   */
  title: string;

  /**
   * Title for the single entry.
   * @example "Link"
   */
  singleEntryTitle: string;

  /**
   * The method to update the entries. Should come from useState.
   */
  setValueMethod: Dispatch<SetStateAction<string[]>>;

  /**
   * The register method from react-hook-form.
   */
  register: UseFormRegister<T>;

  /**
   * If the form is view only. Used for display purposes.
   */
  viewOnly: boolean;

  /**
   * Set if the form is saving, which disables the input fields.
   */
  isSaving: boolean;

  /**
   * Max number of entries allowed.
   * @default 5
   */
  maxEntries?: number;

  /**
   * Specifies if the entries are URLs. If true, Open Link buttons will be provided.
   */
  isURLList?: boolean;

  /**
   * Specifies if entries are required. If true, a badge will be displayed.
   */
  required?: boolean;
}

function MultiStringEntryComponent<T extends FieldValues>({
  objectKey,
  entries,
  title,
  singleEntryTitle,
  setValueMethod,
  register,
  viewOnly,
  isSaving,
  maxEntries,
  isURLList,
  required,
}: MultiStringEntryComponentProps<T>) {
  const configuredMaxEntries = maxEntries ?? 5;
  const [addNew, setAddNew] = useState<boolean>(false);
  const [newEntry, setNewEntry] = useState<string>("");

  const renderOpenURLTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      Opens in new tab
    </Tooltip>
  );

  return (
    <>
      <div className="d-flex align-items-center">
        <h5 className="mb-3 pt-3">{title}</h5>
        {required ? (
          <Badge className="m-2" bg="danger">
            Required
          </Badge>
        ) : null}
      </div>
      {entries.length > 0
        ? entries.map((a, i) => (
            <InputGroup key={objectKey + "-" + i} className="mb-3">
              <Form.Control
                {...register(`${objectKey}.${i}` as Path<T>, {
                  disabled: viewOnly || isSaving,
                  validate: (value) =>
                    (value && value.startsWith("https")) || "Invalid input",
                })}
                type="text"
                value={a}
                onChange={(event) => {
                  const updatedEntries = [...entries];
                  updatedEntries[i] = event.target.value;
                  setValueMethod(updatedEntries);
                }}
                isInvalid={!a || !a.startsWith("https")}
              />
              {!viewOnly ? (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const updatedEntries = [...entries];
                    updatedEntries.splice(i, 1);
                    setValueMethod(updatedEntries);
                  }}
                  disabled={isSaving || viewOnly || entries.length <= 1} // Disable the button if the entries array has 1 or less elements
                >
                  Remove
                </Button>
              ) : null}
              {isURLList ? (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderOpenURLTooltip}
                >
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      window.open(a, "_blank");
                    }}
                  >
                    Open
                    <BoxArrowUpRight
                      style={{ marginLeft: "0.25em", marginBottom: "0.25em" }}
                      size={14}
                    />
                  </Button>
                </OverlayTrigger>
              ) : null}
              <Form.Control.Feedback type="invalid">
                Input must not be empty and must start with 'https'
              </Form.Control.Feedback>
            </InputGroup>
          ))
        : null}
      {!viewOnly ? (
        <Button
          variant="primary"
          disabled={
            isSaving || entries.length >= configuredMaxEntries || addNew
          }
          style={{ marginBottom: "10px" }}
          onClick={() => {
            setAddNew(true);
          }}
        >
          Add {singleEntryTitle}
        </Button>
      ) : null}
      {entries.length >= configuredMaxEntries ? (
        <Form.Text className="text-danger catalogue-field-warning">
          Only {configuredMaxEntries} allowed
        </Form.Text>
      ) : null}
      {addNew && (
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            value={newEntry}
            onChange={(event) => {
              setNewEntry(event.target.value);
            }}
          />
          <Button
            variant="success"
            onClick={() => {
              const updatedEntries = [...entries, newEntry];
              setValueMethod(updatedEntries);
              setNewEntry("");
              setAddNew(false);
            }}
            disabled={!newEntry.startsWith("https")} // Disable the button if the input does not start with 'https'
          >
            Confirm
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setNewEntry("");
              setAddNew(false);
            }}
          >
            Cancel
          </Button>
        </InputGroup>
      )}
    </>
  );
}

export default MultiStringEntryComponent;
