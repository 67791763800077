import React from "react";
import CloseButton from "react-bootstrap/CloseButton";
import { Bell } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import loginScreen from "../../assets/faq-page/login_screen.png";
import pmProductDataViewerMenu from "../../assets/faq-page/pm-menu-productdataviewer.png";
import pmRequestChangesInProductDataViewer from "../../assets/faq-page/pm-requestchanges-productdataviewer.png";
import markReadyToListButton from "../../assets/faq-page/mark-ready-to-list-button.png";
import readyForListingImage from "../../assets/faq-page/ready-for-listing.png";
import readyForListingPopUpImage from "../../assets/faq-page/ready-to-list-pop-up.png";

export interface FAQDataObject {
  heading: string;
  authenticationRequired: boolean;
  faqs: {
    questionNo: number;
    question: string;
    answer: JSX.Element;
  }[];
}

const supportGoogleFormUrl = "https://forms.gle/Vx6TioPKufWkADm48";

const defaultImagesStyle: React.CSSProperties = {
  maxHeight: "500px",
  maxWidth: "80%", // 80% of the viewport width
};

const smallImagesStyle: React.CSSProperties = {
  maxHeight: "250px",
  maxWidth: "40%", // 80% of the viewport width
};

const catalogueRequiredFields = [
  "Client ID",
  "Client SKU/Item ID",
  "Images",
  "Name",
  "Description",
  "RRP",
  "Weight",
  "Box details",
];

const faqsData: FAQDataObject[] = [
  {
    heading: "Getting Started",
    authenticationRequired: false,
    faqs: [
      {
        questionNo: 1,
        question: "How do I create an account?",
        answer: (
          <div>
            <p>
              <b>ClearCycle Staff</b> will most likely login using the button{" "}
              <code>Continue with login as ClearCycle Staff</code>. This will
              use your work Microsoft account. If you do not have an internal
              Microsoft account (with a <code>@clearcycle.co.uk</code> email
              address) for ClearCycle Circle and you believe you require a
              username/password, please contact your manager who can assist.
            </p>
          </div>
        ),
      },
      {
        questionNo: 2,
        question: "How do I log into my account?",
        answer: (
          <div>
            <ul>
              <li>
                Click on the green <code>Log In</code> button in the top right
                hand corner. this will take you to the login page.
              </li>
              <li>
                You can log into your account by clicking on{" "}
                <code>Continue with login as ClearCycle Staff</code> at the
                bottom.
              </li>
            </ul>
            <img
              src={loginScreen}
              alt="Login Page"
              style={defaultImagesStyle}
            />
          </div>
        ),
      },
      {
        questionNo: 3,
        question: "How do I reset my password?",
        answer: (
          <div>
            <p>
              If you login using a Microsoft account (i.e. if you have a{" "}
              <code>@clearcycle.co.uk</code> email address), you will need to
              contact IT247 for a password reset if you don't know how to do
              this yourself.
            </p>
            <p>
              If you have a username and password for Circle, you can reset your
              password from the login page by clicking on{" "}
              <code>Forgot password?</code> and following on-screen prompts.
            </p>
            <img
              src={loginScreen}
              alt="Login Page"
              style={defaultImagesStyle}
            />
          </div>
        ),
      },
    ],
  },
  {
    heading: "Catalogue",
    authenticationRequired: true,
    faqs: [
      {
        questionNo: 1,
        question: "How do I create catalogue items?",
        answer: (
          <div>
            <p>
              For single items, on the <Link to="/catalog">Catalogue page</Link>
              , go to <code>Actions</code> {">"} <code>New Catalogue Item</code>{" "}
            </p>
            <p>
              For bulk uploads, on the <Link to="/catalog">Catalogue page</Link>
              , go to <code>Actions</code> {">"} <code>CSV Upload</code>.
            </p>
            <p>
              Here you will find instructions on how to download, fill out and
              upload a csv file.
            </p>
            <p>
              On upload of this file, all the items will be either updated or
              added to the catalogue.
            </p>
          </div>
        ),
      },
      {
        questionNo: 2,
        question:
          "How do I update catalogue items from the Production Managers?",
        answer: (
          <>
            <p>
              The existing product data viewer has been updated to direct you to
              the correct page.
            </p>
            <p>
              To access the product data viewer, go to the{" "}
              <code>ClearCycle</code> menu in the PM and click on the{" "}
              <code>Refurb - Product Data Viewer</code>. Ensure you have a row
              selected to see the correct product in the viewer.
            </p>
            <img
              src={pmProductDataViewerMenu}
              alt="Menu entry"
              style={defaultImagesStyle}
            />
            <p>
              Then on the data viewer itself, click on the{" "}
              <code>Request Data amendment in Circle</code> button.
            </p>
            <img
              src={pmRequestChangesInProductDataViewer}
              alt="Request changes via the highlighted button"
              style={defaultImagesStyle}
            />
            <p>
              This will take you to the catalogue item in Circle where you can
              make changes and request approval (or, if you have the correct
              permissions, make changes directly).
            </p>
          </>
        ),
      },
      {
        questionNo: 3,
        question: "How do I update catalogue item details in Circle directly?",
        answer: (
          <div>
            <p>
              There are two ways to update catalogue items either individually
              or in bulk.
            </p>
            <ul>
              <li>
                To amend the details of an individual catalogue item, from the{" "}
                <Link to="/catalog">Catalogue page</Link> click on the catalogue
                item.
              </li>
              <li>
                This will take you to the page for that specific item. Fields
                that are not greyed out can be updated.
              </li>
              <li>
                When a value is changed, depending on the permissions you have a
                user on the system you will see at the top pf the page, either
                <code>Save Changes</code> or{" "}
                <code>Submit a Change Request</code>.
              </li>
              <li>
                Clicking on <code>Save Changes</code> will update the catalogue
                item immediately.
              </li>
              <li>
                Clicking on <code>Submit a Change Request</code> will submit a
                request to the ClearCycle team to review and approve the
                changes.
              </li>
            </ul>
          </div>
        ),
      },
      {
        questionNo: 4,
        question: "How do I mark a Catalogue Item as Ready to List?",
        answer: (
          <div>
            <div>
              <p>
                Once we have the catalogue item in the system, and we have all
                the necessary information, we can mark the item as ready to be
                listed. This will allow the item to be listed through one of our
                storefronts.
              </p>
              <img
                src={markReadyToListButton}
                alt="Mark Ready To List button"
                style={{
                  ...smallImagesStyle,
                  border: "1px solid black",
                  marginBottom: "1rem",
                }}
              />
            </div>
            <ul>
              <li>
                Before it can be listed the item must have the following
                information{" "}
                {
                  <ul>
                    {catalogueRequiredFields.map((field) => (
                      <li key={field}>
                        <strong>{field}</strong>
                      </li>
                    ))}
                  </ul>
                }
                If any of these fields are absent clicking on the Mark Ready To
                List button will show you which fields are missing.
              </li>
              <li>
                <div>
                  <p>
                    If all the required are present simply confirm through the
                    pop up.
                  </p>
                  <img
                    src={readyForListingPopUpImage}
                    alt="Mark Ready To List button"
                    style={{
                      ...smallImagesStyle,
                      border: "1px solid black",
                      marginBottom: "1rem",
                    }}
                  />
                </div>
              </li>
              <li>
                You can tell if an item is ready to be listed by the green green
                disabled button on the catalogue item page.
                <img
                  src={readyForListingImage}
                  alt="Mark Ready To List button"
                  style={{
                    ...smallImagesStyle,
                    border: "1px solid black",
                    marginBottom: "1rem",
                  }}
                />
              </li>
            </ul>
          </div>
        ),
      },
      {
        questionNo: 5,
        question: "How do I manage images for a catalogue item?",
        answer: (
          <div>
            <p>
              You can upload a saved image from your device, by providing the
              url to a hosted image, or by scanning a webpage for images and
              selecting images to download and store against a catalogue item.
            </p>
            <ul>
              <li>
                To upload an image from your device, navigate to that items
                catalogue page. Click on the <code>Upload Image</code> button
                which will be in the top section of the page.
              </li>
              <li>
                To store an image from a URL (such as a hosted image from a
                customer site) then click on <code>Import from Image URL</code>.
                This will allow you to enter a URL. When submitted the image
                will be automatically downloaded from the client's website and
                stored against the catalogue item.
              </li>
              <li>
                To retrieve images from a page (say, a client's website page)
                click on <code>Scan for Images from URL</code>. This will query
                the site and if there are images found, it will provide you with
                a list of images. You can choose which images to download and
                store against the catalogue item. This may fail if the server
                blocks our requests or has certain security settings.
              </li>
              <li>
                To remove an image, click on the image and then click on the{" "}
                <CloseButton />
              </li>
              <li>
                Removing images are treated like any other change to a catalogue
                item. If you do not have permission to edit catalogue items and
                you believe you should have access, you will need to raise a
                support request{" "}
                <a href={supportGoogleFormUrl}>via this form.</a>
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    heading: "Tasks",
    authenticationRequired: true,
    faqs: [
      {
        questionNo: 1,
        question: "What tasks will I see?",
        answer: (
          <div>
            <ul>
              <li>
                The tasks you will see will be dependent on the role you have
                assigned.
              </li>
              <li>
                You will receive a notification through Circle (through the{" "}
                <Bell></Bell> icon in the top right) notifying you of any tasks
                relevant to you.
              </li>
              <li>
                If you have any task notifications you will see number against
                the bell icon in the top left hand corner of the screen.
                Clicking on the notification will take you directly to that
                task.
              </li>
            </ul>
          </div>
        ),
      },
      {
        questionNo: 2,
        question: "What is a Catalogue Change Request Task?",
        answer: (
          <div>
            <p>
              Catalogue Change Request tasks are raised when someone requests
              changes to a catalogue item. These will be sent to members of the
              team that can approve changes to a catalogue item.
            </p>
            <ul>
              <li>
                <h5>Raising a Request</h5>

                <p>
                  A Catalogue Change request is raised from a{" "}
                  <Link to="/catalog">Catalogue page</Link>. If you do not have
                  the necessary permissions to update a catalogue item any
                  changes you make will need to be approved.
                </p>
                <p>
                  On the catalogue item page when you have made a change you
                  will see at the top of the page, either{" "}
                  <code>Save Changes</code> or
                  <code>Submit a Change Request</code>.
                </p>
                <p>
                  Clicking on <code>Save Changes</code> will update the
                  catalogue item immediately, if you have the permissions to do
                  so. This means you do not need to raise a request.
                </p>
                <p>
                  If you see <code>Submit a Change Request</code> you will need
                  to request the change, on clicking you will be asked to
                  provide a reason for the change request.
                </p>
                <p>
                  Once you have submitted the change request, the relevant
                  ClearCycle team will be notified and will review the changes.
                </p>
                <p>
                  Once the changes have been reviewed, you will be notified of
                  the outcome via notifications in the top right, or via email
                  depending on your preferences.
                </p>
              </li>
              <li>
                <h5>Processing a Request</h5>
                <p>
                  To process a Catalogue change request task, click on the View
                  Details blue cross.
                </p>
                <p>
                  This will show you the suggested change to the catalogue item,
                  review change, if you are happy with the change then click
                  Accept Button at the bottom of task.
                </p>
                <p>
                  If not you can reject by clicking the red Reject button at the
                  bottom of the task. If you are rejecting you will also need to
                  enter a reason why.
                </p>
                <p>
                  The user who raised the change request will be notified of the
                  outcome.
                </p>
              </li>
            </ul>
          </div>
        ),
      },
      {
        questionNo: 3,
        question: "What is a Catalogue Data Request Task?",
        answer: (
          <div>
            <p>
              Catalogue Data Request tasks are raised when a user needs more
              information about a catalogue item, generally from someone with
              access to the item (such as a warehouse operative or grader)
            </p>
            <ul>
              <li>
                <h5>Raising a Request</h5>
                <p>
                  A Catalogue Data request is raised from the{" "}
                  <Link to="/catalog">Catalogue page</Link> by clicking the blue
                  Data Request button.
                </p>
                <p>
                  This will open a modal allowing you to enter the info you need
                  into the text box, this could be something like confirming a
                  size or other details of the catalogue item.
                </p>
              </li>
              <li>
                <h5>Processing a Request</h5>
                <p>
                  To process a Catalogue Data Request task, from the Task page
                  click on the View Details blue cross.
                </p>
                <p>You will see the details of the request and who made it.</p>
                <p>
                  To reply or supply the information click on the blue Add Note
                  Button.
                </p>
                <p>
                  This will open a modal allowing you to enter the information
                  into the text box.
                </p>
                <p>
                  Once you have entered the information click on the Accept
                  button and the task will be updated.
                </p>
                <p>
                  The user who raised the data request can then Accept to close
                  the task if they have the information they needed.
                </p>
              </li>
            </ul>
          </div>
        ),
      },
      {
        questionNo: 4,
        question: "What is a Catalogue Create Request Task?",
        answer: (
          <div>
            <p>
              Catalogue Create Request tasks are raised when a user needs to
              create a new catalogue item.
            </p>
            <ul>
              <li>
                <h5>Raising a Request</h5>
                <p>
                  A Catalogue Create request task is automatically raised when
                  an inbound manifest is submitted on the inbound manifest tool,
                  and one or more of the items does not have a SKU. Each item
                  that does not have a catalogue entry will have a task created.
                </p>
              </li>
              <li>
                <h5>Completing a Request</h5>
                <p>
                  To complete a Catalogue Create Request you will need to create
                  the item using the CSV upload tool.
                </p>
                <p>
                  The upload CSV template can be found in the{" "}
                  <Link to="/catalog">Catalogue page</Link> by clicking on the
                  blue Action button, selecting CSV upload and following the
                  instructions.
                </p>
                <p>
                  Once the item has been added to the catalogue, you can mark
                  the task complete by clicking on the green{" "}
                  <code>Mark as Complete</code>
                  button.
                </p>
                <p>
                  If the Item is not added to the catalogue you can mark the
                  task as complete by clicking on the red{" "}
                  <code>Close as Incomplete</code>
                  button and adding a reason in the pop up.
                </p>
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    authenticationRequired: false,
    heading:
      "I have an issue or question that is not covered here. How can I get help?",
    faqs: [
      {
        questionNo: 1,
        question: "How can I request support with an issue?",
        answer: (
          <>
            <p>
              Please <a href={supportGoogleFormUrl}>use the Google Form here</a>{" "}
              to request specific support. Please include as much detail as
              possible, including files and/or screenshots if you can.
            </p>
          </>
        ),
      },
      {
        questionNo: 2,
        // This form is the same form as above but with some pre-filled questions
        question: "How can I request a new feature?",
        answer: (
          <>
            <p>
              Please{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSda9HqZFmQPPTrl5H0gpwUBbJTk2cntzTmRE8RBeScki3J_8w/viewform?usp=pp_url&entry.726320630=Change/Feature+request&entry.807766811=ClearCycle+Circle+(internal+system+at+https://circle.clearcycle.co.uk)">
                use the Google Form here
              </a>{" "}
              to request your feature. Please include as much detail as possible
              including files and/or screenshots if you can.
            </p>
          </>
        ),
      },
    ],
  },
];

export default faqsData;
