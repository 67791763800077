import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  AuditHistoryEntry,
  AuditHistorySystem,
  AuditHistoryUserEntry,
} from "../../../external";
import { formatDateFromTimestamp } from "../../../helpers/datetime";
import { InfoCircle } from "react-bootstrap-icons";

const AuditRow = ({ entry }: { entry: AuditHistoryEntry }) => {
  const isSystemAuditHistoryEntry =
    (entry as AuditHistorySystem).systemModification !== undefined;

  if (isSystemAuditHistoryEntry) {
    return (
      <tr>
        <td colSpan={2}>
          System
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-audit-${entry.timestamp}`}>
                System changes will be automatic changes by the system, e.g.
                adding automatically scraped images
              </Tooltip>
            }
          >
            <Button variant="outline" size="sm" style={{ marginLeft: "0.5em" }}>
              <InfoCircle />
            </Button>
          </OverlayTrigger>
        </td>
        <td>{formatDateFromTimestamp(entry.timestamp)}</td>
      </tr>
    );
  }

  const userEntry = entry as AuditHistoryUserEntry;

  return (
    <tr>
      <td>{userEntry.userEmail}</td>
      <td>{userEntry.userName}</td>
      <td>{formatDateFromTimestamp(userEntry.timestamp)}</td>
    </tr>
  );
};

export default AuditRow;
