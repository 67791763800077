import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";
import { useContext, useEffect, useState } from "react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../components/profile";

export interface ImageRetrievalToolProps {}

interface ClientListEntry {
  name: string;
  id: string;
  changes?: string[];
}
const clients: ClientListEntry[] = [
  {
    name: "Generic (change for client specific scrape settings)",
    id: "GENERIC",
  },
  {
    name: "Oak Furnitureland",
    id: "OFL",
    changes: ["Modifies webp image links replaces with raw JPEG links"],
  },
];
const createImageHelpersWorker = createWorkerFactory(
  () => import("../../workers/images")
);

const ImageRetrievalTool = () => {
  const [queryUrl, setQueryUrl] = useState<string>();
  const [returnedResults, setReturnedImages] = useState<string[]>();
  const [userToken, setUserToken] = useState<string>();
  const [isInRequestingStatus, setIsInRequestingStatus] = useState(false);
  const [resultError, setResultError] = useState<string | undefined>(undefined);
  const [selectedClient] = useState<ClientListEntry>(clients[0]);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const imageWorker = useWorker(createImageHelpersWorker);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const getImagesForUrl = async () => {
    if (!userToken) {
      console.error("NO token!");
      return;
    }

    if (!queryUrl) {
      console.error("No URL");
      return;
    }

    setIsInRequestingStatus(true);
    setResultError(undefined);

    try {
      const getImagesResult = await imageWorker.GetImagesForUrl(
        queryUrl,
        selectedClient?.id ?? "GENERIC",
        userToken
      );

      if (getImagesResult.urls.length === 0) {
        setResultError("No images were found at the URL!");
      } else {
        setReturnedImages(getImagesResult.urls);
      }
    } catch (e) {
      setResultError((e as unknown as Error).message);
    } finally {
      setIsInRequestingStatus(false);
    }
  };

  useEffect(() => {
    if (returnedResults && returnedResults.length > 0) {
      const images = document.getElementsByClassName("returnedImageResult");
      for (let i = 0; i < images.length; i++) {
        const image = images.item(i) as HTMLImageElement;
        console.table({
          height: image.naturalHeight,
          width: image.naturalWidth,
        });
      }
    }
  }, [returnedResults, selectedClient]);

  return (
    <>
      <p>
        Enter the URL below, and the server will retrieve all of the image URLs
        present on the page for you.
      </p>
      <Row>
        <Col>
          <Form.Group controlId="url">
            <Form.Label>URL</Form.Label>
            <Form.Control
              onChange={(v) => setQueryUrl(v.currentTarget.value)}
              type="input"
              placeholder="https://example.com/"
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Stack className="mt-2" direction="horizontal" gap={1}>
          <Button
            variant="success"
            disabled={isInRequestingStatus}
            onClick={getImagesForUrl}
          >
            <Spinner
              as={"span"}
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
              style={{ marginRight: "0.5em" }}
              className={isInRequestingStatus ? "" : "visually-hidden"}
            />
            Request Images
          </Button>
        </Stack>
      </Row>
      {selectedClient &&
      selectedClient.changes &&
      selectedClient.changes.length > 0 ? (
        <Container className="mt-4">
          <h5>Client specific settings for {selectedClient.name}</h5>
          <Row>
            <ul className="mb-0">
              {selectedClient.changes.map((c, i) => (
                <li key={i}>
                  <p>{c}</p>
                </li>
              ))}
            </ul>
          </Row>
        </Container>
      ) : (
        <></>
      )}
      <hr />
      {resultError ? (
        <Row className="mt-2">
          <Alert
            variant="danger"
            dismissible
            onClose={() => {
              setResultError(undefined);
            }}
          >
            {resultError}
          </Alert>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-2">
        <h2>Results</h2>
        <Table>
          <thead style={{ fontWeight: "bolder" }}>
            <tr>
              <td>Image</td>
              <td>URL</td>
            </tr>
          </thead>
          <tbody>
            {returnedResults ? (
              returnedResults.map((r, i) => (
                <tr key={i}>
                  <td style={{ width: "410px" }}>
                    <img
                      className="returnedImageResult"
                      style={{ maxWidth: "400px" }}
                      src={r}
                      alt={r}
                    ></img>
                  </td>
                  <td>
                    <input readOnly style={{ width: "100%" }} value={r}></input>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default ImageRetrievalTool;
