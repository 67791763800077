import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  CatalogueCreateRequestItem,
  Task,
} from "../../external/task-component/openapi";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import AcceptModal from "./accept-modal";
import RejectModal from "./reject-modal";
import { useNavigate } from "react-router-dom";
import { PlusCircleFill } from "react-bootstrap-icons";
import { UserAuthenticatedContext } from "../profile";

interface Props {
  taskId: string;
  task: Task;
  taskStatus: string | undefined;
  setTaskStatus: (status: string) => void;
  setTaskReason: (status: string) => void;
}

const TaskCreateCatalogueRequest: React.FC<Props> = ({
  setTaskStatus,
  setTaskReason,
  taskId,
  task,
  taskStatus,
}) => {
  const { scopes } = useContext(UserAuthenticatedContext);
  const canEditCreateRequest =
    scopes?.includes("edit:task-item:create-request") ?? false;

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [isOpenTask, setIsOpenTask] = useState(false);
  const [item] = useState<CatalogueCreateRequestItem>(
    task.data.item as CatalogueCreateRequestItem
  );

  useEffect(() => {
    setTaskReason(rejectReason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectReason]);

  const handleAcceptClick = () => {
    setShowAcceptModal(true);
  };

  const handleRejectClick = () => {
    setShowRejectModal(true);
  };

  useEffect(() => {
    if (
      taskStatus !== "completed" &&
      taskStatus !== "rejected" &&
      taskStatus !== "cancelled"
    ) {
      setIsOpenTask(true);
    }
  }, [taskStatus]);

  const navigate = useNavigate();

  const navigateToNewCatalog = () => {
    navigate("/catalog/new", {
      state: {
        item,
        isCatalogCreateRequest: true,
        taskId,
      },
    });
  };
  return (
    <>
      <Container className="mx-0">
        <Accordion>
          <Accordion.Item eventKey={taskId}>
            <Accordion.Header className="d-flex justify-content-between align-items-center">
              <div className="me-2">SKU: {item.sku}</div>
              {isOpenTask && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>Create Item</Tooltip>}
                >
                  <PlusCircleFill color="blue" onClick={navigateToNewCatalog}>
                    Create Item
                  </PlusCircleFill>
                </OverlayTrigger>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <h4>General Information</h4>
              <Table striped>
                <tbody>
                  <tr>
                    <td>Title: {item.title}</td>
                  </tr>
                  <tr>
                    <td>Quantity: {Math.floor(item.qty)}</td>
                  </tr>
                  <tr>
                    <td>RRP: £{(item.rrp / 100).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      Website URL:{" "}
                      <a
                        href={item.websiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.websiteUrl}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4>Product Dimensions</h4>
              <Table striped>
                <tbody>
                  <tr>
                    <td>Height: {item.productDims.height}cm</td>
                  </tr>
                  <tr>
                    <td>Width: {item.productDims.width}cm</td>
                  </tr>
                  <tr>
                    <td>Depth: {item.productDims.depth}cm</td>
                  </tr>
                  <tr>
                    <td>Weight: {item.productDims.weight / 1000}kg</td>
                  </tr>
                </tbody>
              </Table>
              <h4>Average Box</h4>
              <Table striped>
                <tbody>
                  <tr>
                    <td>Height: {item.averageBox.height}cm</td>
                  </tr>
                  <tr>
                    <td>Width: {item.averageBox.width}cm</td>
                  </tr>
                  <tr>
                    <td>Depth: {item.averageBox.depth}cm</td>
                  </tr>
                  <tr>
                    <td>Weight: {item.averageBox.weight / 1000}kg</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />
      </Container>

      <Container>
        {taskStatus === "inProgress" ||
          (taskStatus === "created" && (
            <Card>
              <Card.Body className="d-flex justify-content-end">
                <Button
                  variant="success"
                  className="mx-4"
                  onClick={handleAcceptClick}
                  disabled={!canEditCreateRequest}
                >
                  Mark as Completed
                  {!canEditCreateRequest ? " (Permissions required)" : ""}
                </Button>

                <Button
                  variant="danger"
                  onClick={handleRejectClick}
                  disabled={!canEditCreateRequest}
                >
                  Close as Incomplete
                  {!canEditCreateRequest ? " (Permissions required)" : ""}
                </Button>
              </Card.Body>
            </Card>
          ))}
      </Container>

      {showAcceptModal && (
        <AcceptModal
          setTaskStatus={setTaskStatus}
          taskId={taskId}
          setShowAcceptModal={setShowAcceptModal}
          showAcceptModal={showAcceptModal}
          task={task}
        />
      )}

      {showRejectModal && (
        <RejectModal
          taskId={taskId}
          setTaskStatus={setTaskStatus}
          setShowRejectModal={setShowRejectModal}
          showRejectModal={showRejectModal}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
        />
      )}
    </>
  );
};

export default TaskCreateCatalogueRequest;
