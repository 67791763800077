import Container from "react-bootstrap/Container";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import InboundManifestComponent from "../../components/manifest/inbound-manifest";
import { useContext, useEffect, useState } from "react";
import { UserAuthenticatedContext } from "../../components/profile";
import { useParams } from "react-router-dom";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { Client } from "../../external";

export interface InboundManifestRow {
  id?: string;
}

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const ManifestPage = (): JSX.Element => {
  const [userToken, setUserToken] = useState<string>();
  const [clients, setClients] = useState<Client[]>();

  const clientsWorker = createClientsWorker();
  const { manifestId } = useParams();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext.token]);

  if (!userToken || !manifestId || !clients) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <InboundManifestComponent
      userToken={userToken}
      manifestId={manifestId}
      clients={clients}
    />
  );
};

export default withAuthenticationRequired(ManifestPage, {
  onRedirecting: () => <Loading />,
});
