import InputGroup from "react-bootstrap/InputGroup";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { RateFlatFee, RatePercentage } from "../../external";
import {
  ClientRateCardAuctionField,
  ClientRateCardRetailField,
  RateType,
} from "./types";

export interface SubClientRateTextBoxComponentProps<
  T extends
    | keyof typeof ClientRateCardAuctionField
    | keyof typeof ClientRateCardRetailField
> {
  defaultValue?: RateFlatFee | RatePercentage;
  field: T;
  childToParent: (
    field: T,
    valid: boolean,
    type?: RateType,
    value?: number,
    data?: RateFlatFee | RatePercentage
  ) => void;
  isSaving: boolean;
}

const SubClientRateTextBoxComponent = <
  T extends
    | keyof typeof ClientRateCardAuctionField
    | keyof typeof ClientRateCardRetailField
>(
  props: SubClientRateTextBoxComponentProps<T>
): JSX.Element => {
  const { defaultValue, childToParent, isSaving, field } = props;

  const [isValid, setIsValid] = useState(true);
  const [type, setType] = useState<"" | RateType.flatFee | RateType.percentage>(
    defaultValue?.type || ""
  );
  const [value, setValue] = useState<number | undefined>(
    defaultValue?.type === RateType.flatFee
      ? defaultValue.value.amount
      : defaultValue?.type === RateType.percentage
      ? defaultValue.value
      : undefined
  );

  useEffect(() => {
    if (type !== "" && value !== undefined && value !== null) {
      let data;
      switch (type) {
        case RateType.flatFee:
          data = {
            type: type,
            value: {
              amount: value,
              currency: {
                code: "GBP",
                base: 10,
                exponent: 2,
              },
              scale: 2,
            },
          };
          break;
        case RateType.percentage:
          data = {
            type: type,
            value: value,
          };
          break;
      }

      setIsValid(true);
      childToParent(field, true, type, value, data);
    } else {
      setIsValid(false);
      childToParent(field, false);
    }
  }, [field, type, value, childToParent]);

  const handleChangeType = (
    type: "" | RateType.flatFee | RateType.percentage
  ) => {
    if (type !== "") {
      setType(type);
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    // Ensure only numbers and up to 2 decimal places are allowed
    const regex = /^(0|\d+(\.\d{0,2})?)$/;
    const isValidInput = regex.test(inputValue);

    if (!isValidInput) {
      // If the input doesn't match the allowed format, set it as invalid
      setValue(undefined);
    } else {
      setValue(Number(inputValue));
    }
  };

  return (
    <InputGroup>
      <Form.Control
        as="select"
        defaultValue={type}
        onChange={(e) => {
          if (
            e.target.value === RateType.flatFee ||
            e.target.value === RateType.percentage
          ) {
            handleChangeType(e.target.value);
          }
        }}
        disabled={isSaving}
      >
        <option value="" disabled>
          Select Type
        </option>
        <option value={RateType.percentage}>%</option>
        <option value={RateType.flatFee}>£</option>
      </Form.Control>
      <FormControl
        type="number"
        onChange={handleChangeValue}
        placeholder="Enter value"
        step="0.01"
        isInvalid={!isValid} // Mark as invalid if input is not valid
        disabled={type === "" || isSaving}
        defaultValue={value}
      />
      {!isValid && (
        <Form.Control.Feedback type="invalid">
          Please enter a valid value. Whole numbers or numbers with 2 decimal
          places are valid.
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};

export default SubClientRateTextBoxComponent;
