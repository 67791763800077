import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";

import { CatalogItem } from "../../../external";
import AuditRow from "./audit-row";

const AuditHistoryComponent = ({
  catalogItem,
}: {
  catalogItem: CatalogItem;
}) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Audit</Accordion.Header>
        <Accordion.Body>
          <Table size="sm" striped hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {catalogItem.auditHistory.map((a, i) => (
                <AuditRow entry={a} key={`audit-${i}`} />
              ))}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AuditHistoryComponent;
