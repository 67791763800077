import InputGroup from "react-bootstrap/InputGroup";
import CurrencyInput from "react-currency-input-field";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const SearchCurrencyRangeComponent = ({
  disabled,
  valueMin,
  valueMax,
  childToParent,
}: {
  disabled?: boolean;
  childToParent: (min?: string, max?: string) => void;
  valueMin?: string | number;
  valueMax?: string | number;
}): JSX.Element => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [priceMin, setPriceMin] = useState<string | undefined>(
    valueMin?.toString()
  );
  const [priceMax, setPriceMax] = useState<string | undefined>(
    valueMax?.toString()
  );

  const handlePriceMin = (newPrice: string) => {
    setPriceMin(newPrice);
  };

  const handlePriceMax = (newPrice: string) => {
    setPriceMax(newPrice);
  };

  const handleButton = () => {
    if (priceMin && priceMax) {
      if (parseFloat(priceMin) > parseFloat(priceMax)) {
        setShowWarning(true);
        return;
      }
    }
    setShowWarning(false);
    setPriceMax("");
    setPriceMin("");
    childToParent(priceMin, priceMax);
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text>£</InputGroup.Text>
        <CurrencyInput
          disabled={disabled}
          allowNegativeValue={false}
          className={"form-control"}
          value={priceMin}
          decimalsLimit={2}
          onValueChange={(value: string | undefined) => {
            if (value === undefined) {
              handlePriceMin("");
              return;
            } else {
              handlePriceMin(value);
            }
          }}
        />
        <InputGroup.Text>-</InputGroup.Text>
        <InputGroup.Text>£</InputGroup.Text>
        <CurrencyInput
          disabled={disabled}
          allowNegativeValue={false}
          className={"form-control"}
          value={priceMax}
          decimalsLimit={2}
          onValueChange={(value: string | undefined) => {
            if (value === undefined) {
              handlePriceMax("");
              return;
            } else {
              handlePriceMax(value);
            }
          }}
        />
        <Button
          variant="outline-secondary"
          onClick={() => handleButton()}
          disabled={disabled}
        >
          Apply
        </Button>
      </InputGroup>
      {showWarning ? (
        <p className="text-danger">
          Minimum Price must be lower than Maximum Price
        </p>
      ) : null}
    </>
  );
};

export default SearchCurrencyRangeComponent;
