/// <reference types="react-scripts" />

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { UserAuthenticatedContext } from "../../components/profile";
import { Client } from "../../external";
import ClientComponent from "../../components/clients/client";

export type ClientComponentProps = {
  client: Client;
};

const NewClientPage = (): JSX.Element => {
  const navigate = useNavigate();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  return (
    <Container>
      <Row>
        <Col>
          <Button
            variant="secondary"
            className="mb-2"
            size="sm"
            onClick={() => {
              navigate("/clients");
            }}
          >
            Back to Clients
          </Button>
        </Col>
      </Row>
      <Row>
        <ClientComponent
          authenticatedUserToken={userAuthenticatedContext.token ?? ""}
        />
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(NewClientPage, {
  onRedirecting: () => <Loading />,
});
