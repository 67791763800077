import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { Client, ItemsPaginated } from "../external";
import { UserAuthenticatedContext } from "./profile";
import { TrashFill } from "react-bootstrap-icons";
import { useDebounceCallback } from "usehooks-ts";

// This defines the configuration for each input field
type SearchFieldConfig = {
  key: string;
  label: string;
  type: "text" | "radio" | "checkbox" | "date";
  options?: string[]; // Used for radio or checkbox groups
};

export interface Filters {
  count?: number;
  page?: number;
  sortField: string;
  sortDirection: "asc" | "desc";
}

interface DynamicSearchComponentProps<T, F extends Filters> {
  clients: Client[];
  isLoaded: boolean;
  setItems: (items: T[]) => void;
  setItemsLoaded: (loaded: boolean) => void;
  searchFields: SearchFieldConfig[];
  filters: F;
  setFilters: (filters: F) => void;
  onSearch: (authToken: string, filters: F) => Promise<ItemsPaginated<T>>;
  sortField: string;
  sortDirection: "asc" | "desc";
  setMeta: (meta: any) => void;
  setIsError: (isError: boolean) => void;
  setError: (error: string | null) => void;
}

const DynamicSearchComponent = <T, F extends Filters>({
  clients,
  isLoaded,
  setItems,
  setItemsLoaded,
  searchFields,
  filters,
  setFilters,
  onSearch,
  sortField,
  sortDirection,
  setMeta,
  setIsError,
  setError,
}: DynamicSearchComponentProps<T, F>) => {
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const [searchValues, setSearchValues] = useState<Record<string, any>>({});
  const fetchInProgressRef = useRef(false);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const getItems = useCallback(async () => {
    if (!userAuthenticatedContext.token) {
      return;
    }
    return await onSearch(userAuthenticatedContext.token, filters);
  }, [onSearch, userAuthenticatedContext.token, filters]);

  const debouncedSearchCallback = useDebounceCallback(() => {
    const newFilters = {
      count: 25,
      page: 1,
      sortField,
      sortDirection,
      clientId: selectedClient,
      ...searchValues,
    };

    setFilters(newFilters);
  }, 500); // Increased debounce time

  useEffect(() => {
    debouncedSearchCallback();

    return () => debouncedSearchCallback.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues, sortField, sortDirection, selectedClient]);

  useEffect(() => {
    const fetchItems = async () => {
      if (fetchInProgressRef.current || !userAuthenticatedContext.token) {
        return;
      }
      fetchInProgressRef.current = true;

      try {
        const items = await getItems();
        if (items) {
          setItems(items.data);
          setMeta(items.meta);
        }
      } catch (error) {
        setIsError(true);
        setError(error.message || "Unknown error");
      } finally {
        fetchInProgressRef.current = false;
        setItemsLoaded(true);
      }
    };

    fetchItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]); // Limit dependency to only `filters`

  const handleInputChange = (key: string, value: string | boolean) => {
    setSearchValues((prev) => {
      const newSearchValues = {
        ...prev,
        [key]: value,
      };
      if (value === "") {
        delete newSearchValues[key];
      }

      return newSearchValues;
    });
  };

  const handleClear = () => {
    setSearchValues({});
    setSelectedClient(undefined);
  };

  const handleClientChange = (clientId?: string) => {
    setSelectedClient(clientId);
  };

  const handleClientClear = () => {
    setSelectedClient(undefined);
  };

  const renderInputField = (field: SearchFieldConfig) => {
    switch (field.type) {
      case "text":
        return (
          <Form.Control
            type="text"
            value={searchValues[field.key] || ""}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
            disabled={!isLoaded}
          />
        );
      case "radio":
        return (
          <div>
            {field.options?.map((option) => (
              <Form.Check
                key={option}
                inline
                label={option}
                type="radio"
                name={field.key}
                value={option}
                checked={searchValues[field.key] === option}
                onChange={(e) => handleInputChange(field.key, e.target.value)}
                disabled={!isLoaded}
              />
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div>
            {field.options?.map((option) => (
              <Form.Check
                key={option}
                inline
                label={option}
                type="checkbox"
                value={option}
                checked={searchValues[field.key]?.includes(option) || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...(searchValues[field.key] || []), option]
                    : searchValues[field.key].filter(
                        (val: string) => val !== option
                      );
                  handleInputChange(field.key, newValue);
                }}
                disabled={!isLoaded}
              />
            ))}
          </div>
        );
      case "date":
        return (
          <Form.Control
            type="date"
            value={searchValues[field.key] || ""}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
            disabled={!isLoaded}
          />
        );
      default:
        return null;
    }
  };

  const getClientDropdownTitle = () => {
    const client = clients.find((c) => c.clientId === selectedClient);

    if (client) {
      return `${client.clientName} (${client.clientId})`;
    } else {
      return "All Clients";
    }
  };

  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} className="d-flex">
              <div
                onClick={handleClear}
                style={{ cursor: "pointer", marginBottom: "10px" }}
              >
                <TrashFill /> Clear All
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <Form.Label>Client</Form.Label>
              <InputGroup className="mb-3">
                <DropdownButton
                  disabled={!isLoaded}
                  variant="outline-secondary"
                  title={getClientDropdownTitle()}
                >
                  <Dropdown.Item key="all" onClick={() => handleClientClear()}>
                    <b>All Clients</b>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {clients
                    .sort((a, b) => a.clientName.localeCompare(b.clientName))
                    .map((client) => (
                      <Dropdown.Item
                        key={client.clientId}
                        onClick={() => handleClientChange(client.clientId)}
                      >
                        {client.clientName} ({client.clientId})
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
          <hr />

          {/* Dynamically render search fields */}
          {searchFields.map((field) => (
            <Row key={field.key}>
              <Col xs={12}>
                <Form.Label>{field.label}</Form.Label>
                {renderInputField(field)}
              </Col>
            </Row>
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DynamicSearchComponent;
