import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InventoryItem } from "../../external";
import Table from "react-bootstrap/Table";
import ImageCarousel from "../image-carousel";

import YieldCalculation from "../yield/yield-calculation";
import { UserAuthenticatedContext } from "../profile";
import { useMediaQuery } from "react-responsive";

interface InventoryInboundModalProps {
  index: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  values: InventoryItem[];
}

const InventoryInboundModal: React.FC<InventoryInboundModalProps> = ({
  index,
  isModalOpen,
  handleCloseModal,
  values,
}) => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-md-center">
          {values[index].images !== undefined &&
          values[index].images.length > 0 ? (
            <ImageCarousel
              imageUrls={[...values[index].images.map((x) => x.imageUrl)]}
              maxWidth={"300px"}
            />
          ) : (
            <p>No Images</p>
          )}
        </Row>
        {isMobile && (
          <Row className="mx-3 mb-3">
            <Col>
              <YieldCalculation
                inventoryId={values[index].id}
                authenticatedUserToken={
                  userAuthenticatedContext.token as string
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Table striped bordered hover className="mx-3">
              <tbody>
                <tr>
                  <td style={{ textAlign: "right" }}>Item ID</td>
                  <td>{values[index].id}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Legacy PM ID</td>
                  <td>{values[index].legacyPMID}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Client</td>
                  <td>{values[index].clientId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Sub Client</td>
                  <td>{values[index].subClientId}</td>
                </tr>

                <tr>
                  <td style={{ textAlign: "right" }}>Catalogue Item ID</td>
                  <td>{values[index].catalogItemId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Client Item ID</td>
                  <td>{values[index].clientItemId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Load Reference ID</td>
                  <td>{values[index].loadReferenceId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Expected Arrival Date</td>
                  <td>{values[index].expectedArrivalDate}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Pallet</td>
                  <td>{values[index].pallet}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Quantity</td>
                  <td>{values[index].quantity}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Description</td>
                  <td>{values[index].description}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Boxes</td>
                  <td>{values[index].boxes}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Exit</td>
                  <td>{values[index].exit}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Comments</td>
                  <td>{values[index].comments}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Arrived?</td>
                  <td>{values[index].arrivalConfirmed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Date Arrived</td>
                  <td>{values[index].arrivalDate}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Manual Entry?</td>
                  <td>{values[index].manualEntry ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          {!isMobile && (
            <Col style={{ maxWidth: "20%", flex: "0 0 20%" }}>
              <YieldCalculation
                inventoryId={values[index].id}
                authenticatedUserToken={
                  userAuthenticatedContext.token as string
                }
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryInboundModal;
