import React, { useContext, useEffect, useState } from "react";
import { Task, User } from "../../external/task-component/openapi";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { formatDateTimeFromTimestamp } from "../../helpers/datetime";
import ComparisonTable from "./catalogue-change-table";
import { CatalogItem as ExternalCatalogItem } from "../../external";
import { getCatalogItem } from "../../workers/catalog";
import { UserAuthenticatedContext } from "../profile";
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import ImageCarousel from "../image-carousel";
import {
  CloseGlobalSidebarEvent,
  OpenGlobalSidebarEvent,
} from "../globaloffcanvas/events";
import Button from "react-bootstrap/Button";
import ConfirmAssigneeModal from "./confirm-assignee-modal";
import { updateAssignedTo } from "../../workers/task";
import axios from "axios";
import TaskNotes from "./task-notes";
import TaskCreateCatalogueRequest from "./task-create-catalogue-request";
import { Link } from "react-router-dom";

interface TaskItemProps {
  task: Task;
  assignees: { email: string; name: string }[];
}

export const getTypeText = (type: string | undefined) => {
  switch (type) {
    case "catalogueChangeRequest":
      return "Catalogue Change";
    case "catalogueDataRequest":
      return "Catalogue Data Request";
    default:
      return type;
  }
};

// to have different colour cards for different task types doesnt have to stay that colour, just an example
export const getHeaderColor = (type: string) => {
  switch (type) {
    case "catalogueChangeRequest":
      return "#9bd8bc";
    case "catalogueDataRequest":
      return "#9d9bd8"; // equivalent to bg-success
    case "catalogueCreateRequest":
      return "#d89bb7"; // equivalent to bg-danger
    case "manifestedItemUpdateRequest":
      return "#d5d89b"; // equivalent to bg-danger
    default:
      return "#cccccc"; // equivalent to bg-primary
  }
};

const TaskItem: React.FC<TaskItemProps> = ({ task, assignees }) => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const [isHovered, setIsHovered] = useState(false);

  const [currentCatalogueState, setCurrentCatalogueState] =
    useState<ExternalCatalogItem>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAssignConfirmModal, setShowAssignConfirmModal] =
    useState<boolean>(false);
  const [assignedSelected, setAssignedSelected] = useState<{
    email: string;
    name: string;
  }>();
  const [taskAssignee, setTaskAssignee] = useState<User | undefined>(
    task.assignedTo ?? undefined
  );
  const [taskStatus, setTaskStatus] = useState<string | undefined>(task.status);
  const [taskReason, setTaskReason] = useState<string>(
    task.actioned?.reason || ""
  );

  const isInProgress = ["rejected", "completed"].includes(taskStatus || "");

  const cardActions = (task: Task) => {
    switch (task.type) {
      case "catalogueChangeRequest":
        return (
          <>
            <Row className="mt-3">
              <Col>
                {!isExpanded ? (
                  <>
                    <PlusCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px" }}
                      onClick={handleExpandClick}
                    />
                    <> View Details</>
                  </>
                ) : (
                  <>
                    <DashCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px" }}
                      onClick={handleHideClick}
                    />
                    <> Close Details</>
                  </>
                )}
              </Col>
            </Row>
            {isExpanded && currentCatalogueState && task.data && (
              <ComparisonTable
                currentCatalogueState={currentCatalogueState}
                proposedCatalogueState={task.data}
                setTaskStatus={setTaskStatus}
                setTaskReason={setTaskReason}
                taskId={task.taskId}
                taskStatus={taskStatus}
              />
            )}
          </>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case "catalogueDataRequest":
        return (
          <>
            <Row className="mt-3">
              <Col>
                {!isExpanded ? (
                  <>
                    <PlusCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px" }}
                      onClick={handleExpandClick}
                    />
                    <> View Details</>
                  </>
                ) : (
                  <>
                    <DashCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px" }}
                      onClick={handleHideClick}
                    />
                    <> Close Details</>
                  </>
                )}
              </Col>
            </Row>
            {isExpanded && task.notes && (
              <TaskNotes
                notes={task.notes}
                setTaskStatus={setTaskStatus}
                taskId={task.taskId}
                taskStatus={taskStatus}
                setTaskReason={setTaskReason}
              ></TaskNotes>
            )}
          </>
        );
      case "catalogueCreateRequest":
        return (
          <TaskCreateCatalogueRequest
            taskId={task.taskId}
            task={task}
            taskStatus={taskStatus}
            setTaskReason={setTaskReason}
            setTaskStatus={setTaskStatus}
          />
        );
      case "manifestedItemUpdateRequest":
        return (
          <>
            <Row className="mt-3">
              <Col>
                <a
                  href={`/catalog/item/${task.data.itemId}?origin=task:${task.taskId}`}
                  style={{
                    marginLeft: "10px",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  View Catalogue Item
                </a>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {!isExpanded ? (
                  <>
                    <PlusCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px", marginBottom: "10px" }}
                      onClick={handleExpandClick}
                    />
                    <> View Details</>
                  </>
                ) : (
                  <>
                    <DashCircleFill
                      size={25}
                      color="blue"
                      style={{ marginLeft: "10px", marginBottom: "10px" }}
                      onClick={handleHideClick}
                    />
                    <> Close Details</>
                  </>
                )}
              </Col>
            </Row>
            {isExpanded && task.notes && (
              <TaskNotes
                notes={task.notes}
                setTaskStatus={setTaskStatus}
                taskId={task.taskId}
                taskStatus={taskStatus}
                setTaskReason={setTaskReason}
              />
            )}
          </>
        );
      default:
        // Handle other cases or provide a default behavior
        return task.taskDescription;
    }
  };

  const cardDetails = (task: Task) => {
    switch (task.type) {
      case "catalogueChangeRequest":
      case "catalogueDataRequest":
        return (
          <>
            <Row>
              <Col xs={12} sm={12} md={9} xxl={9}>
                <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                  {task.data.name}
                </div>
                <div>Client ID: {task.data.clientId}</div>
                <div>Client Item ID: {task.data.clientItemId}</div>
                <Button
                  className="m-2"
                  variant="success"
                  onClick={() => {
                    window.dispatchEvent(CloseGlobalSidebarEvent());

                    window.dispatchEvent(
                      OpenGlobalSidebarEvent("catalog", task.data.itemId)
                    );
                  }}
                >
                  View Existing Item Data
                </Button>
              </Col>
              <Col xs={12} sm={12} md={3} xxl={3}>
                {task.data.clientImageLinks.length > 0 && (
                  <ImageCarousel
                    imageUrls={[...task.data.clientImageLinks]}
                    maxWidth={"300px"}
                  />
                )}
              </Col>
            </Row>
          </>
        );
      case "catalogueCreateRequest":
        return (
          <>
            <Row className="pb-3">
              <Col xs={12} sm={12} md={9} xxl={9}>
                <div>Item Name: {task.data.item.title}</div>
                <Row>
                  <div>
                    Load Reference: {task.data.loadRef} -{" "}
                    {task.data.arrivalDate}
                  </div>
                  <div></div>
                </Row>
                <div>Client ID: {task.data.clientId}</div>
                <div>Arrival Date: {task.data.arrivalDate}</div>
                {task.createdCatalogItemId ? (
                  <div>
                    Created:{" "}
                    <strong>
                      <Link to={`/catalog/item/${task.createdCatalogItemId}`}>
                        {task.createdCatalogItemId}
                      </Link>
                    </strong>
                  </div>
                ) : (
                  <div> {task.data.status}</div>
                )}
              </Col>
            </Row>
          </>
        );
      default:
        // Handle other cases or provide a default behavior
        return task.taskDescription;
    }
  };

  const handleExpandClick = async () => {
    if (
      userAuthenticatedContext &&
      userAuthenticatedContext.token &&
      task.taskId &&
      task.type === "catalogueChangeRequest"
    ) {
      try {
        const catalogueItem = await getCatalogItem(
          userAuthenticatedContext.token,
          task.data.itemId
        );
        setCurrentCatalogueState(catalogueItem);
        setIsExpanded(true);
      } catch (error) {
        throw new Error(
          "Failed to get current catalogue item: " + task.data.itemId
        );
      }
    } else {
      setIsExpanded(true);
    }
  };

  const handleHideClick = () => {
    setIsExpanded(false);
  };

  const assigneeNameToObj: { [key: string]: { email: string; name: string } } =
    assignees.reduce((acc, assignee) => {
      acc[assignee.name] = assignee;
      return acc;
    }, {} as { [key: string]: { email: string; name: string } });

  const handleSelectAssignee = (name: string) => {
    const assignee = assigneeNameToObj[name];
    if (assignee) {
      setAssignedSelected(assignee);
    }
    setShowAssignConfirmModal(true);
  };

  const handleConfirmAssignee = async (name: string) => {
    const assignee = assigneeNameToObj[name];
    if (userAuthenticatedContext && userAuthenticatedContext.token) {
      try {
        await updateAssignedTo(
          userAuthenticatedContext.token,
          task.taskId,
          assignee
        );
        setTaskAssignee(assignee);
        setShowAssignConfirmModal(false);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw new Error(
            "Error Assigning Task: " + error?.response?.data?.message
          );
        } else {
          throw new Error("Error Assigning Task: An unknown error occurred.");
        }
      }
    }
  };

  useEffect(() => {
    if (task.assignedTo) {
      setTaskAssignee(task.assignedTo);
    }
  }, [setTaskAssignee, task.assignedTo]);

  return (
    <>
      <Card
        className="mb-3 shadow-sm"
        border={isHovered ? "primary" : undefined}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Card.Header
          style={{ backgroundColor: getHeaderColor(task.type), color: "black" }}
        >
          <Row>
            <Col>{`${task.taskId} - ${task.taskTitle}`}</Col>
            <Col className="text-dark text-end">
              <Dropdown
                onSelect={(key) => key !== null && handleSelectAssignee(key)}
              >
                <Dropdown.Toggle
                  disabled={isInProgress}
                  variant="secondary"
                  id="assigned-to-dropdown"
                >
                  {taskAssignee?.name ?? "Unassigned"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {assignees.map((assignee, index) => (
                    <Dropdown.Item key={index} eventKey={assignee.name}>
                      {assignee.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {assignedSelected && (
                <ConfirmAssigneeModal
                  user={assignedSelected}
                  show={showAssignConfirmModal}
                  handleConfirmAssignee={handleConfirmAssignee}
                  setShowAssignConfirmModal={setShowAssignConfirmModal}
                />
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col
              className="text-muted"
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              <div>{cardDetails(task)}</div>
              <div>{cardActions(task)}</div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="text-muted">
          <Row>
            <Col>{formatDateTimeFromTimestamp(task.createdAtTimestamp)}</Col>
            <Col className="text-success text-end font-weight-bold">
              {taskStatus}
              {["cancelled", "rejected"].includes(taskStatus as string) &&
                ` - ${taskReason}`}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default TaskItem;
