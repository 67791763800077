import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Notes } from "../../external/task-component/openapi";
import AcceptModal from "./accept-modal";
import RejectModal from "./reject-modal";
import NotesModal from "./notes-modal";
import { formatDateTimeFromTimestamp } from "../../helpers/datetime";

interface Props {
  notes: Notes[];
  taskId: string;
  setTaskStatus: (status: string) => void;
  setTaskReason: (status: string) => void;
  taskStatus: string | undefined;
}

const TaskNotes: React.FC<Props> = ({
  notes,
  taskId,
  setTaskStatus,
  setTaskReason,
  taskStatus,
}) => {
  const [taskNotes, setTaskNotes] = useState<Notes[] | undefined>(notes); // [notes, setNotes
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  useEffect(() => {
    setTaskReason(rejectReason);
    // eslint-disable-next-line
  }, [rejectReason]);

  const handleAcceptClick = () => {
    setShowAcceptModal(true);
  };

  const handleRejectClick = () => {
    setShowRejectModal(true);
  };

  const handleNoteModal = () => {
    setShowNoteModal(true);
  };

  return (
    <Container className="mx-0">
      {taskNotes !== undefined &&
        taskNotes.map((note, index) => (
          <Card key={index} className="mb-3">
            <Card.Header>
              <strong>{note.user.name}</strong> - {note.user.email}
              <br />
              <small className="float-right">
                {formatDateTimeFromTimestamp(note.timestamp)}
              </small>
            </Card.Header>
            <Card.Body>
              <Card.Text>{note.note}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      {taskStatus === "inProgress" ||
        (taskStatus === "created" && (
          <Card style={{ border: "none" }}>
            <Card.Body className="d-flex justify-content-end">
              <Button variant="primary" onClick={handleNoteModal}>
                Add Note
              </Button>
              <Button
                variant="success"
                className="mx-4"
                onClick={handleAcceptClick}
              >
                Accept
              </Button>
              <Button variant="danger" onClick={handleRejectClick}>
                Reject
              </Button>
            </Card.Body>
          </Card>
        ))}

      {showNoteModal && (
        <NotesModal
          taskId={taskId}
          setShowNotesModal={setShowNoteModal}
          showNotesModal={showNoteModal}
          setTaskNotes={setTaskNotes}
        />
      )}

      {showAcceptModal && (
        <AcceptModal
          setTaskStatus={setTaskStatus}
          taskId={taskId}
          setShowAcceptModal={setShowAcceptModal}
          showAcceptModal={showAcceptModal}
        />
      )}

      {showRejectModal && (
        <RejectModal
          taskId={taskId}
          setTaskStatus={setTaskStatus}
          setShowRejectModal={setShowRejectModal}
          showRejectModal={showRejectModal}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
        />
      )}
    </Container>
  );
};

export default TaskNotes;
