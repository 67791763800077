import InputGroup from "react-bootstrap/InputGroup";
import CurrencyInput from "react-currency-input-field";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const SearchCurrencyFixedComponent = ({
  disabled,
  value,
  childToParent,
}: {
  disabled?: boolean;
  childToParent: (value?: string) => void;
  value?: string | number;
}): JSX.Element => {
  const [price, setPrice] = useState<string | undefined>(value?.toString());

  const handlePrice = (newPrice: string) => {
    setPrice(newPrice);
  };

  const handleButton = () => {
    setPrice("");
    childToParent(price);
  };

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text>£</InputGroup.Text>
      <CurrencyInput
        disabled={disabled}
        allowNegativeValue={false}
        className={"form-control"}
        value={price}
        decimalsLimit={2}
        onValueChange={(value: string | undefined) => {
          if (value === undefined) {
            handlePrice("");
            return;
          } else {
            handlePrice(value);
          }
        }}
      />
      <Button
        variant="outline-secondary"
        onClick={() => handleButton()}
        disabled={disabled}
      >
        Apply
      </Button>
    </InputGroup>
  );
};

export default SearchCurrencyFixedComponent;
