import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { PostHogProvider } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
// optionally use the query-string parse / stringify functions to
// handle more advanced cases than URLSearchParams supports.
import QueryString from "query-string";

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export type SystemThemes = "dark" | "light" | "system";
export const ThemeContext = React.createContext<SystemThemes>("dark");

const taskScopeActions = ["read", "edit", "create", "delete"];
const taskScopeTypes = ["change-request", "data-request", "create-request"];
const taskScopes = taskScopeActions.flatMap((action) =>
  taskScopeTypes.map((type) => `${action}:task-item:${type}`)
);
const catalogueScopes = [
  "read:catalogue-item",
  "edit:catalogue-item",
  "create:catalogue-item",
  "delete:catalogue-item",
];
const inventoryScopes = [
  "read:inventory-item",
  "edit:inventory-item",
  "create:inventory-item",
];
const clientScopes = [
  "read:client-item",
  "edit:client-item",
  "create:client-item",
];
const gradingScopes = [
  "read:grading-item",
  "edit:grading-item",
  "create:grading-item",
];
const financeScopes = ["upload:finance-data", "read:finance-data"];
const toolScopes = ["use:image-downloader", "use:reporting-data-uploader"];

const apiScopes = [
  "name",
  "profile",
  "email",
  "offline_access", // Required for refresh tokens to work properly
  ...catalogueScopes,
  ...inventoryScopes,
  ...toolScopes,
  ...clientScopes,
  ...taskScopes,
  ...financeScopes,
  ...gradingScopes,
]
  .flat()
  .join(" ");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const auth0ProviderOptions: Auth0ProviderOptions = {
  useRefreshTokens: true,
  authorizeTimeoutInSeconds: 30,
  cacheLocation: "localstorage",
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID as string,
  httpTimeoutInSeconds: 30,
  authorizationParams: {
    audience: "https://circle.dev.clearcycle.co.uk",
    scope: apiScopes,
    redirect_uri: window.location.origin,
  },
};

const posthogOptions: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  ui_host: "https://eu.i.posthog.com",
  // Disable session recording for development
  disable_session_recording: true,
  loaded: (posthog) => {
    if (process.env.NODE_ENV !== "development") {
      posthog.startSessionRecording({});
    }
  },
  // Session recording options
  // Inspired by https://posthog.com/docs/session-replay/privacy
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      const maskTypes: string[] = ["email", "password"];
      const shouldMask =
        maskTypes.some(
          (type) => (element?.getAttribute("type")?.indexOf(type) ?? -1) > -1
        ) || element?.hasAttribute("data-masked");
      if (shouldMask) {
        return "*".repeat(text.length);
      }
      return text;
    },
    maskTextSelector: "*",
    maskTextFn(text) {
      // A simple email regex - you may want to use something more advanced
      const emailRegex = /(\S+)@(\S+\.\S+)/g;
      return text.replace(emailRegex, (match, g1, g2) => {
        // Replace each email with asterisks - ben@posthog.com becomes ***@***********
        return "*".repeat(g1.length) + "@" + "*".repeat(g2.length);
      });
    },
  },
};

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={posthogOptions}
    >
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: QueryString.parse,
            objectToSearchString: QueryString.stringify,
          }}
        >
          <Auth0ProviderWithRedirectCallback {...auth0ProviderOptions}>
            <App auth0Options={auth0ProviderOptions} />
          </Auth0ProviderWithRedirectCallback>
        </QueryParamProvider>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
