import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Search } from "react-bootstrap-icons";
import EbaySearchComponent from "./ebay-category-search";
import { EbayCategory } from "../../workers/ebay-categories";

const EbayComponent = ({
  isSaving,
  viewOnly,
  handleTextboxChange,
  ebayCategoryOneValue,
  ebayCategoryTwoValue,
  offCanvas,
  offCanvasSize,
  childToParentEbayCategoryTwo,
  childToParentEbayCategoryOne,
}: {
  isSaving: boolean;
  viewOnly: boolean;
  handleTextboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ebayCategoryOneValue: string;
  ebayCategoryTwoValue: string;
  offCanvas: boolean;
  offCanvasSize: number;
  childToParentEbayCategoryOne: (value: string) => void;
  childToParentEbayCategoryTwo: (value: string) => void;
}) => {
  const [searchTextOne, setSearchTextOne] = useState("");
  const [searchTextTwo, setSearchTextTwo] = useState("");
  const [searchBoxOneVisible, setSearchBoxOneVisible] = useState(false);
  const [searchBoxTwoVisible, setSearchBoxTwoVisible] = useState(false);

  const handleEbaySearchOneDropdownChange = (value?: string) => {
    if (!value) {
      childToParentEbayCategoryOne("");
    } else {
      const json: EbayCategory = JSON.parse(value);
      childToParentEbayCategoryOne(json.id.toString());
    }
    setSearchBoxOneVisible(false);
  };

  const handleEbaySearchTwoDropdownChange = (value?: string) => {
    if (!value) {
      childToParentEbayCategoryTwo("");
    } else {
      const json: EbayCategory = JSON.parse(value);
      childToParentEbayCategoryTwo(json.id.toString());
    }
    setSearchBoxTwoVisible(false);
  };

  return (
    <Form.Group>
      <Row>
        <Col sm={offCanvas ? offCanvasSize : 12}>
          <Form.Group>
            <InputGroup className="full-width">
              <Row className="d-flex align-items-center">
                <InputGroup className="d-flex align-items-center">
                  <InputGroup.Text id="ebayCategory">
                    Category 1
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="ebayCategory"
                    aria-describedby="ebayCategory"
                    className="flex-grow-1 rounded-end"
                    type="number"
                    name="ebayCategory1"
                    value={ebayCategoryOneValue}
                    onChange={handleTextboxChange}
                    disabled={isSaving || viewOnly}
                  />
                  {!viewOnly && (
                    <Search
                      color="blue"
                      style={{ margin: "1rem" }}
                      onClick={() => setSearchBoxOneVisible((prev) => !prev)}
                    />
                  )}
                </InputGroup>
              </Row>
              <Row className="d-flex align-items-center">
                {searchBoxOneVisible && (
                  <EbaySearchComponent
                    isSaving={isSaving}
                    viewOnly={viewOnly}
                    childToParent={handleEbaySearchOneDropdownChange}
                    searchTerm={searchTextOne}
                    setSearchTerm={setSearchTextOne}
                  ></EbaySearchComponent>
                )}
              </Row>
            </InputGroup>
            <InputGroup>
              <Row className="d-flex align-items-center">
                <InputGroup className="d-flex align-items-center">
                  <InputGroup.Text id="ebayCategory">
                    Category 2
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="ebayCategory"
                    aria-describedby="ebayCategory"
                    className="flex-grow-1 rounded-end"
                    name="ebayCategory2"
                    type="number"
                    value={ebayCategoryTwoValue}
                    onChange={handleTextboxChange}
                    disabled={isSaving || viewOnly}
                  />
                  {!viewOnly && (
                    <Search
                      color="blue"
                      style={{ margin: "1rem" }}
                      onClick={() => setSearchBoxTwoVisible((prev) => !prev)}
                    />
                  )}
                </InputGroup>
              </Row>
              <Row className="d-flex align-items-center">
                {searchBoxTwoVisible && (
                  <EbaySearchComponent
                    isSaving={isSaving}
                    viewOnly={viewOnly}
                    childToParent={handleEbaySearchTwoDropdownChange}
                    searchTerm={searchTextTwo}
                    setSearchTerm={setSearchTextTwo}
                  ></EbaySearchComponent>
                )}
              </Row>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default EbayComponent;
