export type FinanceUploadOptions = {
  name: string;
  value: string;
};

export const financeUploadOptions: FinanceUploadOptions[] = [
  {
    name: "Trading DB",
    value: "trading-db",
  },
  {
    name: "Trading DB Post Reconciliation",
    value: "trading-db-post-rec",
  },
];
