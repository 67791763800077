import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { CountComponentProps } from "./types";
import { useEffect } from "react";
import { useGradingAndRefurbState } from "./persist-helpers";

const CountComponent = ({
  desiredCount,
  limit,
  stepOutputKey,
  refurbIdentifier,
  setStepCompleted,
  title,
  description,
  minimum,
  subtitle,
}: CountComponentProps): JSX.Element => {
  if (!limit) {
    limit = 10;
  }

  if (minimum && limit && minimum > limit) {
    throw new Error("Minimum cannot be greater than limit");
  }

  const [selectedCount, setSelectedCount] = useGradingAndRefurbState<number>(
    stepOutputKey,
    refurbIdentifier,
    undefined
  );

  const buttonVariant = (buttonNumber: number) => {
    if (!selectedCount) {
      return "primary";
    }

    if (buttonNumber === selectedCount) {
      return "success";
    }
    return "outline-primary";
  };

  const onSelectCount = (count: number) => {
    setSelectedCount(count);
  };

  useEffect(() => {
    if (!!selectedCount) {
      setStepCompleted(true);
    } else {
      setStepCompleted(false);
    }
  }, [selectedCount, setStepCompleted]);

  const getButtons = (l: number) => {
    const buttons: JSX.Element[] = [];
    for (let i = minimum ?? 1; i < l + 1; i++) {
      buttons.push(
        <Col key={`count-${i + 1}`}>
          <Button
            className="m-2 w-100"
            size="lg"
            variant={buttonVariant(i)}
            onClick={() => onSelectCount(i)}
          >
            <b>{i}</b>
          </Button>
        </Col>
      );
    }

    return buttons;
  };

  return (
    <Container>
      <h3>{title}</h3>
      {subtitle ? <b>{subtitle}</b> : null}
      {description ? <p>{description}</p> : null}
      {desiredCount && desiredCount > 0 ? (
        <>
          <br />
          <i>Catalogue expects {desiredCount} boxes</i>
        </>
      ) : null}
      <Row
        xs={1}
        sm={limit < 2 ? limit : 2}
        lg={limit < 3 ? limit : 3}
        xl={limit < 4 ? limit : 4}
        xxl={limit < 4 ? limit : 4}
      >
        {getButtons(limit)}
      </Row>
    </Container>
  );
};

export default CountComponent;
