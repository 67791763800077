import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";

const TraderPage = (): JSX.Element => {
  const appLink = `https://script.google.com/macros/s/AKfycbwQkSrzSNZQnY3OYPfMUzmEZhZer4quY_3dnBuxv8XXG7vwcmi_DN7dcIcY-3dpy6G0mw/exec`;

  return (
    <GoogleAppsScriptIframe
      src={appLink}
      title="Trader Orders"
      style={{ width: "100%", height: "1000px" }}
    />
  );
};
export default TraderPage;
