import { Dispatch, SetStateAction } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

export const ClearCyclePersistPrefix =
  process.env.REACT_LOCALSTORAGE_PERSIST_PREFIX ?? "cc-refurb";

const createGradingAndRefurbStateKey = (refurbIdentifier: string) =>
  `${ClearCyclePersistPrefix}__data-${refurbIdentifier}`;

export const getSelectedIndexLocalStorageKey = (refurbIdentifier: string) =>
  `${ClearCyclePersistPrefix}__curIndex-${refurbIdentifier}`;

export const getCompletedStepsLocalStorageKey = (refurbIdentifier: string) =>
  `${ClearCyclePersistPrefix}__completedSteps-${refurbIdentifier}`;

export const loadGradingAndRefurbStateIntoLocalStorage = (
  refurbIdentifier: string,
  state: Record<string, any>,
  override = false
) => {
  const localStorageKey = createGradingAndRefurbStateKey(refurbIdentifier);

  // Only set the state if it doesn't exist or we are overriding it.
  if (!window.localStorage.getItem(localStorageKey) || override) {
    window.localStorage.setItem(localStorageKey, JSON.stringify(state));
  }
};

/**
 * Use state for the Grading and Refurb, for a 1 level nested field.
 * @param localStorageKey Key used to identify the field in the stored state.
 * @param refurbIdentifier The identifier for this specific grading/refurb.
 * @param levelOneKey The key for the option of the step (e.g. "Cracked")
 * @param defaultValue The default value. Defaults to false.
 * @returns [T, setter]
 */
export const useGradingAndRefurbStateNestedField = <T>(
  localStorageKey: string,
  refurbIdentifier: string,
  levelOneKey: string,
  defaultValue: T
): readonly [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useGradingAndRefurbState<any>(
    localStorageKey,
    refurbIdentifier
  );

  const updateFunc: Dispatch<SetStateAction<T>> = (value) => {
    const newValue =
      value instanceof Function ? value(state[localStorageKey]) : value;

    setState({
      ...(state ? state : {}),
      [levelOneKey]: newValue,
    });
  };

  if (state && state[levelOneKey] && state[levelOneKey]) {
    return [state[levelOneKey], updateFunc];
  } else {
    return [defaultValue, updateFunc];
  }
};

/**
 * Use state for the Grading and Refurb, for a 2 level nested field.
 * @param localStorageKey Key used to identify the field in the stored state.
 * @param refurbIdentifier The identifier for this specific grading/refurb.
 * @param levelOneKey The key for the option of the step (e.g. "Scratch")
 * @param levelTwoKey The key for the result of the step (e.g. "Minor" or "Major")
 * @param defaultValue The default value. Defaults to 0.
 * @returns [T, setter]
 */
export const useGradingAndRefurbStateTwoLevelNestedField = <T>(
  localStorageKey: string,
  refurbIdentifier: string,
  levelOneKey: string,
  levelTwoKey: string,
  defaultValue: T
): readonly [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useGradingAndRefurbState<any>(
    localStorageKey,
    refurbIdentifier
  );

  const updateFunc: Dispatch<SetStateAction<T>> = (value) => {
    const newValue =
      value instanceof Function ? value(state[localStorageKey]) : value;

    setState({
      ...(state ? state : {}),
      [levelOneKey]: {
        ...(state && state[levelOneKey] ? state[levelOneKey] : {}),
        [levelTwoKey]: newValue,
      },
    });
  };

  if (state && state[levelOneKey] && state[levelOneKey][levelTwoKey]) {
    return [state[levelOneKey][levelTwoKey], updateFunc];
  } else {
    return [defaultValue, updateFunc];
  }
};

/**
 * Use state for the Grading and Refurb information.
 * @param localStorageKey Key used to identify the field in the stored state.
 * @param refurbIdentifier The identifier for this specific grading/refurb.
 * @param defaultValue Default value for the state.
 * @returns [T, setter]
 */
export const useGradingAndRefurbState = <T>(
  localStorageKey: string,
  refurbIdentifier: string,
  defaultValue?: T
): readonly [T, Dispatch<SetStateAction<T>>] => {
  const key = createGradingAndRefurbStateKey(refurbIdentifier);
  const [stateValue, update] = useLocalStorage<Record<string, T>>(key, {});

  if (stateValue[localStorageKey] === undefined) {
    stateValue[localStorageKey] = (defaultValue ?? undefined) as T;
  }

  const updateFunc: Dispatch<SetStateAction<T>> = (value) => {
    // Allow value to be a function so we have the same API as useState
    const newValue =
      value instanceof Function ? value(stateValue[localStorageKey]) : value;

    stateValue[localStorageKey] = newValue;
    update(stateValue);
  };

  return [stateValue[localStorageKey], updateFunc];
};

/**
 * Allows reading of the grading and refurb state for a particular field.
 * @param refurbIdentifier The identifier for this specific grading/refurb.
 * @param localStorageKey Key used to identify the field in the stored state.
 * @returns State
 */
export const useReadGradingAndRefurbState = <T>(
  refurbIdentifier: string,
  localStorageKey: string
): T | null => {
  const key = createGradingAndRefurbStateKey(refurbIdentifier);
  const v = useReadLocalStorage<Record<string, T>>(key);

  return v ? v[localStorageKey] : null;
};

/**
 * Allows reading the entire grading and refurb state.
 * @param refurbIdentifier The identifier for this specific grading/refurb.
 * @returns State object.
 */
export const useReadAllGradingAndRefurbState = (refurbIdentifier: string) => {
  const key = createGradingAndRefurbStateKey(refurbIdentifier);
  return useReadLocalStorage<Record<string, any>>(key);
};
