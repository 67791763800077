import axios from "axios";

export const retrieveTemplate = async (templateLabel: string) => {
  try {
    const response = await axios.get<string>(templateLabel);
    return response.data;
  } catch (error) {
    console.error("Error fetching template:", error);
    throw new Error("Error fetching template");
  }
};
