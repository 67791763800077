import { withAuthenticationRequired } from "@auth0/auth0-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Loading from "../components/loading";
import { useLocalStorage } from "usehooks-ts";
import { useCircleDarkMode } from "../hooks/useCircleDarkMode";

// TODO: retrieve from elsewhere
const reports = [
  {
    title: "Pick",
    key: "pick-tab",
    url: "https://dashboards.cluvio.com/dashboards/vrep-xmxr-061z/shared?sharingToken=53f9c27a-e14e-462f-9ea9-bccd84a1b5d7",
  },
  {
    title: "Order Activity Summary",
    key: "order-activity-tab",
    url: "https://dashboards.cluvio.com/dashboards/o756-4edk-dyqx/shared?sharingToken=397838f6-fa3f-498d-9159-eb227f96d4ec",
  },
  {
    title: "Production",
    key: "production-tab",
    url: "https://dashboards.cluvio.com/dashboards/vrep-xmvv-061z/shared?sharingToken=f39aabc6-2881-4d73-8db6-385e68240b01",
  },
];

const reportHeight = 1200;

const ReportingPage = () => {
  const darkMode = useCircleDarkMode();
  const [key, setKey] = useLocalStorage(
    "cc-circle-selectedreport",
    reports[0].key
  );
  return (
    <Container>
      <Tabs
        id="reports-page-tab-container"
        activeKey={key}
        onSelect={(k) => setKey(k!)}
      >
        {reports.map((report, i) => (
          <Tab eventKey={report.key} key={`report${i}`} title={report.title}>
            <iframe
              title={report.title}
              allowFullScreen={true}
              height={reportHeight}
              width="100%"
              src={`${report.url}&darkMode=${darkMode ? "true" : "false"}`}
            ></iframe>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuthenticationRequired(ReportingPage, {
  onRedirecting: () => <Loading />,
});
