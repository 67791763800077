import React, { useContext, useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";

import { financeUploadOptions, FinanceUploadOptions } from "./types";
import { useDropzone } from "react-dropzone";
import { AxiosError } from "axios";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const createFinanceWorker = createWorkerFactory(
  () => import("../../workers/finance")
);

const FinanceUploadComponent = (): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<FinanceUploadOptions>();
  const [userToken, setUserToken] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [uploadComplete, setUploadIsComplete] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const financeWorker = useWorker(createFinanceWorker);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [lastUploaded, setLastUploaded] = useState<string | null>("");
  const darkMode = useCircleDarkMode();

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  useEffect(() => {
    if (userToken && selectedOption) {
      console.log("Getting last uploaded");
      (async () => {
        setLastUploaded(null);
        const data = await financeWorker.GetLastUploadedTimestamp(
          userToken,
          selectedOption.value
        );
        setLastUploaded(data.lastUpdated);
      })();
    }
  }, [selectedOption, userToken, financeWorker]);

  const upload = () => {
    setUploadPercentage(0);
    setIsUploading(true);
    setUploadMessage("");
    setUploadIsComplete(false);

    if (!userToken) {
      setIsUploading(false);
      setUploadMessage("Could not validate user");
      return;
    }

    if (!acceptedFiles[0]) {
      setIsUploading(false);
      setUploadMessage("Bad file upload");
      return;
    }

    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
      fileReader.onload = (e) => {
        const file = e.target?.result;

        if (!file) {
          setIsUploading(false);
          setUploadMessage("Could not read file");
          return;
        }

        const now = new Date();
        const filename = `${now.toISOString().replace(/[:.]/g, "-")}.xlsx`;

        financeWorker
          .GetPresignedUrlForUpload(filename, userToken, selectedOption?.value)
          .then((result) => {
            console.info("retrieved presigned URL for upload");
            financeWorker
              .UploadFileViaPresignedUrl(
                result.uploadUrl,
                file,
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                handleUploadProgress
              )
              .then((uploadResult) => {
                console.info(uploadResult);
                setUploadMessage("File Uploaded");
                setUploadIsComplete(true);
                setIsUploading(false);
              })
              .catch((error: AxiosError) => {
                console.error("Error uploading file");
                console.error(error);
                setUploadMessage("Error uploading file");
                setIsUploading(false);
              });
          })
          .catch((error: AxiosError) => {
            console.error("Error generating pre-signed URL");
            console.error(error);
            setUploadMessage("Error generating pre-signed URL");
            setIsUploading(false);
          });
      };
    } catch (e) {
      setUploadMessage("Unknown error occurred");
      setIsUploading(false);
      setUploadIsComplete(false);
    }
  };

  const handleUploadProgress = (progressEvent: ProgressEvent<EventTarget>) => {
    setUploadPercentage(
      Math.round((progressEvent.loaded * 100) / progressEvent.total)
    );
  };

  const confirmUpload = () => {
    upload();
    setShowConfirmationModal(false);
  };

  const showUploadModal = () => {
    setShowConfirmationModal(true);
  };

  const {
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    acceptedFiles,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
    disabled: isUploading,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      backgroundColor: darkMode ? "#333" : "#fafafa",
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [darkMode, isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  return (
    <Container>
      <h1>Finance Upload</h1>
      <Row className="m-1">
        <Col>
          <Alert variant="warning">
            <p>
              Only upload files containing the full set of fields, this includes
              the latest fields added.
            </p>
            <ul>
              <li>RETAIL | Orders: Number of Seats & Client Yield Per Seat</li>
              <li>
                RETAIL | Refunds: Number of Seats & Client Yield Deduction Per
                Seat
              </li>
              <li>
                AUCTION | Statement: Number of Seats & Client Yield Per Seat
              </li>
            </ul>
          </Alert>
        </Col>
      </Row>
      <Row className="m-1">
        <Col>
          <ButtonGroup>
            {Object.values(financeUploadOptions).map((option) => (
              <Button
                key={option.value}
                variant={
                  selectedOption && selectedOption.value === option.value
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setSelectedOption(option)}
                disabled={isUploading}
              >
                {option.name}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mt-4 m-2">
        {selectedOption ? (
          <div className="container">
            <span>
              <b>Last uploaded:</b>{" "}
              {lastUploaded ? (
                new Date(lastUploaded).toLocaleString("en-US", {
                  weekday: "short",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
              ) : lastUploaded === null ? (
                <Spinner size="sm" />
              ) : (
                "Never"
              )}
            </span>
            <div style={{ padding: "0.75em" }}></div>
            <Col>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {acceptedFiles && acceptedFiles.length > 0 ? (
                  acceptedFiles.map((f, i) => (
                    <p
                      style={{
                        color: darkMode ? "lightgrey" : "black",
                        textAlign: "center",
                      }}
                      className="m-3"
                      key={i}
                    >
                      📄 {f.name} ({(f.size / 1024 / 1024).toFixed(2)}MB)
                    </p>
                  ))
                ) : (
                  <p className="m-3">
                    Drag 'n' drop your populated XLSX here with items for{" "}
                    {selectedOption.name}, or click to select file
                  </p>
                )}
              </div>
            </Col>
          </div>
        ) : (
          <i>Select an Option to Continue</i>
        )}
      </Row>
      <Row>
        <Col className="m-3">
          {isUploading ? <ProgressBar now={uploadPercentage} /> : <></>}
        </Col>
      </Row>
      <Row>
        <Col className="m-3">
          <Alert
            className={uploadMessage ? "" : "visually-hidden"}
            variant={uploadMessage && uploadComplete ? "success" : "danger"}
          >
            <p>{uploadMessage && uploadComplete ? "Success" : "Error"}</p>
            {uploadMessage ? (
              <>
                <hr />
                <p className="mb-0">{uploadMessage}</p>
              </>
            ) : (
              <></>
            )}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="m-3">
          <Button
            variant="success"
            disabled={
              isUploading ||
              !selectedOption ||
              !acceptedFiles ||
              acceptedFiles.length === 0
            }
            onClick={showUploadModal}
          >
            <Spinner
              as={"span"}
              animation="border"
              role="status"
              aria-hidden="true"
              size="sm"
              style={{ marginRight: "0.5em" }}
              className={isUploading ? "" : "visually-hidden"}
            />
            Upload
          </Button>
        </Col>
      </Row>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <p>Are you sure you want to upload?</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FinanceUploadComponent;
