import { withAuthenticationRequired } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Loading from "../components/loading";

const ColourBlindTestPage = () => {
  return (
    <Container>
      <iframe
        style={{ height: "900px", width: "100%" }}
        src="https://www.eyeque.com/color-blind-test-embed/"
        title="Colourblind test"
      ></iframe>
    </Container>
  );
};

export default withAuthenticationRequired(ColourBlindTestPage, {
  onRedirecting: () => <Loading />,
});
