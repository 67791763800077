import { useGradingAndRefurbState } from "./persist-helpers";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { ChoiceComponentProps } from "./types";
import { useEffect } from "react";

const ChoiceComponent = ({
  multiChoice,
  options,
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  minSelections,
  title,
  subtitle,
}: ChoiceComponentProps): JSX.Element => {
  const [selectedChoices, setSelectedChoices] = useGradingAndRefurbState<
    string[]
  >(stepOutputKey, refurbIdentifier, []);

  // Set step completed when the user has selected the required number of choices
  useEffect(() => {
    if (multiChoice) {
      if (selectedChoices.length >= (minSelections ?? 1)) {
        setStepCompleted(true);
      } else {
        setStepCompleted(false);
      }
    } else {
      if (selectedChoices.length > 0) {
        setStepCompleted(true);
      } else {
        setStepCompleted(false);
      }
    }
  }, [minSelections, multiChoice, selectedChoices, setStepCompleted]);

  const buttonVariant = (value: string) => {
    if (!value) {
      return "primary";
    } else if (selectedChoices && selectedChoices.includes(value)) {
      return "success";
    }
    return "outline-primary";
  };

  const Choices = () => (
    <Table className="choice-table">
      <tbody>
        {options.map((g, i) => (
          <tr key={i}>
            <td className="mb-2">
              <Button
                variant={buttonVariant(g.value)}
                size="lg"
                onClick={() => {
                  if (multiChoice) {
                    const isSelected = selectedChoices.includes(g.value);
                    setSelectedChoices(
                      isSelected
                        ? selectedChoices.filter((value) => value !== g.value) // Remove item if selected
                        : Array.from(new Set([...selectedChoices, g.value])) // Add item if not selected
                    );
                  } else {
                    setSelectedChoices([g.value]);
                  }
                }}
              >
                {g.label}
              </Button>
            </td>
            <td className="choice-description">{g.description}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <>
      <h4>{title}</h4>
      {subtitle ? <p>{subtitle}</p> : null}
      {multiChoice ? (
        <i>
          Please select at least {minSelections ?? 1} option
          {minSelections && minSelections > 1 ? "s" : ""}
        </i>
      ) : (
        <i>Please select one option</i>
      )}
      <Choices />
      <hr />
      <div>
        <Button
          variant="outline-danger"
          onClick={() => {
            setSelectedChoices([]);
          }}
        >
          Clear selected choice{multiChoice ? "(s)" : ""}
        </Button>
      </div>
    </>
  );
};

export default ChoiceComponent;
