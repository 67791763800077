import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

interface ConfirmAssigneeModalProps {
  user: { email: string; name: string };
  show: boolean;
  handleConfirmAssignee: (name: string) => void;
  setShowAssignConfirmModal: (show: boolean) => void;
}

const ConfirmAssigneeModal: React.FC<ConfirmAssigneeModalProps> = (props) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClose = () => {
    props.setShowAssignConfirmModal(false);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await props.handleConfirmAssignee(props.user.name);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setIsError(true);
      } else {
        setErrorMessage("An unknown error occurred.");
        setIsError(true);
      }
    }
    setIsSaving(false);
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Assignee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{errorMessage}</div>}
        <p>
          {" "}
          {props.user.name === "Unassigned"
            ? "Are you sure you want to unassign this task?"
            : `Are you sure you want to assign this task to ${props.user.name}?`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={isSaving}
          className="btn btn-secondary"
          onClick={() => handleSubmit()}
        >
          Confirm
        </button>
        <button
          disabled={isSaving}
          className="btn btn-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAssigneeModal;
