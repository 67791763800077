import { withAuthenticationRequired } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "../components/loading";
import { useNavigate } from "react-router-dom";

const MockupsPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Container>
      <h2>Mockups</h2>
      <p>
        All mockups are purely non-functional, prototype pages that have limited
        functionality. No mockup pages shown reflect final functionality.
      </p>
      <p>No feature, button, text or title shown is final.</p>
      <Row>
        <Col>
          <Button onClick={() => navigate("/mockups/default")} size="lg">
            Default
          </Button>
          <hr />
          <Button onClick={() => navigate("/mockups/peloton")} size="lg">
            Peloton
          </Button>
          <hr />
          <Button onClick={() => navigate("/mockups/jlp")} size="lg">
            John Lewis & Partners
          </Button>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MockupsPage, {
  onRedirecting: () => <Loading />,
});
