import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { CheckSquareFill, Square } from "react-bootstrap-icons";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../components/profile";
import { AxiosError } from "axios";

export interface InboundManifest {
  id: string;
  clientId: string;
  createdAtTimestamp: string;
  lastUpdatedTimestamp: string;
  inboundTimestamp: string;
  itemCount?: number;
  uploadedCSVLink?: string;
  complete: boolean;
}

const createManifestWorker = createWorkerFactory(
  () => import("../../workers/manifest")
);

const ManifestsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [manifests, setManifests] = useState<InboundManifest[]>([]);
  const [hasLoadedManifests, setHasLoadedManifests] = useState<boolean>(false);

  const manifestWorker = createManifestWorker();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  useEffect(() => {
    if (userAuthenticatedContext.token && !hasLoadedManifests) {
      manifestWorker
        .getAllManifests(userAuthenticatedContext.token!)
        .then((m) => {
          setManifests(m);
          setHasLoadedManifests(true);
        })
        .catch((e) => {
          if (e instanceof AxiosError) {
            if (e.response?.status === 401) {
              alert(
                "Your session has expired. Please refresh the page or log in again."
              );
            } else {
              // TODO: handle other errors and show better
              alert(
                "There was an error retrieving your manifests. Please try again later."
              );
            }
          }
          console.error(e);
        });
    }
  }, [userAuthenticatedContext, manifestWorker, hasLoadedManifests]);

  if (!hasLoadedManifests) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (manifests.length === 0) {
    return (
      <Container>
        <h1>No manifests</h1>
      </Container>
    );
  }

  return (
    <Container>
      <ManfiestsTopNav navigate={navigate} />
      <Table striped>
        <thead>
          <tr>
            <th>Client</th>
            <th>Created</th>
            <th>Last Updated</th>
            <th>Inbound date</th>
            <th>Items</th>
            <th>Complete</th>
            <th>Open</th>
          </tr>
        </thead>
        <tbody>
          {manifests.map((m, i) => (
            <tr key={i}>
              <td>{m.clientId}</td>
              <td>
                {new Date(m.createdAtTimestamp).toLocaleDateString()}
                <br />
                {new Date(m.createdAtTimestamp).toLocaleTimeString()}
              </td>
              <td>
                {new Date(m.lastUpdatedTimestamp).toLocaleDateString()}
                <br />
                {new Date(m.lastUpdatedTimestamp).toLocaleTimeString()}
              </td>
              <td>
                {new Date(m.inboundTimestamp).toLocaleDateString()}
                <br />
                {new Date(m.inboundTimestamp).toLocaleTimeString()}
              </td>
              <td>{m.itemCount}</td>
              <td>
                {m.complete ? (
                  <CheckSquareFill
                    size={30}
                    color="darkgreen"
                    className="mt-1"
                    aria-label="Complete"
                  />
                ) : (
                  <Square
                    size={30}
                    color="grey"
                    aria-label="Incomplete"
                    className="mt-1"
                  />
                )}
              </td>
              <td>
                <Button
                  variant="success"
                  onClick={() => {
                    navigate("/manifest/CCMABC123");
                  }}
                >
                  Open
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const ManfiestsTopNav = ({
  navigate,
}: {
  navigate: NavigateFunction;
}): JSX.Element => {
  return (
    <Row>
      <Col>
        <Button variant="success" onClick={() => navigate(`/manifest/new`)}>
          New Manifest
        </Button>
      </Col>
      <Col>
        <Form>
          <Form.Switch label={"Show Complete"} />
        </Form>
      </Col>
    </Row>
  );
};

export default withAuthenticationRequired(ManifestsPage, {
  onRedirecting: () => <Loading />,
});
