/// <reference types="react-scripts" />

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../components/profile";
import { Client, SubClient } from "../../external";
import ClientComponent from "../../components/clients/client";
import { useDebugMode } from "../../hooks/useDebugMode";
import { PlusCircleFill } from "react-bootstrap-icons";
import SubClientModalComponent from "../../components/clients/subclient-modal";
import { useFeatureFlagEnabled } from "posthog-js/react";

export type ClientComponentProps = {
  client: Client;
};

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const ClientPage = (): JSX.Element => {
  const { clientId } = useParams();

  const defaultSubClientData: SubClient = {
    subClientId: "",
    skuSuffix: "",
    displayInfo: {
      name: "",
    },
    rateCard: {},
  };

  const navigate = useNavigate();
  const clientsWorker = useWorker(createClientsWorker);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const subclientsEnabled = useFeatureFlagEnabled("showSubClients");
  const [client, setClient] = useState<Client>();
  const [subClients, setSubClients] = useState<SubClient[]>();
  const [subClient, setSubClient] = useState<SubClient>(defaultSubClientData);
  const [clientLoaded, setClientLoaded] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [clientRefresh, setClientRefresh] = useState(true);
  const [subClientAction, setSubClientAction] = useState<"create" | "update">(
    "create"
  );
  const [selectedSubClient, setSelectedSubClient] = useState<SubClient | null>(
    null
  );
  const isExistingClient = !!client;

  const debugModeEnabled = useDebugMode();

  const handleAddSubClient = () => {
    setSubClient(defaultSubClientData);
    setSubClientAction("create");
    setShowModal(true);
  };

  const handleUpdateSubClient = (subClient: SubClient) => {
    setSelectedSubClient(subClient);
    setSubClient(subClient);
    setSubClientAction("update");
    setShowModal(true);
  };

  const handleRefreshClient = (value: boolean) => {
    setClientRefresh(value);
  };

  const handleCloseModal = () => {
    setSelectedSubClient(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      if (!clientId) {
        return;
      }

      if (clientRefresh) {
        setSubClients(undefined);
        (async () => {
          const client = await clientsWorker.getClient(
            clientId,
            userAuthenticatedContext.token!
          );
          setClient(client);
          setSubClients(client.subClients);
          setClientLoaded(true);
          setClientRefresh(false);
        })();
      }
    }
  }, [userAuthenticatedContext, clientId, clientsWorker, clientRefresh]);

  if (!clientId) {
    return <div>No client ID provided</div>;
  }

  if (!clientLoaded || !client) {
    return <Loading />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Button
            variant="secondary"
            className="mb-1"
            size="sm"
            onClick={() => {
              navigate("/clients");
            }}
          >
            Back to Clients
          </Button>
        </Col>
      </Row>
      <Row>
        <ClientComponent
          authenticatedUserToken={userAuthenticatedContext.token ?? ""}
          client={client}
        />
      </Row>

      {subclientsEnabled ? (
        <>
          <hr />
          <Container>
            <Row>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5 className="mb-3 pt-3">Sub Clients</h5>
                {userAuthenticatedContext.scopes?.includes(
                  "edit:client-item"
                ) ? (
                  <PlusCircleFill
                    size={25}
                    color="blue"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      handleAddSubClient();
                    }}
                  />
                ) : null}
              </div>
            </Row>
            <Row>
              {subClients && subClients?.length !== 0 ? (
                <ListGroup>
                  {Object.entries(subClients).map(([key, value], i) => (
                    <ListGroup.Item
                      key={i}
                      eventKey={`${i}`}
                      active={value === selectedSubClient} // Highlight the selected item
                      onClick={() => {
                        handleUpdateSubClient(value);
                      }}
                    >
                      {value.displayInfo.name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <h3>No subclients configured</h3>
              )}
            </Row>
          </Container>
        </>
      ) : null}
      {subclientsEnabled ? (
        <Row>
          <SubClientModalComponent
            clientId={client.clientId}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            handleRefreshClient={handleRefreshClient}
            action={subClientAction}
            subClient={subClient}
          />
        </Row>
      ) : null}
      <hr />
      {isExistingClient ? (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Audit</Accordion.Header>
            <Accordion.Body>
              <b>Last Updated</b>
              <p>{client.lastUpdatedTimestamp}</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
      <hr />
      {debugModeEnabled ? (
        <Row>
          <h3>Debug</h3>
          <code>{JSON.stringify(client)}</code>
        </Row>
      ) : null}
    </Container>
  );
};

export default withAuthenticationRequired(ClientPage, {
  onRedirecting: () => <Loading />,
});
