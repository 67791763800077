import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Col from "react-bootstrap/Col";
import { CatalogItemCSVData } from "./catalog-csv-upload";
import PaginationComponent from "../pagination";

export interface CatalogCSVUploadPreviewTableProps {
  csvData: CatalogItemCSVData[];
  rowStatuses: string[];
}

const CatalogCSVUploadPreviewTable = (
  props: CatalogCSVUploadPreviewTableProps
) => {
  const { csvData, rowStatuses: rowStatus } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = csvData.slice(indexOfFirstItem, indexOfLastItem);

  const renderTDForStatus = (status: string, key: number) => {
    if (status === "Create") {
      return (
        <td style={{ backgroundColor: "lightgreen" }}>
          <b>{status}</b>
        </td>
      );
    } else if (status === "Update") {
      return (
        <td style={{ backgroundColor: "lightblue" }}>
          <b>{status}</b>
        </td>
      );
    } else {
      // Error
      return (
        <td style={{ backgroundColor: "lightcoral" }}>
          <OverlayTrigger
            key="top"
            placement="right"
            overlay={
              <Tooltip id={`error-tooltip-${key}`}>
                Items must have either:
                <br />
                <code>itemId</code> to be updated
                <br />
                <code>clientId</code> and <code>clientItemId</code> to be
                created
              </Tooltip>
            }
          >
            <b>{status}</b>
          </OverlayTrigger>
        </td>
      );
    }
  };

  return (
    <>
      <Row>
        <Col
          style={{
            overflowX: "scroll",
            overflowY: "auto",
            maxHeight: "calc(80vh - 300px)",
          }}
        >
          <Table striped hover className="csv-preview-table">
            <thead>
              <tr>
                <th>Action</th>
                {csvData[0] &&
                  Object.keys(csvData[0]).map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((row, index) => (
                <tr key={index}>
                  {renderTDForStatus(rowStatus[index], index)}
                  {Object.values(row).map((cell, i) => {
                    const columnHeader = Object.keys(row)[i];
                    if (columnHeader === "description") {
                      return (
                        <td key={i}>
                          <div
                            className="csv-description-preview"
                            dangerouslySetInnerHTML={{ __html: cell }}
                          ></div>
                        </td>
                      );
                    } else if (["itemId", "archived"].includes(columnHeader)) {
                      return (
                        <td key={i}>
                          <code>{cell}</code>
                        </td>
                      );
                    }
                    return <td key={i}>{cell}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <br />
      <PaginationComponent
        meta={{
          current: currentPage,
          previous: null,
          next: null,
          totalPages: Math.ceil(csvData.length / itemsPerPage),
          maxPerPage: itemsPerPage,
          totalResults: csvData.length,
        }}
        itemsLoaded={true}
        childToParentPages={setCurrentPage}
        childToParentCount={setItemsPerPage}
        compactPageCount={true}
      ></PaginationComponent>
    </>
  );
};

export default CatalogCSVUploadPreviewTable;
