import { useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { uploadImageFromUrl } from "../../../helpers/uploadImageFromUrl";
import Loading from "../../loading";

export interface CatalogItemImageImportUrlProps {
  urls: string[];
  urlForImageUpload: string;
  clientId: string;
  itemId: string;
  userToken: string;
}

const CatalogItemImageImportUrl = ({
  urls,
  urlForImageUpload,
  clientId,
  itemId,
  userToken,
}: CatalogItemImageImportUrlProps) => {
  const [imageUrlsToImport, setImageUrlsToImport] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const handleCheckboxChange = (url: string, isChecked: boolean) => {
    if (isChecked) {
      setImageUrlsToImport((prevUrls) => [...prevUrls, url]);
    } else {
      setImageUrlsToImport((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== url)
      );
    }
  };

  const handleButtonClick = async () => {
    setIsButtonDisabled(true);
    for (const url of imageUrlsToImport) {
      try {
        const imageUploaded = await uploadImageFromUrl(
          urlForImageUpload,
          url,
          itemId,
          clientId,
          userToken
        );

        if (!imageUploaded) {
          console.warn("Image failed to upload");
        }
      } catch (e) {
        console.error("Error uploading Image", e);
      }
    }
    setIsButtonDisabled(false);
    window.location.reload();
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Image</th>
            <th>Import</th>
          </tr>
        </thead>
        <tbody>
          {urls.map((url, index) => (
            <tr key={index} className="border-bottom">
              <td className="w-50">
                <img
                  className="returnedImageResult img-fluid"
                  src={url}
                  alt={`Img ${index + 1}`}
                />
              </td>
              <td
                className="d-flex justify-content-center align-items-center"
                style={{ borderBottomWidth: 0 }}
              >
                <Form.Check
                  type="checkbox"
                  onChange={(e) => handleCheckboxChange(url, e.target.checked)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex flex-column align-items-center justify-content-center mb-3">
        {isButtonDisabled ? (
          <>
            <Loading />
            <p>
              Importing {imageUrlsToImport.length}{" "}
              {imageUrlsToImport.length > 1 ? "images" : "image"}
            </p>
          </>
        ) : (
          <>
            <Button
              variant="primary"
              onClick={handleButtonClick}
              disabled={imageUrlsToImport.length === 0}
            >
              Import Images
            </Button>
            {imageUrlsToImport.length > 0 ? (
              <p>
                {imageUrlsToImport.length}{" "}
                {imageUrlsToImport.length > 1 ? "Images" : "Image"} Selected
              </p>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default CatalogItemImageImportUrl;
