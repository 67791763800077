import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { BaseGradingComponentProps } from "./types";
import { useReadAllGradingAndRefurbState } from "./persist-helpers";
import { GradingSummaryTable } from "./refurb-summary-table";
import { GradingStepsConfiguration } from "../../../external/inventory-component/configuration/types";

export interface CompleteGradeComponentProps {
  refurbConfiguration: GradingStepsConfiguration;
}

const CompleteGradeComponent = ({
  refurbConfiguration,
  setStepCompleted,
  refurbIdentifier,
}: CompleteGradeComponentProps & BaseGradingComponentProps): JSX.Element => {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    setStepCompleted(confirmed);
  }, [confirmed, setConfirmed, setStepCompleted]);

  // const debugMode = useDebugMode();

  const refurbState = useReadAllGradingAndRefurbState(refurbIdentifier);

  if (!refurbState) {
    return (
      <Container>
        <Row>
          <Col>
            <h3>Refurb Summary</h3>
            <h2>No refurb state available.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3>Refurb Summary</h3>
          <GradingSummaryTable
            refurbConfiguration={refurbConfiguration}
            refurbIdentifier={refurbIdentifier}
          />
          <ConfirmCheckbox
            currentCheckboxState={confirmed}
            setCheckboxState={(value) => setConfirmed(value)}
            refurbIdentifier={refurbIdentifier}
          />
          <hr />
          {/* {debugMode ? (
            <code>{JSON.stringify(refurbState, null, "  ")}</code>
          ) : null} */}
        </Col>
      </Row>
    </Container>
  );
};

const ConfirmCheckbox = ({
  refurbIdentifier,
  currentCheckboxState,
  setCheckboxState,
}: {
  refurbIdentifier: string;
  currentCheckboxState: boolean;
  setCheckboxState: (state: boolean) => void;
}) => {
  const id = `checkbox-confirm-${refurbIdentifier}`;
  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        type="checkbox"
        id={id}
        checked={currentCheckboxState}
        style={{
          width: "40px",
          height: "40px",
          margin: "12px 2em",
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCheckboxState(e.target.checked);
        }}
      ></input>
      <label
        style={{
          fontSize: "1.5em",
        }}
        htmlFor={id}
      >
        Please tick to confirm that the information is correct.
      </label>
    </Container>
  );
};

export default CompleteGradeComponent;
