import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Client } from "../../external";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

interface SelectClientProps {
  selectedClient: Client | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  clients?: Client[];
}

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const SelectClient = ({
  selectedClient,
  setSelectedClient,
  setFormDirty,
  clients,
}: SelectClientProps): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [clientDropdownClients, setClientDropdownClients] = useState<Client[]>(
    []
  );

  const clientsWorker = useWorker(createClientsWorker);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        const sortedClients = [...clients].sort((a, b) =>
          a.clientName.localeCompare(b.clientName)
        );
        setClientDropdownClients(sortedClients);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant={selectedClient ? "success" : "danger"}
        id="search-client"
      >
        {selectedClient ? selectedClient.clientName : "Select Client"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setSelectedClient(undefined)}>
          Unselect Client
        </Dropdown.Item>
        {clientDropdownClients.map((c, i) => (
          <Dropdown.Item
            onClick={() => {
              setFormDirty(true);
              setSelectedClient(c);
            }}
            eventKey={`${i}`}
            key={c.clientId}
          >
            {c.clientName}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectClient;
