/// <reference types="react-scripts" />

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";

const Dashboard = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Dashboard</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
