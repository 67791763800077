import Alert from "react-bootstrap/Alert";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";

const GlobalAlerts = () => {
  const circleInfoAlertEnabled = useFeatureFlagEnabled("circleInfoAlert");
  const circleWarningAlertEnabled = useFeatureFlagEnabled("circleWarningAlert");
  const circleErrorAlertEnabled = useFeatureFlagEnabled("circleErrorAlert");

  const circleInfoAlertPayload = useFeatureFlagPayload(
    "circleInfoAlert"
  ) as string;
  const circleWarningAlertPayload = useFeatureFlagPayload(
    "circleWarningAlert"
  ) as string;
  const circleErrorAlertPayload = useFeatureFlagPayload(
    "circleErrorAlert"
  ) as string;
  return (
    <>
      {circleInfoAlertEnabled ? (
        <Alert variant="info">{circleInfoAlertPayload}</Alert>
      ) : null}
      {circleWarningAlertEnabled ? (
        <Alert variant="warning">{circleWarningAlertPayload}</Alert>
      ) : null}
      {circleErrorAlertEnabled ? (
        <Alert variant="danger">{circleErrorAlertPayload}</Alert>
      ) : null}
    </>
  );
};
export default GlobalAlerts;
