export const formatDateFromTimestamp = (timestamp: string) =>
  new Intl.DateTimeFormat("en-GB", {
    dateStyle: "full",
    timeStyle: "medium",
  }).format(new Date(timestamp));

/**
 * Formats a date object's date in format YYYY-MM-DD
 * @param inputDate The date object with which to format the date from
 * @returns The input date object's date, in the format YYYY-MM-DD
 */
export const getDateString = (inputDate: Date) => {
  return [
    inputDate.getFullYear(),
    String(inputDate.getMonth() + 1).padStart(2, "0"),
    String(inputDate.getDate()).padStart(2, "0"),
  ].join("-");
};

/**
 * Formats a date object's date in format DD/MM/YYYY HH:MM
 * @param inputDate a timestamp
 * @returns The input date object's date, in the format DD/MM/YYYY HH:MM
 */
export function formatDateTimeFromTimestamp(originalDate: string) {
  const date = new Date(originalDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
