import React, { useContext, useEffect, useState } from "react";
import {
  TaskStatus,
  TaskTypes,
  User,
} from "../../external/task-component/openapi";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { TrashFill } from "react-bootstrap-icons";
import { UserAuthenticatedContext } from "../../components/profile";
import { useLocalStorage } from "usehooks-ts";
import Button from "react-bootstrap/Button";
import { TaskStatusOptions } from "../../components/task/types";

type TaskSearchProps = {
  onSearch: (searchParams: { [key: string]: any }) => void;
  taskItemsLoaded: boolean;
  setTaskItemsLoaded: (taskItemsLoaded: boolean) => void;
  assigneesList: User[];
};

const TaskSearch = ({
  onSearch,
  taskItemsLoaded,
  setTaskItemsLoaded,
  assigneesList,
}: TaskSearchProps): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [type, setType] = useState<TaskTypes | "All">();
  const defaultStatus: TaskStatus[] = ["created", "in-progress"];
  const [status, setStatus] = useState<TaskStatus[]>(defaultStatus);
  const [sortBy, setSortBy] = useState("createdAtTimestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [assignedTo, setAssignedTo] = useState<User>({
    email: "",
    name: "All",
  });

  const [taskIdTextBox, setTaskIdTextBox] = useState<string>("");
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [triggerSearch, setTriggerSearch] = useState(true);

  const [assignedToMeChecked, setAssignedToMeChecked] =
    useLocalStorage<boolean>("assignedToMeChecked", false);

  const assigneeNameToObj: { [key: string]: { email: string; name: string } } =
    assigneesList.reduce((acc, assignee) => {
      acc[assignee.name] = assignee;
      return acc;
    }, {} as { [key: string]: { email: string; name: string } });

  const handleAssignedChange = (assigned: string) => {
    let assignee;
    if (assigned === "Unassigned") {
      assignee = { email: "", name: "Unassigned" };
    } else {
      assignee = assigneeNameToObj[assigned];
    }
    setAssignedTo(assignee);
    setTriggerSearch(true);
  };

  const handleTextSearchButton = () => {
    setTaskId(taskIdTextBox);
    setTaskIdTextBox("");
    setTriggerSearch(true);
  };

  const handleTextSearch = (search: string) => {
    setTaskIdTextBox(search);
  };

  const handleClear = (clearAll: boolean) => {
    if (clearAll) {
      setTaskIdTextBox("");
      setTaskId(undefined);
      setType("All");
      setStatus(defaultStatus);
      setSortBy("createdAtTimestamp");
      setSortOrder("desc");
      setAssignedTo({ email: "", name: "All" });
      setAssignedToMeChecked(false);
    }
    setTriggerSearch(true);
  };

  const handleTypeChange = (e: TaskTypes) => {
    setType(e);
    setTriggerSearch(true);
  };

  const handleSetMine = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAssignedToMeChecked(e.target.checked);

  const handleStatusChange = (selectedStatus: TaskStatus) => {
    setTaskItemsLoaded(false);

    if (status.includes(selectedStatus)) {
      setStatus(status.filter((status) => status !== selectedStatus));
    } else {
      setStatus([...status, selectedStatus]);
    }

    setTriggerSearch(true);
  };

  const handleStatusReset = () => {
    setStatus(defaultStatus);
    setTaskItemsLoaded(false);
    setTriggerSearch(true);
  };

  const handleSortByChange = (sortBy: string) => {
    setSortBy(sortBy);
    setTriggerSearch(true);
  };

  const handleSortOrderChange = (sortOrder: string) => {
    setSortOrder(sortOrder);
    setTriggerSearch(true);
  };

  useEffect(() => {
    if (assignedToMeChecked) {
      setAssignedTo({
        email: userAuthenticatedContext.user?.email ?? "",
        name: userAuthenticatedContext.user?.name ?? "",
      });
    } else {
      setAssignedTo({ email: "", name: "All" });
    }
    setTriggerSearch(true);
  }, [assignedToMeChecked, userAuthenticatedContext.user]);

  useEffect(() => {
    if (triggerSearch) {
      setTaskItemsLoaded(false);
      onSearch({
        type,
        status,
        sortBy,
        sortOrder,
        assignedTo,
        taskId,
      });
      setTriggerSearch(false);
    }
  }, [
    triggerSearch,
    onSearch,
    type,
    status,
    sortBy,
    sortOrder,
    assignedTo,
    setTaskItemsLoaded,
    taskId,
  ]);

  return (
    <Card>
      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} className="d-flex">
              <div
                onClick={() => {
                  handleClear(true);
                }}
                style={{ cursor: "pointer", marginBottom: "10px" }}
              >
                <TrashFill /> Clear All
              </div>
            </Col>
          </Row>
          <hr />
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={assignedToMeChecked}
                onChange={handleSetMine}
              />
              <span style={{ marginLeft: "10px" }}>View my Assigned Tasks</span>
            </div>
          </Col>
          <hr />
          <Row>
            <Col>
              <Form.Label>Assigned</Form.Label>
              <InputGroup>
                <Form.Select
                  aria-label="Assigned"
                  value={assignedTo?.name}
                  onChange={(e) => {
                    handleAssignedChange(e.target.value);
                  }}
                  disabled={assignedToMeChecked}
                >
                  <option value="">All</option>
                  {assigneesList.map((assignee) => (
                    <option key={assignee.email} value={assignee.name}>
                      {assignee.name}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form.Label>Task ID: {taskId}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={taskIdTextBox}
                  disabled={!taskItemsLoaded}
                  onChange={(e) => handleTextSearch(e.target.value)}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => handleTextSearchButton()}
                  disabled={!taskItemsLoaded}
                >
                  Apply
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <Form.Group controlId="type">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  disabled={!taskItemsLoaded}
                  as="select"
                  value={type}
                  onChange={(e) => {
                    handleTypeChange(e.target.value as TaskTypes);
                  }}
                >
                  <option value={"all"}>All</option>
                  <option value={"catalogueChangeRequest" as TaskTypes}>
                    Catalogue Change
                  </option>
                  <option value={"catalogueDataRequest" as TaskTypes}>
                    Catalogue Data Request
                  </option>
                  <option value={"catalogueCreateRequest" as TaskTypes}>
                    Catalogue Create Request
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <Form.Group controlId="sortBy">
                <Form.Label>Sort By:</Form.Label>
                <Form.Select
                  disabled={!taskItemsLoaded}
                  as="select"
                  value={sortBy}
                  onChange={(e) => handleSortByChange(e.target.value)}
                >
                  <option value="createdAtTimestamp">Created Date</option>
                  <option value="status">Status</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <Form.Group controlId="sortOrder">
                <Form.Label>Sort Order</Form.Label>
                <Form.Select
                  disabled={!taskItemsLoaded}
                  as="select"
                  value={sortOrder}
                  onChange={(e) => handleSortOrderChange(e.target.value)}
                >
                  <option value="desc">Descending</option>
                  <option value="asc">Ascending</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Form.Label>
              Status <small>(Min. 1 Selected Option)</small>
            </Form.Label>
            <div className="mb-3">
              {Object.entries(TaskStatusOptions).map(([value, display]) => (
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      id={value}
                      label={display}
                      name="statusGroup"
                      type="checkbox"
                      value={value}
                      disabled={!taskItemsLoaded}
                      checked={status.includes(value as TaskStatus)}
                      onChange={(e) => {
                        if (
                          status.length === 1 &&
                          status.includes(e.target.value as TaskStatus)
                        ) {
                          return;
                        }
                        handleStatusChange(e.target.value as TaskStatus);
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  handleStatusReset();
                }}
              >
                Reset
              </Button>
            </div>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TaskSearch;
