import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useEffect } from "react";
import CatalogItemPreviewComponent from "./catalog-item-preview";
import { useGradingAndRefurbState } from "./persist-helpers";
import { CatalogueCheckComponentProps } from "./types";

const CatalogueCheckComponent = ({
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  catalogItem,
}: CatalogueCheckComponentProps) => {
  const [checked, setIsChecked] = useGradingAndRefurbState<boolean>(
    stepOutputKey,
    refurbIdentifier
  );

  useEffect(() => {
    setStepCompleted(checked);
  }, [setStepCompleted, checked]);

  return (
    <div style={{ marginBottom: "8em" }}>
      <h1>Catalogue Check</h1>
      <p>Check if the item matches what you have in the catalogue</p>
      <CatalogItemPreviewComponent
        width="100%"
        marginBottom="1em"
        catalogItem={catalogItem}
        fullPreview={true}
      />
      <Row>
        <Col md={1}>
          <input
            type="checkbox"
            defaultChecked={checked}
            onChange={(e) => setIsChecked(e.target.checked)}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </Col>
        <Col md={{ span: 10, offset: 1 }}>
          <label
            style={{
              fontSize: "1em",
              flexGrow: 1,
            }}
          >
            This item matches the catalogue.{" "}
            <i>
              If this item does not match, ensure you have the correct barcode
              for the item. If this is correct, please seek assistance.
            </i>
          </label>
        </Col>
      </Row>
    </div>
  );
};

export default CatalogueCheckComponent;
