import React, { useState, useEffect, useContext } from "react";
import { PalletState } from "./types";
import { useForm, Controller } from "react-hook-form";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import Loading from "../loading";

const createPalletWorker = createWorkerFactory(
  () => import("../../workers/pallet")
);

export interface PalletComponentProps {
  palletNumber: string;
}

const PalletComponent = ({
  palletNumber,
}: PalletComponentProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PalletState>();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const palletWorker = useWorker(createPalletWorker);
  const [pallet, setPallet] = useState<PalletState | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  useEffect(() => {
    const fetchPallet = async () => {
      if (userAuthenticatedContext.token) {
        try {
          console.debug("Fetching pallet:", palletNumber);
          const result = await palletWorker.getPallet(
            palletNumber,
            userAuthenticatedContext.token
          );
          setPallet(result);
        } catch (error) {
          console.error("Error fetching pallet:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPallet();
  }, [userAuthenticatedContext, palletWorker, palletNumber]);

  const onSubmit = async (data: Partial<PalletState>) => {
    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    try {
      if (userAuthenticatedContext.token) {
        await palletWorker.patchPallet(
          palletNumber,
          data,
          userAuthenticatedContext.token
        );
        setSaveSuccess(true);
      }
    } catch (error) {
      setSaveError("Error saving pallet data");
      console.error("Error saving pallet data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!pallet) {
    return <Alert variant="danger">Error loading pallet data</Alert>;
  }

  return (
    <Container>
      <h1>Edit Pallet</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Pallet Number</Form.Label>
              <Form.Control
                {...register("id", { required: true })}
                defaultValue={pallet.id}
                isInvalid={!!errors.id}
              />
              <Form.Control.Feedback type="invalid">
                Pallet number is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Client ID</Form.Label>
              <Form.Control
                {...register("clientId", { required: true })}
                defaultValue={pallet.clientId}
                isInvalid={!!errors.clientId}
              />
              <Form.Control.Feedback type="invalid">
                Client ID is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Subclient ID</Form.Label>
              <Form.Control
                {...register("subClientId", { required: true })}
                defaultValue={pallet.subClientId}
                isInvalid={!!errors.subClientId}
              />
              <Form.Control.Feedback type="invalid">
                Subclient ID is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                {...register("location")}
                defaultValue={pallet.location}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Load</Form.Label>
              <Form.Control {...register("load")} defaultValue={pallet.load} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Pallet Type</Form.Label>
              <Controller
                name="palletType"
                control={control}
                defaultValue={pallet.palletType}
                render={({ field }) => (
                  <Form.Select {...field}>
                    <option value="Retail">Retail</option>
                    <option value="Auction">Auction</option>
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Stock Type</Form.Label>
              <Controller
                name="stockType"
                control={control}
                defaultValue={pallet.stockType}
                render={({ field }) => (
                  <Form.Select {...field}>
                    <option value="Mixed">Mixed</option>
                    <option value="Raw">Raw</option>
                    <option value="BER">BER</option>
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register("comments")}
                defaultValue={pallet.comments}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={isSaving}>
          {isSaving ? "Saving..." : "Save"}
        </Button>
        {saveError && (
          <Alert variant="danger" className="mt-3">
            {saveError}
          </Alert>
        )}
        {saveSuccess && (
          <Alert variant="success" className="mt-3">
            Pallet data saved successfully
          </Alert>
        )}
      </Form>
    </Container>
  );
};

export default PalletComponent;
