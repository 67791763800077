import { Column, Table } from "@tanstack/react-table";
import Stack from "react-bootstrap/Stack";

const TableFilter = ({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <Stack direction="horizontal">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        style={{ width: column.getSize() / 2 }}
        className="border rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        style={{ width: column.getSize() / 2 }}
        className="border rounded"
      />
    </Stack>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      style={{ width: column.getSize() - 20 }}
      className="border rounded"
    />
  );
};

export default TableFilter;
