import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { CatalogItem, Client, InventoryItem } from "../../external";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import { UserAuthenticatedContext } from "../profile";
import Table from "react-bootstrap/Table";
import Loading from "../loading";
import Container from "react-bootstrap/Container";
import { CheckLg, Image, X } from "react-bootstrap-icons";
import ImageCarousel from "../image-carousel";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";

interface InventoryInboundModalProps {
  index: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  inventoryItems: InventoryItem[];
  handleAddInventoryItem: (inventoryItem: InventoryItem) => void;
}

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

const createInventoryWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);

const InboundNewItemModal: React.FC<InventoryInboundModalProps> = ({
  index,
  isModalOpen,
  handleCloseModal,
  inventoryItems,
  handleAddInventoryItem,
}) => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const clientsWorker = useWorker(createClientsWorker);
  const catalogItemsWorker = useWorker(createCatalogWorker);
  const inventoryWorker = useWorker(createInventoryWorker);
  const darkMode = useCircleDarkMode();

  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>("");
  const [catalogItems, setCatalogItems] = useState<CatalogItem[]>([]);
  const [selectedCatalogItem, setSelectedCatalogItem] = useState<
    CatalogItem | undefined
  >(undefined);
  const [showError, setShowError] = useState<boolean>(false);

  const picWidth = 90;

  const [showForm, setShowForm] = useState<boolean>(false);

  const handleSearchResults = async () => {
    if (userAuthenticatedContext.token) {
      setIsLoaded(false);

      const catalogueItems = await catalogItemsWorker.getCatalogItems(
        {
          sortDirection: "asc",
          sortField: "lastUpdatedTimestamp",
          page: 1,
          count: 400,
          text: searchText,
          clientId: client?.clientId,
        },
        userAuthenticatedContext.token
      );

      setCatalogItems(catalogueItems.data);
      setIsLoaded(true);
    }
  };

  const handleTextSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClientChange = (clientId?: string) => {
    if (clientId === "all" || clientId === undefined) {
      setClient(undefined);
    } else {
      const client = clients.find((c) => c.clientId === clientId);
      setClient(client);
    }
  };

  const getClientFilterTitle = (clientId: string, clientName: string) => (
    <span>
      {clientName}{" "}
      <span style={{ color: darkMode ? "lightcoral" : "coral" }}>
        {clientId}
      </span>
    </span>
  );

  const getClientDropdownTitle = () => {
    if (client) {
      return getClientFilterTitle(client.clientId, client.clientName);
    } else {
      return "All Clients";
    }
  };

  const closeModal = () => {
    setShowForm(false);
    setSelectedCatalogItem(undefined);
    setShowError(false);
    handleCloseModal();
  };

  useEffect(() => {
    (async () => {
      if (userAuthenticatedContext.token) {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token
        );
        setClients(clients);
        setClientsLoaded(true);

        const catalogueItems = await catalogItemsWorker.getCatalogItems(
          {
            sortDirection: "asc",
            sortField: "lastUpdatedTimestamp",
            page: 1,
            count: 400,
            text: searchText,
            clientId: client?.clientId,
          },
          userAuthenticatedContext.token
        );

        setCatalogItems(catalogueItems.data);
      }
    })();
    // eslint-disable-next-line
  }, [userAuthenticatedContext, clientsWorker]);

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    pallet: Yup.string().required("Required"),
    boxes: Yup.number()
      .moreThan(0, "Must be greater than 0")
      .required("Required"),
    comments: Yup.string(),
  });

  const NewItemForm = () => {
    return selectedCatalogItem !== undefined && showForm ? (
      <Formik
        initialValues={{
          manualEntry: true,
          loadReferenceId: inventoryItems[0].loadReferenceId,
          clientId: inventoryItems[0].clientId,
          legacyPMID: inventoryItems[0].legacyPMID,
          catalogItemId: selectedCatalogItem.itemId,
          imageLinks: selectedCatalogItem.clientImageLinks,
          description: selectedCatalogItem.description,
          storefronts: [],
          quantity: 0,
          pallet: "",
          boxes: 0,
          comments: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setShowError(false);
          if (!userAuthenticatedContext.token) {
            setShowError(true);
          } else {
            try {
              const result = await inventoryWorker.createInventoryItem(
                values,
                userAuthenticatedContext.token
              );

              handleAddInventoryItem(result);
              resetForm();
              closeModal();
            } catch (error) {
              setShowError(true);
            }
          }
        }}
      >
        {({
          values,
          isValid,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
          errors,
        }) => (
          <FormikForm>
            <Form.Group>
              <Form.Label>
                Quantity <Badge bg="danger">Required</Badge>
              </Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                id="quantity"
                value={values.quantity}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                isInvalid={touched.quantity && !!errors.quantity}
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Pallet <Badge bg="danger">Required</Badge>
              </Form.Label>
              <Form.Control
                type="string"
                name="pallet"
                id="pallet"
                value={values.pallet}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                isInvalid={touched.pallet && !!errors.pallet}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pallet}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Boxes <Badge bg="danger">Required</Badge>
              </Form.Label>
              <Form.Control
                type="number"
                name="boxes"
                id="boxes"
                value={values.boxes}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                isInvalid={touched.boxes && !!errors.boxes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.boxes}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="string"
                name="comments"
                id="comments"
                value={values.comments}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                isInvalid={touched.comments && !!errors.comments}
              />
              <Form.Control.Feedback type="invalid">
                {errors.comments}
              </Form.Control.Feedback>
            </Form.Group>
            {showError ? (
              <Alert variant="danger">
                There was an error adding the new item to the inbound. Please
                try again.
              </Alert>
            ) : null}
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="mt-3"
            >
              Submit
            </Button>
          </FormikForm>
        )}
      </Formik>
    ) : null;
  };

  const SelectedItemView = () => {
    return selectedCatalogItem !== undefined ? (
      <>
        <Button
          onClick={() => {
            setSelectedCatalogItem(undefined);
            setShowError(false);
            setShowForm(false);
          }}
          className="mb-3"
          variant="danger"
        >
          Return to Search Results
        </Button>
        <Row className="justify-content-md-center">
          {selectedCatalogItem.clientImageLinks !== undefined &&
          selectedCatalogItem.clientImageLinks.length > 0 ? (
            <ImageCarousel
              imageUrls={[...selectedCatalogItem.clientImageLinks]}
              maxWidth={"300px"}
            />
          ) : (
            "No Images"
          )}
        </Row>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td style={{ textAlign: "right" }}>Name</td>
              <td>{selectedCatalogItem.name}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "right" }}>Item ID</td>
              <td>{selectedCatalogItem.itemId}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "right" }}>Client Item ID</td>
              <td>{selectedCatalogItem.clientItemId}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "right" }}>Client</td>
              <td>{selectedCatalogItem.clientId}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "right" }}>Description</td>
              <td>
                {selectedCatalogItem.description !== undefined && (
                  <div
                    className="csv-description-preview"
                    dangerouslySetInnerHTML={{
                      __html: selectedCatalogItem.description,
                    }}
                  ></div>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "right" }}>Archived?</td>
              <td>{selectedCatalogItem.archived}</td>
            </tr>
          </tbody>
        </Table>
        <Button
          onClick={() => setShowForm(true)}
          variant="success"
          className="mb-3"
        >
          Add Item to Inbound?
        </Button>
      </>
    ) : null;
  };

  const CatalogSearchView = () => {
    return clientsLoaded ? (
      <Row>
        <Col xs={12}>
          <Form.Label>Search</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={searchText}
              disabled={!isLoaded}
              onChange={handleTextSearch}
            />
          </InputGroup>
        </Col>
        <Col xs={8}>
          <Form.Label>Client</Form.Label>
          <InputGroup className="mb-3">
            <DropdownButton
              disabled={!isLoaded}
              variant={darkMode ? "outline-primary" : "outline-secondary"}
              aria-label="Select Client"
              title={getClientDropdownTitle()}
            >
              <Dropdown.Item
                key="all"
                onClick={() => handleClientChange("all")}
              >
                <b>All Clients</b>
              </Dropdown.Item>
              <Dropdown.Divider />
              {clients
                .sort((a, b) => a.clientName.localeCompare(b.clientName))
                .map((c) => (
                  <Dropdown.Item
                    key={c.clientId}
                    onClick={() => handleClientChange(c.clientId)}
                  >
                    {getClientFilterTitle(c.clientId, c.clientName)}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </InputGroup>
        </Col>
        <Col xs={4}>
          <Button
            style={{ marginTop: "32px" }}
            disabled={!isLoaded}
            onClick={handleSearchResults}
          >
            Find Items
          </Button>
        </Col>
        <Row>
          {isLoaded ? (
            catalogItems.length > 0 ? (
              <Container
                style={{
                  width: "100%",
                  minWidth: "100%",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <thead>
                    <tr style={{ fontWeight: "bolder" }}>
                      <th className="sticky">Image</th>
                      <th className="sticky">Client</th>
                      <th className="sticky">Client Item ID</th>
                      <th className="sticky">Name</th>
                      <th className="sticky">Archived</th>
                    </tr>
                  </thead>
                  <tbody>
                    {catalogItems.map((catalogItem, index) => (
                      <tr
                        key={index}
                        onClick={() => setSelectedCatalogItem(catalogItem)}
                      >
                        <td
                          style={{
                            maxWidth: `${picWidth + 2}px`,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            {catalogItem.clientImageLinks &&
                            catalogItem.clientImageLinks.length > 0 ? (
                              <picture>
                                <source
                                  srcSet={catalogItem.clientImageLinks[0]}
                                ></source>
                                <img
                                  style={{ maxWidth: `${picWidth}px` }}
                                  alt={`${catalogItem.clientItemId}`}
                                  src={catalogItem.clientImageLinks[0]}
                                ></img>
                              </picture>
                            ) : (
                              <Image color="lightgrey" />
                            )}
                          </div>
                        </td>
                        <td>{catalogItem.clientId}</td>
                        <td>{catalogItem.clientItemId}</td>
                        <td>{catalogItem.name}</td>
                        <td>{catalogItem.archived ? <CheckLg /> : <X />}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
            ) : (
              <div>No Catalogue Items</div>
            )
          ) : (
            <Loading />
          )}
        </Row>
      </Row>
    ) : (
      <Row>
        <Loading />
      </Row>
    );
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Search for an Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedCatalogItem ? (
          <>
            <SelectedItemView />
            <NewItemForm />
          </>
        ) : (
          <CatalogSearchView />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InboundNewItemModal;
