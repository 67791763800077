import { BaseGradingComponentProps } from "./types";
import Container from "react-bootstrap/Container";
import { useGradingAndRefurbState } from "./persist-helpers";
import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface ConfirmComponentProps extends BaseGradingComponentProps {
  title: string;
  description: string;
}

/**
 * This component is used when the user needs to confirm that they have done something.
 * For example, confirmed PII from a shipping box.
 * @param param0
 * @returns
 */
const ConfirmComponent = ({
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  title,
  description,
}: ConfirmComponentProps): JSX.Element => {
  const [confirmed, setIsConfirmed] = useGradingAndRefurbState<boolean>(
    stepOutputKey,
    refurbIdentifier
  );

  useEffect(() => {
    setStepCompleted(confirmed);
  }, [setStepCompleted, confirmed]);

  return (
    <Container>
      <h2>{title}</h2>
      <Row>
        <Col md={1}>
          <input
            type="checkbox"
            defaultChecked={confirmed}
            onChange={(e) => setIsConfirmed(e.target.checked)}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </Col>
        <Col md={{ span: 10, offset: 1 }}>
          <label
            style={{
              fontSize: "1em",
              flexGrow: 1,
            }}
          >
            {description}
          </label>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmComponent;
