import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SubClient } from "../../external";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import SubClientComponent from "./subclient";

export interface SubClientModalComponentProps {
  clientId: string;
  showModal: boolean;
  handleCloseModal: () => void;
  handleRefreshClient: (value: boolean) => void;
  action: "create" | "update";
  subClient: SubClient;
}

const SubClientModalComponent = (
  props: SubClientModalComponentProps
): JSX.Element => {
  const {
    clientId,
    showModal,
    handleCloseModal,
    handleRefreshClient,
    subClient,
    action,
  } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleIsSaving = (data: boolean) => {
    setIsSaving(data);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size={"lg"}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>
          {action === "create" ? "New Sub Client" : subClient?.displayInfo.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Container>
            <SubClientComponent
              action={action}
              clientId={clientId}
              subClient={subClient}
              childToParentIsSaving={handleIsSaving}
              childToParentRefresh={handleRefreshClient}
              childToParentCloseModal={handleCloseModal}
            />
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
          disabled={isSaving}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubClientModalComponent;
