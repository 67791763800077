import PalletComponent from "../../components/pallet/pallet-component";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

const PalletPage = () => {
  const { palletNumber } = useParams();

  if (!palletNumber) {
    return (
      <Container>
        <h4>Invalid pallet number</h4>
      </Container>
    );
  }
  return (
    <Container>
      <PalletComponent palletNumber={palletNumber} />
    </Container>
  );
};

export default PalletPage;
