import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import GlobalAlerts from "../components/global-alerts";
import { useState } from "react";
import {
  Box2Fill,
  BoxSeam,
  CameraFill,
  MenuButton,
  Truck,
  XDiamond,
} from "react-bootstrap-icons";
import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";

type MenuOptions =
  | "menu"
  | "outbound"
  | "stockimages"
  | "auctionpallet"
  | "palletmanager"
  | "retailStockLocation";

const ScannerHomePage = (): JSX.Element => {
  const [selectedMenuOption, setMenuOption] = useState<MenuOptions>("menu");

  const scannerOutboundConsignmentEnabled = useFeatureFlagEnabled(
    "scanner_outboundConsignment"
  );
  const stockImagesEnabled = useFeatureFlagEnabled("scanner_stockImages");
  const auctionPalletDespatchEnabled = useFeatureFlagEnabled(
    "scanner_auctionPalletDespatch"
  );
  const palletManagerEnabled = useFeatureFlagEnabled("scanner_palletManager");
  const retailStockLocationEnabled = useFeatureFlagEnabled(
    "scanner_retailStockLocation"
  );

  const scannerOutboundConsignmentURL = useFeatureFlagPayload(
    "scanner_outboundConsignment"
  ) as string;
  const stockImagesURL = useFeatureFlagPayload("scanner_stockImages") as string;
  const auctionPalletDespatchURL = useFeatureFlagPayload(
    "scanner_auctionPalletDespatch"
  ) as string;
  const palletManagerURL = useFeatureFlagPayload(
    "scanner_palletManager"
  ) as string;
  const retailStockLocationURL = useFeatureFlagPayload(
    "scanner_retailStockLocation"
  ) as string;

  const menuUI = (
    <Stack gap={3}>
      {scannerOutboundConsignmentEnabled ? (
        <Button
          variant="success"
          size="lg"
          onClick={() => setMenuOption("outbound")}
        >
          Outbound Consignment <BoxSeam className="ms-2" />
        </Button>
      ) : null}
      {stockImagesEnabled ? (
        <Button
          variant="warning"
          size="lg"
          onClick={() => setMenuOption("stockimages")}
        >
          Stock Images <CameraFill className="ms-2" />
        </Button>
      ) : null}
      {auctionPalletDespatchEnabled ? (
        <Button
          variant="primary"
          size="lg"
          onClick={() => setMenuOption("auctionpallet")}
        >
          Auction Pallet Despatch <Truck className="ms-2" />
        </Button>
      ) : null}
      {palletManagerEnabled ? (
        <Button
          variant="info"
          size="lg"
          onClick={() => setMenuOption("palletmanager")}
        >
          Pallet Manager <XDiamond className="ms-2" />
        </Button>
      ) : null}
      {retailStockLocationEnabled ? (
        <Button
          variant="info"
          size="lg"
          onClick={() => setMenuOption("retailStockLocation")}
        >
          Retail Stock Location <Box2Fill className="ms-2" />
        </Button>
      ) : null}
    </Stack>
  );

  const outboundConsignmentUI = (
    <>
      <GoogleAppsScriptIframe
        src={scannerOutboundConsignmentURL}
        title="Outbound Consignment Scanner"
      />
    </>
  );
  const stockImagesUI = (
    <>
      <GoogleAppsScriptIframe src={stockImagesURL} title="Stock Images" />
    </>
  );
  const auctionPalletDespatchUI = (
    <>
      <GoogleAppsScriptIframe
        src={auctionPalletDespatchURL}
        title="Auction Pallet Despatch"
      />
    </>
  );
  const palletManagerUI = (
    <>
      <GoogleAppsScriptIframe src={palletManagerURL} title="Pallet Manager" />
    </>
  );
  const retailStockLocationUI = (
    <>
      <GoogleAppsScriptIframe
        src={retailStockLocationURL}
        title="Retail Stock Location"
      />
    </>
  );

  return (
    <Container fluid className="mt-3">
      {selectedMenuOption === "menu" ? <GlobalAlerts /> : null}
      {selectedMenuOption !== "menu" ? (
        <Button
          variant="outline-primary"
          style={{ width: "100%" }}
          size="lg"
          onClick={() => {
            setMenuOption("menu");
          }}
        >
          <MenuButton className="me-2" />
          Return to Menu
        </Button>
      ) : null}
      {selectedMenuOption === "menu" ? menuUI : null}
      {selectedMenuOption === "outbound" ? outboundConsignmentUI : null}
      {selectedMenuOption === "stockimages" ? stockImagesUI : null}
      {selectedMenuOption === "auctionpallet" ? auctionPalletDespatchUI : null}
      {selectedMenuOption === "palletmanager" ? palletManagerUI : null}
      {selectedMenuOption === "retailStockLocation"
        ? retailStockLocationUI
        : null}
    </Container>
  );
};

export default ScannerHomePage;
