import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { UserAuthenticatedContext } from "./profile";
import Loading from "./loading";
import Form from "react-bootstrap/Form";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { CatalogItem } from "../external";

export interface CatalogItemDuplicateModalComponentProps {
  catalogItem: CatalogItem;
  showModal: boolean;
  handleCloseModal: () => void;
  openDataRequests: number;
  setOpenDataRequests: (openDataRequests: number) => void;
}

const createTaskWorker = createWorkerFactory(() => import("../workers/task"));

const DataRequestModalComponent = (
  props: CatalogItemDuplicateModalComponentProps
): JSX.Element => {
  const {
    catalogItem,
    showModal,
    handleCloseModal,
    openDataRequests,
    setOpenDataRequests,
  } = props;
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const taskWorker = useWorker(createTaskWorker);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    notes: string;
  }>();

  const [userToken, setUserToken] = useState<string>();
  const [showPostSuccess, setShowPostSuccess] = useState(false);
  const [showPostError, setShowPostError] = useState(false);
  const [postError, setPostError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      setUserToken(userAuthenticatedContext.token);
    }
  }, [userAuthenticatedContext]);

  const onSubmit = handleSubmit(
    (formDataRequest) => {
      if (formDataRequest) {
        (async () => {
          setIsSaving(true);
          if (userToken) {
            try {
              const results = await taskWorker.postTask(
                "catalogueDataRequest",
                catalogItem,
                userToken,
                "Catalogue Item Data Request",
                "A data request has been submitted for a Catalogue Item.",
                formDataRequest.notes
              );

              if (results.status === 201) {
                setOpenDataRequests(openDataRequests + 1);
                handleCloseModal();
                reset();
              } else {
                setPostError(
                  "An Error occurred trying to create a Data Request"
                );
                setShowPostError(true);
              }
            } catch (error) {
              setPostError(
                "We could not create your Data Request at this time."
              );
              setShowPostError(true);
            }
          } else {
            setShowPostError(true);
            setPostError("User token not found.");
          }

          setIsSaving(false);
        })();
      }
    },
    (invalidData) => {
      console.error("invalid submit attempted");
      console.table(invalidData);
    }
  );

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size={"lg"}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>Data Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {isSaving ? <Loading /> : <></>}
          <Container>
            {showPostError ? (
              <Alert
                variant="danger"
                dismissible
                onClose={() => {
                  setPostError(undefined);
                  setShowPostError(false);
                }}
              >
                {postError}
              </Alert>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            {showPostSuccess ? (
              <Alert
                variant="success"
                dismissible
                onClose={() => {
                  setShowPostSuccess(false);
                }}
              >
                Data Request successfully submitted.
              </Alert>
            ) : (
              <></>
            )}
          </Container>
          <Container>
            <Form noValidate onSubmit={onSubmit}>
              <Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Add Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...register("notes", {
                      disabled: isSaving,
                      required: {
                        message: "Please provide notes.",
                        value: true,
                      },
                    })}
                    isInvalid={errors.notes?.type === "required"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.notes?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Group>
            </Form>
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseModal}
          disabled={isSaving}
        >
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={isSaving}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataRequestModalComponent;
