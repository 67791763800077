import { useEffect, useState } from "react";
import { YieldCalculationResults } from "../../external/inventory-component/types";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import {
  Container,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Loading from "../loading";

export interface YieldCalculationProps {
  inventoryId: string;
  authenticatedUserToken: string;
}

const createInventoryWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);

const YieldCalculation = ({
  inventoryId,
  authenticatedUserToken,
}: YieldCalculationProps) => {
  const inventoryWorker = useWorker(createInventoryWorker);
  const [calculationResults, setCalculationResults] =
    useState<YieldCalculationResults | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSmallBox, setIsSmallBox] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const checkBoxSize = () => {
    return window.innerWidth < 600;
  };

  useEffect(() => {
    setIsSmallBox(checkBoxSize());

    // Update box size state on window resize
    const handleResize = () => setIsSmallBox(checkBoxSize());
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async (id: string) => {
      try {
        const result = await inventoryWorker.getYieldCalculation(
          authenticatedUserToken,
          id
        );
        if (result.data) {
          setCalculationResults(result.data);
          setLoading(false);
          setError(null);
        } else if (result.error) {
          setError(result.error.message);
          setCalculationResults(null);
        }
      } catch (err) {
        setError("An unexpected error occurred");
        setLoading(false);
        setCalculationResults(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData(inventoryId);
  }, [inventoryId, authenticatedUserToken, inventoryWorker]);

  const additionalInfo = `
        Parcelforce Girth: <strong>${calculationResults?.pfGirth}</strong>\n
        cbm: <strong>${calculationResults?.cbm}</strong>\n
        One Man Service: <strong>${calculationResults?.oneManService}</strong>\n
        Two Man Service: <strong>${calculationResults?.twoManService}</strong>\n
        `;

  const getBackgroundColor = (exit: string | undefined) => {
    switch (exit) {
      case "Auction":
        return "#f9cb9c";
      case "Retail":
        return "#b6e1cd";
      default:
        return "white";
    }
  };

  const missingAttributes = error;

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Card
            style={{
              backgroundColor: getBackgroundColor(calculationResults?.exit),
            }}
          >
            <Card.Body>
              <Card.Title>Yield Calculation</Card.Title>
              <div
                className={`content-container ${
                  isSmallBox ? "small-box" : "wide-box"
                }`}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <div className="main-content">
                    {isSmallBox ? (
                      error ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="error-tooltip">
                              {missingAttributes}
                            </Tooltip>
                          }
                        >
                          <Card.Text style={{ color: "red" }}>
                            <strong>Incomplete</strong>
                          </Card.Text>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="additional-info-tooltip">
                              <div
                                style={{ whiteSpace: "pre-line" }}
                                dangerouslySetInnerHTML={{
                                  __html: additionalInfo,
                                }}
                              />
                            </Tooltip>
                          }
                        >
                          <Card.Text>
                            Exit: <strong>{calculationResults?.exit}</strong>{" "}
                            <br />
                            Final Size:{" "}
                            <strong>{calculationResults?.finalSize}</strong>
                          </Card.Text>
                        </OverlayTrigger>
                      )
                    ) : error ? (
                      <Card.Text style={{ color: "red" }}>
                        <strong>Incomplete:</strong> {error}
                      </Card.Text>
                    ) : (
                      <>
                        <Card.Text
                          style={{
                            marginBottom: "0.1rem",
                            paddingBottom: "0.1rem",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Exit: <strong>{calculationResults?.exit}</strong>
                        </Card.Text>
                        {!error && (
                          <div className="additional-info">
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              dangerouslySetInnerHTML={{
                                __html: additionalInfo,
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default YieldCalculation;
