import React, { useCallback, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";

import { parse } from "papaparse";
import { ManifestEntry } from "./inbound-manifest";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export interface UploadManifestFileComponentProps {
  setCSVData: (data: ManifestEntry[]) => void;
}

const UploadManifestFileComponent = ({
  setCSVData,
}: UploadManifestFileComponentProps) => {
  const [showRejections, setShowRejections] = useState<boolean>(false);
  const [uploadComplete, setUploadIsComplete] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>();

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      file.text().then((t) => {
        parse<ManifestEntry>(t, {
          complete: function (results) {
            setUploadMessage("Uploading...");
            setCSVData(results.data);
          },
          header: true,
          dynamicTyping: true,
        });
      });
    },
    [setCSVData]
  );

  const {
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  useMemo(() => {
    if (fileRejections && fileRejections.length > 0) {
      setShowRejections(true);
    }
  }, [fileRejections]);

  const uploadStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  return (
    <Container>
      <h1>Manifest Upload</h1>
      <Row className="m-1">
        <Alert variant="success">
          <p>
            <a
              href="/downloads/ManifestTemplate.csv"
              download={"ManifestTemplate_v1.csv"}
            >
              Download the template
            </a>
            , populate it and upload it again here. Select the client below. You
            will be asked to confirm before submission.
          </p>
          <hr />
          <p className="mb-0">
            To create a file in the correct format, download the template, open
            it in Excel, paste in data and make corrections, then save the file
            as a CSV (Save As &gt; File Format &gt; CSV (Comma-delimited)) and
            upload here.
          </p>
        </Alert>
      </Row>
      {fileRejections && fileRejections.length > 0 && showRejections ? (
        <Row className="m-1">
          <Alert
            variant="danger"
            onClose={() => {
              setShowRejections(false);
            }}
            dismissible
          >
            <p className="m-0">
              Invalid file type uploaded! The file must be a CSV file.
            </p>
          </Alert>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-4 m-2">
        <div className="container">
          <div {...getRootProps({ style: uploadStyle })}>
            <input {...getInputProps()} />
            {acceptedFiles && acceptedFiles.length > 0 ? (
              acceptedFiles.map((f, i) => (
                <p style={{ color: "black" }} className="m-3" key={i}>
                  📄 {f.name} ({Math.floor(f.size / 1024)}KB)
                </p>
              ))
            ) : (
              <p className="m-3">
                Drag 'n' drop your populated CSV here, or click to select file
              </p>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <Col className="m-3">
          <Alert
            className={uploadMessage && uploadComplete ? "" : "visually-hidden"}
            variant="success"
            onClose={() => setUploadIsComplete(false)}
            dismissible
          >
            <p>File uploaded successfully.</p>
            {uploadMessage && uploadComplete ? (
              <>
                <hr />
                <p className="mb-0">{uploadMessage}</p>
              </>
            ) : (
              <></>
            )}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadManifestFileComponent;
