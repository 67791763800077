/* eslint-disable jsx-a11y/alt-text */
import React, { CSSProperties, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import { Camera } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";

const img: CSSProperties = {
  aspectRatio: "auto",
  maxHeight: "300px",
};

const UploadImagesDetailedInfoComponent = (): JSX.Element => {
  // TODO: upload the image

  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file, fileIndex) => (
    <Card
      key={`imgcard-${fileIndex}`}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Card.Img src={(file as any).preview} style={img} alt={file.name} />
      <Card.Body>
        <Button
          style={{ width: "100%" }}
          variant="outline-danger"
          onClick={removeFile(file)}
        >
          Remove
        </Button>
      </Card.Body>
    </Card>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
    },
    [files]
  );

  return (
    <Container>
      <Row>
        <Stack className="container">
          <div
            {...getRootProps({ className: "dropzone" })}
            onClick={(e) => e.stopPropagation}
          >
            <input {...getInputProps()} capture="environment" />
            <Button
              type="button"
              className={
                /* Hide button when a file is uploaded */
                files.length > 0 ? "d-none" : ""
              }
              style={{ width: "calc(100% - 20px)", margin: "10px" }}
              onClick={open}
            >
              <Camera size={36}></Camera>
            </Button>
          </div>
          <Stack direction="horizontal">{thumbs}</Stack>
        </Stack>
      </Row>
    </Container>
  );
};

export default UploadImagesDetailedInfoComponent;
