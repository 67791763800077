import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { useContext, useEffect, useState } from "react";
import CatalogUploadedTemplateStatus from "../components/catalog/catalog-uploaded-template-status";
import Loading from "../components/loading";
import { UserAuthenticatedContext } from "../components/profile";
import { CatalogUploadedTemplateStatusRecord, Client } from "../external";
import { getDateString } from "../helpers/datetime";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const createCatalogWorker = createWorkerFactory(
  () => import("../workers/catalog")
);
const createClientWorker = createWorkerFactory(
  () => import("../workers/clients")
);

const CatalogUploadStatusPage = () => {
  const catalogWorker = useWorker(createCatalogWorker);
  const clientsWorker = useWorker(createClientWorker);
  const navigate = useNavigate();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const [statusRecords, setStatusRecords] = useState<
    CatalogUploadedTemplateStatusRecord[]
  >([]);
  const [statusRecordsLoaded, setStatusRecordsLoaded] =
    useState<boolean>(false);

  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);
  const [dateChoices, setDateChoices] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();

  useEffect(() => {
    if (userAuthenticatedContext.token && selectedDate) {
      (async () => {
        const statusRecords =
          await catalogWorker.getCatalogTemplateUploadStatuses(
            userAuthenticatedContext.token!,
            selectedDate
          );
        setStatusRecords(statusRecords);
        console.log(statusRecords);
        setStatusRecordsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, catalogWorker, selectedDate]);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  useEffect(() => {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    console.log("Get date choices");
    const today = new Date();
    const previousDays = 7;
    const dates: string[] = [];

    // Loop backwards so that oldest date is at the start
    for (let i = previousDays; i >= 0; i--) {
      const newDate = new Date(
        new Date().setTime(today.getTime() - dayInMilliseconds * i)
      );
      dates.push(getDateString(newDate));
    }

    setDateChoices(dates);
    setSelectedDate(dates[dates.length - 1]);
  }, []);

  if (!statusRecordsLoaded || !clientsLoaded) {
    return <Loading />;
  }

  return (
    <Container>
      <h1>Uploaded Catalogue templates</h1>
      <Button
        variant="secondary"
        style={{ marginBottom: "1em" }}
        size="sm"
        onClick={() => navigate("/catalog")}
      >
        Return to Catalogue
      </Button>
      <h5>Select the date of upload</h5>
      <ButtonGroup>
        {dateChoices.map((date, i) => (
          <ToggleButton
            type="radio"
            value={date}
            name="date-choice"
            id={`radio-${i}`}
            variant={"outline-success"}
            key={i}
            checked={selectedDate === date}
            onClick={() => {
              if (selectedDate !== date) {
                setStatusRecordsLoaded(false);
                setSelectedDate(date);
              }
            }}
          >
            {date} {i === dateChoices.length - 1 ? "(Today)" : ""}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <CatalogUploadedTemplateStatus
        selectedDate={selectedDate ?? ""}
        clients={clients}
        rows={statusRecords}
      />
    </Container>
  );
};

export default withAuthenticationRequired(CatalogUploadStatusPage, {
  onRedirecting: () => <Loading />,
});
